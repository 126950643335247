import { apiInstance } from "@/utils/ApiUtil.js";
const API_URL = "/target";

const setOneTarget = async (req) => {
  try {
    const response = await apiInstance.post(API_URL, {
      app_id: req.app_id,
      item_id: req.item_id,
      general_id: req.general_id,
      name: req.name,
      target_price: req.target_price,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getTargets = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/", {
      params: {
        app_id: req.app_id,
        page: req.page,
        limit: req.limit,
        search: req.search,
        order_by: req.order_by,
        dir: req.dir
      },
    });

    if (!response || !response.data.success) return false;

    return response.data.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const deleteOne = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/delete", {
      id: req.id,
    });

    if (!response || !response.data.success) return false;

    return response.data.id;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const updateOne = async (req) => {
    try {
      const response = await apiInstance.patch(API_URL + "/", {
        id: req.id,
        price: req.price
      });
  
      if (!response || !response.data.success) return false;
  
      return response.data.id;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  
const TargetService = {
  setOneTarget,
  getTargets,
  deleteOne,
  updateOne
};

export default TargetService;
