<template>
  <div class="background-small">
    <div v-if="!loading" class="row px-2 py-3">
      <div class="col-xl-4 col-lg-3 col-md-12 col-xs-12 f sb">
        <h4 class="text-white mt-2">{{ title }}</h4>
        <div
          class="col-xl-2 col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 f align-bottom"
        >
          <button class="custom_button" @click="fetchBynogame">Bynogame</button>
        </div>
      </div>
      <div class="col-xl-8 col-lg-9 col-md-12 col-xs-12 responsive">
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12">
          <div class="select-menu" @click="toggleDropdown('hold')">
            <span class="text-white text-center">Hold</span>
            <div class="select-btn">
              <span class="sBtn-text">{{ this.selectedHold }}</span>
              <i class="bx bx-chevron-down"></i>
            </div>
            <ul
              class="options"
              v-show="isHoldOpen"
              :class="{ active: isHoldOpen }"
            >
              <li
                v-for="(item, index) in holdOptions"
                :key="index"
                class="option"
                @click="selectOption('hold', item)"
              >
                <span class="option-text p-3">{{ item }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-1">
          <div class="select-menu" @click="toggleDropdown('profit')">
            <span class="text-white text-center">Profit Ratio (%)</span>
            <div class="select-btn">
              <span class="sBtn-text">{{ this.selectedProfitRate }}</span>
              <i class="bx bx-chevron-down"></i>
            </div>
            <ul
              class="options"
              v-show="isProfitOpen"
              :class="{ active: isProfitOpen }"
            >
              <li
                v-for="(item, index) in profitOptions"
                :key="index"
                class="option"
                @click="selectOption('profit', item)"
              >
                <span class="option-text p-2">{{ item }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-1">
          <div class="select-menu" @click="toggleDropdown('volume')">
            <span class="text-white text-center">Volume</span>
            <div class="select-btn">
              <span class="sBtn-text">{{ selectedVolume }}</span>
              <i class="bx bx-chevron-down"></i>
            </div>
            <ul
              class="options"
              v-show="isVolumeOpen"
              :class="{ active: isVolumeOpen }"
            >
              <li
                v-for="(item, index) in volumeOptions"
                :key="index"
                class="option"
                @click="selectOption('volume', item)"
              >
                <span class="option-text p-2">{{ item }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 px-1">
          <div
            style="flex-direction: column; align-items: flex-start"
            class="f"
          >
            <span class="text-white text-center">Enter Page Code</span>
            <textarea
              style="width: 100%"
              type="text"
              rows="2"
              v-model="textAreaContent"
              @keypress.enter="processItems"
            ></textarea>
          </div>
        </div>
        <div
          class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-1 f align-bottom"
        >
          <button class="custom_button" @click="processItems">Submit</button>
        </div>
      </div>
    </div>

    <PaginationBar @move-page="movePage" :pageNumber="currentPage" />

    <div class="row px-2 pb-4">
      <div class="col-lg-12 col-md-12 col-xs-12">
        <div v-if="loading" class="loading-screen">
          <DotLoader :loading="loading" :width="'50px'" />
        </div>
        <div v-if="!loading" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th
                  v-for="column in columns"
                  :key="column"
                  @click="setOrder(column)"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in pagedItems" :key="index">
                <td @click="showItemModal(item)">
                  <img
                    v-if="item.img && !item.img.toUpperCase().includes('NULL')"
                    :src="item.img"
                  />
                  <img v-else src="../assets/images/csgo.png" />
                </td>
                <td :style="{ color: item.color }">
                  <div class="sb f">
                    <div class="f flex-column flex-start">
                      <div>
                        <span class="body-title">{{ item.name }}</span>
                      </div>

                      <a
                        :href="item?.mininimumPrice?.url"
                        target="_blank"
                        class="body-title href"
                        >Min. Price: {{ item?.mininimumPrice?.market_name }} >
                        ${{
                          (item?.mininimumPrice?.price / 1000).toFixed(2)
                        }}</a
                      >
                    </div>
                    <button
                      @click="copy(item.name)"
                      aria-label="Copy"
                      class="btn_copyname"
                    >
                      <VueIcon height="22px" color="white" icon="bx:copy" />
                    </button>
                  </div>
                </td>
                <td>
                  {{ item.hold }} {{ item.holdType === "h" ? "Hours" : "Days" }}
                </td>
                <td>$ {{ item?.itemPrice }}</td>

                <td v-for="(market, index) in item.markets" :key="index">
                  <div class="inner_td">
                    <span>Price: ${{ market.price?.toFixed(2) }}</span>
                    <span :style="{ color: market.profit > 0 ? green : 'red' }">
                      Profit: ${{ market.profit?.toFixed(2) }}</span
                    >
                    <span :style="{ color: market.profit > 0 ? green : 'red' }"
                      >%{{ market.ratio?.toFixed(2) }}</span
                    >
                    <span>Volume: {{ market.volume }}</span>
                  </div>
                </td>
                <td>
                  <button
                    class="button btn-secondary"
                    @click="goExternalUrl(item.link)"
                  >
                    Buy
                  </button>
                </td>
                <td>
                  <button
                    class="button btn-secondary"
                    @click="goToLink(item.name)"
                  >
                    <img
                      style="width: 20px; height: 20px"
                      src="../assets/images/external-link.png"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="infoMessage"
    @close="showModal = false"
  />
  <LisItemDetailModal
    v-if="selectedItem"
    :item="selectedItem"
    @close="closeItemModal"
  />
</template>
<script>
import InfoModal from "../components/modal/InfoModal.vue";
import LisService from "../services/LisService.js";
import buffIcon from "../assets/marketIcons/buff_icon.png";
import waxIcon from "../assets/marketIcons/waxpeer_icon.png";
import LisItemDetailModal from "../components/modal/LisItemDetailModal.vue";

import { extractItems } from "../utils/parserUtil.js";

export default {
  data() {
    return {
      green: "rgb(63, 217, 134)",
      statsLoading: true,
      loading: false,
      showModal: false,
      selectedItem: null,
      infoMessage: "Items added successfully",
      textAreaContent: "",
      searchTimeout: null,
      searchTerm: "",
      title: "Calculations",
      items: [],
      itemsPerPage: 10,
      currentPage: 1,
      values: null,
      startDate: new Date(),
      endDate: "",
      columns: [
        { name: "Image", code: "" },
        { name: "Name", code: "" },
        { name: "Hold", code: "" },
        { name: "Buy Price", code: "buy_price" },
        { name: "Waxpeer", code: "profit" },
        { name: "MarketCSGO", code: "profit" },
        { name: "Shadowpay", code: "profit" },
        { name: "CSFloat", code: "profit" },
        { name: "HaloSkins", code: "profit" },
        { name: "Lis-Skins", code: "" },
        { name: "Details", code: "" },
      ],
      marketOptions: [
        { name: "Waxpeer", icon: waxIcon },
        { name: "MarketCSGO", icon: waxIcon },
        { name: "Shadowpay", icon: waxIcon },
        { name: "CSFloat", icon: waxIcon },
        { name: "HaloSkins", icon: waxIcon },
      ],
      dropName: "Price Provider",
      selectedIcon: buffIcon,
      isProfitOpen: false,
      selectedProfitRate: 200,
      profitOptions: [5, 10, 15, 20, 25, 30, 35, 50, 100, 200],
      isHoldOpen: false,
      selectedHold: 8,
      holdOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      volumeOptions: [1, 5, 10, 20, 50, 100],
      selectedVolume: 1,
      isVolumeOpen: false,
      selectedMarket: "Buff.163",
      selectedId: 0,
      isActive: false,
      sortColumn: "Waxpeer",
      sortDirection: "desc",
      game: "cs2",
      type: "profit",
      marketType: "lisskins",
      bynoFilter: {
        page: 1,
        limit: 100,
        price_from: 1000,
      },
    };
  },
  methods: {
    goExternalUrl(link) {
      window.open(link, "_blank");
    },
    copy(text) {
      navigator.clipboard.writeText(text);
    },
    showItemModal(item) {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "hidden";
      this.selectedItem = item;
    },
    closeItemModal() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "auto";
      this.selectedItem = null;
    },
    goToLink(name) {
      const route = this.$router.resolve({
        name: "item",
        params: { game: this.game, name: name },
      });
      window.open(route.href, "_blank");
    },
    toggleDropdown(str) {
      if (str === "hold") {
        this.isHoldOpen = !this.isHoldOpen;
      }
      if (str === "profit") {
        this.isProfitOpen = !this.isProfitOpen;
      }
      if (str === "volume") {
        this.isVolumeOpen = !this.isVolumeOpen;
      }
    },
    selectOption(type, option) {
      if (type === "hold") {
        this.selectedHold = option;
      }
      if (type === "profit") {
        this.selectedProfitRate = option;
      }
      if (type === "volume") {
        this.selectedVolume = option;
      }
    },
    async setMarket(option) {
      this.selectedMarket = option.name;
      this.selectedIcon = option.icon;
    },
    async infoModalToggle() {
      this.showModal = true;
      this.infoMessage = "Items Retrieved Successfully!";
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    setOrder(item) {
      this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
      this.sortColumn = item.name;
      this.type = item.code;
    },
    async processItems() {
      try {
        this.marketType = "lisskins";
        this.loading = true;
        this.currentPage = 1;
        localStorage.setItem("lis-items", "");
        const sanitizedHtmlCode = JSON.parse(this.textAreaContent);
        const items = extractItems(sanitizedHtmlCode.skins);

        const response = await LisService.getProfits({ items: items });

        localStorage.setItem("lis-items", JSON.stringify(response));
        this.textAreaContent = "";
        this.items = response;
      } catch (error) {
        console.log(error);
        this.textAreaContent = "";
        this.items = [];
        localStorage.setItem("lis-items", JSON.stringify([]));
      } finally {
        this.infoModalToggle();
        this.loading = false;
      }
    },
    async fetchBynogame() {
      this.marketType = "bynogame";
      const res = await LisService.getBynogameProfits({
        page: this.bynoFilter.page,
        limit: this.bynoFilter.limit,
        price_from: this.bynoFilter.price_from,
      });

      if (!res) return false;

      this.items = res;
      localStorage.setItem("bynogame-items", JSON.stringify(res));
    },
    async movePage(pageCount) {
      this.currentPage += pageCount;
      this.currentPage < 1 ? (this.currentPage = 1) : this.currentPage;
      this.bynoFilter.page = this.currentPage;
      if (this.marketType == "bynogame") {
        await this.fetchBynogame();
      }
    },
    initialData() {
      try {
        this.marketType = "lisskins";
        this.loading = true;
        this.selectedMarket = this.marketOptions[1].name;
        const storedItemArrayJSON = localStorage.getItem("lis-items");
        this.items = JSON.parse(storedItemArrayJSON);
        this.loading = false;
      } catch (error) {
        this.items = [];
        localStorage.setItem("lis-items", JSON.stringify([]));
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.initialData();
  },
  computed: {
    processedItems() {
      if (!this.items) return;
      return this.items.map((item) => {
        const markets = this.marketOptions.map((option) => {
          if (!item.markets || item.markets.length === 0) {
            return {
              name: option.name,
              general_fee: 0,
              price: 0,
              net: 0,
              profit: 0,
              ratio: 0,
              volume: 0,
              buy_price: 0,
            };
          }
          const market = item.markets.find((m) => m.name === option.name);
          if (market) return market;
          else
            return {
              name: option.name,
              general_fee: 0,
              price: 0,
              net: 0,
              profit: 0,
              ratio: 0,
              volume: 0,
              buy_price: 0,
            };
        });
        return { ...item, markets };
      });
    },
    pagedItems() {
      if (!this.items) return [];
      return this.marketType === "bynogame"
        ? this.pagedBynoItems
        : this.pagedLisItems;
    },
    pagedBynoItems() {
      if (!this.items) return;

      let sortedItems = this.processedItems.slice().sort((a, b) => {
        const multiplier = this.sortDirection === "asc" ? -1 : 1;
        const marketA = a.markets.find(
          (market) => market.name === this.sortColumn
        );
        const marketB = b.markets.find(
          (market) => market.name === this.sortColumn
        );

        if (this.type == "buy_price") {
          return (b.itemPrice - a.itemPrice) * multiplier;
        } else if (this.type == "profit") {
          return (marketB.ratio - marketA.ratio) * multiplier;
        } else {
          return (a.name - b.name) * multiplier;
        }
      });

      return sortedItems;
    },
    pagedLisItems() {
      if (!this.items) return;
      const selectedVolume = this.selectedVolume; // Assuming selectedVolume is defined in your component

      let sortedItems = this.processedItems.slice().sort((a, b) => {
        const multiplier = this.sortDirection === "asc" ? -1 : 1;
        const marketA = a.markets.find(
          (market) => market.name === this.sortColumn
        );
        const marketB = b.markets.find(
          (market) => market.name === this.sortColumn
        );

        if (this.type == "buy_price") {
          return (b.itemPrice - a.itemPrice) * multiplier;
        } else if (this.type == "profit") {
          return (marketB.ratio - marketA.ratio) * multiplier;
        } else {
          return (a.name - b.name) * multiplier;
        }
      });

      sortedItems.sort((a, b) => {
        a.holdGroup - b.holdGroup;
      });

      sortedItems = sortedItems.filter((item) => {
        const marketCSGO = item.markets.find(
          (market) => market.name === "MarketCSGO"
        );
        return marketCSGO && marketCSGO.volume > selectedVolume;
      });

      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return sortedItems.slice(startIndex, endIndex);
    },
  },
  components: {
    InfoModal,
    LisItemDetailModal,
  },
};
</script>

<style scoped>
.href {
  color: yellow;
  text-decoration: none;
}

th:hover {
  cursor: pointer;
}

.responsive {
  display: flex;
}

.inner_td {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.select-menu {
  transition: 0.5s;
}

.select-menu .select-btn {
  display: flex;
  height: 44px;
  background: #313156;
  padding: 20px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 500;
  color: white;
  transition: 0.5s;
  border: 1px rgb(134, 80, 172) solid;
}

.select-btn:hover {
  background-color: #6767a3;
}

.select-btn i {
  font-size: 25px;
  transition: 0.3s;
}

.select-menu.active .select-btn i {
  transform: rotate(-180deg);
  transition: 0.3s;
}

.select-menu .options {
  position: absolute;
  padding: 5px;
  width: 200px;
  max-height: 250px;
  overflow-y: auto;
  margin-top: 5px;
  border-radius: 4px;
  background: #313156;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  width: 120px;
  border: 1px solid rgb(134, 80, 172);
}

.select-menu.active .options {
  display: block;
  z-index: 999;
}

.options .option {
  display: flex;
  gap: 10px;
  height: 40px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 2px;
  align-items: center;
  background: #313156;
}

.options .option:hover {
  background: #7878af;
}

.option img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.option .option-text {
  font-size: 2vh;
  font-weight: 500;
  color: white;
}

.select-menu {
  transition: 0.5s;
}

.sBtn-text {
  color: white;
  font-size: 2vh;
}

@media screen and (max-width: 992px) {
  .responsive {
    flex-direction: column;
    gap: 5px;
  }
}
</style>
