<template>
  <div class="shimmer-container">
    <div class="w-100 f flex-column als fls" style="align-items: flex-start">
      <div class="shimmer-name">
        <span class="name gray"> {{ item.title }}</span>
      </div>
      <div class="shimmer-name">
        <span class="value"> {{ item.value }}</span>
      </div>
    </div>
    <div class="shimmer-img">
      <VueIcon :icon="item.icon" width="30px" color="white" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  components: {},
};
</script>

<style scoped>
.shimmer-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #21242c;
  border-radius: 0.2rem;
  margin-block: 0rem;
  padding: 1rem 1rem;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  gap: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
}

.shimmer-container:hover {
  transition: 0.1s;
  background-color: #2a2a49;
  transition: 0.3s;
  border: 2px solid #5a3d92;
}

.rank {
  font-size: 18px;
  font-weight: 900;
  color: #e0e005;
  position: absolute;
  left: 5px;
  top: 5px;
}

.shimmer-img {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shimmer-name {
  width: 100%;
  text-overflow: ellipsis;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shimmer-price {
  height: 20px;
  width: 80px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_avatar {
  cursor: pointer;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: white;
}

.name {
  font-size: 13px;
}

.value {
  font-size: 16px;
  font-weight: bold;
  color: rgb(76, 223, 153);
}

@media only screen and (max-width: 745px) {
  .shimmer-img {
    height: 60px;
    width: 60px;
  }

  .shimmer-container {
    padding: 0.6rem 2.4rem;
    position: relative;
    transition: 0.3s;
    min-height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}

@keyframes shimmer {
  to {
    background-position: 200% 0;
  }
}
</style>
