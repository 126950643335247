import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/marketCSGO";

export const getItemHistory = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/item-sale-history", {
            name: req.name
        });
        if (!response) return false;
        if (!response.data.success) return false;
        return response.data;
    } catch (error) {
        return [];
    }
};

const MarketCSGOService = {
    getItemHistory,
};

export default MarketCSGOService;
