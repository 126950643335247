<template>
  <div class="background-small">
    <div class="row py-3 px-2 f align-center" style="justify-content: center">
      <CollectionInfo :item="collectionInfo" />
    </div>
    <div class="wrapper px-3">
      <div v-if="itemLoading" class="f flex-column g10">
        <ShimmerEffect v-for="index in 6" :key="index" />
      </div>
      <div v-if="!itemLoading && items && items.length > 0">
        <div class="collection_grid py-2">
          <CollectionCard
            @go-case="goCase"
            @go-item="goItem"
            v-for="(item, index) in items"
            :key="index"
            :item="item"
          />
        </div>
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
</template>
<script>
/* eslint-disable */
import InfoModal from "../components/modal/InfoModal.vue";
import CollectionCard from "@/components/cards/CollectionCard.vue";
import CollectionInfo from "@/components/collection/CollectionInfo.vue";

import ItemService from "@/services/ItemService.js";

import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";

import DateUtils from "@/utils/DateUtils.js";
import steamIcon from "../assets/images/steam-white.png";
import edit from "../assets/images/edit.png";
import tick from "../assets/images/tick-white.png";
import moment from "moment";

import allIcon from "../assets/images/all.png";

export default {
  data() {
    return {
      isMobile: false,
      title: "Collection",
      stats: {
        total: 0,
      },
      collectionInfo: {},
      items: [],
      searchQuery: "",
      loading: true,
      infoLoading: false,
      itemLoading: true,
      isDropdownOpen: false,
      steamIcon: steamIcon,
      editIcon: edit,
      tickIcon: tick,
      searchTimeout: null,
      showModal: false,
      filterObject: {
        pageSize: 10,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        market: "all",
      },
      info: [],
      selectedIcon: allIcon,
      currentTime: moment().now,
      orderOptions: ["Price", "Name", "Rarity"],
      selectedOrder: "Price",
      selectedDirection: "DESC",
      collectionName: "",
      collectionID: null,
      appID: "cs2",
    };
  },
  methods: {
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
    },
    async showInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 1500);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async handleSearch() {
      this.filterObject.pageNumber = 1;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getHistory();
      }, 1000);
    },
    async getItems() {
      this.itemLoading = true;
      const response = await ItemService.getItemCollection({
        collectionID: this.collectionID,
      });

      if (!response) {
        this.itemLoading = false;
        return (this.items = []);
      }

      this.items = response.items;
      this.collectionInfo.count = response.count;
      this.itemLoading = false;
    },
    async getCollectionInfo() {
      const response = await ItemService.getItemCollectionInfo({
        collectionID: this.collectionID,
      });
      if (!response) return;
      this.collectionInfo = response;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 1000;
    },
    async getData() {
      Promise.all([this.getCollectionInfo(), this.getItems()]);
    },
    goItem(item) {
      this.$router.push({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
    },
    goCase(name, id) {
      this.$router.push({
        name: "case",
        params: { name: name, game: this.appID },
        query: { id: id },
      });
    },
  },
  async created() {
    try {
      this.collectionName = this.$route.params.name;
      this.collectionID = this.$route.query.id;
      await this.getData();
      this.$store.dispatch("router/updateTitle", this.collectionName);
    } catch (err) {
      console.error(err);
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  components: {
    CollectionCard,
    CollectionInfo,
    InfoModal,
    ShimmerEffect,
    IconDropdown,
    SortDropdown,
    SelectorDropdown,
  },
};
</script>
<style scoped>
@import "../assets/style/customTable.css";

.collection_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.filter-div-mobile {
  display: none;
}

.filter-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-div .filter-left {
  display: flex;
  gap: 10px;
}

.filter-div .right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-icon {
  height: 20px;
  width: 20px;
}

.filter-div .left {
  padding-left: 0px;
}

@media screen and (max-width: 728px) {
  .filter-div-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
