<template>
  <div class="community_block">
    <div class="info_left">
      <div @click="this.$emit('go-user', item.steam_id)" class="f align-center g10">
        <span class="rank">#{{ item.rank }}</span>
        <div class="img_block">
          <img v-if="item.image" class="active_avatar" :src="item.image" />
        </div>
      </div>
      <div class="info_right">
        <span class="name"> {{ item.name }}</span>
        <span class="value"
          >
          {{ 
            (item.value/1000).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }}</span
        >
      </div>
    </div>
    <div class="steam_linkage">
      <button
        @click="this.$emit('goLink', item.profile_url)"
        class="btn_copyname"
      >
        <VueIcon height="25px" color="white" icon="mdi:steam" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  components: {},
};
</script>

<style scoped>
.gray_body {
  color: #989a9b;
  font-size: 2vh;
}

.img_block {
  text-shadow: 4px 4px 4px black;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.img_block img {
  z-index: 1;
}

.img_block span {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.community_block {
  position: relative;
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  background-color: #21242c;
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  gap: 10px;
  width: 100%;
  border: 2px solid transparent;
  max-height: 100px;
}

.community_block:hover {
  transition: 0.2s;
  transform: scale(1.01);
  background-color: #2a2a49;
  transition: 0.3s;
  border: 2px solid #5a3d92;

}

.steam_linkage {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.user_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0rem;
  width: 100%;
}

.info_wrapper span {
  font-weight: bold;
}

.info_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.rank {
  font-size: 14px;
  font-weight: bold;
  color: #e0e005;
}

.value {
  font-size: 15px;
  font-weight: bold;
  color: rgb(76, 223, 153);
}

.info_right {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.info_wrapper:hover img {
  transition: 0.5s;
  transform: scale(1.1);
}

.name {
  font-size: 11px;
  font-size: 400;
  color: white;
  text-align: center;
}

.info_wrapper:hover .gray_body {
  color: #000000;
}

.info_wrapper:hover .name {
  color: #000000;
}

.community_block p {
  color: white;
}

.info_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: rgb(62, 61, 61);
  border-radius: 4px;
  padding: 0.6rem;
}

.info_wrapper:hover {
  background-color: #f8b632;
  cursor: pointer;
  transition: 0.5s;
}

.active_avatar {
  cursor: pointer;
  height: 3.4rem;
  width: 3rem;
  border-radius: 4px;
  background-color: white;
}
</style>
