<template>
  <div class="background-small">
    <div class="main-wrapper">
      <div class="row">
        <h4 class="text-white">User Details</h4>
      </div>
      <div class="row f py-4">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 f flex-column g20">
          <section class="section">
            <div class="section-header f">
              <div class="f lside">
                <div class="wh32 icon">
                  <img class="img-small-custom circle" :src="getUserAvatar" />
                </div>
                <h5 class="profile_name">
                  {{ getUsername }}
                </h5>
              </div>
            </div>
            <hr class="gray-line" />
            <div class="section-body">
              <div class="balance-div">
                <div class="nw small-gray-text">My Market Balances</div>
              </div>
              <div v-if="balanceItems" class="f flex-column g10 balances">
                <div
                  class="amount-wrapper"
                  v-for="(item, index) in balanceItems"
                  :key="index"
                >
                  <div style="width: 100px;">
                    <span style="padding: 0px;" class="gray mid-span">{{ item.name }} &#160; </span>
                  </div>
                  <div>
                    <span :style="{ color: green, padding: '0' }" class=" mid-span bold">
                      {{
                        item.value
                          ? item.value.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "$0.00"
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="section settings">
            <div class="section-header f">
              <div class="f lside">
                <div class="wh32 icon">
                  <img class="img-small-custom" :src="icons[0]" />
                </div>
                <h5 class="profile_name">Settings</h5>
              </div>
            </div>
            <hr class="gray-line" />
            <div class="f title align-center">
              <div class="wh32 icon">
                <img class="img-small-custom" :src="icons[1]" />
              </div>
              <h6 class="profile_name">Email</h6>
            </div>
            <div class="toolbar">
              <div class="inp">
                <input
                  v-model="this.mail"
                  type="text"
                  @click="copyText()"
                  autocomplete="off"
                  class="form_input"
                />
              </div>
              <button @click="setUserMail" class="button btn-secondary">
                {{ this.buttonSet }}
              </button>
            </div>
          </section>
          <section class="section settings">
            <div class="section-header f gap8">
              <div class="f lside">
                <div class="wh32 icon">
                  <img class="img-small-custom" :src="icons[0]" />
                </div>
                <h5 class="profile_name">Last Sessions</h5>
              </div>
            </div>
            <hr class="gray-line" />
            <div v-if="sessions.length > 0">
              <div
                style="width: 100%"
                class="flex-column session"
                v-for="(item, index) in sessions"
                :key="index"
              >
                <span v-if="item.parsedInfo != undefined">
                  Device: {{ item.parsedInfo.device ?? "" }}
                  {{ item.parsedInfo.os ?? "" }}
                </span>
                <div class="session-info">
                  <span class="">IP: {{ item.ip }} </span>
                  <span>Date: {{ item.session_start }} </span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 f flex-column g20">
          <section class="section">
            <div class="section-header f fw sb">
              <div class="f lside">
                <div class="wh32 icon">
                  <img class="img-small-custom" :src="icons[2]" />
                </div>
                <h5 class="profile_name">Steam Account</h5>
              </div>
              <hr class="gray-line" />
              <div class="rside">
                <button @click="copySteamId()" class="btn-steamid btn-dark g10">
                  <p class="responsive-title-font ell">
                    {{ this.steamIdText }}{{ this.steamId }}
                  </p>
                  <div>
                    <VueIcon height="15px" color="white" icon="bx:copy" />
                  </div>
                </button>
              </div>
            </div>
            <hr class="gray-line" />
            <div class="f title align-center">
              <div class="wh32 icon">
                <img class="img-small-custom" :src="icons[4]" />
              </div>
              <h6 class="profile_name">Steam Trade Link</h6>
            </div>
            <div class="toolbar">
              <div class="inp">
                <input
                  v-model="this.tradeLink"
                  type="text"
                  onclick="this.select();"
                  autocomplete="off"
                  class="form_input"
                />
              </div>
              <div>
                <a
                  class="button btn-secondary"
                  :href="
                    'https://steamcommunity.com/profiles/' +
                    this.steamId +
                    '/tradeoffers/privacy#trade_offer_access_url'
                  "
                  target="_blank"
                >
                  {{ this.getButtonText }}
                </a>
                <button
                  @click="this.setUserTradeLink('trade')"
                  class="button btn-secondary"
                >
                  {{ this.setButtonText }}
                </button>
              </div>
            </div>
          </section>

          <section class="section profile_steam">
            <div class="section-header f">
              <div class="f lside">
                <div class="wh32 icon">
                  <img class="img-small-custom" :src="icons[5]" />
                </div>
                <h5 class="profile_name">
                  {{ apiText }}
                </h5>
              </div>
            </div>
            <hr class="gray-line" />
            <div v-for="(item, index) in apiObjects" :key="index">
              <div class="f title align-center">
                <div class="wh32 icon">
                  <img class="img-small-custom" :src="item.icon" />
                </div>
                <h6 class="profile_name">{{ item.name }}</h6>
              </div>
              <div class="toolbar">
                <div class="inp">
                  <input
                    v-model="item.text"
                    type="text"
                    autocomplete="off"
                    class="form_input"
                  />
                </div>
                <button
                  @click="setUserApi(item.market, item.text)"
                  class="button btn-secondary"
                >
                  {{ this.setButtonText }}
                </button>
              </div>
            </div>

            <p class="danger-text">{{ warn }}</p>
          </section>
        </div>
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
</template>
<script>
import { mapGetters } from "vuex";
import UserService from "@/services/UserService";
import InfoModal from "@/components/modal/InfoModal.vue";
import settingsIcon from "../assets/images/setting.png";
import mailIcon from "../assets/images/mail.png";
import steamIcon from "../assets/images/steam-gray.png";
import copyIcon from "../assets/images/copy-white.png";
import linkIcon from "../assets/images/link.png";
import marketIcon from "../assets/images/marketplace.png";
import csgotmIcon from "../assets/marketIcons/csgotm_icon.png";
import shadowIcon from "../assets/marketIcons/shadowpay_icon.png";
import waxIcon from "../assets/marketIcons/waxpeer_icon.png";
import csfloat from "../assets/marketIcons/csgofloat_icon.png";
import haloIcon from "../assets/marketIcons/haloskins_icon.png";
import lisskinsIcon from "../assets/marketIcons/lisskins_icon.png";
import skinthunderIcon from "../assets/marketIcons/skinthunder.png";

export default {
  data() {
    return {
      loading: true,
      showModal: false,
      icons: [
        settingsIcon,
        mailIcon,
        steamIcon,
        copyIcon,
        linkIcon,
        marketIcon,
        waxIcon,
        csgotmIcon,
        shadowIcon,
        csfloat,
        haloIcon,
        skinthunderIcon,
      ],
      green: "#10e862",
      modalMessage: "Operation Successfull",
      user_name: "",
      steamId: "",
      tradeLink: "",
      apiText: "Market API Integration",
      getButtonText: "Get",
      setButtonText: "Save",
      steamIdText: "SteamID: ",
      mail: "",
      danger: "Please, never share your API key!",
      warn: "Your API keys will be kept encrypted in our database.",
      buttonSet: "Set",
      sessions: [],
      balanceItems: [],
      apiObjects: [
        { name: "Waxpeer", icon: waxIcon, market: "waxpeer", text: "*****" },
        {
          name: "MarketCSGO",
          icon: csgotmIcon,
          market: "marketcsgo",
          text: "*****",
        },
        {
          name: "ShadowPay",
          icon: shadowIcon,
          market: "shadowpay",
          text: "*****",
        },
        { name: "CSFloat", icon: csfloat, market: "csfloat", text: "*****" },
        {
          name: "HaloSkins",
          icon: haloIcon,
          market: "haloskins",
          text: "*****",
        },
        {
          name: "Lis-Skins",
          icon: lisskinsIcon,
          market: "lisskins",
          text: "**************",
        },
        {
          name: "SkinThunder",
          icon: skinthunderIcon,
          market: "skinthunder",
          text: "**************",
        },
      ],
    };
  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.mail);
    },
    copySteamId() {
      navigator.clipboard.writeText(this.steamId);
    },
    async getUserBalances() {
      const response = await UserService.getUserBalances();
      if (!response) this.balanceItems = [];
      this.balanceItems = response;
      const total = this.balanceItems.reduce((acc, num) => acc + num.value, 0);
      this.balanceItems.push({ name: "Total", value: total });
    },
    async getUser() {
      const res = await UserService.getUserDetails();
      if (!res) return;
      this.steamId = res.steam_id;
      this.user_name = res.user_name;
      this.mail = res.mail;
      this.tradeLink = res.trade_link;
    },
    async getSessions() {
      const res = await UserService.getUserSessions();
      if (!res) return (this.sessions = []);

      this.sessions = res.map((session) => {
        const parsedInfo = this.parseUserAgent(session.device);
        return {
          ...session,
          parsedInfo: parsedInfo,
        };
      });
    },
    parseUserAgent(userAgentString) {
      const result = {
        device: null,
        os: null,
      };

      const match = userAgentString.match(/\((.*?)\)/);
      if (match) {
        const info = match[1];
        const parts = info.split("; ");

        if (parts.length >= 2) {
          result.device = parts[0];
          result.os = parts[1];
        }
      }
      return result;
    },
    async getUserApi() {
      const apiPack = await UserService.getUserApis();

      this.apiObjects.forEach((e) => {
        if (e.name.toLowerCase() in apiPack) {
          e.text = apiPack[e.name.toLowerCase()];
        }
      });
    },
    async setUserApi(market, key) {
      this.toggleLoading();
      const response = await UserService.setUserApi({
        market: market,
        secret: key,
      });
      this.toggleLoading();
      if (!response) {
        return (this.modalMessage = "Try Again Later!");
      }
      this.modalMessage = "API Changed Successfully!";
      this.showInfoModal();
    },
    async setUserMail() {
      this.toggleLoading();
      const response = await UserService.setUserMail({ mail: this.mail });
      this.toggleLoading();
      if (!response) {
        return (this.modalMessage = "Try Again Later!");
      }
      this.modalMessage = "Mail Changed Successfully!";
      this.showInfoModal();
    },
    async setUserTradeLink() {
      this.toggleLoading();
      const response = await UserService.setUserTradeLink({
        trade: this.tradeLink,
      });
      this.toggleLoading();
      if (!response) {
        return (this.modalMessage = "Try Again Later!");
      }
      this.modalMessage = "Tradelink Changed Successfully!";
      this.showInfoModal();
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    async showInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 3000);
    },
    async createdConfig() {
      this.loading = true;

      await Promise.all([
        this.getUserBalances(),
        this.getUser(),
        this.getUserApi(),
        this.getSessions(),
      ]);

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters("user", ["getUsername", "getUserAvatar"]),
  },
  async created() {
    await this.createdConfig();
  },
  components: { InfoModal },
};
</script>
<style scoped>
p {
  margin-bottom: 0px;
}

.responsive-title-font {
  margin-bottom: 0px;
}

.danger-text {
  margin-bottom: 0;
}

h6 {
  color: white;
  margin-bottom: 0px;
  margin:0px
}

h5 {
  margin-bottom: 0px;
  margin:0px
}

.session {
  padding: 0.8rem 1.4rem;
  border-bottom: 1px solid #475057;
  border-radius: 0;
  margin-block: 5px;
}

.session:hover {
  transition: 0.3s;
  background-color: #45457e;
}

.session-info {
  display: flex;
  justify-content: space-between;
}

.lside {
  align-items: center;
}

.img-small-custom {
  width: 25px;
  height: 25px;
}

.copy-img {
  width: 20px;
  height: 20px;
}

h5 {
  margin-bottom: 0px;
}

.profile_name {
  color: white;
  margin-bottom: 0px;
}

.main-wrapper {
  position: relative;
  transition: top 0.3s;
  height: 90%;
}

.container {
  width: 100%;
  height: 100%;
}

.user-wrap {
  font-size: 18px;
  line-height: 180%;
  height: 100%;
  align-items: flex-start;
}

.left-side {
  width: 50%;
  height: 100%;

  margin-right: 4px;
}

.right-side {
  width: 50%;
  height: 100%;
  margin-left: 4px;
}

.profile-main {
  display: block;
  border-radius: 0.8rem;
  margin: 8px 4px;
  padding: 24px;
  color: bisque;
}

.section {
  border-radius: 0.1rem;
  padding: 24px;
  background-color: #313163a5;
  min-height: 200px;
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.icon .img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  color: transparent;
  border: 0;
  border-radius: 3.2rem;
}

.wh32 {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  margin-right: 0px;
  aspect-ratio: 1;
}

.profile_wrap .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.2rem;
  font-size: 18px;
  margin-right: 25px;
}

.f .title {
  margin: 0.8rem 0 0.2rem;
}

.profile_steam .f {
  align-items: center;
  justify-content: flex-start;
}

.toolbar {
  display: flex;
  align-items: center;
  width: calc(100% + 1rem);
  margin: 0 -0.5rem;
}

/* */
.inp {
  flex-grow: 10;
  height: 2rem;
  background-color: #171717;
  border-radius: 0.4rem;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}

.fg {
  flex-grow: 1;
}

.form_input {
  border: none;
  box-sizing: border-box;
  height: 2rem;
  transition: 0.3s;
  max-width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white;
  background-color: #171717;
  outline: none;
  font-size: 12px;
  width: 100%;
  padding-top: 10px;
}

.button {
  text-decoration: none;
  order: 0;
  padding: 0;
  border-radius: 0.2rem;
  height: 35px;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.2;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
}

.btn-secondary {
  background-color: #212121;
  border: 1px solid rgb(100, 99, 99);
  padding: 0.1rem 1.6rem;
  height: 2rem;
  color: white;
}

.profile_steam .button {
  margin: 0;
  text-align: center;
  white-space: nowrap;
  flex-grow: 1;
}

.danger-text {
  margin-top: 4px;
  color: orange;
  font-size: 13px;
}

.small-gray-text {
  font-size: 15px;
  font-weight: 600;
  color: white;
}

.btn-steamid {
  transition: 0.4s;
  display: inline-flex;
  border-radius: 5px;
  height: 2rem;
  padding: 0.1rem 1rem;
  align-items: center;
  border: none;
}

.btn-dark {
  background: #333333;
  padding: 0rem 0.6rem;
  height: 2rem;
  box-shadow: none;
  border: none;
}

.btn-steamid .wh32 {
  min-width: 1.2rem;
  height: 1.2rem;
  margin-left: 10px;
  margin-bottom: 5px;
}

.btn-steamid .img {
  border-radius: 0rem;
  margin-bottom: 5px;
}

.ell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gap30 {
  grid-gap: 3rem;
  gap: 3rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #475057;
}



.balance-div {
  border-top: 1px solid
    hsl(var(--primary-h), var(--primary-s), var(--primary-l-19));
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.nw {
  white-space: nowrap;
}

.amount-wrapper {
  display: flex;
  align-items: center;
}

.amount {
  display: flex;
  align-items: center;
}

.settings .f {
  align-items: center;
  justify-content: flex-start;
}

.section-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
