<template>
  <div class="table-responsive">
    <table class="table align-items-center">
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody v-if="items && items.length > 0">
        <tr @click="goLink(item)" v-for="(item, index) in items" :key="index">
          <td>
            <div>
              <img class="icon-mid item" :src="item.image" alt="Item Img" />
            </div>
          </td>
          <td class="">
            <div class="d-flex">
              <div class="ms-2">
                <h6
                  class="mid_span"
                  :style="{ color: item.typeColor, fontSize: '13px' }"
                >
                  {{ getType(item) }}
                </h6>
              </div>
            </div>
            <div class="d-flex">
              <div class="ms-2">
                <h6 style="font-size: 14px" class="mb-0">
                  {{ item.short_name }}
                </h6>
              </div>
            </div>
            <div class="d-flex">
              <div class="ms-2">
                <h6
                  style="font-size: 13px"
                  :style="{ color: item.exterior_color || 'white' }"
                  class="mb-0"
                >
                  {{ item.exterior }}
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div class="text-center">
              <h6
                class="mb-0"
                :style="{ color: item.profit > 0 ? '#AAF683' : 'red' }"
              >
                $ {{ item.profit }}
              </h6>
            </div>
          </td>
          <td>
            <div class="text-center">
              <h6
                class="mb-0"
                :style="{ color: item.profit_rate > 0 ? '#AAF683' : 'red' }"
              >
                {{ item.profit_rate }}%
              </h6>
            </div>
          </td>
          <td class="align-middle">
            <div class="text-center col">
              <img
                class="icon-mid"
                :src="getMarketIcon(item.sell_market)"
                alt="Item Img"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import buffIcon from "@/assets/marketIcons/buff_icon.png";
import waxpeerIcon from "@/assets/marketIcons/waxpeer_icon.png";
import marketIcon from "@/assets/marketIcons/csgotm_icon.png";
import shadowIcon from "@/assets/marketIcons/shadowpay_icon.png";
import floatIcon from "@/assets/marketIcons/float_icon.png";

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      columns: ["Image", "Name", "Profit", "ROI", "Market"],
      typeColor: "white",
    };
  },
  methods: {
    getType(item) {
      if (item.is_stattrak) {
        item.typeColor = "#CF6830";
        return "ST™";
      }
      if (item.is_souvenir) {
        item.typeColor = "#FFD601";
        return "SV";
      }

      item.typeColor = "white";
      return item.type;
    },
    getMarketIcon(market) {
      if (market === "MarketCSGO") return marketIcon;
      if (market === "Waxpeer") return waxpeerIcon;
      if (market === "Shadowpay") return shadowIcon;
      if (market === "Buff.163") return buffIcon;
      if (market === "CSFloat") return floatIcon;
    },
    goLink(game, name) {
      this.$emit("go-item", game, name);
    },
  },
  created() {},
};
</script>

<style scoped>
.table > tbody > tr {
  background-color: transparent !important;
  color: white;
  transition: 0.1s ease-in-out;
  font-size: 16px;
}

.table > tbody > tr > td {
  padding-bottom: 8px;
}

.item {
  height: 50px;
  width: 50px;
}

.table > tbody > tr:hover {
  background-color: #1f1f38 !important;
  cursor: pointer;
}

.table > tbody > tr:hover .item {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.table > tbody > tr:hover .item {
  -webkit-transform: scale(1.35);
  transform: scale(1.35);
}

.table > thead th {
  padding: 5px;
  border: none;
  margin: 0px;
  font-size: 2vh;
}
</style>
