<template>
  <div class="auto_complete">
    <ul v-if="data.length > 0" class="auto_wrap">
      <li
        @click="select(item)"
        class="item" style="justify-content: space-between;"
        v-for="(item, index) in data"
        :key="index"
      >
        <div class="item">
          <img
            class="small-icon"
            :src="item.image"
            :style="{ '--drop-shadow-color': item.color }"
          />
          <span> {{ item.name }}</span>
        </div>

        <span class="green"> ${{ item.price }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      suggestions: [],
    };
  },
  methods: {
    async select(item) {
      this.$emit("selectImage", item);
    },
  },
  components: {},
};
</script>

<style scoped>
.auto_complete {
  position: absolute;
  margin-top: 2px;
  z-index: 99;
  height: 280px;
  background: #21242c;
  width: auto;
  overflow-y: auto;
  min-width: 400px;
  width: auto;
  border: 1px solid #954ce9;
}

.small-icon {
  width: 45px;
  filter: drop-shadow(0 0 11px var(--drop-shadow-color));
}

.auto_wrap {
  list-style-type: none;
  margin: 0;
  padding: 0.2rem 0.2rem;
  background-color: #212529;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.auto_wrap span {
  list-style-type: none;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.item {
  padding: 0.3rem 0.8rem;
  border-radius: 3px;
  min-height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.item:hover {
  background-color: rgb(134, 80, 172);
}

@media screen and (max-width: 872px) {
  .auto_complete {
    position: absolute;
    margin-top: 2px;
    z-index: 5;
    height: 220px;
    background: transparent;
    overflow-y: auto;
    min-width: 310px;
    width: auto;
    border: 1px solid #954ce9;
  }

  .auto_wrap span {
    list-style-type: none;
    margin: 0;
    font-size: 10px;
    font-weight: 400;
  }
}
</style>
