<template>
  <Line
    :options="isMobile ? mobileOptions : desktopOptions"
    :data="chartData"
  />
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Line,
  },
  props: {
    labelName: String,
    data: Array,
    chartOption: Object,
  },
  computed: {
    ...mapGetters("ui", ["isMobile"]),
    chartData() {
      return {
        labels: this.data.map((item) => item.date),
        datasets: [
          {
            label: this.labelName,
            data: this.data.map((item) => item.value),
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;

              const gradient = canvas.createLinearGradient(0, 0, 0, 340);

              gradient.addColorStop(0, this.colors.purple.half);
              gradient.addColorStop(0.75, this.colors.purple.quarter);
              gradient.addColorStop(1, this.colors.purple.zero);

              return gradient;
            },
            borderColor: this.colors.purple.default,
            fill: true,
            tension: 0.3,
            pointRadius: 2,
            pointHoverRadius: 10,
          },
        ],
      };
    },
  },
  data() {
    return {
      desktopOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 45,
              minRotation: 45,
              padding: 10,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 10 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
              fontSize: 20,
            },
          },
        },
      },
      mobileOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 45,
              minRotation: 45,
              padding: 5,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 5 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
              fontSize: 15,
            },
          },
        },
      },
      colors: {
        purple: {
          default: "rgba(149, 76, 233, 1)",
          half: "rgba(149, 76, 233, 0.5)",
          quarter: "rgba(149, 76, 233, 0.25)",
          zero: "rgba(149, 76, 233, 0)",
        },
        indigo: {
          default: "rgba(80, 102, 120, 1)",
          quarter: "rgba(80, 102, 120, 0.25)",
        },
      },
    };
  },
  created() {
    if (!this.chartOption) return this.desktopOptions;
    this.desktopOptions = this.chartOption;
  },
  watch: {
    isMobile() {
      this.desktopOptions.maintainAspectRatio = !this.isMobile;
    },
  },
};
</script>

<style scoped></style>
