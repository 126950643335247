<template>
  <div class="background-small">
    <div class="row">
      <h4 class="text-white">{{ title }}</h4>
    </div>
    <div class="wrapper my-4">
      <div class="row pb-2">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <LineGraph
            :options="chartOption"
            @selectInterval="selectInterval"
            :interval="timeInterval"
            :header="'Portfolio History'"
            :sub-header="''"
            :chart-data="valueGraph"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-lg-6 col-md-6 h-100 py-2">
          <div class="card-heading market-card">
            <div class="card-body py-4 px-5 h-100">
              <h5 class="my-3">Asset Allocation</h5>
              <div
                v-if="chartLoading"
                class="d-flex justify-content-center p-5"
              >
                <DotLoader :loading="chartLoading" :width="'50px'" />
              </div>
              <div
                class="d-flex justify-content-center"
                v-if="this.values === null && !chartLoading"
              >
                Data Not Found
              </div>
              <div v-if="!chartLoading" class="info-section">
                <Doughnut
                  v-if="this.values !== null"
                  :data="this.dataVal"
                  :options="chartConfig.options"
                  style="width: 375px; height: 375px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-lg-6 col-md-6 h-100 py-2">
          <div class="card-heading market-card">
            <div class="card-body py-4 px-5">
              <h5 class="my-3">Update Portfolio</h5>
              <div class="f g10">
                <div class="left-section">
                  <div
                    v-for="input in filteredInputs"
                    :key="input.label"
                    class="input-group"
                  >
                    <label class="white">{{ input.label }}</label>
                    <input
                      v-model="input.value"
                      type="number"
                      step="0.01"
                      class="input-field dark inp-div"
                    />
                  </div>
                  <div class="input-group">
                    <label class="white">Sum</label>
                    <input
                      v-model="sum"
                      type="number"
                      step="0.01"
                      class="input-field dark inp-div"
                      readonly="readonly"
                    />
                    <p class="danger-text">{{ danger }} {{ updateTime }}</p>
                  </div>
                </div>
                <div class="right-section">
                  <div
                    v-for="input in filteredInputsRight"
                    :key="input.label"
                    class="input-group"
                  >
                    <label class="white">{{ input.label }}</label>
                    <input
                      v-model="input.value"
                      type="number"
                      step="0.01"
                      class="input-field dark inp-div-r"
                    />
                  </div>
                  <div class="full-width">
                    <button
                      @click="updatePortfolio"
                      class="custom_button full-width"
                    >
                      Save
                    </button>
                    <button
                      @click="fetcData"
                      class="custom_button full-width mt-2"
                    >
                      Fetch
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
  <ErrorModal
    v-if="showErrorModal"
    :message="errorMessage"
    @close="showErrorModal = false"
  />
</template>
<script>
import PortfolioService from "@/services/PortfolioService.js";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import * as chartConfig from "../components/charts/chartConfig.js";
import { createPieChartConfig } from "../utils/pieChartUtils.js";
import LineGraph from "@/components/graphs/LineGraph.vue";
import InfoModal from "@/components/modal/InfoModal.vue";
import ErrorModal from "@/components/modal/ErrorModal.vue";

import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  data() {
    return {
      showModal: false,
      showErrorModal: false,
      modalMessage: "Portfolio updated successfully",
      errormessage:
        "An error occured while updating portolio. Try again later.",
      valueLoading: true,
      statsLoading: true,
      dataVal: null,
      chartLoading: true,
      title: "Portfolio",
      chartConfig,
      danger: "Last Update: ",
      updateTime: "",
      values: null,
      inputs: [
        { label: "Investments", value: 0 },
        { label: "Stocks", value: 0 },
        { label: "Cash", value: 0 },
        { label: "Crypto", value: 0 },
        { label: "Bank", value: 0 },
        { label: "Buff163", value: 0 },
        { label: "Waxpeer", value: 0 },
        { label: "MarketCSGO", value: 0 },
        { label: "Shadowpay", value: 0 },
        { label: "SkinLand", value: 0 },
        { label: "LisSkins", value: 0 },
        { label: "Dmarket", value: 0 },
        { label: "CSFloat", value: 0 },
        { label: "White Market", value: 0 },
        { label: "Gamerpay", value: 0 },
        { label: "CSGO Inventory", value: 0 },
        { label: "DOTA2 Inventory", value: 0 },
      ],
      chartData: [],
      timePeriods: ["7", "14", "30", "90"],
      selectedTimePeriod: "30",
      startDate: "",
      endDate: "",
      colors: {
        purple: {
          default: "rgba(149, 76, 233, 1)",
          half: "rgba(149, 76, 233, 0.5)",
          quarter: "rgba(149, 76, 233, 0.25)",
          zero: "rgba(149, 76, 233, 0)",
        },
        indigo: {
          default: "rgba(80, 102, 120, 1)",
          quarter: "rgba(80, 102, 120, 0.25)",
        },
      },
      options: {
        layout: {
          padding: 10,
        },
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 10,
                autoSkip: false,
                maxRotation: 15,
                minRotation: 15,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Weight in KG",
                padding: 10,
              },
              gridLines: {
                display: true,
                color: "rgba(80, 102, 120, 0.25)",
              },
              ticks: {
                beginAtZero: false,
                max: 63,
                min: 57,
                padding: 10,
              },
            },
          ],
        },
      },
      timeInterval: [
        { name: "7 D", code: "7_d", value: 7 },
        { name: "14 D", code: "14_d", value: 14 },
        { name: "30 D", code: "30_d", value: 30 },
        { name: "3 M", code: "3_m", value: 90 },
      ],
      chartOption: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 0,
              minRotation: 0,
              padding: 10,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 5 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
              fontSize: 20,
            },
          },
        },
      },
      valueGraph: [],
      selectedInterval: 30,
      selectedMarket: "Buff",
    };
  },
  computed: {
    filteredInputs() {
      return this.inputs.slice(0, 8);
    },
    filteredInputsRight() {
      return this.inputs.slice(8, 17);
    },
    sum() {
      return this.inputs
        .reduce((total, input) => total + parseFloat(input.value || 0), 0)
        .toFixed(2);
    },
  },
  methods: {
    async showInfoModal(success) {
      if (success) {
        this.showModal = true;
        setTimeout(() => {
          this.showModal = false;
        }, 4000);
      } else {
        this.showErrorModal = true;
        setTimeout(() => {
          this.showErrorModal = false;
        }, 4000);
      }
    },
    async selectInterval(time) {
      if (!time) return;
      this.selectedInterval = time;
      await this.getPortfolioHistory();
    },
    async getPortfolioHistory() {
      const response = await PortfolioService.portfolioGraph({
        market: this.selectedMarket.toLowerCase(),
        interval: this.selectedInterval,
      });

      if (!response) return;

      this.valueGraph = response;
    },
    async updatePortfolio() {
      let req = {
        update_time: new Date().toISOString().slice(0, 19).replace("T", " "),
      };
      this.inputs.forEach((item) => {
        req[item.label.toLowerCase().replace(/\s+/g, "_")] = item.value;
      });

      const response = await PortfolioService.addPortfolio(req);

      if (!response) return this.showInfoModal(false);
      else this.showInfoModal(true);
      await this.getPortfolioHistory();
    },
    async fetcData() {
      const response = await PortfolioService.getLast();
      if (!response) return;
      this.values = response;

      if (this.values) {
        this.inputs.forEach((input, index) => {
          input.value = Object.values(this.values)[index];
        });

        const parsedDate = moment.utc(this.values.update_time);
        const formattedDate = parsedDate.format("DD MM YYYY HH:mm");
        this.updateTime = formattedDate;
        this.createChart();
        return;
      }
    },
    async createChart() {
      this.chartLoading = true;

      if (this.values === null) return (this.chartLoading = false);

      let proxy = window.structuredClone(this.values);
      delete proxy.update_time;
      this.dataVal = await createPieChartConfig(proxy);
      this.chartLoading = false;
    },
    setScreenSize() {
      this.$store.dispatch("ui/checkMobile");
    },
    async updateData() {
      await Promise.all([this.getPortfolioHistory(), this.fetcData()]);
    },
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener("resize", this.setScreenSize);
  },
  async created() {
    this.endDate = moment().format("YYYY-MM-DD");
    this.startDate = moment()
      .clone()
      .subtract(parseInt(this.selectedTimePeriod), "days")
      .format("YYYY-MM-DD");
    await this.updateData();
  },

  components: {
    Doughnut,
    LineGraph,
    InfoModal,
    ErrorModal,
  },
};
</script>
<style scoped>
.market-card {
  width: 100%;
  background-color: #313155;
  color: white;
  border-radius: 5px;
}

.button-group {
  margin-bottom: 10px;
  gap: 5px;
}

.desktop {
  display: flex;
}

.mobile-graph {
  display: none;
}

.inp-div {
  height: 2rem;
}

.inp-div-r {
  height: 2rem;
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #6c757d;
}

.center {
  display: flex;
  justify-content: center;
}

.dark-bg {
  background-color: #2f3f4c;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 8px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.input-field {
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
}

.dark:focus {
  outline-color: #444;
}

.day-button-1 {
  background-color: #212529;
  color: white;
  text-align: center;
  border-radius: 3px;
  font-size: 16px;
  opacity: 0.8;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.circle {
  border-radius: 50%;
}

.day-button-1.active {
  background-color: #007bff;
  opacity: 1;
}

.full-width {
  width: 100% !important;
}

.inp-div {
  height: 2rem;
}

.full-width {
  width: 100%;
}

@media screen and (max-width: 769px) {
  .desktop {
    display: none;
  }

  .mobile-graph {
    display: flex;
    min-height: 260px;
  }

  .mobile-graph-card {
    min-height: 480px;
  }

  .day-button-1 {
    background-color: #212529;
    color: white;
    text-align: center;
    border-radius: 3px;
    font-size: 16px;
    opacity: 0.8;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
    height: 44px;
    width: 44px;
  }

  .circle {
    border-radius: 50%;
  }
}
</style>
