<template>
    <div class="collapse_list_wrap w100">
        <div class="f sb w100 align-center" @click="setFunction()">
            <h6 class="text-white">{{ title }}</h6>
            <VueIcon v-if="isOpen" icon="iconamoon:arrow-up-2-light" width="24px" color="white" />
            <VueIcon v-else icon="iconamoon:arrow-down-2-light" width="24px" color="white" />
        </div>
        <div v-show="isOpen" class="collapsible-list">
            <div class="item" v-for="(item) in options" :key="item.label">
                <input class="checkbox" type="checkbox" :id="'checkbox-' + item.label" v-model="item.checked"
                    @click="checkItem(item.label)">
                <label :style="{ color: item.color || 'white' }" :for="'checkbox-' + item.label">{{ item.label }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: Array,
        refFunction: Function,
        title: String,
        isOpen: Boolean,
        type: String
    },
    data() {
        return {
            isDropdownOpen: false,
        };
    },
    methods: {
        setFunction() {
            this.refFunction(this.type, this.isOpen);
        },
        checkItem(label) {
            this.$emit('check', label, this.type);
        },
    },
};
</script>
<style scoped>

h6 {
    font-size: 0.8rem;
    margin-bottom: 0;
}
.collapse_list_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #212529;
    align-items: center;
    padding: 0.2rem 0.6rem;
    transition: 0.4s;
    border: 1px rgb(134, 80, 172) solid;
    min-height: 35px;
    cursor: pointer;
}

.collapsible-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.4s;
    padding: 0.4rem;
    overflow-y:auto;
    max-height: 220px;
}

.item {
    margin-bottom: 0px;
    min-height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

label {
    margin-left: 8px;
    cursor: pointer;
    font-size: 12px;
    color: white;
}  

.checkbox {
    width: 18px;
    accent-color: rgb(134, 80, 172);
    background-color: #212529;
}

.w100 {
    width: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
