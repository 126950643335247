import { apiInstance } from "@/utils/ApiUtil.js";
const API_URL = "/item";

const getOneItem = async (req) => {
  try {
    const response = await apiInstance.post(API_URL, {
      slug: req.slug,
      game: req.game,
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getPriceHistory = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/history", {
      params: {
        slug: req.slug,
        interval: req.interval,
        game: req.game,
        market: req.market,
      }
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data.history;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getOneItemImage = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/image", {
      name: req.name,
      game: req.game,
      limit: req.limit,
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data.item;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getAllItemImage = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/all", {
      name: req.name,
      limit: req.limit,
      game: req.game,
    });

    if (!response) return [];
    if (!response.data.success) return [];

    return response.data.items;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getComparison = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/comparison", {
      game: req.game,
      currentPage: req.currentPage,
      pageSize: req.pageSize,
      sortBy: req.sortBy,
      sortOrder: req.sortOrder,
      searchQuery: req.searchQuery,
      priceFrom: req.priceFrom,
      priceTo: req.priceTo,
      minROI: req.minROI,
      maxROI: req.maxROI,
      quantity: req.quantity,
      volume: req.volume,
      type: req.type,
      exterior: req.exterior,
      rarity: req.rarity,
      extra: req.extra,
      sourceMarket: req.source,
      targetMarket: req.target,
      globalMinimum: req.globalMinimum
    });
    if (!response.data.success) return [];
    return response.data.items;
  } catch (error) {
    return [];
  }
};

export const getFilters = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/filters", {
      params: {
        game: req.appID,
      },
    });
    if (!response.data.success) return [];

    return response.data.items;
  } catch (error) {
    return [];
  }
};

export const getPriceTrends = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/trend", {
      game: req.game,
      currentPage: req.currentPage,
      pageSize: req.pageSize,
      sortBy: req.sortBy,
      sortOrder: req.sortOrder,
      searchQuery: req.searchQuery,
      priceFrom: req.priceFrom,
      priceTo: req.priceTo,
      minROI: req.minROI,
      maxROI: req.maxROI,
      quantity: req.quantity,
      volume: req.volume,
      type: req.type,
      exterior: req.exterior,
      rarity: req.rarity,
      extra: req.extra,
      sourceMarket: req.source,
      targetMarket: req.target,
    });
    if (!response.data.success) return [];
    return response.data.data;
  } catch (error) {
    return [];
  }
};

export const getPopularItems = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/popular", {
      game: req.game,
      currentPage: req.currentPage,
      pageSize: req.pageSize,
      sortBy: req.sortBy,
      sortOrder: req.sortOrder,
      priceFrom: req.priceFrom,
      priceTo: req.priceTo,
      quantity: req.quantity,
      volume: req.volume,
      interval: req.interval,
    });
    if (!response.data.success) return [];
    return response.data.data;
  } catch (error) {
    return [];
  }
};

export const getItemTrends = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/trending-item", {
      params: {
        game: req.game,
        currentPage: req.currentPage,
        pageSize: req.pageSize,
        sortBy: req.sortBy,
        sortOrder: req.sortOrder,
        priceFrom: req.priceFrom,
        priceTo: req.priceTo,
        quantity: req.quantity,
        volume: req.volume,
        interval: req.interval,
        type: req.type,
      },
    });
    if (!response.data.success) return [];

    return response.data.data;
  } catch (error) {
    return [];
  }
};

const voteItem = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/vote", {
      item_id: req.itemID,
      vote: req.vote,
      game: req.game,
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data.item_id;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getItemVote = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/vote", {
      params: {
        item_id: req.itemID,
        game: req.game,
      },
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getItemCollection = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/collection", {
      params: {
        id: req.collectionID,
      },
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getItemCollectionInfo = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/collection-info", {
      params: {
        id: req.collectionID,
      },
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getItemCaseInfo = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/case-info", {
      params: {
        id: req.caseID,
      },
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getCaseItems = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/case", {
      params: {
        id: req.caseID,
        rare: req.rare ?? 0,
      },
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getAllCases = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + "/cases", {
      params: {
        limit: req.limit,
        page: req.page,
        orderDir: req.dir,
        orderBy: req.orderBy,
      },
    });

    if (!response) return false;
    if (!response.data.success) return false;

    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const ItemService = {
  getOneItem,
  getOneItemImage,
  getAllItemImage,
  getPriceHistory,
  getComparison,
  getPriceTrends,
  voteItem,
  getItemVote,
  getItemCollection,
  getItemCollectionInfo,
  getItemCaseInfo,
  getCaseItems,
  getAllCases,
  getPopularItems,
  getItemTrends,
  getFilters
};

export default ItemService;
