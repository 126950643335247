export async function createPieChartConfig(inputs) {
    const config = {
        labels: [],
        datasets: [
            {
                backgroundColor: [],
                data: [],
                borderWidth: 0,
            }
        ]
    };

    for (const key in inputs) {
        config.labels.push(key.toUpperCase());
        config.datasets[0].data.push(inputs[key]);
        config.datasets[0].backgroundColor.push(getRandomColor());
    }

    return config;
}

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}