<template>
  <div class="background-small">
    <div class="f sb flex-start pb-2">
      <div>
        <h4 class="text-white">Case Simulator</h4>
        <h6 class="text-white mt-1">Key Price: 2.49$</h6>
        <h6 class="text-white">Total Cases: {{ caseCount ?? 0 }}</h6>
      </div>
      <div class="stat">
        <h4 class="text-white">Statistics</h4>
        <h6 class="text-white mt-1">Spent: $ {{ getTotalSpent }}</h6>
        <h6 class="text-white">Opened: {{ this.selectedItems.length }}</h6>
        <h6 class="text-white">Profit/Loss: $ {{ getTotalWin }}</h6>
      </div>
    </div>
    <div
      v-if="items && items.length > 0"
      class="row px-4 mb-4 f align-center flex-column g20"
    >
      <CaseSpinner ref="spinner" :items="items" />
      <button class="open_case" @click="spin">Open Case For Free</button>
    </div>
    <div
      v-if="selectedItems && selectedItems.length > 0"
      class="wrapper px-3 mb-3"
    >
      <h5 class="text-white my-2">Latest Drops</h5>
      <div class="drop_grid">
        <div
          style="cursor: pointer"
          @click="goItem(item.name)"
          v-for="(item, index) in selectedItems"
          :key="index"
          class="latest-card"
          :style="{ borderColor: item.color ?? 'white' }"
        >
          <img :src="item.image" alt="Card Image" />
          <span class="mid-span">{{ item.name }}</span>
          <span class="mid-span">$ {{ item.maxPrice }}</span>
        </div>
      </div>
    </div>

    <div v-if="!itemLoading && caseID" class="wrapper px-3">
      <h5 class="text-white">Possible Items: {{ caseContent.length }}</h5>
      <div class="collection_grid py-2">
        <RareCard
          @check-rares="check"
          v-if="caseRareContent && caseRareContent.length > 0"
          :items="caseRareContent"
        ></RareCard>
        <CaseCard
          @go-item="goItem"
          v-for="item in caseContent"
          :key="item.name"
          :item="item"
        />
      </div>
    </div>
    <div style="justify-content: flex-end; width: 100%" class="f">
      <div class="mobile-width">
        <h6 class="text-white">Sort</h6>
        <SortDropdown
          :order-by-function="setOrderBy"
          :options="orderOptions"
          :selectedItem="selectedOrder"
          :selected-dir="selectedDirection"
          :order-dir-function="setOrderDir"
        />
      </div>
    </div>
    <div class="wrapper py-2">
      <div v-if="itemLoading">
        <div class="collection_grid">
          <div v-for="index in filterObject.pageSize" :key="index">
            <ShimmerCard />
          </div>
        </div>
      </div>
      <div v-else class="py-2">
        <div class="collection_grid">
          <ContainerCard
            @open-case="chooseCase"
            @go-item="goLink"
            v-for="item in cases"
            :key="item.itemID"
            :item="item"
          />
        </div>
      </div>
    </div>
    <div class="page_row pt-2 pb-3 f align-center">
      <PaginationRow
        :pageNumber="filterObject.pageNumber"
        :totalPages="totalPages"
        @update:pageNumber="filterObject.pageNumber = $event"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import CaseSpinner from "@/components/simulator/CaseSpinner.vue";
import ContainerCard from "@/components/cards/ContainerCard.vue";
import ItemService from "@/services/ItemService.js";
import ShimmerCard from "@/components/skeleton/ShimmerCard.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import CaseCard from "@/components/cards/CaseCard.vue";
import RareCard from "@/components/cards/RareCard.vue";

import DateUtils from "@/utils/DateUtils.js";
import steamIcon from "../assets/images/steam-white.png";
import sound from "../assets/sound/opening.mp3";

export default {
  data() {
    return {
      totalPages: 6,
      title: "Collection",
      stats: {
        total: 0,
      },
      caseInfo: {},
      rareItems: [],
      loading: true,
      itemLoading: true,
      isDropdownOpen: false,
      steamIcon: steamIcon,
      filterObject: {
        pageSize: 20,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
      },
      orderOptions: ["Date", "Price", "Name"],
      selectedOrder: "Date",
      selectedDirection: "DESC",
      caseName: "",
      caseID: null,
      selectedCase: null,
      cases: [],
      caseCount: null,
      items: [],
      selectedItem: {},
      currentIndex: 0,
      normalOdds: [
        { rarity: "Mil-Spec Grade", value: 79.92 },
        { rarity: "Restricted", value: 15.98 },
        { rarity: "Classified", value: 3.2 },
        { rarity: "Covert", value: 0.64 },
        { rarity: "Exceedingly Rare", value: 0.26 },
      ],
      souvenirOdds: [
        { rarity: "Consumer Grade", value: 80 },
        { rarity: "Industrial Grade", value: 16 },
        { rarity: "Mil-Spec Grade", value: 3.2 },
        { rarity: "Restricted", value: 0.64 },
        { rarity: "Classified", value: 0.128 },
        { rarity: "Covert", value: 0.0256 },
      ],
      stickerOdds: [
        { rarity: "High Grade", value: 80 },
        { rarity: "Remarkable", value: 16 },
        { rarity: "Exotic", value: 3.2 },
        { rarity: "Extraordinary", value: 0.641 },
      ],
      caseContent: [],
      caseRareContent: [],
      tempArray: [],
      isRareView: false,
      tempArray: [],
      selectedItem: null,
      selectedItems: [],
      totalWin: 0,
      totalSpent: 0,
      sound,
    };
  },
  methods: {
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
      await this.getCaseInfo();
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
      await this.getCaseInfo();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    check(rareToggle) {
      if (rareToggle) {
        this.isRareView = true;
        this.tempArray = this.caseContent;
        this.caseContent = this.caseRareContent;
        this.caseRareContent = this.tempArray;
      } else {
        this.isRareView = false;
        this.caseRareContent = this.caseContent;
        this.caseContent = this.tempArray;
      }
    },
    formatItems(items) {
      let result = [];
      this.caseContent = [];
      items
        .filter((skin) => skin.isNormal === 1)
        .forEach((skin) => {
          const newItem = {
            name: skin.name,
            shortName: skin.shortName,
            image: skin.image,
            rarity: skin.rarity,
            type: skin.type,
            color: skin.color,
            slug:skin.slug,
            game:skin.game,
            isSouvenir: false,
            isStattrak: false,
            isNormal: skin.isNormal,
            prices: {
              stattrakMinPrice: 0,
              stattrakMaxPrice: 0,
              souvenirMinPrice: 0,
              souvenirMaxPrice: 0,
              normalMinPrice: skin.minPrice,
              normalMaxPrice: skin.maxPrice,
            },
          };
          result.push(newItem);
        });

      const odds = items.filter((skin) => skin.isNormal === 0);

      result.forEach((element) => {
        const souvenirItem = odds.find(
          (e) => e.shortName === element.shortName && e.isSouvenir === 1
        );

        if (souvenirItem) {
          element.isSouvenir = true;
          element.prices.souvenirMinPrice = souvenirItem.minPrice;
          element.prices.souvenirMaxPrice = souvenirItem.maxPrice;
        }

        const stItem = odds.find(
          (e) => e.shortName === element.shortName && e.isStattrak === 1
        );

        if (stItem) {
          element.isStattrak = true;
          element.prices.stattrakMinPrice = stItem.minPrice;
          element.prices.stattrakMaxPrice = stItem.maxPrice;
        }
      });
      this.caseContent = result;
    },
    formatRareItems(items) {
      this.caseRareContent = [];
      if (!items || items.length === 0) return;
      let result = [];

      items
        .filter((skin) => skin.isNormal === 1)
        .forEach((skin) => {
          const newItem = {
            name: skin.name,
            shortName: skin.shortName,
            image: skin.image,
            rarity: skin.rarity,
            type: skin.type,
            slug:skin.slug,
            game:skin.game,
            color: skin.color,
            isStattrak: false,
            isNormal: skin.isNormal,
            prices: {
              stattrakMinPrice: 0,
              stattrakMaxPrice: 0,
              normalMinPrice: skin.minPrice,
              normalMaxPrice: skin.maxPrice,
            },
          };
          result.push(newItem);
        });

      const odds = items.filter((skin) => skin.isNormal === 0);

      result.forEach((element) => {
        const stItem = odds.find((e) => e.shortName && e.isStattrak === 1);

        if (stItem) {
          element.isStattrak = true;
          element.prices.stattrakMinPrice = stItem.minPrice;
          element.prices.stattrakMaxPrice = stItem.maxPrice;
        }
      });

      this.caseRareContent = result;
    },
    groupItems(response) {
      this.items = [];
      const randomKnife =
        response.items.knives[
          Math.floor(Math.random() * response.items.knives.length)
        ];
      const normalItems = Array.from(
        { length: 10 },
        () =>
          response.items.skins[
            Math.floor(Math.random() * response.items.skins.length)
          ]
      );

      if (randomKnife) this.items = [...normalItems, randomKnife];
      else this.items = [...normalItems];
    },
    async getItems() {
      this.itemLoading = true;
      const response = await ItemService.getCaseItems({ caseID: this.caseID });

      if (!response) return;

      this.groupItems(response);
      this.formatItems(response.items.skins);
      this.formatRareItems(response.items.knives);

      this.caseInfo.count = this.items.length;
      this.itemLoading = false;
    },
    async getCaseInfo() {
      this.itemLoading = true;
      const response = await ItemService.getAllCases({
        limit: this.filterObject.pageSize,
        page: this.filterObject.pageNumber,
        dir: this.filterObject.orderDir,
        orderBy: this.filterObject.orderBy,
      });
      if (!response) return (this.itemLoading = false);

      this.cases = response.data;
      this.caseCount = response.count;
      this.itemLoading = false;
    },
    getData() {
      Promise.all([this.getCaseInfo()]);
    },
    async chooseCase(caseID) {
      this.caseID = caseID;
      await this.getItems();
      if(this.items && this.items.length > 0) {
        this.$refs.spinner.fillRows();
      }
      return false;
    },
    async spin() {
      const randomRoll = Math.floor(
        Math.random() * (this.items.length - 0) + 0
      );
      this.$refs.spinner.spinWheel(randomRoll);
      this.selectedItem = this.items[randomRoll];
      var audio = new Audio(sound);
      audio.play();

      setTimeout(() => {
        this.selectedItems.push(this.selectedItem);
      }, 7200);
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    goLink(item) {
 
      const routeData = this.$router.resolve({
        name: "item",
        params: {  slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    goItem(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    async checkControl() {
      const id = this.$router.currentRoute.value.params.id;
      if (id) this.chooseCase(id);
    },
  },
  async created() {
    await this.checkControl();
    this.getData();
  },
  watch: {
    "filterObject.pageNumber"() {
      this.getCaseInfo();
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
    getTotalWin() {
      let sum = 0;
      this.selectedItems.forEach((e) => (sum += e.maxPrice - 2.49));
      return sum.toFixed(2);
    },
    getTotalSpent() {
      return (2.49 * this.selectedItems.length).toFixed(2);
    },
  },
  components: {
    CaseCard,
    RareCard,
    ContainerCard,
    ShimmerCard,
    SortDropdown,
    CaseSpinner,
  },
};
</script>

<style>
p {
  margin-bottom: 0;
}

.collection_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.drop_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 5px;
  align-items: stretch;
}

.open_case {
  background-image: linear-gradient(to right, #25aae1, #166f44, #219f62, #3a7);
  box-shadow: 0 4px 10px 0 rgba(28, 103, 100, 0.75);
  padding: 4px 8px;
  width: 180px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  margin-top: 0px;
  height: 45px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 4px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.open_case:hover {
  transition: 0.3s;
  transform: translateY(-4px);
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.latest-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 100%;
  width: auto;
  min-width: 100px;
  padding: 5px;
  border: 1px transparent solid;
  text-align: center;
}

.latest-card:hover {
  box-shadow: 0px 0px 12px 10px;
  transition: 0.3s;
}

.latest-card > img {
  width: 80px;
  height: 70px;
}

.mid-span {
  font-size: 12px;
}

@media screen and (max-width: 728px) {
  p {
    font-size: 16px;
  }

  .collection_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    align-items: stretch;
  }

  .sort-select-menu {
    width: 100%;
    transition: 0.5s;
  }

  .mobile-width {
    width: 100%;
  }

  .sort-select-menu {
    width: auto !important;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
