<template>
  <div class="logos">
    <div class="logos-slide">
      <div v-for="(item, index) in items" :key="index" class="text-wrapper g10">
        <img :src="item.icon" :alt="item.name" />
        <div class="number-text">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      suggestions: [],
    };
  },
  methods: {
    async select(item) {
      this.$emit("", item);
    },
  },
};
</script>
<style scoped>
.logos {
  overflow: hidden;
  padding: 30px 0;
  background-color: #21213b;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #21213b);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #21213b);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: flex;
  gap: 3.5rem;
  animation: 30s slide infinite linear;
}

.logos-slide img {
  height: 40px;
}


.text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 130px;
}

.number-text {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.603);
}

.text-wrapper:hover > img {
  transition: 0.4s;
  cursor: pointer;
  filter: drop-shadow(0 0 11px #565555);
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-120%);
  }
}

@media screen and (max-width: 576px) {
  .logos-slide {
    display: flex;
    animation: 20s slide infinite linear;
  }

  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    content: "";
    z-index: 2;
  }
}
</style>
