<template>
    <div class="background-small">
        <div class=" f sb">
            <h4 class="text-white">Marketplaces And Trading Platforms</h4>
        </div>
        <div class="wrapper mt-4">
            <div v-if="loading" class="loading-screen">
                <DotLoader :loading="loading" :width="'50px'" />
            </div>
            <div v-if="!loading" class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th v-for="column in columns" :key="column" @click="sortByColumn(column)">
                                {{ column }}
                                <img v-if="sortBy === column"
                                    :src="sortOrder === 'ASC' ? './assets/images/asc.png' : './assets/images/desc.png'"
                                    class="sort-icon">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in markets" :key="index">
                            <td> {{ index + 1 }}</td>

                            <td style="cursor: pointer;" @click="goLink(item.link)">
                                <img v-if="item.image_full" class="img" :src="item.image_full" />
                                <img v-else class="img" src="../assets/items/csgo/blood.png" />
                            </td>
                            <td> {{ item.fees_sell_percent }} %</td>
                            <td v-html="item.fees_payout"></td>
                            <td>
                                <div class="f">
                                    <span>
                                        {{ item.payment_method_deposit_methods.map(method => method.name).join(', ') }}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div class="f">
                                    <span>
                                        {{ item.payment_method_withdraw_methods.map(method => method.name).join(', ') }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import upIcon from '../assets/images/asc.png';
import downIcon from '../assets/images/desc.png';

import MarketplaceService from '@/services/MarketplaceService';

export default {
    data() {
        return {
            volumeTitle: 'Sales Volume',
            loading: false,
            orderIcon: downIcon,
            sortBy: 'i.shadow_volume',
            items: [],
            markets: [],
            pageNumber: 1,
            pageSize: 10,
            sortOrder: 'DESC',
            columns: [
                '#',
                'Market',
                'Sales Fee',
                'Withdrawal Fee',
                'Deposit Methods',
                'Withdraw Methods',
            ],
        };
    },
    methods: {
        closeItemModal() {
            this.selectedItem = null;
        },
        copyText(item) {
            const textToCopy = item;
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    this.showModal = true;
                    setTimeout(() => {
                        this.showModal = false;
                    }, 1500);
                })
                .catch((error) => {
                    this.copyMessage = 'Error copying text' + error;
                });
        },
        async sortByColumn(column) {
            document.getElementById('sort-price').innerHTML = column;
            this.setSortBy(column);
            if (this.searchTerm === '') {
                await this.getItems();
                return;
            }
            await this.getItemsByQuery();
        },
        setOrder() {
            this.sortOrder = this.sortOrder === 'DESC' ? 'ASC' : 'DESC';
            this.orderIcon = this.sortOrder === 'DESC' ? downIcon : upIcon
        },
        setSortBy(column) {
            if (column === 'Volume(M)') {
                this.sortBy = 'i.marketcsgo_volume'
            }
            if (column === 'Volume(S)') {
                this.sortBy = 'i.shadow_volume'
            }
            if (column === 'CSGOTM') {
                this.sortBy = 'i.marketcsgo_price'
            }
            if (column === 'Shadow') {
                this.sortBy = 'i.shadow_price'
            }
            if (column === 'Market(%)') {
                this.sortBy = 'buff_market_ratio'
            }
            if (column === 'Market(P)') {
                this.sortBy = 'buff_market_profit'
            }
            if (column === 'Wax') {
                this.sortBy = 'i.wax_price'
            }
            if (column === 'Wax(P)') {
                this.sortBy = 'buff_wax_profit'
            }
            if (column === 'Wax(%)') {
                this.sortBy = 'buff_wax_ratio'
            }
            this.setOrder();
        },
        async getItemsByQuery() {
            this.loading = true;
            this.markets = await MarketplaceService.getAll();
            this.loading = false;
        },
        goLink(name) {
            window.open(name, '_blank');
        },
    },
    async created() {
        await this.getItemsByQuery();
    },
    computed: {

    },
    components: {}
};
</script>
<style scoped>
.tab {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #313156;
    overflow: hidden;
    border-radius: 0.4rem;
    color: white;
    margin-block: 0.4rem;
    border-bottom: none;
}

.table>tbody>tr>td>img {
    display: flex;
    align-items: center;
    width: 180px;
    height: 65px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 4px;
}
.table>tbody>tr:hover img {
    transition: 0.2s;
    transform: scale(1.02);
    filter: none;
}

.table>tbody>tr:not(hover) img {
    transition: 0.2s;
    transform: scale(1);
}

.table>tbody>tr>td {
    max-width: 300px;
    text-wrap: wrap;
    text-align: start;
}

</style>
