import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/steam";

export const getStats = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + `/statistics`, {
            params: {
                appid: req.appid
            }
        });

        if (!response.data.success) return false;
        return response.data.meta;
    } catch (error) {

        return false;
    }
};

export const getInventoryOverview = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + `/inventory-statistic`, {
            params: {
                appid: req.appid
            }
        });

        if (!response.data.success) return false;
        return response.data.items;
    } catch (error) {

        return false;
    }
};

export const getInventory = async (req) => {
    try {

        const response = await apiInstance.post(API_URL + `/fetch-inventory`, {
            appid: req.appid,
            page: req.page,
            pageSize: req.pageSize,
            excludeMarkets:req.excludeMarkets,
            type: req.type,
            exterior: req.exterior,
            rarity: req.rarity
        });

        if (!response.data.success) return false;
        if (response.data.count < 1) return false;
        return response.data;
    } catch (error) {

        return [];
    }
};

export const getGameInventory = async (req) => {
    try {

        const response = await apiInstance.post(API_URL + `/inventory`, {
            appid: req.appid,
            page: req.page,
            pageSize: req.pageSize,
            search: req.search,
            type: req.type,
            exterior: req.exterior
        });

        if (!response.data.success) return false;
        if (response.data.count < 1) return false;
        return response.data;
    } catch (error) {
        console.log(error)
        return [];
    }
};

export const getValuationGraph = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + "/graph", {
            params: {
                market: req.market,
                interval: req.interval,
            }
        });

        if (!response.data.success) return [];
        return response.data.data;
    } catch (error) {
        return [];
    }

};

export const getTotalCount = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + `/total-count`, {
            appid: req.appid,
        });

        if (!response.data.success) return "Inventory Couldn't Loaded!";
        if (response.data.count < 1) return "Inventory Is Empty!";
        return response.data.count;
    } catch (error) {
        //console.error("Error fetching total count:", error);
        return 0;
    }
};

export const getValuation = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + `/inventory-value`, { appid: req.appid });

        if (!response.data.success) return 0;
        return response.data.value;
    } catch (error) {
        return 0;
    }
};

export const getTradableCount = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + `/tradable-count`,
            {
                params: {
                    appid: req.appid,
                }
            });

        if (!response.data.success) return 0;
        return response.data.tradable_item_count;
    } catch (error) {
        return 0;
    }
};

export const refreshInventory = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + `/refresh-inventory`, {
            appid: req.appid,
        });

        if (!response.data.success) return false;
        return response.data;
    } catch (error) {
        if (error.response.status === 429) {
            return { success: false, code: error.response.status, metaData: error.response.data }
        }
        return false;
    }
};

export const updatePrice = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/update-price", {
            market_id: req.market_id,
            item: req.item
        });
        return response.data.success;
    } catch (error) {
        return false;
    }
};


const SteamService = {
    getValuationGraph,
    getStats,
    getInventory,
    getTotalCount,
    refreshInventory,
    getValuation,
    getTradableCount,
    updatePrice,
    getGameInventory,
    getInventoryOverview,
}

export default SteamService;

