<template>
  <div class="contents">
    <div class="f align-center sb item_row">
      <div class="f align-bottom skin">
        <div class="f skin_main g20">
          <a
            style="cursor: pointer"
            @mousedown.middle="
              this.$emit('go-item', item.item_detail)
            "
            @click="this.$emit('go-item', item.item_detail)"
            class="thumb null"
          >
            <div class="thumb_shadow" style="color: rgb(235, 75, 75)"></div>
            <img
              :src="item.item_detail.image"
              class="img-item"
              :style="{ '--drop-shadow-color': item.item_detail.color }"
              :alt="item.item_name"
              loading="lazy"
            />
          </a>
          <div class="name ovh">
            <div class="f sb mb-2">
              <span :style="{ color: '#fff' }" class="responsive-title bold">
                {{ item.item_name }}
              </span>
              <button
                @click="$emit('copyText', item.item_name)"
                class="btn_copyname mx-2"
              >
                <VueIcon height="22px" color="white" icon="bx:copy" />
              </button>
            </div>
            <div class="f align-bottom">
              <VueIcon height="20px" color="white" icon="mdi:steam" />
              <span :style="{ color: '#fff' }" class="text-white mx-2">
                $ {{ (item.referanceMarket.price / 1000).toFixed(2) || 0 }}
              </span>
            </div>
          </div>
          <button
            aria-label="Copy"
            class="btn_copyname btn-default p5 mr-5"
          ></button>
        </div>
      </div>

      <div class="f align-bottom mx-3">
        <template v-if="item.editingPrice">
          <div class="f align-bottom">
            <div class="mb-1">
              <span class="mid-font text-white bold py-1">Target Price</span>
              <div class="inner-input-div">
                <input
                  v-model="newPrice"
                  type="number"
                  step="1"
                  min="0"
                  max="1000"
                  class="input-area-dark"
                />
              </div>
            </div>
            <button @click="updatePrice" class="edit-btn f align-center">
              <img class="small-img-button" :src="tickIcon" />
            </button>
          </div>
        </template>
        <template v-else>
          <div class="">
            <div class="f align-bottom">
              <span class="m-r-s orange currency">
                $ {{ (item.target_price / 1000).toFixed(2) }}
              </span>
              <button
                @click="$emit('startEditingPrice', item)"
                class="edit-btn f align-center"  style="border-radius: 50%"
              >
                <img class="small-img-button" :src="editIcon" />
              </button>
            </div>
            <span class="mid-font gray bold" style="color: gray"
              >Target Price</span
            >
          </div>
        </template>
      </div>
      <div class="f flex-column justify-center">
        <span>{{ DateUtils.getDate(item.created_at) }}</span>
        <span class="mid-font" style="color: gray">Created At</span>
      </div>
      <div
        @click="this.$emit('goExternal', item.minimumMarket.url)"
        class="f g10 min_price align-center"
      >
        <div class="f flex-column">
          <span class="mid-font bold  green">
            $ {{ item.minimumMarket.price / 1000 }}</span
          >
          <span class="mid-font" style="color: gray">Min. Price</span>
          <span class="mid-font" style="color: gray">{{
            item.minimumMarket.name
          }}</span>
        </div>

        <VueIcon
          class="btn_icon"
          icon="iconamoon:arrow-right-2-light"
          width="34px"
          color="white"
        />
      </div>
      <div class="f align-center justify-center">
        <button @click="this.$emit('removeItem', item.id)" class="btn_copyname drop">
          <VueIcon height="24px" color="red" icon="lets-icons:remove-light" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import copy from "../../assets/images/copy-white.png";
import edit from "../../assets/images/edit.png";
import tick from "../../assets/images/tick-white.png";
import DateUtils from "@/utils/DateUtils";

export default {
  data() {
    return {
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      newPrice: this.item.newPrice,
      color: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updatePrice() {
      this.$emit("updatePrice", this.item.id, this.newPrice, this.item);
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  created() {},
};
</script>

<style scoped>

.orange {
    color: orange
  }

  .green {
    color:rgb(124, 252, 0)
  }

  .red {
    color:#ff0f0f
  }
.item_row {
  background: #21242c;
  border-radius: 0.2rem;
  margin-block: 0.5rem;
  padding: 0.5rem 2.4rem;
  position: relative;
  transition: 0.3s;
  max-height: 9rem;
  min-height: 6rem;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 0.5fr;
}

.item_row:hover {
  background-color: #3a3e4a;
}

.min_price {
  cursor: pointer;
}

.min_price:hover .btn_icon {
  transition: 0.25s;
  transform: translateX(10px);
}

.min_price:not(hover) .btn_icon {
  transition: 0.25s;
  transform: translateX(0px);
}

.drop:hover {
  transform: scale(1.15);
  transition: 0.2s;
  filter: drop-shadow(0 0 20px red);
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

.img-xsmall {
  height: 25px;
  width: 25px;
}

.item_row:hover .img-item {
  filter: drop-shadow(0 0 11px var(--drop-shadow-color));
  transition: 0.2s;
  transform: scale(1.1);
}

.item_row:not(hover) .img-item {
  transition: 0.2s;
  transform: scale(1);
}

.img-item {
  height: 75px !important;
  width: 105px !important;
}

.edit-btn {
  background-color: #212121;
  border-radius: 6px;
  border: 1px solid rgb(100, 99, 99);
  height: 34px;
  width: 34px;
  color: white;
}

.edit-btn:hover {
  background-color: #626060;
  color: white;
}

.small-img-button {
  height: 20px;
  width: 20px;
}

.inner-input-div {
  height: 2rem;
  width: 90px;
}

.input-area-dark {
  background-color: black;
  color: white;
  font-weight: 600;
  width: 90%;
  min-width: 60px;
}
</style>
