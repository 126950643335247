<template>
  <div class="item_grid_row g20">
    <div v-if="item?.global_rank == 1" class="crown">
      <ToolTip :text="globalText" position="top">
        <VueIcon height="24px" color="#FCEA2A" icon="mdi:crown" />
      </ToolTip>
    </div>
    <div
      style="cursor: pointer"
      class="f align-center justify-center img-block"
      :style="{ '--hover-color': item.color || 'white' }"
    >
      <img
        v-if="item.image"
        class="img"
        :src="item.image"
        @click="goRoute(item)"
        @mousedown.middle="goRoute(item)"
      />
      <img v-else class="img" src="../../assets/items/csgo/blood.png" />
    </div>
    <div class="sb f align-center">
      <div
        style="cursor: pointer"
        class="f flex-column flex-start"
      >
        <span class="mid_span" :style="{ color: item.color || 'white' }">{{
          getType()
        }}</span>
        <span class="mid_span" :style="{ color: item.color || 'white' }">{{
          item.short_name
        }}</span>
        <span class="small_span">{{ item.exterior }}</span>
        <div
          @click="
            goExternalLink(
              `https://steamcommunity.com/market/listings/${item.app_id}/${item.name}`
            )
          "
          class="steam_wrap"
        >
          <VueIcon height="18px" color="white" icon="mdi:steam" />
          <span class="type_span">
            {{
              (item?.referance_price / 1000).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            }}</span
          >
        </div>
      </div>
      <div class="f g5 fls">
        <button
          @click="copyText(item.name)"
          aria-label="Copy"
          class="btn_copyname"
        >
          <VueIcon height="20px" color="white" icon="bx:copy" />
        </button>
        <button
          @click="addWatchlist(item.name)"
          aria-label="Copy"
          class="btn_copyname"
        >
          <VueIcon height="20px" color="white" icon="bx:bookmark" />
        </button>
      </div>
    </div>
    <div class="f market_outer">
      <div @click="goExternalLink(item.source_url)" class="market_wrap">
        <div class="f g5 align-center">
          <img class="img-xsmall" :src="getMarketIcon(item.source_name)" />
          <span :style="{ color: '#fff' }" class="text-white">
            {{
              (item?.source_price / 1000).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            }}
          </span>
        </div>
        <div class="f g5 align-center">
          <VueIcon height="25px" color="white" icon="radix-icons:stack" />
          <span :style="{ color: '#fff' }" class="text-white">
            {{ item?.source_qty || "1+" }}
          </span>
        </div>
      </div>
      <div class="f align-center justify-center">
        <VueIcon
          height="28px"
          color="#3fd986"
          icon="mingcute:arrows-right-line"
        />
      </div>
      <div @click="goExternalLink(item.target_url)" class="market_wrap">
        <div class="f g5 align-center">
          <img class="img-xsmall" :src="getMarketIcon(item.target_name)" />
          <span :style="{ color: '#fff' }" class="text-white">
            {{
              (item.target_price / 1000).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            }}
          </span>
        </div>
        <div class="f g5 align-center">
          <VueIcon height="25px" color="white" icon="radix-icons:stack" />
          <span :style="{ color: '#fff' }" class="text-white">
            {{ item?.target_qty || "1+" }}
          </span>
        </div>
      </div>
    </div>

    <div class="f align-center justify-center">
      <span
        class="body_span"
        :style="{ color: item.profit >= 0 ? '#3fd986' : 'red' }"
        >{{
          (item.profit / 1000).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}
      </span>
    </div>
    <div class="f align-center justify-center">
      <span
        class="body_span"
        :style="{ color: item.profitRatio >= 0 ? '#3fd986' : 'red' }"
      >
        {{
          (parseFloat(item?.profitRatio) / 100).toLocaleString("en-US", {
            style: "percent",
            maximumFractionDigits: 2,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import copy from "../../assets/images/copy-white.png";
import edit from "../../assets/images/edit.png";
import tick from "../../assets/images/tick-white.png";
import DateUtils from "@/utils/DateUtils";
import MarketOptions from "../../core/constants.js";

export default {
  data() {
    return {
      MarketOptions,
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      typeColor: "white",
      globalText: "",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goRoute(item) {
      this.$emit("goItem", item);
    },
    addWatchlist(name) {
      this.$emit("addWatchlist", name);
    },
    copyText(name) {
      this.$emit("copyText", name);
    },
    goExternalLink(link) {
      this.$emit("externalLink", link);
    },
    getMarketIcon(name) {
      if (!name) return;
      const found = this.MarketOptions.find(
        (e) => e.name.toLocaleLowerCase() === name.toLocaleLowerCase()
      );
      if (found) return found.icon;
    },
    getType() {
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  created() {
    this.globalText = `This item has the lowest price globally on ${this.item.source_name}`;
  },
};
</script>

<style scoped>
.crown {
  position: absolute;
  top: -4px;
  left: 4px;
  cursor: pointer;
}

.item_grid_row {
  display: grid;
  grid-template-columns: 1fr 3fr 2.5fr 1fr 1fr;
  background: #21242c;
  border-radius: 0.2rem;
  padding: 0.4rem 0.8rem;
  transition: 0.2s;
  max-height: 9rem;
  min-height: 6rem;
  border: 1px transparent solid;
  position: relative;
}

.steam_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0rem 0rem;
  margin-top: 4px;
}

.market_outer {
  margin-left: 3rem;
  gap: 1rem;
}

.market_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 0rem 0.8rem;
  border-radius: 4px;
}

.market_wrap:hover {
  cursor: pointer;
  transition: 0.4s;
  background-color: #21242c;
}

.item_grid_row:hover {
  background-color: transparent;
  border: 1px #8650ac solid;
}

.item_grid_row:hover .img {
  transition: 0.2s;
  transform: scale(1.1);
  filter: drop-shadow(0 0 11px #757373);
}

.item_grid_row:not(hover) .img {
  transition: 0.2s;
  transform: scale(1);
}

.small_span {
  font-size: 11.5px;
  font-weight: bold;
}

.type_span {
  font-size: 12px;
  font-weight: bold;
}

.mid_span {
  font-size: 13px;
  font-weight: bold;
}

.body_span {
  font-size: 16px;
  font-weight: bold;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

.img-xsmall {
  height: 25px;
  width: 25px;
}

.img {
  width: 100%;
  max-width: 100px;
  width: 80px;
}

.img-block {
  background-image: radial-gradient(
    circle,
    #33373a 0% 30%,
    #2a2c2f 30% 50%,
    #242628 50% 70%,
    #464b51 70% 90%,
    #242628 90% 100%
  );
  background-position: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.item_grid_row:hover .img {
  filter: drop-shadow(0 0 10px var(--hover-color));
}
</style>
