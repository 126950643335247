<template>
    <div class="item-modal">
        <div class="modal-content">
            <div class="header">
                <h3 :style="{ color: item.rarity_color }">{{ item.name }}</h3>
                <span class="close" @click="closeModal">&times;</span>
            </div>
            <div class="body">
                <div class="item-photo-container">
                    <img :src="item.img" alt="Item Photo" class="item-photo">
                    <button class="watchlist-button" @click="toggleWatchlist()">
                        <img :src="watchlistIcon" alt="Watchlist Icon">
                        <span class="sp">{{ this.buttonText }}</span>
                    </button>
                </div>
                <div v-if="isLoading" class="loading-screen">
                    <div class="loading-spinner"></div>
                </div>
                <table class="item-properties">
                    <tr>
                        <th>Steam</th>
                        <th>Buff</th>
                        <th>Shadow</th>
                        <th>MarketCSGO</th>
                        <th>Market Volume</th>
                    </tr>
                    <tr>
                        <td>$ {{ item.steam_last_7d }}</td>
                        <td>$ {{ item.buff_starting_at }}</td>
                        <td>$ {{ item.shadow_price }}</td>
                        <td>$ {{ item.market_price }}</td>
                        <td> {{ item.cstm_volume }}</td>
                    </tr>
                </table>
                <table class="item-properties">
                    <tr>
                        <th>Market Profit</th>
                        <th>Market (%)</th>
                        <th>Wax</th>
                        <th>Wax Profit</th>
                        <th>Wax (%)</th>
                    </tr>
                    <tr>
                        <td :style="{ color: item.buff_market_profit > 0 ? 'green' : 'red' }">$ {{ item.buff_market_profit
                        }}</td>
                        <td :style="{ color: item.buff_market_ratio > 0 ? 'green' : 'red' }">% {{ item.buff_market_ratio }}
                        </td>
                        <td>$ {{ item.wax_price }}</td>
                        <td :style="{ color: item.buff_wax_profit > 0 ? 'green' : 'red' }">$ {{ item.buff_wax_profit }}
                        </td>
                        <td :style="{ color: item.buff_wax_ratio > 0 ? 'green' : 'red' }">% {{ item.buff_wax_ratio }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script>
import bookmarked from '../../assets/images/bookmarked.svg';
import addBookmark from '../../assets/images/add-bookmark.png';
import WatchlistService from '../../services/WatchlistService.js'
import loadingAnimation from '../../assets/lottieFiles/success.json';

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loadingIcon: loadingAnimation,
            isLoading: false,
            watchlistIcon: addBookmark,
            isAddedToWatchlist: false,
            itemCheck: false,
            buttonText: "Add To Watchlist",
            appID: 730
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async toggleWatchlist() {
            this.isAddedToWatchlist = !this.isAddedToWatchlist;
            this.isLoading = true;

            if (this.isAddedToWatchlist) {
                await this.addWatchlist();
            } else {
                await this.removeWatchlist();
            }

            await this.checkItem();
            this.isLoading = false;
        },
        async addWatchlist() {
            await WatchlistService.addWatchlist({
                appid: 730,
                market_hash_name: this.item.name
            });
        },
        async removeWatchlist() {
            this.feedbackMsg = await WatchlistService.deleteWatchlist({
                appid: 730,
                market_hash_name: this.item.name
            });
            await this.checkItem();
        },
        async checkItem() {
            this.isAddedToWatchlist = await WatchlistService.fetchWatchItem({
                market_hash_name: this.item.name,
                app_id: this.appID || 730
            });
            this.updateBookIcon();
            this.updateBookText();
        },
        updateBookIcon() {
            this.watchlistIcon = this.isAddedToWatchlist
                ? bookmarked
                : addBookmark
        },
        updateBookText() {
            this.buttonText = this.isAddedToWatchlist
                ? "Remove Item"
                : "Add To Watchlist"
        }
    },
    async created() {
        await this.checkItem();
    }

};
</script>

  
<style scoped>

h3 {
    color: white;
}

.item-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}

.modal-content {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    min-height: 400;
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 15px;
}

.close {
    font-size: 40px;
    padding-bottom: 5px;
    cursor: pointer;
    color: red
}

.body {
    display: block;
}

.item-photo {
    width: 200px;
    height: auto;
    margin-right: 20px;
}

.properties {
    display: flex;
    flex-direction: column;
}

.property {
    margin-bottom: 10px;
}

.label {
    font-weight: bold;
    border-bottom: none
}

.value {
    margin-left: 5px;
}


.item-properties {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.item-properties th,
.item-properties td {
    padding: 8px;
    border: 1px solid #ffffff;
    text-align: center;
}

.item-properties th {
    background-color: #c6c2c2;
    font-weight: bold;
}

.item-properties td {
    background-color: #1a1a1a;
    color: white;
    font-size: 15px
}

.item-photo-container {
    display: flex;
    align-items: flex-start;
}

.item-photo {
    width: 200px;
    height: auto;
    margin-right: 20px;
}

.watchlist-button {
    padding: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.watchlist-button:hover {
    opacity: 0.8;
    border-radius: 30px;
}

.watchlist-button img {
    width: 50px;
    height: 50px;
}

.sp {
    font-size: 16px;
    color: white;
    margin-left: 5px;
}
</style>
