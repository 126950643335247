<template>
    <div class="contact-us-page">
        <div>
            <h1>Contact With Us</h1>

            <a href="mailto:admin@steamanalyzer.com" class="admin-email"  target="_blank" rel="noreferrer noopener">
                <VueIcon icon="mdi:email-outline" width="40px" color="white" />
                <p>Mail Address: admin@steamanalyzer.com</p>
                
            </a>
        </div>

    </div>
    
</template>
  
<script>
export default {
    methods: {
    }
};
</script>
  
<style scoped>
.admin-email {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.admin-email img:hover {
    animation: anim 0.3s ease-in;
}

@keyframes anim {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-10px);
  }
}
.contact-us-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1A1C2A;
    color: white;
    text-align: center;
    height: 60vh;
}

.admin-email p {
    font-size: 20px;
    color: white;
    text-decoration: none;
    margin: 0;
}
</style>
  