<template>
    <div class="search_bar_custom">
        <span>$</span>
        <input id="desk-search" v-model="search" @input="handle" type="decimal" :placeholder="holder"
            class="search_input">
    </div>
</template>

<script>
export default {
    props: {
        holder: {
            type: String,
            default: 'Amount'
        }
    },
    data() {
        return {
            search: "",
        };
    },
    methods: {
        handle() {
            this.$emit('handleInput', this.search);
        }
    }
};

</script>

<style>
.search_input {
    border-radius: 4px;
    font-size: 0.8rem;
    margin-left: 5px;
    border-width: 0px 0px 1px;
    background-color: #212529;
    text-align: left;
    letter-spacing: 0.5px;
    color: beige;
    width: 90%;
    min-height: 40px;
    border-bottom: none;
}

input:focus {
    outline: none;
}

.search_bar_custom {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding-left: 10px;
    border-radius: 4px;
    background-color: #212529;
    border: 1.5px #454545 solid;
}

.search_bar_custom:focus-within {
    border: 1px rgb(134, 80, 172) solid;
}

.search_bar_custom:hover {
    border: 1px rgb(134, 80, 172) solid;
    transition: 0.4s;
}

.search_input {
    padding-left: 8px;
    outline-width: 0;
}

@media screen and (max-width: 576px) {
    .search_bar_custom {
        width: 100%;
    }
}
</style>