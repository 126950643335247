<template>
  <footer>
    <div class="footer">
      <div class="">
        <a
          class="mx-3"
          href="https://x.com/steamanalyzer"
          target="_blank"
          rel="noreferrer noopener"
          id="twitter_btn"
        >
          <VueIcon
            class="ic"
            icon="pajamas:twitter"
            color="white"
            width="30px"
          />
        </a>
        <a
          class="mx-3"
          href="https://discord.gg/nfCfpE3d6V"
          target="_blank"
          rel="noreferrer noopener"
          id="discord_btn"
        >
          <VueIcon
            class="ic"
            icon="pajamas:discord"
            color="white"
            width="30px"
          />
        </a>
      </div>

      <div class="row">
        <ul>
          <li><a href="contact">Contact us</a></li>
          <li><a href="#">Our Services</a></li>
          <li><a href="#">API Access</a></li>
          <li><a href="#">Privacy Policy</a></li>
          <li><a href="#">Terms & Conditions</a></li>
        </ul>
      </div>

      <div class="row justify-content-center">
        <p>© SteamAnalyzer.com 2024. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      author: "Numan KILINCOGLU",
      appName: "Inventory Manager ©",
    };
  },
};
</script>

<style scoped>
p {
  font-size: 2.2vh;
}

.footer {
  background: #000;
  padding: 30px 0px;
  text-align: center;
}

.footer .row {
  width: 100%;
  margin: 1% 0%;
  padding: 0.6% 0%;
  color: gray;
  font-size: 1.5em;
}

.footer .row a {
  text-decoration: none;
  color: gray;
  transition: 0.5s;
  font-size: 2.2vh;
  cursor: pointer;
}

.footer .row a:hover {
  color: #fff;
}

.footer .row ul {
  width: 100%;
}

.footer .row ul li {
  display: inline-block;
  margin: 0px 30px;
}

.footer .row a i {
  font-size: 1em;
  margin: 0% 1%;
}

.ic:hover {
  transition: 0.2s;
  transform: translateY(-10px);
}

.ic:not(hover) {
  transition: 0.2s;
  transform: translateY(0px);
}

@media (max-width: 720px) {
  .footer {
    text-align: left;
    padding: 5%;
  }

  .footer .row ul li {
    display: block;
    margin: 10px 0px;
    text-align: left;
  }

  .footer .row a i {
    margin: 0% 3%;
  }

  .icon-mid {
    height: 30px;
    width: 30px;
  }

  img {
    animation: none;
  }
}
</style>
