<template>
    <div class="item-detail-modal">
        <div class="detail-modal-content">
            <div class="list-header">
                <h5 class="text-white bold">{{ item.name }}</h5>
                <div class="detail-close" @click="closeModal">&times;</div>
            </div>
            <div class="body">
                <div class="top_section">
                    <div class="top_left">
                        <img :src="item.img" alt="Item Photo" class="item-photo">
                    </div>
                    <div class="graph_wrap">
                        <h6>CSGOTM Item History</h6>
                        <LineChart :data="history" :labelName="'Price'" />
                    </div>
                </div>
                <div class="mobile_item_img">
                    <img :src="item.img" alt="Item Photo" class="item-photo">
                </div>
                <div class="mobile_chart">
                    <h6>CSGOTM Item History</h6>
                    <LineChart :chartOption="lineOptions" :data="history" :labelName="'Price'" />
                </div>

                <div v-if="isLoading" class="loading-screen">
                    <div class="loading-spinner"></div>
                </div>
                <div class="btn_wrap">
                    <h6>Buying Price: {{ item.itemPrice }} $</h6>
                    <div class="discount">
                        <h6>{{ item.discount }}%</h6>
                    </div>
                    <a target="_blank" :href="item.link">
                        <button class="buy_btn">
                            Buy
                        </button>
                    </a>
                </div>
                <div>
                    <a
                        :href="item?.mininimumPrice?.url"
                        target="_blank"
                        class="body-title href"
                        >Min. Price: {{ item?.mininimumPrice?.market_name }} >
                        ${{
                          (item?.mininimumPrice?.price / 1000).toFixed(2)
                        }}</a
                      >
                </div>
                <div class="info_grid">
                    <div @mousedown.middle="goExternalUrl(item[`${platform.name.toLowerCase()}_url`])" @click="goExternalUrl(item[`${platform.name.toLowerCase()}_url`])" v-for="platform in item.markets"
                        :key="platform.name" class="item_info">
                        <div class="f sb">
                            <h6>{{ platform.name }}</h6>
                            <img :src="getIcon(platform.name)" style="width: 22px; height: 22px;" />
                        </div>

                        <span class="info_span">Net: {{ platform.net.toFixed(2) }}</span>
                        <span :style="{ color: getProfitColor(platform.profit) }" class="info_span">
                            Profit:{{ platform.profit.toFixed(2) }} (%{{ platform.ratio.toFixed(2) }})
                        </span>
                        <span class="info_span">Volume: {{ platform.volume }}</span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import addBookmark from '../../assets/images/add-bookmark.png';
import MarketCSGOService from '../../services/MarketCSGOService.js';
import loadingAnimation from '../../assets/lottieFiles/success.json';

import LineChart from '@/components/charts/LineChart';

import csgotmIcon from '../../assets/marketIcons/csgotm_icon.png';
import shadowIcon from '../../assets/marketIcons/shadowpay_icon.png';
import portIcon from '../../assets/marketIcons/skinport_icon.png';
import waxIcon from '../../assets/marketIcons/waxpeer_icon.png';
import whiteIcon from '../../assets/marketIcons/whitemarket_icon.png';
import bitskins from '../../assets/marketIcons/bitskins_icon.png';
import haloIcon from '../../assets/marketIcons/haloskins_icon.png';
import csfloat from '../../assets/marketIcons/csgofloat_icon.png';
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loadingIcon: loadingAnimation,
            isLoading: false,
            watchlistIcon: addBookmark,
            isAddedToWatchlist: false,
            itemCheck: false,
            buttonText: "Add To Watchlist",
            columns:
                [
                    'Net Price: ',
                    'Profit :',
                    'ROI :',
                    'Volume:',
                ],
            lineOptions: {
                responsive: true,
                maintainAspectRatio: false,
                tension: 0.3,
                scales: {
                    y: {
                        ticks: { color: 'white', beginAtZero: true }
                    },
                    x: {
                        ticks: { color: 'white', beginAtZero: true }
                    }
                },
                plugins: {
                    legend: {
                        labels: {
                            color: 'white',
                            fontSize: 18
                        }
                    }
                }
            },
            lightGreen: '#adf6ad',
            history: [],
            marketOptions:
                [
                    { name: 'Waxpeer', icon: waxIcon },
                    { name: 'MarketCSGO', icon: csgotmIcon },
                    { name: 'Bitskins', icon: bitskins },
                    { name: 'Skinport', icon: portIcon },
                    { name: 'White Market', icon: whiteIcon },
                    { name: 'Shadowpay', icon: shadowIcon },
                    { name: 'HaloSkins', icon: haloIcon },
                    { name: 'CSFloat', icon: csfloat },
                ],
        }
    },
    methods: {
        closeModal() {
            const htmlElement = document.querySelector('html');
            htmlElement.style.overflowY = 'auto';
            this.$emit('close');
        },
        getColumnValue() {

            if (!this.item && !this.item.markets) return;

            this.item.markets.forEach(e => {
                const ic = this.marketOptions.find(el => el.name == e.name);
                if (!ic) return;
                e.icon = ic.icon;
            })

        },
        getProfitColor(profit) {
            return profit > 0 ? 'lightGreen' : 'red';
        },
        async getInventoryValue() {
            this.valueLoading = true;
            const response = await MarketCSGOService.getItemHistory({
                name: this.item.name
            });

            if (!response) return;

            await this.parseData(response.data);

            this.valueLoading = false;
        },
        async parseData(data) {

            const itemName = Object.keys(data)[0];

            if (!itemName) return;
            if (!data[itemName]["history"]) return;

            const historyData = data[itemName]["history"];

            const resultArray = historyData.map(entry => {
                const timestamp = entry[0] * 1000;
                const date = new Date(timestamp);
                const value = entry[1];

                return { date: date.toLocaleDateString(), value: value };
            }).reverse();

            if (!resultArray) return this.history = [];
            this.history = resultArray;
        },
        goExternalUrl(link) {
            window.open(link)
        },
        getIcon(name) {
            const i = this.marketOptions.find(e => e.name === name);
            if (i != undefined) return i.icon;
        },
        sortProviders() {
            const items = this.marketValues.sort((a, b) => {
                return b.value - a.value;
            });
            return items;
        }

    },
    computed: {
        platforms() {
            return [
                { name: 'Waxpeer', key: 'wax' },
                { name: 'Shadowpay', key: 'shadow' },
                { name: 'MarketCSGO', key: 'market' },
                { name: 'Skinport', key: 'skinport' },
                { name: 'Bitskins', key: 'bitskins' },
                { name: 'White Market', key: 'white' },
            ];
        },
    },
    async created() {
        await this.getInventoryValue();
        this.getColumnValue();
    },
    components: { LineChart }
}

</script>


<style scoped>
.href {
  color: yellow;
  text-decoration: none;
}
h5 {
    font-size: 25px;
    font-weight: bold;
}
.graph_wrap {
    width: 60%;
}

.mobile_chart {
    display: none;
}

.top_section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.buy_btn {
    border: none;
    background-color: orange;
    color: white;
    border-radius: 3px;
    width: 100px;
    height: 28px;
    transition: 0.5s;
}

.buy_btn:hover {
    background-color: rgb(208, 139, 11);
}

.btn_wrap {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.discount {
    border: 1px #adf6ad solid;
    font-size: 15px;
    padding: 1px 6px;
    color: white;
    height: 28px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.info_grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 15px;
}

.info_span {
    font-size: 0.8rem;
    font-weight: 600;
}

.info_cell {
    display: flex;
    align-items: center;
    gap: 2%;
    cursor: pointer;
}

h6 {
    color: white;
    margin-bottom: 0px;
}

.item_info {
    min-height: 50px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    border: 1px gray solid;
    border-radius: 4px;
    padding: 0.5rem;
    min-width: 150px;
    transition: 0.4s;
}

.item_info:hover {
    background-color: rgb(51, 50, 50);
}

.item-detail-modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}

.detail-modal-content {
    overflow-y: auto;
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    min-height: 70%;
    height: 90%;
    width: 100%;
    position: absolute;
    left: 12%;
    top: 4%;
}

.list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    position: sticky;
    top: 0px;
    background-color: rgb(26, 26, 26, 1);
}

.detail-close {
    font-size: 45px;
    cursor: pointer;
    color: red;
    transition: 0.5s;
}

.detail-close:hover {
    color: rgb(254, 49, 49);
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 10px;
}

.item-photo {
    height: 60%;
}


.top_left {
    display: flex;
    align-items: center;
    width: 10%;
}

.mobile_item_img {
    display: none;
}

@media screen and (max-width: 900px) {

    .item-detail-modal {
        overflow-y: auto;
    }

    .detail-modal-content {
        max-width: 100%;
        min-height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .info_grid {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
        margin-top: 15px;
    }

    .btn_wrap {
        margin-top: 0px;
        justify-content: space-between;
    }

    .mobile_item_img {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile_item_img img {
        width: 50%;
    }

    .body {
        gap: 20px;
    }

    .top_section {
        display: none;
    }

    .graph_wrap {
        display: none;
    }

    .mobile_chart {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 280px;
        margin-bottom: 15px;
    }

    .detail-modal-content {
        background-color: #1a1a1a;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        max-width: 100%;
        min-height: 100%;
        width: 100%;
    }

    .info_grid {
        gap: 15px;
        justify-content: space-between;
        margin-top: 0px;
    }

    .item_info {
        display: flex;
        flex-direction: column;
        border: 1px gray solid;
        border-radius: 6px;
        padding: 0.8rem;
        min-width: 175px;
    }

    .buy_btn {
        height: 35px;
    }

}
</style>
