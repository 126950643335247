<template>
    <div class="filter_bar">
        <div class="filter_tab">
            <h6 :style="{ color: item.name_color }">Marketplace Prices</h6>
            <h6 :style="{ color: item.name_color }"> {{ count }} Markets</h6>
            <div v-if="false" class="f align-center g10">
                <h6 class="text-white">Sort</h6>
                <SortDropdown :order-by-function="setOrderBy" :options="orderOptions" :selectedItem="selectedOrder"
                    :selectedIcon="null" :selected-dir="selectedDirection" :order-dir-function="setOrderDir" />
            </div>
        </div>
    </div>
</template>

<script>
import cs2 from '../../assets/images/csgo.png'
import SortDropdown from '../dropdownButtons/SortDropdown.vue';

export default {
    components: { SortDropdown },
    data() {
        return {
            filterObject: {
                appID: 730,
                pageSize: 10,
                pageNumber: 1,
                orderDir: "DESC",
                orderBy: "date"
            },
            cs2,
            exteriors: [
                'BS',
                'WW',
                'FT',
                'MW',
                'FN'
            ],
            exteriorsExtended: [
                { name: 'Factory New', value: 10009.2, color: 'white', code: 'FN' },
                { name: 'Minimal Wear', value: 1200, color: 'white', code: 'MW' },
                { name: 'Field Tested', value: 21000, color: 'white', code: 'FT' },
                { name: 'Well Worn', value: 4000, color: 'white', code: 'WW' },
                { name: 'Battle Scarred', value: 12000, color: 'white', code: 'BS' },
                { name: 'StatTrak™ Factory New', value: 1000, color: '#CF6931', code: 'STFN' },
                { name: 'StatTrak™ Minimal Wear', value: 1000, color: '#CF6931', code: 'STMW' },
                { name: 'StatTrak™ Field Tested', value: 1000, color: '#CF6931', code: 'STFT' },
                { name: 'StatTrak™ Well Worn', value: 14000, color: '#CF6931', code: 'STWW' },
                { name: 'StatTrak™ Battle Scarred', value: 11000, color: '#CF6931', code: 'STBS' }
            ],
            selectedExterior: null,
            orderOptions: ["Date", "Price", "Name"],
            selectedOrder: "Price",
            selectedDirection: "DESC",
        }
    },
    methods: {
        findExterior() {
            this.selectedExterior = this.exteriors.find(e => e === this.item.exterior_code);
        },
        async setOrderDir(isAscOrder) {
            this.filterObject.orderDir = this.selectedDirection = isAscOrder ? "ASC" : "DESC";
        },
        async setOrderBy(option) {
            this.filterObject.orderBy = option.toLowerCase();
            this.selectedOrder = option;
        },
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        count: {
            type: Number,
            default: 0
        }
    },
    created() {
        this.findExterior();
    }
};
</script>

<style scoped>
h5,
h6 {
    color: white;
    margin-bottom: 0;
}

.filter_bar {
    min-width: 100%;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.filter_tab {
    padding: 1rem 1.2rem;
    min-height: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #313156;
    overflow: hidden;
    border-radius: 4px;
    color: white;
    border-bottom: none;
}

@media screen and (max-width: 769px) {
    .filter_bar {
        margin-bottom: 0;
    }

}
</style>
