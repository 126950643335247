<template>
  <div class="f sb align-bottom mt-2 mobile">
    <div>
    </div>
    <div class="f g10 flex-wrap">
      <div class="f flex-column flex-start">
        <h6 class="text-white">Sort</h6>
        <SortDropdown
          :order-by-function="setOrderBy"
          :options="orderOptions"
          :selectedItem="selectedOrder"
          :selected-dir="selectedDirection"
          :order-dir-function="setOrderDir"
          :height-option="'40px'"
        />
      </div>

      <div class="f flex-column flex-start">
        <h6 class="text-white">Method</h6>
        <SelectorDropdown
          style="min-width: 120px"
          :refFunction="setMethod"
          :options="methodOptions"
          :selectedItem="selectedMethod"
        />
      </div>
      <div class="f flex-column flex-start">
        <h6 class="text-white">Type</h6>
        <SelectorDropdown
          style="min-width: 120px"
          :refFunction="setType"
          :options="typeOptions"
          :selectedItem="selectedType"
        />
      </div>
      <div class="f flex-column flex-start">
        <h6 class="text-white">Size</h6>
        <SelectorDropdown
          style="min-width: 80px"
          :refFunction="setSize"
          :options="sizeOptions"
          :selectedItem="selectedSize"
          :heightOption="'40px'"
        />
      </div>
    </div>
  </div>

  <div class="table_wrap">
    <div
      v-if="!itemLoading && items && items.length > 0"
      class="table-responsive mt-3"
    >
      <table class="table">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column" class="text-sm">
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index" class="text-xs">
            <td style="cursor: pointer" @click="copyText(item.id, 'ID')">
              #{{ item.id }}
            </td>
            <td>{{ DateUtils.getDate(item.created_at) }}</td>
            <td>
              <div class="status_div" :class="getColor(item.operation_status)">
                {{ item.operation_status }}
              </div>
            </td>
            <td>{{ item.operation_type }}</td>
            <td>{{ item.payment_method }}</td>
            <td>{{ item.wallet }}</td>
            <td style="font-weight: bold;" :class="item.amount > 0 ? 'green' : 'red'">
              $ {{ item.amount }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else-if="!itemLoading && items.length == 0"
      class="text-center text-white mt-5"
    >
      <h4>No transactions found.</h4>
    </div>
    <div v-else class="f justify-center align-center mt-5">
      <div class="dot_loader"></div>
    </div>
  </div>
  <div class="page_row pt-2 pb-3 f align-center">
    <PaginationRow
      :pageNumber="filterObject.pageNumber"
      :totalPages="totalPages"
      @update:pageNumber="filterObject.pageNumber = $event"
    />
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
</template>
<script>
/* eslint-disable */
import InfoModal from "../components/modal/InfoModal.vue";
import SideBar from "@/components/shared/SideBar.vue";
import DateUtils from "@/utils/DateUtils.js";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";

import btc from "../assets/images/bitcoin.png";
import usdt from "../assets/images/tether.png";

import PaymentService from "@/services/PaymentService";

export default {
  data() {
    return {
      totalPages: 6,
      items: [],
      isMobile: false,
      searchQuery: "",
      modalMessage: "Price Successfully Updated",
      loading: true,
      infoLoading: false,
      itemLoading: true,
      isDropdownOpen: false,
      searchTimeout: null,
      showModal: false,
      filterObject: {
        pageSize: 12,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        method: "All",
        type: "All",
      },
      sizeOptions: ["10", "15", "25", "30"],
      selectedSize: "10",
      methodOptions: ["All", "ItemPay", "CryptoPay"],
      selectedMethod: "All",
      typeOptions: ["All", "DEPOSIT"],
      selectedType: "All",
      orderOptions: ["Date", "Amount", "Status"],
      selectedOrder: "Date",
      selectedDirection: "DESC",
      columns: ["ID", "Date", "Status", "Type", "Method", "Wallet", "Amount"],
    };
  },

  methods: {
    getColor(status) {
      if (!status) return "white";
      if (status == "COMPLETED") return "accepted";
      if (status == "FAILED") return "canceled";
      if (status == "PENDING") return "pending";
      return "white";
    },
    setTab(item) {
      this.selectedTab = item.code;
      this.selectedInterval = item;
      this.$router.push({ path: "/payment/" + item.path });
    },
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
      await this.getHistory();
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
      await this.getHistory();
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
      await this.getHistory();
    },
    async setSize(size) {
      this.selectedSize = size;
      this.isDropdownOpen = false;
      this.filterObject.pageSize = parseInt(size) ?? 10;
      await this.getHistory();
    },
    async setMethod(method) {
      this.selectedMethod = method;
      this.isDropdownOpen = false;
      this.filterObject.method = method;
      await this.getHistory();
    },
    async setType(type) {
      this.selectedType = type;
      this.isDropdownOpen = false;
      this.filterObject.type = type;
      await this.getHistory();
    },
    showSaleInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 4000);
    },
    copyText(item, type) {
      navigator.clipboard.writeText(item);
      this.modalMessage = `${type} copied!`;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 4000);
    },
    async showInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 1500);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async refreshData() {
      this.loading = true;
      await this.getHistory();
      this.loading = false;
    },
    async getHistory() {
      this.itemLoading = true;

      const response = await PaymentService.getAllTransactions({
        page: this.filterObject.pageNumber,
        limit: this.filterObject.pageSize,
        method: this.filterObject.method,
        type: this.filterObject.type,
        orderBy: this.filterObject.orderBy,
        orderDir: this.filterObject.orderDir.toLowerCase(),
      });

      if (!response || response.length === 0) {
        this.items = [];
        this.itemLoading = false;
        return;
      }

      this.items = response;
      this.itemLoading = false;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 1000;
    },
  },
  async created() {
    try {
      this.refreshData();
    } catch (err) {
      console.error(err);
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
    listedItems() {
      return this.items;
    },
  },
  watch: {
    "filterObject.pageNumber"() {
      this.getHistory();
    },
  },
  components: {
    InfoModal,
    SideBar,
    IconDropdown,
    SortDropdown,
    SelectorDropdown,
  },
};
</script>
<style scoped>
@import "../assets/style/customTable.css";

.table_wrap {
  min-height: 700px;
}

.table > tbody > tr > td {
  padding: 1rem 0.6rem;
  position: relative;
  z-index: 2;
  font-weight: 500;
  text-align: start;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.table > thead th {
  padding: 1rem;
  border: none;
  margin: 12px;
  text-align: start;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.accepted {
  background-color: rgb(73, 175, 65, 0.75);
}

.canceled {
  background-color: rgba(247, 15, 15, 0.75);
}

.pending {
  background-color: rgba(243, 214, 117, 0.75);
}

.status_div {
  padding: 0.4rem 0.5rem;
  border-radius: 0.4rem;
  width: 120px;
  text-align: center;
}

@media screen and (max-width: 728px) {
  .mobile {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px !important;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
@/services/PaymentService