<template>
    <div class="pagination">
      <a @click="movePage('left_right', -1)" class="previous">
        <VueIcon icon="mdi:arrow-left" width="20px" color="white" />
      </a>
      <a 
        v-for="(item, index) in pageNumbers" 
        :key="index" 
        @click="movePage('normal', item)" 
        class="btn" 
        :class="{ 'active': pageNumber === item }"
      >
        {{ item }}
      </a>
      <a @click="movePage('left_right', 1)" class="next">
        <VueIcon icon="mdi:arrow-right" width="20px" color="white" />
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PaginationRow',
    props: {
      pageNumber: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        pageNumbers: [1, 2, 3, 4, 5, 6],
      };
    },
    watch: {
      pageNumber() {
        this.updatePageNumbers();
      }
    },
    methods: {
      movePage(type, number) {
        let newPage = this.pageNumber;
        if (type === "left_right") {
          newPage = Math.max(1, this.pageNumber + number);
        } else if (type === "normal") {
          newPage = number;
        }
  
        this.$emit('update:pageNumber', newPage);
      },
      updatePageNumbers() {
        const currentPage = this.pageNumber;
        const maxPage = this.pageNumbers[this.pageNumbers.length - 1];
        const minPage = this.pageNumbers[0];
  
        if (currentPage > maxPage) {
          const diff = currentPage - maxPage;
          this.pageNumbers = this.pageNumbers.map(num => num + diff);
        } else if (currentPage < minPage) {
          const diff = minPage - currentPage;
          this.pageNumbers = this.pageNumbers.map(num => num - diff);
        }
      }
    },
    mounted() {
      this.updatePageNumbers();
    }
  };
  </script>
  
  <style scoped>
  .pagination {
    text-align: center;
    display: flex;
    gap: 2px;
  }
  
  .pagination a {
    font-family: "Open Sans", sans-serif;
    color: white;
    height: 40px;
    width: 40px;
    text-decoration: none;
    position: relative;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
  }
  
  .pagination a:hover {
    border: 1px solid #BB79F6;
    transition-duration: 0.1s;
    cursor: pointer;
  }
  
  .active {
    background: #BB79F6;
    transition-duration: 0.1s;
  }
  </style>
  