import { createRouter, createWebHistory } from "vue-router";

import LandingView from "@/views/LandingView.vue";
import LoginView from "@/views/LoginView.vue";
import UserDetailView from "@/views/UserDetailsView.vue";
import DashboardView from "@/views/DashboardView.vue";
import SaleHistory from "@/views/SaleHistory.vue";
import ListItems from "@/views/ListItems.vue";
import InventoryView from "@/views/InventoryView.vue";
import InventoryRankView from "@/views/InventoryRankView.vue";
import PublicInventoryView from "@/views/PublicInventoryView.vue";
import ListedItemsView from "@/views/ListedItemsView.vue";
import InvestmentView from "@/views/InvestmentView.vue";
import CalculationView from "@/views/CalculationView.vue";
import WatchListView from "@/views/WatchListView.vue";
import PortfolioView from "@/views/PortfolioView.vue";
import SplashView from "@/views/SplashView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
//import ConstructionPage from "@/views/ConstructionView.vue";

import TransactionHistory from "@/views/TransactionHistory.vue";
import TargetView from "@/views/TargetView.vue";

import Contact from "@/views/ContactView.vue";
import ItemView from "@/views/ItemView.vue";
import CollectionView from "@/views/CollectionView.vue";
import CaseView from "@/views/CaseView.vue";
import TrendView from "@/views/TrendView.vue";
import CaseSimulator from "@/views/CaseSimulator.vue";
import Comparison from "@/views/PriceComparison.vue";
import Markets from "@/views/MarketsView.vue";
import PaymentOperationsView from "@/views/PaymentOperationsView.vue";
import RefillBalanceView from "@/views/RefillBalanceView.vue";
import TransactionView from "@/views/PaymentTransactionView.vue";
import SubscriptionView from "@/views/SubscriptionView.vue";
import ItemDepositView from "@/views/ItemDepositView.vue";

import LogoutService from "@/services/LogoutService.js";
import store from "@/store/store.js"; // Vuex Store'a erişim sağlamak için

const routes = [
  {
    path: "/",
    component: LandingView,
    meta: {
      authRequired: false,
      metaTags: [
        {
          name: 'description',
          content: 'The login page has a description, maybe.'
        },
        {
          property: 'og:description',
          content: 'The login page has an open graph description, too, maybe.'
        }]
    },
  },
  {
    path: "/landing",
    name: "landing",
    component: LandingView,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/splash",
    name: "splash",
    component: SplashView,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: {
      title: "Dashboard",
      description: "",
      authRequired: true,
      transition: "slide-left",
    },
  },
  {
    path: "/marketplaces",
    name: "marketplaces",
    component: Markets,
    meta: {
      authRequired: false,
      transition: "slide-left",
      title: 'Discover Marketplaces'
    },
  },
  {
    path: "/trends",
    name: "trends",
    component: TrendView,
    meta: {
      authRequired: false,
      transition: "slide-left",
      title: 'Price Trends'
    },
  },
  {
    path: "/sales",
    name: "saleHistory",
    component: SaleHistory,
    meta: {
      authRequired: true,
      title: 'My Sales'
    },
  },
  {
    path: "/user-details",
    name: "user",
    component: UserDetailView,
    meta: {
      authRequired: true,
      title: 'User Details'
    },
  },
  {
    path: "/payment",
    name: "payment",
    component: PaymentOperationsView,
    children: [
      {
        path: "",
        name: "payment-default",
        component: RefillBalanceView
      },
      {
        path: "refill",
        name: "refill",
        component: RefillBalanceView
      },
      {
        path: "subscription",
        name: "subscription",
        component: SubscriptionView
      },
      {
        path: "transactions",
        name: "payment-transactions",
        component: TransactionView
      },
    ],
    meta: {
      authRequired: true,
      title: 'Payments'
    },
  },
  {
    path: "/calculation",
    name: "calculation",
    component: CalculationView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/payment/itempay",
    name: "itempay",
    component: ItemDepositView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/comparison",
    name: "Price Comparison",
    component: Comparison,
    meta: {
      authRequired: false,
      title: 'Price Comparison'
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: TransactionHistory,
    meta: {
      title: "Transactions",
      authRequired: true,
    },
  },
  {
    path: "/target",
    name: "target",
    component: TargetView,
    meta: {
      title: "Price Targets",
      authRequired: true,
    },
  },
  {
    path: "/watchlist",
    name: "watchlist",
    component: WatchListView,
    meta: {
      authRequired: true,
      title: 'My Watchlist'
    },
  },
  {
    path: "/analytics",
    name: "analytics",
    component: PortfolioView,
    meta: {
      authRequired: true,
      title: 'Steam Analyzer: Analytics'
    },
  },
  {
    path: "/list-items",
    name: "list-items",
    component: ListItems,
    meta: {
      authRequired: true,
      title: 'List Items'
    },
  },
  {
    path: "/inventory",
    name: "inventory",
    component: InventoryView,
    meta: {
      authRequired: true,
      title: 'My Inventory'
    },
  },
  {
    path: "/ranking",
    name: "ranking",
    component: InventoryRankView,
    meta: {
      authRequired: false,
      title: 'Inventory Ranking'
    },
  },
  {
    path: "/public-inventory/:steamid",
    name: "public-inventory",
    component: PublicInventoryView,
    meta: {
      authRequired: false,
      title: ''
    },
  },
  {
    path: "/:game/item/:slug",
    name: "item",
    component: ItemView,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/cs2/collection/:name",
    name: "collection",
    component: CollectionView,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/cs2/case/:name",
    name: "case",
    component: CaseView,
    meta: {
      authRequired: false,
      title: "",
    },
  },
  {
    path: "/cs2/simulator/:id?",
    name: "simulator",
    component: CaseSimulator,
    meta: {
      authRequired: false,
      title: "Open CS2 Case For Free",
    },
  },
  {
    path: "/listed-items",
    name: "listed-items",
    component: ListedItemsView,
    meta: {
      authRequired: true,
      title: 'My Listings'
    },
  },
  {
    path: "/investments",
    name: "investments",
    component: InvestmentView,
    meta: {
      authRequired: true,
      title: 'My Investments'
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: NotFoundView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      authRequired: false,
      title: 'Contact with Us'
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


async function getUserDetail() {
  try {
    const response = await store.dispatch("user/fetchAndSetUserData");
    if (!response) return false;
    return true;
  } catch (error) {
    return false;
  }
}

const defaultTitle = 'Steam Analyzer'

router.beforeEach(async (to, from, next) => {

  store.dispatch('ui/toggleRouteLoading', true);

  if (to.meta.authRequired || from.meta.authRequired) {
    try {

      const user = await getUserDetail();

      if (!user) {

        const response = await LogoutService.logoutConfig();
        if (!response) {
          store.dispatch('ui/toggleRouteLoading', false);
          document.title = to.meta.title || defaultTitle;
          next();
          return
        }

        store.dispatch('ui/toggleRouteLoading', false);
        document.title = to.meta.title || defaultTitle;
        return next({ name: "login" });
      } else {
        store.dispatch('ui/toggleRouteLoading', false);
        // Yetkilendirme gerekmiyorsa veya kullanıcı yetkilendirilmişse geçişe izin ver
        document.title = to.meta.title || defaultTitle;
        next();
      }
    } catch (error) {
      // Hata durumunda login sayfasına yönlendir
      store.dispatch('ui/toggleRouteLoading', false);
      console.error("Auth check error:", error);
      next({ name: "login" });
    }
  } else {
    // Yetkilendirme gerekmiyorsa geçişe izin ver
    store.dispatch('ui/toggleRouteLoading', false);
    document.title = to.meta.title || defaultTitle;
    next();
  }
});

export default router;
