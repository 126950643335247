<template>
    <div class="vote_bar">
        <div>
            <h5>{{ header }}</h5>
        </div>
        <ul class="ratings">
            <li v-for="(star, index) in stars" :key="index" @click="selectStar(index)" @mouseover="hoverStar(index)"
                @mouseleave="resetHover" class="star"
                :class="{ 'selected': index < selectedStar, 'hovered': index <= hoverIndex }">
            </li>
        </ul>
        <div class="f sb">
            <div class="f flex-column align-center">
                <span class="orange-span">{{ rating.value }}</span>
                <span class="sp" style="color: #9f9d9d;">Rating</span>
            </div>
            <div class="f flex-column align-center">
                <span class="orange-span">{{ rating.totalVote }}</span>
                <span class="sp" style="color: rgb(158, 156, 156);">Total Votes</span>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    data() {
        return {
            stars: [1, 2, 3, 4, 5],
            selectedStar: this.rating.value,
            hoverIndex: -1
        }
    },
    methods: {
        selectStar(index) {
            this.selectedStar = index;
            this.$emit('setVote', this.selectedStar + 1);
        },
        hoverStar(index) {
            this.hoverIndex = index;
        },
        resetHover() {
            this.hoverIndex = -1;
        }
    },
    props: {
        header: {
            type: String
        },
        rating: {
            type: Object,
            required: true
        }
    },
    watch: {
        'rating.value'(newValue) {
            this.selectedStar = newValue;
        },
    },
    mounted() {
        this.selectedStar = this.rating.value;
    }
};
</script>

<style scoped>
.star.hovered {
    color: #e87c42;
}

.orange-span {
    color: #e87c42;
    font-size: 18px;
}

.sp {
    font-size: 13px;
}

h5,
h6 {
    color: white;
    margin-bottom: 0;
}

.vote_bar {
    width: 100%;
    min-height: 10vh;
    background-color: #313156;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 3px;
}

/* Star vote */
.ratings {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    direction: ltr;
    display: flex;
    justify-content: space-between;
}

.star {
    position: relative;
    line-height: 50px;
    display: inline-block;
    transition: color 0.2s ease;
    cursor: pointer;
    color: #404b5c;
}

.star:before {
    content: '\2605';
    width: 30px;
    height: 30px;
    font-size: 30px;
}

.star:hover,
.star.selected,
.star.selected {
    transition: color 0.8s ease;
    color: #e87c42;
}
</style>
