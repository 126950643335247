import { apiInstance } from "@/utils/ApiUtil";
const API_URL = "/investments";

export const getAll = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + "/get-all", {
            params: {
                currentPage: req.currentPage,
                pageSize: req.pageSize,
                sortBy: req.sortBy,
                sortOrder: req.sortOrder,
                priceFrom: req.priceFrom,
                priceTo: req.priceTo,
                market: req.market,
                q: req.search
            }
        });

        if (!response.data.success) return [];
        return response.data.items;
    } catch (error) {
        return [];
    }
};

export const exportAll = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + "/export-all", {
            params: {
                pageSize: req.pageSize,
                sortBy: req.sortBy,
                sortOrder: req.sortOrder,
                market: req.market
            }
        });

        if (!response.data.success) return [];
        return response.data.items;
    } catch (error) {
        return [];
    }

}
export const getTotalInvest = async () => {

    try {
        const response = await apiInstance.get(API_URL + "/total-invest");
        if (!response.data.success) return 0;

        return response.data.data;
    } catch (error) {
        return 0;
    }
};


export const getTotalProfit = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + "/total-profit", {
            params: {
                market: req.market
            }
        });

        if (!response.data.success) return 0;

        return response.data.data;
    } catch (error) {
        return 0;
    }

};

export const addItem = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + "/", {
            name: req.name,
            category: req.category,
            quantity: req.quantity,
            buyPrice: req.buyPrice,
            buyDate: req.buyDate,
            item_id:req.item_id
        });

        return response.data.success;
    } catch (error) {
        return false;
    }

};

export const deleteItem = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + "/delete", {
            investment_id: req.investmentID,
            app_id: req.appID,
            item_id: req.itemID
        });

        if (!response || !response.data) return false;

        return response.data.success;
    } catch (error) {
        return false;
    }

};

export const updateItem = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + "/update-item", {
            item: {
                id: req.id,
                name: req.name,
                category: req.category,
                quantity: req.quantity,
                buyPrice: req.buyPrice,
                buyDate: req.buyDate,
            }
        });
        return response.data.success;
    } catch (error) {
        return false;
    }

};

export const importCSV = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + "/import-investment", {
            items: req.items
        });

        return response.data;

    } catch (error) {
        return false;
    }

};

export const getGraph = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + "/graph", {
            params: {
                market: req.market,
                interval: req.interval,
            }
        });

        if (!response.data.success) return [];
        return response.data.data;
    } catch (error) {
        return [];
    }

};


export const getValuations = async () => {

    try {
        const response = await apiInstance.get(API_URL + `/valuation`, {});

        if (!response.data.success) return false;
        return response.data.data;
    } catch (error) {

        return false;
    }
};


const InvestmentService = {
    getAll,
    exportAll,
    importCSV,
    addItem,
    updateItem,
    getTotalInvest,
    getTotalProfit,
    getGraph,
    getValuations,
    deleteItem
}

export default InvestmentService;