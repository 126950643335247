<template>
    <div class="paginaiton-row pb-4">
        <button class="pagination-button" @click="$emit('movePage', -1)">
            <img class="pagination-icon" :src="leftArrow" />
        </button>
        <span class="page-number"> {{ pageNumber }} </span>
        <button class="pagination-button" @click="$emit('movePage', 1)">
            <img class="pagination-icon" :src="rightArrow" />
        </button>
    </div>
</template>

<script>

import leftIcon from '@/assets/images/left-arrow.png';
import rightIcon from '@/assets/images/right-arrow.png';

export default {
    name: 'PaginationBar',
    props: {
        pageNumber: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            leftArrow: leftIcon,
            rightArrow: rightIcon
        }
    },

};
</script>

<style scoped>
.pagination-button {
    transition: 0.3s;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0px;
    border-radius: 50%;
    border: 2px solid transparent;
}

.pagination-button:hover {
    border: 2px rgb(134, 80, 172) solid;
}

.pagination-icon {
    height: 35px;
    width: 35px;
}

.page-number {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  height: 35px;
  width: 40px;
  font-size: 16px;
  font-weight: 500;
}

</style>