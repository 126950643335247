export const data = {
  labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs', 'VueJs', 'EmberJs', 'ReactJs', 'EmberJs', 'ReactJs',],
  datasets: [
    {
      backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
      data: [40, 20, 80, 10],
    }
  ]
}

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      labels: {
        fontSize: 18,
        color: '#fff'
      }
    }
  },
  animation: {
    animateRotate: true,
    animateScale: true
  },
  cutoutPercentage: 90, 
}