import { fetchInitialData } from '@/services/InitService.js';

const userModule = {
  namespaced: true,
  state: () => ({
    username: '',
    avatar: '',
    profileURL: '',
    isAuthenticated: false,
    balance: 0,
  }),
  getters: {
    getUsername: (state) => state.username,
    getUserAvatar: (state) => state.avatar,
    getIsAuthenticated: (state) => state.isAuthenticated,
    getUserBalance: (state) => state.balance,
    getUserProfile: (state) => state.profileURL,
  },
  mutations: {
    setUserData(state, userData) {
      state.username = userData.user_name;
      state.avatar = userData.avatar_medium;
      state.profileURL = userData.profile_url;
      state.isAuthenticated = true;
      state.balance = userData.balance;
    },
  },
  actions: {
    async fetchAndSetUserData({ commit }) {
      try {
        const userData = await fetchInitialData();
        if (!userData) return false;
        commit('setUserData', userData);
        return true;
      } catch (error) {
        console.error('Error fetching user data:', error);
        return false;
      }
    },
  },
};

export default userModule;