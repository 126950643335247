import moment from 'moment';

function convertUnix(time) {
    return moment(time, "MM-DD-YYYY").unix();
}

function unixToDate(unix) {
    return moment.unix(unix).format("DD-MM-YYYY / hh:mm ss");
}

function getDate(date) {
    return moment(String(date)).format('DD-MM-YYYY hh:mm');
}

function getLocalDate(date) {
    return moment(String(date)).format("DD-MM-YYYY HH:MM");
}

function getOnlyDate(date) {
    return moment(String(date)).format('DD-MM-YYYY');
}

function getDateDiff(init, final) {
    return moment(final).diff(moment(init), 'days');
}

function getCurrentTime() {
    return moment().format("DD-MM-YYYY hh:ss");
}

const DateUtils = {
    convertUnix,
    unixToDate,
    getDate,
    getOnlyDate,
    getCurrentTime,
    getDateDiff,
    getLocalDate
}

export default DateUtils;