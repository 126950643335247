const API_URL = "logout";
import { apiInstance } from "@/utils/ApiUtil.js";

const logoutConfig = async () => {

    try {

        const response = await apiInstance.get(API_URL + "/");

        if (response.data.success) {
            localStorage.clear();
            window.document.body.style.paddingLeft = '0px';
            return true;
        }
        return false;

    } catch (error) {
        return false;
    }
}

const LogoutService = {
    logoutConfig
}

export default LogoutService;