<template>
  <div class="background-small">
    <div class="row">
      <div class="col-xs-12 col-md-12 col-lg-12 g20 f align-bottom sb">
        <h4 class="text-white">{{ title }}</h4>
        <span v-if="infoLoading" class="loader"></span>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-xs-12 col-md-12 col-lg-12">
        <div class="f sb align-bottom mb-2">
          <h5 class="text-white">Create New Target</h5>
          <div class="f flex-column">
            <h6>Game</h6>
            <IconDropdown
              :refFunction="setGame"
              :options="gameOptions"
              :selectedItem="selectedGame"
              :selectedIcon="selectedGameIcon"
            />
          </div>
        </div>
        <div class="new_item">
          <div class="item_left">
            <div><label class="text-white">Item Name</label></div>
            <div class="f dark_outer">
              <input
                @input="handleSuggestion"
                class="dark_in"
                style="width: 100%"
                v-model="formData[0].value"
                type="text"
                name=""
                placeholder="Search an item..."
              />
              <img
                v-if="selectedItem.image"
                class="skin_image"
                :style="{
                  width: '45px',
                  background: `linear-gradient(180deg, rgb(24, 26, 30) 0%, ${selectedItem.color} 160%)`,
                }"
                :src="selectedItem.image"
              />
              <button @click="clearSearch" class="clear_btn">
                <VueIcon
                  class="clear"
                  icon="fluent-mdl2:clear"
                  width="15px"
                  color="gray"
                />
              </button>
            </div>
            <AutoComplete
              @selectImage="selectImage"
              v-if="autoComplete"
              :data="suggestions"
            />
          </div>

          <div class="item_right">
            <div>
              <div><label class="text-white">Market Price</label></div>

              <div class="f dark_outer">
                <div class="currency_sign">{{ currencySign }}</div>
                <input
                  :disabled="true"
                  class="dark_in"
                  v-model="selectedItem.price"
                  type="text"
                  name=""
                />
              </div>
            </div>
            <div>
              <div><label class="text-white">Target Price</label></div>
              <div class="f dark_outer">
                <div class="currency_sign">{{ currencySign }}</div>
                <input
                  class="dark_in"
                  v-model="selectedItem.target_price"
                  type="number"
                  name=""
                />
              </div>
            </div>

            <button @click="setTargetPrice" class="target_btn">
              <VueIcon
                v-if="setTargetLoading"
                icon="eos-icons:loading"
                width="40px"
                color="white"
              />
              <span v-else>Set Price</span>
            </button>
          </div>
        </div>
        <p class="danger-text">
          When your target price is triggered, you will receive an email. Please
          enter your e-mail address on the
          <a
            class="danger-text"
            href="/user-details"
            target="_blank"
            style="
              text-decoration: none;
              font-weight: bold;
              text-decoration: underline;
              cursor: pointer;
            "
            >User Details</a
          >
          page.
        </p>
      </div>
    </div>
    <div class="f py-1 sb visibility">
      <div class="f align-bottom sb">
        <h4 class="text-white">{{ slotSize }}/10 Target Slots</h4>
      </div>
      <div class="right_filter g10">
        <div class="f flex-column" style="align-items: flex-start">
          <h6>Sort By</h6>
          <SortDropdown
            :height-option="'40px'"
            :order-by-function="setOrderBy"
            :options="orderOptions"
            :selectedItem="selectedOrder"
            :selected-dir="selectedDirection"
            :order-dir-function="setOrderDir"
          />
        </div>
        <div class="f flex-column" style="align-items: flex-start">
          <h6>Size</h6>
          <SelectorDropdown
            :background-color="'#313156'"
            :refFunction="setSize"
            :options="sizeOptions"
            :selectedItem="selectedSize"
          />
        </div>
      </div>
    </div>
    <div class="filter-div-mobile">
      <div class="f g10">
        <div class="f flex-column" style="align-items: flex-start">
          <h6>Sort By</h6>
          <SortDropdown
            :height-option="'40px'"
            :order-by-function="setOrderBy"
            :options="orderOptions"
            :selectedItem="selectedOrder"
            :selected-dir="selectedDirection"
            :order-dir-function="setOrderDir"
          />
        </div>
        <div class="f flex-column" style="align-items: flex-start">
          <h6>Size</h6>
          <SelectorDropdown
            :background-color="'#313156'"
            :refFunction="setSize"
            :options="sizeOptions"
            :selectedItem="selectedSize"
          />
        </div>
      </div>
      <div class="f align-bottom sb">
        <h5 class="text-white">{{ slotSize }}/10 Target Slots</h5>
      </div>
    </div>

    <div class="wrapper">
      <div>
        <div v-if="itemLoading">
          <div v-for="index in 6" :key="index">
            <ShimmerEffect />
          </div>
        </div>
        <div v-if="!itemLoading && items && items.length == 0">
          <div class="empty_inventory g5">
            <h5 class="text-white">No result found.</h5>
            <VueIcon height="30px" color="white" icon="mingcute:sad-line" />
          </div>
        </div>
        <div v-else>
          <div class="contents" v-for="(item, index) in items" :key="index">
            <component
              :is="isMobile ? 'TableContentMobile' : 'TableContent'"
              @copy-text="copyText"
              :item="item"
              @go-item="goLink"
              @go-external="goExternalLink"
              @remove-item="removeItem"
              @update-price="updatePrice"
              @start-editing-price="startEditingPrice"
            />
          </div>
        </div>
      </div>
      <div class="page_row pt-2 pb-3 f align-center">
        <PaginationRow
        :pageNumber="this.filterObject.pageNumber"
        :totalPages="totalPages"
        @update:pageNumber="this.filterObject.pageNumber = $event"
      />
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
</template>
<script>
/* eslint-disable */
import InfoModal from "../components/modal/InfoModal.vue";
import SideBar from "@/components/shared/SideBar.vue";
import repeatIcon from "../assets/images/refresh.png";
import TargetService from "@/services/TargetService.js";
import AutoComplete from "@/components/targets/AutoCompleteList.vue";
import ItemService from "@/services/ItemService";

import TableContent from "@/components/targets/TargetItem.vue";
import TableContentMobile from "@/components/targets/TargetItemMobile.vue";

import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";

import DateUtils from "@/utils/DateUtils.js";

import moment from "moment";
import csgo from "../assets/images/csgo.png";
import dota from "../assets/images/dota2.png";
import allIcon from "../assets/images/all.png";
import csgotmIcon from "../assets/marketIcons/csgotm_icon.png";
import shadowIcon from "../assets/marketIcons/shadowpay_icon.png";
import waxIcon from "../assets/marketIcons/waxpeer_icon.png";

export default {
  data() {
    return {
      totalPages:6,
      isMobile: false,
      setTargetLoading: false,
      title: "Target Items",
      stats: {
        total: 0,
      },
      suggestions: [],
      formData: [{ value: "", placeholder: "Item Name" }],
      currencySign: "$",
      searchQuery: "",
      modalMessage: "Price target successfully completed.",
      loading: true,
      infoLoading: false,
      itemLoading: true,
      isDropdownOpen: false,
      autoComplete: false,
      repeatIcon: repeatIcon,
      searchTimeout: null,
      showModal: false,
      filterObject: {
        pageSize: 10,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        game: "all",
        appID: 730,
      },
      marketOptions: [
        { name: "All", icon: allIcon },
        { name: "MarketCSGO", icon: csgotmIcon },
        { name: "Waxpeer", icon: waxIcon },
        { name: "Shadowpay", icon: shadowIcon },
      ],
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        { name: "Dota2", icon: dota, appID: 570 },
      ],
      items: [],
      selectedGameIcon: csgo,
      selectedGame: "CS2",
      info: [],
      currentTime: moment().now,
      sizeOptions: ["10", "15", "25", "30"],
      selectedSize: "10",
      orderOptions: ["Price", "Date", "Name"],
      selectedOrder: "Date",
      selectedDirection: "DESC",
      selectedItem: {
        app_id: 730,
        item_id: null,
        general_id: null,
        name: null,
        buy_market: "",
        sell_market: "",
        price: 0,
        target_price: 0,
        date: "",
        image: null,
        color: "white",
      },
      appID: 730,
      slotSize: 0,
    };
  },

  methods: {
    clearSearch() {
      this.formData[0].value = "";
      this.selectedItem = {
        app_id: 730,
        item_id: null,
        general_id: null,
        name: null,
        buy_market: "",
        sell_market: "",
        price: 0,
        target_price: 0,
        date: "",
        image: null,
        color: "white",
      };

      this.autoComplete = false;
    },
    startEditingPrice(item) {
      item.editingPrice = true;
      item.newPrice = item.target_price / 1000;
    },
    async updatePrice(id, price, item) {
      if (!id) return (item.editingPrice = false);

      this.setTargetLoading = true;
      const response = await TargetService.updateOne({
        id: id,
        price: price * 1000,
      });

      if (!response) this.showInfoModal("Error");
      else {
        this.showInfoModal("Target item updated.");
        item.target_price = price * 1000;
      }
      item.editingPrice = false;
      this.setTargetLoading = false;
    },
    async removeItem(id) {
      if (!id) return;

      this.setTargetLoading = true;
      const response = await TargetService.deleteOne({
        id: id,
      });

      if (!response) this.showInfoModal("Error");
      else {
        this.showInfoModal("Target item deleted.");
        this.items = this.items.filter((e) => e.id != response);
      }
      this.setTargetLoading = false;
    },
    goExternalLink(link) {
      window.open(link, "_blank");
    },
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.appID = type.appID;
      this.filterObject.appID = type.appID;
      this.isDropdownOpen = false;
    },
    async handleSuggestion() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getImage();
      }, 1000);
    },
    async getImage() {
      const response = await ItemService.getOneItemImage({
        name: this.formData[0].value,
        limit: 10,
        game: this.appID,
      });
      this.suggestions = response;
      if (this.suggestions.length > 0) return (this.autoComplete = true);
      return (this.autoComplete = false);
    },
    selectImage(item) {
      if (!item) return false;
      this.selectedItem.name = this.formData[0].value = item.name;
      this.selectedItem.image = item.image;
      this.selectedItem.color = item.color;
      this.selectedItem.price = item.price;
      this.selectedItem.app_id = item.app_id;
      this.selectedItem.name = item.name;
      this.selectedItem.general_id = item.general_id;
      this.selectedItem.item_id = item.item_id;
      this.autoComplete = false;
    },
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
      await this.getAllTargets();
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
      await this.getAllTargets();
    },
    async setSize(size) {
      this.selectedSize = size;
      this.isDropdownOpen = false;
      this.filterObject.pageSize = parseInt(size) ?? 10;
      await this.getHistory();
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.modalMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async showInfoModal(msg) {
      this.showModal = true;
      this.modalMessage = msg;
      setTimeout(() => {
        this.showModal = false;
      }, 2500);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async handleSearch() {
      this.filterObject.pageNumber = 1;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getHistory();
      }, 1000);
    },
    async getAllTargets() {
      this.itemLoading = true;

      const response = await TargetService.getTargets({
        page: this.filterObject.pageNumber,
        limit: this.filterObject.pageSize,
        app_id: this.appID,
        search: this.searchQuery,
        order_by: this.filterObject.orderBy,
        dir: this.filterObject.orderDir.toLowerCase(),
      });

      if (!response || response.length === 0) {
        this.items = [];
        this.itemLoading = false;
        return;
      }

      this.items = response;
      this.slotSize = response.length;
      this.setReferancePrice(this.items);
      this.itemLoading = false;
    },
    async setTargetPrice() {
      if (!this.selectedItem.target_price || !this.selectedItem.name)
        return this.showInfoModal("Please enter an item!");

      this.setTargetLoading = true;
      const response = await TargetService.setOneTarget({
        app_id: this.selectedItem.app_id,
        item_id: this.selectedItem.item_id,
        general_id: this.selectedItem.general_id,
        name: this.selectedItem.name,
        target_price: this.selectedItem.target_price * 1000,
      });

      if (!response.success) this.showInfoModal(response.msg);
      else {
        const message = `Item: ${response.data.item_name} - Price: ${(
          response.data.target_price / 1000
        ).toFixed(1)} ✔️`;
        this.showInfoModal(message);
      }
      this.setTargetLoading = false;
    },
    setReferancePrice(items) {
      if (!items || items.length == 0) return;

      items.forEach((e) => {
        e.minimumMarket = { name: "", price: 0 };
        e.referanceMarket = { name: "", price: 0 };

        if (e.item_detail?.prices?.length > 0) {
          const markets = e.item_detail.prices;

          e.minimumMarket.price = markets[0]?.price?.toFixed(2);
          e.minimumMarket.name = markets[0].markets.name;
          e.minimumMarket.id = markets[0].markets.id;

          const marketUrls = e.item_detail.market_item_url;

          if (marketUrls && marketUrls.length > 0) {
            const find = marketUrls.find(
              (e1) => e1.market_id == e.minimumMarket.id
            );

            if (!find) e.minimumMarket.url = "";
            else e.minimumMarket.url = find.url;
          }

          const steamPrice = markets.find(
            (item) => item.markets.name == "Steam"
          );
          const buffPrice = markets.find(
            (item) => item.markets.name == "Buff.163"
          );

          if (steamPrice) {
            e.referanceMarket.name = "Steam";
            e.referanceMarket.price = steamPrice?.price?.toFixed(2);
          } else {
            e.referanceMarket.name = "Buff.163";
            e.referanceMarket.price = buffPrice?.price?.toFixed(2);
          }
        }
      });
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 1000;
    },
  },
  async created() {
    try {
      await this.getAllTargets();
      this.get;
    } catch (err) {
      console.error(err);
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
    listedItems() {
      return this.items;
    },
  },
  watch: {
    "filterObject.pageNumber"() {
      this.getAllTargets();
    },
  },
  components: {
    AutoComplete,
    InfoModal,
    SideBar,
    TableContent,
    TableContentMobile,
    ShimmerEffect,
    IconDropdown,
    SortDropdown,
    SelectorDropdown,
  },
};
</script>
<style scoped>
@import "../assets/style/customTable.css";

a:hover {
  color: orange;
}

h6 {
  font-size: 14px;
  color: white;
}

.clear_btn {
  background-color: transparent;
  border: none;
}

.empty_inventory {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21242c;
  height: 80px;
  width: 100%;
  border-radius: 4px;
}

.clear_btn:hover .clear {
  transform: scale(1.1);
  transition: 0.2s;
}

.clear_btn:not(hover) .clear {
  transform: scale(1);
  transition: 0.2s;
}

.target_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  cursor: pointer;
  border-radius: 3px;
  color: #fff;
  background: rgb(33, 37, 41);
  height: 50px;
  padding: 5px 10px;
  min-width: 110px;
  text-align: center;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px #8650ac solid;
}

.target_btn span {
  font-size: 18px;
  font-weight: 600;
}

.target_btn:hover {
  color: white;
  background: #8650ac;
}

.new_item {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  background-color: #313156;
  border: 1px solid transparent;
  padding: 1rem 1.2rem;
  border-radius: 4px;
}

.item_left {
  min-width: 40%;
  width: auto;
}

.item_right {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.filter-div-mobile {
  display: none;
}

.filter-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-div .filter-left {
  display: flex;
  gap: 10px;
}

.filter-div .right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.skin_image {
  border-radius: 3px;
}

.dark_in {
  background: rgb(31, 32, 35);
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: rgb(247, 248, 248);
  appearance: none;
  transition: border 0.15s ease 0s;
}

.dark_in:focus {
  outline: none;
  box-shadow: none;
  border-color: #8650ac;
}

.dark_outer {
  padding: 0.4rem 0.8rem;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 14px;
  color: rgb(247, 248, 248);
  height: 50px;
  appearance: none;
  transition: border 0.15s ease 0s;
  align-items: center;
  width: auto;
  min-width: 80%;
}

.dark_outer:focus {
  outline: none;
  box-shadow: none;
  border-color: #8650ac;
}

.rotate-360 {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.currency_sign {
  height: 100%;
  width: 10px;
  color: gray;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selector-menu {
  width: 120px;
}

.selector-menu .select-btn {
  padding-left: 15px;
  padding-right: 15px;
}

.filter-div .left {
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right_filter {
  display: flex;
}

.game_filter {
  display: none;
}

@media screen and (max-width: 872px) {
  .filter-div {
    display: none;
  }

  
.game_filter {
  display: flex;
}

  .filter-div-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }

  .right_filter {
    display: none;
  }

  .button_wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .new_item {
    flex-direction: column;
  }

  .item_left {
    width: 100%;
  }

  .item_left .dark_outer {
    width: 100%;
  }

  .item_right {
    display: flex;
    gap: 5px;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
  }

  .dark_outer {
    width: 100px;
    height: 40px;
  }

  .visibility {
    display: none;
  }

  .dark_in {
    width: 80%;
  }

  label {
    font-size: 11px;
  }

  .target_btn {
    width: 80px;
    min-width: 40px;
    height: 40px;
  }

  .target_btn span {
    font-size: 12px;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
