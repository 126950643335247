<template>
  <div class="content-wrap">
    <div class="main">
      <div class="left-wrapper">
        <div class="title-part">
          <h2>Compare</h2>
          <h2>Analyze</h2>
          <h2>Manage</h2>
          <h1 class="light-green">Make Profit</h1>
        </div>
        <p></p>
        <div class="left-bottom-wrapper">
          <div class="f align-center">
            <a href="login" class="cta">
              <span class="sign">Sign In With</span>
              <VueIcon class="sign" icon="mdi:steam" width="28px"></VueIcon>
              <span>
                <svg
                  width="40px"
                  height="30px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="arrow"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      class="one"
                      d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      class="two"
                      d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      class="three"
                      d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                      fill="#FFFFFF"
                    ></path>
                  </g>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="right_wrapper">
        <div class="f g30">
          <div class="box">
            <img
              class="game_icon unselectable"
              :src="gameIcons.cs2"
              alt="CS2"
            />
            <img
              class="featured_image unselectable"
              :src="csItem.icon"
              alt="CS2"
            />
            <div class="info">
              <h6>{{ csItem.name }}</h6>
              <div class="f align-center g5">
                <VueIcon
                  icon="solar:tag-price-linear"
                  color="2bce7b"
                  height="24px"
                  width="24px"
                ></VueIcon>
                <h5 style="color: #2bce7b">${{ csItem.price }}</h5>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="game_icon tf2_game_icon unselectable"
              :src="gameIcons.tf2"
              alt="TF2"
            />
            <img
              class="featured_image tf_key unselectable"
              :src="tfItem.icon"
              alt="TF2"
            />
            <div class="info">
              <h6>{{ tfItem.name }}</h6>
              <div class="f align-center g5">
                <VueIcon
                  icon="solar:tag-price-linear"
                  color="2bce7b"
                  height="24px"
                  width="24px"
                ></VueIcon>
                <h5 style="color: #2bce7b">${{ tfItem.price }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 30px"></div>
        <div class="f g30">
          <div class="hidden" style="width: 80px"></div>
          <div class="box">
            <img
              class="game_icon unselectable"
              :src="gameIcons.dota2"
              alt="Dota2"
            />
            <img class="featured_image" :src="dotaItem.icon" alt="Dota2" />
            <div class="info">
              <h6>{{ dotaItem.name }}</h6>
              <div class="f align-center g5">
                <VueIcon
                  icon="solar:tag-price-linear"
                  color="2bce7b"
                  height="24px"
                  width="24px"
                ></VueIcon>
                <h5 style="color: #2bce7b">${{ dotaItem.price }}</h5>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="game_icon unselectable"
              :src="gameIcons.rust"
              alt="Rust"
            />
            <img
              class="featured_image_rust unselectable"
              :src="rustItem.icon"
              alt="Rust"
            />
            <div class="info">
              <h6>{{ rustItem.name }}</h6>
              <div class="f align-center g5">
                <VueIcon
                  icon="solar:tag-price-linear"
                  color="2bce7b"
                  height="24px"
                  width="24px"
                ></VueIcon>
                <h5 style="color: #2bce7b">${{ rustItem.price }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="game_info">
      <h3>Supported Games</h3>
      <div class="game_wrap">
        <div
          v-for="(game, index) in supportedGames"
          :key="index"
          class="number-wrapper g5"
        >
          <img :class="game.imgClass" :src="game.imgSrc" :alt="game.altText" />
          <p class="number-text">{{ game.text }}</p>
        </div>
      </div>
    </div>
    <div class="summary-numbers">
      <h3>Statistics</h3>
      <div class="number_wrap">
        <div
          v-for="(item, index) in animatedItems"
          :key="index"
          class="number-wrapper"
        >
          <div class="number">
            {{ Math.floor(item.currentValue)
            }}<span class="text-other">{{ item.suffix }}</span>
          </div>
          <p class="number-text">{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div class="feature-info">
      <div class="text-center">
        <h3 class="text-white">Features</h3>
      </div>

      <div class="feature_section pb-5">
        <a
          v-for="(item, index) in features"
          :key="index"
          class="feature_card unselectable"
          :href="item.link"
          target="_blank"
        >
          <img
            :src="item.img"
            class="feature_img unselectable"
            alt="featured"
          />
          <h4 class="text-white">
            {{ item.title }}
          </h4>
          <div class="f align-center">
            <p class="text-white">
              {{ item.description }}
            </p>
          </div>
          <div class="quarter-circle go_route">
            <VueIcon
              icon="fluent:ios-arrow-right-24-filled"
              width="24px"
              style="color: white"
            ></VueIcon>
          </div>
        </a>
      </div>
    </div>
    <div class="support_info">
      <div></div>
      <h3>Supported Marketplaces</h3>
      <a href="marketplaces" class="detail-more"> See All </a>
    </div>

    <LogoSlider :items="MarketOptionsRef" />
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import cs2 from "../assets/images/cs2-banner.png";
import rust from "../assets/images/rust-banner.png";
import tf2 from "../assets/images/tf2-banner.png";
import dota from "../assets/images/dota2.png";
import dlore from "../assets/images/dlore.png";
import hook from "../assets/images/hook.png";
import ak from "../assets/images/rust-item.png";
import caseIcon from "../assets/images/case.png";
import tf2Icon from "../assets/images/tf2-key.png";
import transfer from "../assets/images/transfer.png";
import inventoryIcon from "../assets/images/briefcase.png";
import manage from "../assets/images/checklist.png";
import trend from "../assets/images/trend-chart.png";
import invest from "../assets/images/investments.png";
import gift from "../assets/images/gift.png";
import target from "../assets/images/target.png";

import portfolio from "../assets/images/portfolio.png";
import watchlist from "../assets/images/add-bookmark.png";
import LogoSlider from "../components/slider/LogoSlider.vue";
import MarketOptions from "../core/constants.js";
import { useHead } from "@unhead/vue";

export default {
  cs2,
  setup() {
    const gameIcons = ref({
      cs2: cs2,
      tf2: tf2,
      rust: rust,
      dota2: dota,
    });

    const MarketOptionsRef = ref(MarketOptions);
    const supportedGames = ref([
      {
        imgClass: "cs_img",
        imgSrc: cs2,
        altText: "CS2",
        text: "CS 2",
      },
      {
        imgClass: "dota_img",
        imgSrc: dota,
        altText: "Dota2",
        text: "Dota 2",
      },
      {
        imgClass: "rust_img",
        imgSrc: rust,
        altText: "Rust",
        text: "Rust (soon)",
      },
      {
        imgClass: "tf2_img",
        imgSrc: tf2,
        altText: "TF-2",
        text: "TF 2 (soon)",
      },
    ]);

    const summaryItems = ref([
      { value: 50, suffix: "K+", text: "Tracked Items", duration: 800 },
      { value: 500, suffix: "+", text: "Users", duration: 800 },
      { value: 24, suffix: "/7", text: "Support", duration: 800 },
    ]);

    const animatedItems = ref([]);
    const isAnimated = ref(false);

    const csItem = reactive({
      name: "AWP | Dragon Lore",
      color: "#EB4B4B",
      price: 4999,
      icon: dlore,
    });

    const tfItem = reactive({
      name: "Mann Co. Supply Crate Key",
      color: "#EB4B4B",
      price: 1.68,
      icon: tf2Icon,
      game: tf2,
    });

    const dotaItem = reactive({
      name: "Dragonclaw Hook",
      price: 129,
      icon: hook,
    });

    const rustItem = reactive({
      name: "AK47U | Alien Red",
      price: 129,
      icon: ak,
    });

    const features = ref([
      {
        title: "Case Opening Simulator",
        description: "Try your luck with the FREE CS2 case opening simulator.",
        img: caseIcon,
        link: "/cs2/simulator",
      },
      {
        title: "Manage Your Trades",
        description: "Manage all your trades at one place.",
        img: manage,
        link: "/dashboard",
      },
      {
        title: "Inventory",
        description: "Track your CS2, Dota2, TF2 and Rust inventories.",
        img: inventoryIcon,
        link: "/inventory",
      },
      {
        title: "Price Comparison",
        description:
          "Compare item prices across 20 marketplaces to maximize profits.",
        img: transfer,
        link: "/comparison",
      },
      {
        title: "Price Trends",
        description: "Get real-time updates on skin market trends.",
        img: trend,
        link: "/trends",
      },
      {
        title: "Investment Tracker",
        description: "Track your item investments easily in one place.",
        img: invest,
        link: "/investments",
      },
      {
        title: "Portfolio",
        description:
          "Create a portfolio to easily track and analyze your total worth.",
        img: portfolio,
        link: "/analytics",
      },
      {
        title: "Watchlist",
        description:
          "Observe price movements by adding desired items to your watch list.",
        img: watchlist,
        link: "/watchlist",
      },
      {
        title: "Price Targets",
        description:
          "Get notified when the items you follow hit your target price drops.",
        img: target,
        link: "/target",
      },
      {
        title: "Free Giveaways",
        description:
          "Participate in free giveaways for a chance to win exclusive CS2 skins",
        img: gift,
        link: "/",
      },
    ]);

    const goUrl = (link) => {
      this.$router.push({ path: link });
    };

    const initIntersectionObserver = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !isAnimated.value) {
              isAnimated.value = true;
              initAnimation();
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );

      const element = document.querySelector(".summary-numbers");
      if (element) {
        observer.observe(element);
      }
    };

    const initAnimation = () => {
      summaryItems.value.forEach((item) => {
        const isNumber = typeof item.value === "number";
        animatedItems.value.push({
          ...item,
          currentValue: isNumber ? 0 : item.value,
        });

        if (isNumber) {
          animateCount(
            animatedItems.value.length - 1,
            item.value,
            item.duration
          );
        }
      });
    };

    const animateCount = (index, targetValue, duration) => {
      const intervalTime = 20;
      const totalSteps = duration / intervalTime;

      const easeInOut = (t) => {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      };

      let currentStep = 0;
      const startValue = 0;

      const interval = setInterval(() => {
        if (currentStep < totalSteps) {
          const progress = currentStep / totalSteps;
          const easedProgress = easeInOut(progress);
          animatedItems.value[index].currentValue =
            startValue + easedProgress * targetValue;
          currentStep++;
        } else {
          animatedItems.value[index].currentValue = targetValue; // Hedef değere tam olarak ayarla
          clearInterval(interval); // Animasyonu durdur
        }
      }, intervalTime);
    };

    useHead({
      meta: [
        {
          name: "description",
          content:
            "Enhance your trading game with our comprehensive CS:GO, csgo stats, Dota 2, Rust, and TF2 items price analysis and inventory management tools for traders.",
        },
        {
          name: "keywords",
          content: `CS:GO, CS2, Dota 2, Rust, TF2, items, price analysis, Analyzer, inventory,CS:GO skins, csgo skins, csgo cases, csgo live stats, csgo knives, CS:GO trading, CS:GO in-game items,CS:GO virtual items, CS:GO cosmetic items, buy CS:GO skins, sell CS:GO skins, trade CS:GO skins, cryptocurrency, Steam, steam market analyzer, steam account analyzer, dragonclaw hook price history, csgo investment tracker, steam analyser, steam analysis, steam analyzer, secure trading, Rust skins,buy cs2 skins, sell cs2 skins, cs2 marketplace, buy csgo skins, sell csgo skins, csgo marketplace, csgo`,
        },
      ],
    });

    onMounted(() => {
      initIntersectionObserver();
    });

    return {
      MarketOptionsRef,
      supportedGames,
      summaryItems,
      animatedItems,
      isAnimated,
      csItem,
      tfItem,
      dotaItem,
      rustItem,
      features,
      goUrl,
      gameIcons,
    };
  },
  components: {
    LogoSlider,
  },
};
</script>

<style scoped>
.content-wrap {
  padding: 0rem 0rem;
}

.sign {
  transform: skewX(15deg);
}

.cta {
  display: flex;
  padding: 10px 45px;
  text-decoration: none;
  align-items: center;
  font-family: "Poppins", sans-serif;
  color: white;
  background: #6225e6;
  transition: 1s;
  border-radius: 1px;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  gap: 0.8rem;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #fbc638;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

.cta span {
  transform: skewX(15deg);
  font-size: 18px;
}

.cta span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: 12%;
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: #fbc638;
  }

  100% {
    fill: white;
  }
}

p {
  margin-bottom: 0;
}

h1,
h3 {
  margin-bottom: 0px;
}

.feature-info {
  display: flex;
  flex-direction: column;
  padding-block: 4%;
  background-color: #0c0c15;
}

.feature_section {
  background-color: #0c0c15;
  min-height: auto;
  padding: 2rem 6rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
}

.feature_card {
  border-radius: 4px;
  background-color: #18182b;
  padding: 1.3rem;
  max-height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  position: relative;
}

.feature_card p {
  font-size: 15px;
  font-weight: 400;
}

.feature_card h4 {
  font-size: 22px;
}

.feature_card:hover {
  transition: 0.3s;
  background-color: #282860;
  cursor: pointer;
}

h5 {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 17px;
}

.game_icon {
  width: 30px;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 3px;
}

.tf2_game_icon {
  width: 40px !important;
}

.tf_key {
  margin-top: 10px !important;
}

.box {
  padding: 0.5rem;
  position: relative;
  width: 170px;
  height: 230px;
  display: flex;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-evenly;
  background-color: green;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 5;
  width: 90%;
}

.box img {
  z-index: 5;
}

.box:hover,
.featured_image .featured_image_rust {
  filter: drop-shadow(0 0 10px #757373);
  transition: 0.5s;
  transform: translateY(-10px);
}

.box:not(hover),
.featured_image,
.featured_image_rust {
  transition: 0.4s;
}

.box h6 {
  font-size: 14px;
  z-index: 5;
}

.main {
  width: 100%;
  background: rgb(53, 53, 108);
  background: linear-gradient(
    38deg,
    rgba(53, 53, 108, 1) 0%,
    rgba(39, 39, 68, 1) 41%,
    rgba(46, 46, 85, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.number_wrap {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.images-wrapper {
  display: flex;
  flex-direction: row;
}

.game_wrap {
  display: flex;
  width: 90%;
  justify-content: space-around;
}

.markets_wrap {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}

.game_img {
  width: 60px;
}

.cs_img {
  width: 68px;
  height: 68px;
}

.tf2_img {
  width: 76px;
  height: 76px;
}

.dota_img {
  width: 78px;
  height: 78px;
}

.rust_img {
  width: 70px;
  height: 70px;
}

.skin_img {
  width: 90%;
  height: 230px;
}

.feature_img {
  width: 50px;
  height: 48px;
}

.featured_image {
  margin-top: 40px;
  width: 90%;
}

.featured_image_rust {
  margin-top: 20px;
  width: 90%;
  height: 60%;
}

.number-wrapper:hover > img {
  transition: 0.4s;
  cursor: pointer;
  filter: drop-shadow(0 0 11px #565555);
}

.images-wrapper > img {
  width: 50px;
}

.left-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  margin-left: 7%;
  margin-bottom: 5%;
  margin-right: 0;
  width: 51%;
}

.left-wrapper > .title-part > h1,
h2 {
  animation: left-wrapper-anim 1s ease forwards;
}

@keyframes left-wrapper-anim {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.right_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 49%;
  height: 100%;
  justify-content: center;
  padding: 2rem 0.5rem;
}

.left-wrapper h1 {
  font-size: 6rem;
  margin-bottom: 0%;
  margin-top: 0;
}

.left-wrapper h2 {
  font-size: 5rem;
  margin-bottom: 0%;
  margin-top: 0;
}

.left-wrapper > p {
  font-size: 1em;
  margin-bottom: 5%;
  margin-top: 0;
  text-wrap: wrap;
  width: 70%;
}

.left-bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.left-bottom-text {
  margin-top: 5%;
  padding: 3%;
  display: flex;
  text-align: center;
  height: 100%;
}

.support_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #21213b;
  color: white;
  padding-top: 2%;
  padding-right: 2%;
  gap: 5px;
}

.game_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #21213b;
  color: white;
  padding-block: 3%;
  gap: 40px;
}

.summary-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
  justify-content: space-between;
  background-color: #1d1d34;
  color: white;
  padding-block: 2%;
  padding-inline: 3%;
  gap: 40px;
}

.number-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 130px;
  gap: 0;
}

.summary-numbers .number-wrapper .number-text {
  border-top: 1px solid #854cff;
  width: 150px;
  text-align: center;
}

.text-other {
  color: yellow;
  font-size: 24px;
}

.number-text {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.603);
}

.sign_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  outline: 0;
  cursor: pointer;
  border: 1px #8650ac solid;
  border-radius: 3px;
  width: 250px;
  height: 60px;
  color: #fff;
  background: #4d4d86;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding: 12px 20px;
  text-align: center;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.sign_btn:hover {
  transition: 0.4s;
  color: #000;
  background: #6d6dbe;
}

.detail {
  height: 35vh;
  padding: 1rem 0.6rem;
  background-color: #171728;
  margin: 0;
  display: flex;
  color: white;
  overflow: hidden;
}

.manage_trades {
  height: 35vh;
  padding: 1rem 0.6rem;
  background-color: #181835;
  margin: 0;
  display: flex;
  color: white;
  overflow: hidden;
  justify-content: flex-start;
}

.detail_right {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 1rem;
  width: 65%;
  margin-left: 30px;
}

.detail:hover img {
  filter: drop-shadow(0 0 13px #757373);
  transition: 0.5s;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.feature_card:hover .go_route {
  transition: 0.2s;
  opacity: 1;
}

.go_route {
  opacity: 0;
}

.quarter-circle {
  padding-top: 8px;
  padding-left: 8px;
  width: 50px;
  height: 50px;
  background-color: #854cff;
  border-radius: 50px 0 0 0;
  -moz-border-radius: 50px 0 0 0;
  -webkit-border-radius: 50px 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-left {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 1rem;
  width: 80%;
  margin-left: 30px;
}

.detail-title {
  font-size: 3rem;
  margin-bottom: 0;
}

.detail-title > span {
  color: yellow;
  margin: 0;
}

.detail-more {
  color: yellow;
  text-decoration: none;
}

.detail-more:hover {
  color: rgb(218, 218, 41);
  transition: 0.3s;
}

.detail-right {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail-right > img {
  width: 200%;
}

.detail-right > div:nth-child(2) {
  top: 63%;
  right: 10%;
}

.detail-right > div:nth-child(3) {
  top: 74%;
  right: 13%;
}

.items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
  background-color: #0c0c15;
  color: white;
}

.items-left {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-block: 2%;
}

.items-right {
  width: 50%;
  margin: 5%;
}

.item-bottom {
  display: flex;
  flex-direction: row;
}

.sniper {
  width: 70%;
  margin-inline: 20%;
  animation: item-animation 3s infinite linear;
}

.pistol {
  width: 50%;
  margin-inline: 20%;
  margin-top: 10%;
  animation: item-animation 3s infinite linear;
}

.knife {
  width: 50%;
  margin-inline: 20%;
  margin-bottom: 10%;
  animation: item-animation 3s infinite linear;
}

@keyframes item-animation {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}

.item-text {
  position: relative;
  z-index: 2;
  left: 10%;
  top: 70%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, black, rgba(0, 128, 0, 0.364));

  width: 150px;
  padding: 10px;
  border-radius: 15px;
}

.item-text > span {
  color: yellow;
}

.features {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  padding: 2%;
  background-color: rgb(68, 0, 68);
}

.feature {
  display: flex;
  flex-direction: column;
  background-color: rgb(151, 3, 151);
  color: white;
  border-radius: 25px;
  padding-inline: 2%;
  width: 20%;
}

.feature-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.feature-logo {
  width: 30%;
  margin: 0;
}

.feature-title {
  margin: 0;
}

.feature-hr {
  width: 100%;
  border-color: rgb(151, 3, 151);
}

.feature-button {
  width: 20%;
  aspect-ratio: 1;
  border-radius: 50%;
  align-self: flex-end;
  margin: 20px;
  background-color: rgb(151, 3, 151);
  color: rgba(68, 0, 68, 0.614);
  font-size: 30px;
}

.sites {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 10px;
}

.site {
  display: flex;
  align-items: center;
  background-color: rgb(151, 3, 151);
  width: 18%;
  border-radius: 10px;
  margin-block: 0.2rem;
}

.site-icon {
  width: 25%;
}

.site-name {
  justify-self: start;
  width: 50%;
  color: white;
}

.features-title,
.sites-title {
  font-size: 5rem;
  width: 100%;
  text-align: center;
}

.box::before {
  content: "";
  position: absolute;
  height: 150%;
  width: 200%;
  background: linear-gradient(#00ccff, #d500f9);
  animation: rotate 2.4s linear infinite;
}

.box::after {
  content: "";
  position: absolute;
  background: #0e1538;
  inset: 5px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card::after {
  filter: blur(3.5rem);
}

@media screen and (max-width: 1230px) {
  .feature_section {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
  }

  .feature_card p {
    font-size: 13px;
  }

  .feature_card h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 976px) {
  .main {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0.4rem 0.8rem;
  }

  .main .left-wrapper {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.4rem;
    text-align: center;
  }

  .main .right_wrapper {
    width: 100%;
  }

  .main .left-wrapper h1 {
    font-size: 3.5rem;
    text-align: center;
  }

  .left-wrapper h2 {
    font-size: 3rem;
  }

  .sign_btn {
    width: 100%;
  }

  .box {
    width: 195px;
    height: 233px;
  }

  .left-bottom-wrapper {
    justify-content: center;
  }

  .feature_section {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 1.2rem;
    padding: 2rem 1.5rem;
    height: auto;
  }

  .feature_card h4 {
    font-size: 16px;
  }

  .feature_card p {
    font-size: 11px;
  }

  .feature_card {
    padding: 1rem;
    height: 200px;
    gap: 10px;
    border-radius: 1px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .detail-more {
    font-size: 13px;
  }

  .feature_card a {
    bottom: 13px;
    position: unset;
  }

  h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 476px) {
  .feature_card:hover .go_route {
    opacity: 0;
  }
  .summary-numbers .number-wrapper .number-text {
    width: 90px;
  }

  .left-wrapper > .title-part > h1 {
    animation: left-wrapper-anim-mobile 1s ease forwards;
  }

  @keyframes left-wrapper-anim-mobile {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .box {
    width: 50%;
    height: 255px;
  }

  .hidden {
    display: none;
  }

  .main .right_wrapper {
    margin-bottom: 1rem;
  }

  .game_info {
    padding-block: 4%;
    gap: 10px;
    height: 20vh;
    justify-content: space-around;
  }

  .feature-info {
    display: flex;
    flex-direction: column;
    padding-block: 2.5rem;
    background-color: #0c0c15;
  }

  .number-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .summary-numbers {
    padding-block: 4%;
    font-size: 2rem;
    height: 20vh;
    justify-content: space-around;
    gap: 10px;
  }

  .number-text {
    font-size: 0.8rem;
  }

  .cs_img {
    width: 40px;
    height: 40px;
  }

  .dota_img {
    width: 40px;
    height: 40px;
  }

  .rust_img {
    width: 40px;
    height: 40px;
  }

  .tf2_img {
    width: 45px;
    height: 45px;
  }

  .detail-title {
    font-size: 1.6rem;
    margin-bottom: 0;
  }

  .support_info {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #21213b;
    color: white;
    padding-top: 4%;
  }

  h3 {
    font-size: 20px;
  }

  .detail {
    height: 50vh;
  }

  .manage_trades {
    height: 36vh;
  }
}
</style>
