<template>
  <div class="background-small">
    <div class="row mb-3">
      <h4 class="text-white">{{ title }}</h4>
    </div>
    <div class="interval_wrap">
      <div
        @click="setTab(item)"
        v-for="(item, index) in tabs"
        :key="index"
        :class="{ interval_info: true, active: item.path === selectedTab }"
      >
        <VueIcon :icon="item.icon" width="18px" color="white" />
        <span class="mid-span"> {{ item.name }}</span>
      </div>
    </div>

    <router-view></router-view>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
</template>
<script>
import InfoModal from "../components/modal/InfoModal.vue";

import DateUtils from "@/utils/DateUtils.js";

export default {
  data() {
    return {
      isMobile: false,
      showModal: false,
      title: "Payments",
      tabs: [
        {
          name: "Refill Balance",
          path: "refill",
          icon: "material-symbols:add-circle-outline",
        },
        {
          name: "Subscription",
          path: "subscription",
          icon: "material-symbols:calendar-month-outline",
        },
        {
          name: "Transactions",
          path: "transactions",
          icon: "material-symbols:history-toggle-off",
        },
      ],
      selectedTab: "refill",
      modalMessage: "Price Successfully Updated",
      loading: true,
      infoLoading: false,
      itemLoading: true,
    };
  },
  methods: {
    setTab(item) {
      this.selectedTab = item.path;
      this.$router.push(`/payment/${item.path}`);
    },
    showSaleInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 4000);
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.modalMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async showInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 1500);
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 1000;
    },
  },
  async created() {
    try {
      const foundTab = this.tabs.find(
        (item) => `/payment/${item.path}` === this.$route.path
      );
      if (foundTab) {
        this.selectedTab = foundTab.path;
      }
    } catch (err) {
      console.error(err);
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  watch: {
    $route(to) {
      // Update selectedTab based on the current route
      const foundTab = this.tabs.find(
        (item) => `/payment/${item.path}` === to.path
      );
      if (foundTab) {
        this.selectedTab = foundTab.path;
      }
    },
  },
  components: {
    InfoModal,
  },
};
</script>
<style scoped>
@import "../assets/style/customTable.css";


.interval_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border: 1px solid #954ce9;
  border-radius: 3px;
  height: 40px;
  max-width: 500px;
  padding: 0.1rem;
}

.interval_info {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active {
  background-color: #954ce9;
  transition: 0.4s;
}

.filter-div-mobile {
  display: none;
}

.filter-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-div .filter-left {
  display: flex;
  gap: 10px;
}

.filter-div .right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-icon {
  height: 20px;
  width: 20px;
}

.custom_button {
  width: 100px;
  background-color: #313156;
  border: 1px #8650ac solid;
}

.custom_button:hover {
  background-color: #8650ac;
}

.rotate-360 {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.selector-menu {
  width: 120px;
}

.selector-menu .select-btn {
  padding-left: 15px;
  padding-right: 15px;
}

.filter-div .left {
  padding-left: 0px;
}

.button_wrap {
  display: none;
}

.mid-span {
  font-size: 13px;
  font-weight: 700;
}

@media screen and (max-width: 728px) {
  .mid-span {
    font-size: 11px;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
