<template>
    <label :class="['checkbox-container', { disabled }]">
      <input
        type="checkbox"
        :checked="modelValue"
        :disabled="disabled"
        @change="handleChange"
      />
  
      <span class="checkmark"></span>
  
    </label>
  </template>
  
  <script>
  export default {
    name: "CheckBoxButton",
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["update:modelValue"],
    methods: {
      handleChange(event) {
        this.$emit("update:modelValue", event.target.checked);
      },
    },
  };
  </script>
  
  <style scoped>
  label {
    margin: 0px;
  }
  
  .checkbox-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;
    font-size: 24px;
    user-select: none;
    gap: 4px;
  }
  
  .checkbox-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 2px solid #8650ac;
    border-radius: 4px;
    font-size: 24px;
  }
  .checkbox-container input:checked ~ .checkmark {
    background-color: #8650ac;
    font-size: 24px;
  }
  
  .checkmark:after {
    content:"";
    position: absolute;
    display: none;
    font-size: 24px;
  }
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
    font-size: 24px;
  }
  .checkbox-container .checkmark:after {
    left: 6px;
    top: 0px;
    width: 5px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    font-size: 24px;
  }
  
  .checkbox-container.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  </style>
  