<template>
  <div
    class="catalog-card catalog-card2 sell_card select_item"
    :class="{ active: isActive }"
    @click="toggleSelection"
  >
    <a class="catalog-card__img">
      <img
        :style="{ '--drop-shadow-color': item.rarity_color }"
        class="img"
        :src="item.image"
      />
    </a>
    <div
      class="f align-center evenly mx-1 my-2"
      v-if="item.markets && item.markets.length > 0"
      style="width: 100%"
    >
      <div class="f" v-for="(market, index) in item.markets" :key="index">
        <img
          v-if="market.is_listed === 1"
          class="img-market"
          :src="getMarketIcon(market.market_name)"
        />
      </div>
    </div>
    <div>
      <a class="catalog-card__title sell-card-title">
        <span
          class="mid-span bold"
          :style="{ color: item.rarity_color || 'white' }"
          >{{ item.name }}</span
        >
      </a>
    </div>
    <div class="f steam-price align-center">
      <img class="img-market" :src="buffIcon" />
      <span class="mid-span sm-gap">
        {{
          item.referance_price
            ? (item.referance_price / 1000).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : "$0"
        }}</span
      >
    </div>
  </div>
</template>

<script>
import steamIcon from "@/assets/images/steam-white.png";
import buffIcon from "@/assets/marketIcons/buff_icon.png";
import csgotmIcon from "@/assets/marketIcons/csgotm_icon.png";
import shadowIcon from "@/assets/marketIcons/shadowpay_icon.png";
import waxIcon from "@/assets/marketIcons/waxpeer_icon.png";
import float from "../../assets/marketIcons/float_icon.png";
import haloIcon from "../../assets/marketIcons/haloskins_icon.png";
import thunderIcon from "../../assets/marketIcons/skinthunder.png";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    childFunction: Function,
  },
  data() {
    return {
      isActive: false,
      icon: steamIcon,
      waxIcon: waxIcon,
      shadowIcon: shadowIcon,
      csgotmIcon: csgotmIcon,
      haloskinsIcon: haloIcon,
      buffIcon,
      float,
      marketMapping: [
        { name: "Steam", code: "steam", icon: steamIcon },
        { name: "Waxpeer", code: "waxpeer", icon: waxIcon },
        { name: "MarketCSGO", code: "market", icon: csgotmIcon },
        { name: "Shadowpay", code: "shadowpay", icon: shadowIcon },
        // { name: 'Gamerpay', code: 'gamerpay', icon: gamerpayIcon },
        { name: "HaloSkins", code: "haloskins", icon: haloIcon },
        { name: "CSFloat", code: "csfloat", icon: float },
        { name: "Skinthunder", code: "skinthunder", icon: thunderIcon },
      ],
    };
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    },
    checkActive() {
      const selectedItems = JSON.parse(localStorage.getItem("selectedItems"));
      if (!selectedItems || selectedItems.length === 0)
        return (this.isActive = false);

      const itemIndex = selectedItems.findIndex(
        (item) => item.general_id === this.item.general_id
      );
      if (itemIndex !== -1) return this.toggleActive();
    },
    toggleSelection() {
      this.toggleActive();
      const id = this.item.assetid;
      const selectedItems = JSON.parse(
        localStorage.getItem("selectedItems") || "[]"
      );
      const itemIndex = selectedItems.findIndex((item) => item.assetid === id);

      if (itemIndex !== -1) {
        selectedItems.splice(itemIndex, 1);
      } else {
        selectedItems.push(this.item);
      }

      localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    },
    getMarketIcon(name) {
      const res = this.marketMapping.find((e) => e.name == name);

      if (!res) return null;

      return res.icon;
    },
  },
  created() {
    this.checkActive();
  },
};
</script>

<style scoped>
.sm-gap {
  margin-left: 6px;
}

.mid-span {
  font-size: 11px;
  padding-left: 2px;
  padding-right: 2px;
}

.bold {
  font-weight: 600;
}

.img-small {
  width: 20px;
  height: 20px;
  min-width: 0%;
}

.img {
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.sell_card {
  padding: 10px 0 16px;
  background: #15142a;
  border-radius: 10px;
  background: -o-linear-gradient(top, rgb(30 12 36 / 45%) 0, #15142a 100%);
  background: linear-gradient(180deg, rgb(30 12 36 / 45%) 0, #15142a 100%);
  cursor: pointer;
}

.sell_card.active {
  background: #15142a;
  border: 1.5px solid #e149bf;
}

.sell_card:hover {
  background: #15142a;
}

.sell_card:hover img {
  filter: drop-shadow(0 0 18px var(--drop-shadow-color));
}

.catalog-card {
  display: flex;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  border: 1.5px solid transparent;
}

.catalog-card__hours {
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  color: #8787ab;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.catalog-card__img {
  display: block;
  position: relative;
  width: 54%;
  margin: 0px;
}

.catalog-card2 .catalog-card__img {
  margin-top: 0;
}

.img-market {
  width: 18px;
  aspect-ratio: 1;
}

.sell-card-title {
  line-height: 20px;
}

.catalog-card__title {
  padding: 0 2px;
}

.catalog-card__title {
  display: block;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.catalog-card__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  line-height: 25px;
  color: #dd1b5f;
  margin-top: 10px;
}

@media screen and (min-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .sell_card {
    height: 100%;
  }
}
</style>
