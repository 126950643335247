<template>
    <div class="history_bar">
        <div style="height: 200px">
            <LineChart :data="chartData" :labelName="'Price'" :chartOption="options ?? desktopOptions"></LineChart>
        </div>
    </div>
</template>

<script>
import LineChart from '../charts/MiniLineChart.vue';

export default {
    components: { LineChart },
    data() {
        return {
            green: '#4CDF99',
            red: '#ff0f0f',
            desktopOptions: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: 'white', beginAtZero: true,
                            maxRotation: 45,
                            minRotation: 45,
                            padding: 10
                        }
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        ticks: { color: 'white', beginAtZero: true, padding: 10 },
                    },

                },
                plugins: {
                    legend: {
                        labels: {
                            color: 'white',
                            fontSize: 20
                        },
                    }
                }
            },
        }
    },
    props: {
        header: {
            type: String,
            default: ''
        },
        subHeader: {
            type: String,
            default: ''
        },
        interval: {
            type: Array,
            required: true
        },
        chartData: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            required: false
        }
    },
};
</script>

<style scoped>

h5,
h6 {
    color: white;
    margin-bottom: 0;
}

.history_bar {
    width: 100%;
    height: 100%;
    background-color: #313155;
    padding: 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 3px;
}

.interval_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    border: 1px solid #954CE9;
    border-radius: 3px;
    min-height: 40px;
    max-width: 300px;
}

.interval_info {
    width: 100%;
    height: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.active {
    background-color: #954CE9;
    transition: 0.4s;
}

@media screen and (max-width: 769px) {

    .interval_info {
        min-height: 25px;
    }

    .history_bar {
    width: 100%;
    height: 100%;
    background-color: #313155;
    padding: 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 3px;
}

}
</style>
