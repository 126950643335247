import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/payment";

const depositItemBalance = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/itempay", {
            assetids: req.assetids,
            appid: req.appid,
            tradelink: req.tradelink,
            amount: req.amount * 1000
        });

        if (!response.data.success || !response.data.metaData) return { success: false, message: 'An error ocurred.' };
        return { success: true, data: response.data.metaData };

    } catch (error) {
        if (!error.response.data.success && error.response.data.message === 'ITEMS_NOT_EXIST')
            return { success: false, message: 'Please refresh your inventory. Some of your items are missing.' };
        return { success: false, message: 'An error ocurred. You can contact with us.' };
    }
};

const depositCryptoBalance = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/cryptopay",
            {
                amount: req.amount,
                currency: req.currency
            });
        if (!response.data.success) return false;
        if (!response.data?.order) return false;

        return response.data.order.invoice_url;
    } catch (error) {
        console.log(error)
        return false;
    }
};

const getAllTransactions = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/all", {
            params: {
                limit: req.limit,
                page: req.page,
                method: req.method,
                type: req.type,
                orderBy: req.orderBy,
                orderDir: req.orderDir
            }
        });
        if (!response.data.success) return [];
        return response.data.items;
    } catch (error) {
        console.log(error)
        return [];
    }

};

const getSubscriptionPlans = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/subscription/plans", {
            params: {
                periode: req.periode
            }
        });
        if (!response?.data?.success) return [];
        return response.data.data;
    } catch (error) {
        console.log(error)
        return [];
    }

};

const PaymentService = {
    depositItemBalance,
    getAllTransactions,
    depositCryptoBalance,
    getSubscriptionPlans
};

export default PaymentService;

