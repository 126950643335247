<template>
  <div class="selector-menu" @click="toggleDropdown">
    <div
      id="select-btn"
      class="select-btn"
      :style="{
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        height: customHeight,
      }"
    >
      <span class="sBtn-text">{{ selectedItem }}</span>
      <i
        class="bx"
        :class="{
          'bx-chevron-down': !isDropdownOpen,
          'bx-chevron-up': isDropdownOpen,
        }"
      ></i>
    </div>
    <ul
      class="options"
      v-show="isDropdownOpen"
      :class="{ active: isDropdownOpen }"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        class="option"
        @click="setFunction($event, option)"
      >
        <span class="option-text p-3">{{ option }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    selectedItem: String,
    options: Array,
    refFunction: Function,
    backgroundColor: {
      type: String,
      required: false,
      default: "#313156",
    },
    borderColor: {
      type: String,
      required: false,
      default: "#8650ac",
    },
    customHeight: {
      type: String,
      required: false,
      default: "40px",
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },

  methods: {
    toggleDropdown(event) {
      event.stopPropagation();
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    setFunction(event, option) {
      event.stopPropagation();
      this.isDropdownOpen = false;
      this.refFunction(option);
    },
  },
};
</script>

<style>
.selector-menu {
  transition: 0.5s;
}

.selector-menu .select-btn {
  display: flex;
  padding: 5px;
  border-radius: 1px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 500;
  color: white;
  transition: 0.5s;
  border: 1px solid;
}

.select-btn:hover {
  background-color: #8650ac;
}

.select-btn i {
  font-size: 25px;
  transition: 0.3s;
}

.selector-menu.active .select-btn i {
  transform: rotate(-180deg);
  transition: 0.3s;
}

.selector-menu .options {
  position: absolute;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
  background: #313156;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  max-height: 230px;
  overflow-y: auto;
  z-index: 99999;
  border: 1px #8650ac solid;
}

.selector-menu.active .options {
  display: block;
  z-index: 999;
}

.options .option {
  display: flex;
  gap: 10px;
  height: 40px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 2px;
  align-items: center;
  background: #313156;
}

.options .option:hover {
  background: #7878af;
}

.option img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.option .option-text {
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.selector-menu {
  transition: 0.5s;
}

.i-arrow {
  transition: 2s ease-out;
}

.sBtn-text {
  color: white;
  font-size: 1rem;
  font-weight: 500;
}
</style>
