<template>
  <div :class="{ overlay: modalOpen }">
    <div class="modal-a" :class="{ active: modalOpen }">
      <div v-if="!loading" class="modal-content-a">
        <div class="f sb py-1">
          <div class="f align-center g20">
            <h5 class="white-text">Sell {{ items.length }} Items <br /></h5>
            <div class="f align-center">
              <h6>Group Items</h6>
              <div class="custom-switch mx-2">
                <input type="checkbox" id="switch1" v-model="isGrouped" />
                <label for="switch1"></label>
              </div>
            </div>
            <div class="custom-checkboxes align-center">
              <span class="gray py-1 mx-1">Apply Lowest Price To All:</span>
              <label class="custom-checkbox align-center">
                <input type="checkbox" v-model="setMinPriceCheck" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="custom-checkboxes align-center g10">
              <span class="gray py-1">Markets:</span>
              <label
                v-for="item in checkboxes"
                :key="item.id"
                class="custom-checkbox align-center"
              >
                <img class="img-small mx-1" :src="item.icon" />
                <input type="checkbox" v-model="item.isChecked" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <span class="close" @click="closeModalTop('close')">&times;</span>
        </div>
        <hr />
        <div v-if="isGrouped" class="item-container">
          <div v-for="item in groupedStack" :key="item.item_id">
            <div class="f sb">
              <div class="f align-center sb">
                <div class="f align-bottom">
                  <div class="f align-center qty">
                    <span class="mid-span">x{{ item.quantity }}</span>
                  </div>
                  <a
                    @click="goLink(item.items[0])"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      class="item-img"
                      :src="item.items[0].image"
                      alt="null"
                    />
                  </a>
                </div>
                <div>
                  <h6 class="px-3">{{ item.items[0].name }}</h6>
                  <div class="f steam-price px-3 py-2">
                    <VueIcon height="20px" color="white" icon="mdi:steam" />
                    <h6 class="mid-span sm-gap">
                      ${{ item.items[0].referance_price / 1000 }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="f align-bottom mt-1">
              <div class="f align-bottom">
                <h6>{{ sellPriceTitle }}</h6>
                <input
                  v-model="item.items[0].sell_price"
                  @input="updateNetPrice(item.items[0])"
                  class="dark-input-div mx-2"
                  type="number"
                  name=""
                />
              </div>
              <div class="f align-bottom mx-3 g30">
                <div class="f flex-column flex-start">
                  <span class="gray">Lowest Price</span>
                  <div class="f align-center steam-price">
                    <span :style="{ color: green }" class="mid-span sm-gap"
                      >${{ item.items[0].min_price / 1000 || 0 }}</span
                    >
                  </div>
                </div>
                <div>
                  <span>{{ afterFee }}</span>
                  <div class="f g10">
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.waxpeer" />
                      <input
                        v-model="item.items[0].afterWax"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.csgotm" />
                      <input
                        v-model="item.items[0].afterMarket"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.shadowpay" />
                      <input
                        v-model="item.items[0].afterShadow"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.csfloat" />
                      <input
                        v-model="item.items[0].afterCSFloat"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.haloksins" />
                      <input
                        v-model="item.items[0].afterHaloskins"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div v-else class="item-container">
          <div v-for="item in itemArray" :key="item.item_id">
            <div class="f sb">
              <div class="f align-center sb">
                <div class="f align-bottom">
                  <a
                    @click="goLink(item)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img class="item-img" :src="item.image" alt="null" />
                  </a>
                </div>
                <div>
                  <h6 class="px-3">{{ item.name }}</h6>
                  <div class="f steam-price align-center px-3 py-2">
                    <VueIcon height="20px" color="white" icon="mdi:steam" />
                    <h6 class="mid-span sm-gap">
                      ${{ item.referance_price / 1000 }}
                    </h6>
                  </div>
                </div>
              </div>
              <div class="f align-center px-4">
                <button
                  @click="removeItem(item)"
                  class="button btn-secondary square custom"
                >
                  <img class="sml" :src="thrashIcon" />
                </button>
              </div>
            </div>
            <div class="f align-bottom mt-1">
              <div class="f align-bottom">
                <h6>{{ sellPriceTitle }}</h6>
                <input
                  v-model="item.sell_price"
                  @input="updateNetPrice(item)"
                  class="dark-input-div mx-2"
                  type="number"
                  name=""
                />
              </div>
              <div class="f align-bottom mx-3 g30">
                <div class="f flex-column flex-start">
                  <span class="gray">Lowest Price</span>
                  <div class="f align-center steam-price">
                    <span :style="{ color: green }" class="mid-span sm-gap"
                      >${{ item.min_price / 1000 }}</span
                    >
                  </div>
                </div>
                <div>
                  <span class="gray">{{ afterFee }}</span>
                  <div class="f g10">
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.waxpeer" />
                      <input
                        v-model="item.afterWax"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.csgotm" />
                      <input
                        v-model="item.afterMarket"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.shadowpay" />
                      <input
                        v-model="item.afterShadow"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.csfloat" />
                      <input
                        v-model="item.afterCSFloat"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                    <div class="f g5 align-center">
                      <img class="sml" :src="marketIcons.haloksins" />
                      <input
                        v-model="item.afterHaloskins"
                        class="dark-input-div short-input"
                        type="text"
                        name=""
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div class="di">
          <button @click="putOnSale" class="btn-secondary custom">
            {{ this.buttonSet }}
          </button>
        </div>
      </div>
      <div v-if="loading" class="lottie-area">
        <h4 class="center">{{ listTitle }}</h4>
        <h4 class="center">{{ wait }}</h4>
        <Vue3Lottie
          :animationData="playIcon"
          :height="150"
          :width="700"
          :speed="1.2"
          :scale="1.1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SaleService from "@/services/SaleService.js";
import buffIcon from "../../assets/marketIcons/buff_icon.png";
import csgotmIcon from "../../assets/marketIcons/csgotm_icon.png";
import shadowIcon from "../../assets/marketIcons/shadowpay_icon.png";
import waxIcon from "../../assets/marketIcons/waxpeer_icon.png";
import haloIcon from "../../assets/marketIcons/haloskins_icon.png";
import csfloat from "../../assets/marketIcons/csgofloat_icon.png";
import thunder from "../../assets/marketIcons/skinthunder.png";

import thrashIcon from "../../assets/images/trash.png";
import playJSON from "../../assets/lottieFiles/multi-progress.json";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    groupedStack: {
      type: Array,
      required: true,
    },
    modalOpen: {
      type: Boolean,
      required: true,
    },
    appID: {
      type: Number,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      thrashIcon,
      green: "#30BC91",
      setMinPriceCheck: false,
      listTitle: "Items Are Being Listed On Markets",
      wait: "Please Wait",
      buttonSet: "Sell Selected Skins",
      sellPriceTitle: "Sell Price",
      afterFee: "After Fee",
      isGrouped: false,
      afterMarket: 0,
      afterShadow: 0,
      loading: false,
      markets: [],
      test: false,
      playIcon: playJSON,
      checkboxes: [
        { id: 0, name: "MarketCSGO", isChecked: false, icon: csgotmIcon },
        { id: 1, name: "Waxpeer", isChecked: false, icon: waxIcon },
        { id: 2, name: "Shadowpay", isChecked: false, icon: shadowIcon },
        { id: 3, name: "CSFloat", isChecked: false, icon: csfloat },
        { id: 4, name: "Skinthunder", isChecked: false, icon: thunder },
        //{ id: 3, name: 'HaloSkins', isChecked: true, icon: haloIcon },
      ],
      marketIcons: {
        waxpeer: waxIcon,
        csgotm: csgotmIcon,
        shadowpay: shadowIcon,
        csfloat: csfloat,
        buff: buffIcon,
        haloksins: haloIcon,
      },
    };
  },
  methods: {
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.item_slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    async closeModalTop(response) {
      this.$emit("show-message", response);
      this.$emit("closeModal");
    },
    removeItem(item) {
      this.$emit("item-remove", item.assetid);
    },
    async putOnSale() {
      this.loading = true;
      let response = false;
      this.clearMarkets();
      this.setMarkets();
      if (this.isGrouped) {
        const convertedItems = await this.convertStackedItems();
        const sellItems = convertedItems.map((item) => ({
          general_id: item.general_id,
          item_id: item.item_id,
          asset_id: item.assetid,
          market_item_id: item.market_item_id,
          name: item.name,
          price: item.sell_price * 1000,
        }));

        response = await SaleService.sellMultipleItems({
          items: sellItems,
          markets: this.markets,
          appid: this.appID,
        });
      } else {
        const sellItems = this.items.map((item) => ({
          general_id: item.general_id,
          item_id: item.item_id,
          asset_id: item.assetid,
          name: item.name,
          market_item_id: item.market_item_id,
          price: item.sell_price * 1000,
        }));
        response = await SaleService.sellMultipleItems({
          items: sellItems,
          markets: this.markets,
          appid: this.appID,
        });
      }

      if (response.success)
        localStorage.setItem("selectedItems", JSON.stringify([]));

      this.loading = false;
      if (!response.success) return this.closeModalTop(false);
      return this.closeModalTop(response.metadata);
    },
    setMarkets() {
      const data = this.checkboxes;

      if (data[0].isChecked) {
        this.markets[0] = data[0].name.toLowerCase();
      }
      if (data[1].isChecked) {
        this.markets[1] = data[1].name.toLowerCase();
      }
      if (data[2].isChecked) {
        this.markets[2] = data[2].name.toLowerCase();
      }
      if (data[3].isChecked) {
        this.markets[3] = data[3].name.toLowerCase();
      }
      if (data[4].isChecked) {
        this.markets[4] = data[4].name.toLowerCase();
      }
      /*
            if (data[4].isChecked) {
                this.markets[4] = data[4].name.toLowerCase();
            }*/
    },
    clearMarkets() {
      this.markets = [];
    },
    async convertStackedItems() {
      const items = [];

      for (const group of this.groupedStack) {
        for (const steamPrice of group.items) {
          items.push({
            name: group.name,
            quantity: 1,
            sell_price: group.sell_price,
            ...steamPrice,
          });
        }
      }

      return items;
    },
    updateNetPrice(item) {
      item.afterWax = (item.sell_price * 0.92).toFixed(2);
      item.afterCSFloat = (item.sell_price * 0.97).toFixed(2);
      item.afterShadow = item.afterMarket = (item.sell_price * 0.9).toFixed(2);
      item.afterHaloskins = item.afterHaloskins = (
        item.sell_price * 0.965
      ).toFixed(2);
    },
    findPrice(item, market) {
      if (!item || !item.price_info || item.price_info.length == 0) return 0;
      const price = item.price_info.find((e) => e.name === market);
      if (!price) return 0;
      return price.price.toFixed(2);
    },
  },
  created() {
    this.clearMarkets();
  },
  computed: {
    itemArray() {
      return this.items;
    },
  },
  watch: {
    setMinPriceCheck: {
      async handler() {
        if (!this.items) return;
        this.items.forEach((e) => {
          this.updateNetPrice(e);
          if (this.setMinPriceCheck) {
            const minPrice = e.min_price / 1000 || 0;
            e.sell_price = parseFloat((minPrice - 0.01).toFixed(2));
          } else {
            e.sell_price = parseFloat(e.referance_price / 1000 || "0");
          }
        });
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.custom-switch label {
  background-color: #b32fe8;
}

.custom-checkboxes {
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  border: 1px solid gray;
}

.gray {
  color: rgb(207, 206, 206);
}

h6,
h5 {
  margin-bottom: 0;
}

a:hover {
  cursor: pointer;
}

.img-small {
  width: 22px;
  height: 22px;
  min-width: 0%;
}

.mid-span {
  font-size: 14px;
  font-weight: 500;
}

.custom {
  border: 1px solid rgb(134, 80, 172);
}

.custom:hover {
  background-color: rgb(134, 80, 172);
}

.qty {
  display: flex;
  justify-content: center;
  height: 28px;
  width: 28px;
  background-color: #333333;
  color: white;
  border-radius: 50%;
}

.btn-secondary {
  height: 50px;
  width: 250px;
}

.square {
  height: 50px;
  width: 50px;
}

.sml {
  height: 20px;
  width: 20px;
}

.item-img {
  height: 75px;
  width: 100px;
}

.purple {
  color: #aa5cff;
}

.custom-checkbox input {
  width: 0px;
}

.di {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.modal-a {
  color: white;
  background-color: rgb(0, 0, 0);
  display: none;
  position: fixed;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 85%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 8px;

  /* Enable vertical scrolling */
}

.modal-a.active {
  display: flex;
}

.modal-content-a {
  background-color: #191a19;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.checkmark {
  border: 2px solid #a73dd2;
  margin-right: 0;
}

.custom-checkbox input:checked + .checkmark:after {
  background-color: #b32fe8;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 35px;
}

.close:hover {
  color: #f44336;
}

.item-container {
  height: 75%;
  overflow-y: auto;
}

label {
  font-weight: bold;
  margin-right: 0px;
}

input {
  width: 120px;
  height: 30px;
  padding: 8px;
  border-radius: 2px;
}

input:active {
  border: none;
}

.short-input {
  width: 80px;
}
</style>
