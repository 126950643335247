<template>
  <div
    class="catalog-card catalog-card2 sell_card select_item"
    :class="{ active: isActive }"
    @click="toggleSelection"
  >
    <a class="catalog-card__img">
      <img
        class="img"
        :src="item.icon_url"
        :style="{ '--drop-shadow-color': item.rarity_color }"
      />
    </a>
    <div class="f flex-column als w100 mt-2 p-1">
      <span v-if="getType()" class="mid-span bold" :style="{ color: typeColor }">{{
        getType()
      }}</span>
      <span class="name-span" :style="{ color: item.rarity_color }">{{
        item.short_name
      }}</span>
      <span v-if="item.exterior" class="mid-span" :style="{ color: item.exterior_color || 'white' }">{{
        item.exterior
      }}</span>
    </div>
    <div class="f steam-price text-start w100 px-1">
      <span class="price-span bold">{{
        (item.price / 1000).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    childFunction: Function,
  },
  data() {
    return {
      isActive: false,
      typeColor: "white",
    };
  },
  methods: {
    toggleActive(control) {
      this.isActive = control;
    },
    checkActive() {
      const selectedItems = JSON.parse(localStorage.getItem("depositItems"));
      if (!selectedItems || selectedItems.length === 0)
        return (this.isActive = false);

      const itemIndex = selectedItems.findIndex((item) => item.asset_id === this.item.asset_id);
      if (itemIndex !== -1) return this.toggleActive(true);
      this.toggleActive(false);
    },
    toggleSelection() {
      this.toggleActive(true);

      const selectedItems = JSON.parse(localStorage.getItem("depositItems") || "[]");
      const itemIndex = selectedItems.findIndex((item) => item.asset_id === this.item.asset_id);

      if (itemIndex !== -1) {
        selectedItems.splice(itemIndex, 1);
        this.toggleActive(false);
      } else {
        selectedItems.push(this.item);
        this.toggleActive(true);
      }

      localStorage.setItem("depositItems", JSON.stringify(selectedItems));
      this.$emit("calculateSum");
    },
    getType() {
      if(this.item.app_id == 440) return false;
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
  },
  created() {
    this.checkActive();
  },
};
</script>

<style scoped>
.sm-gap {
  margin-left: 6px;
}

.mid-span {
  font-size: 11px;
  padding: 0;
}

.name-span {
  font-size: 11px;
  padding: 0;
}

.price-span {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.img-small {
  width: 20px;
  height: 20px;
  min-width: 0%;
}

.img {
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.sell_card:hover .img {
  transition: 0.2s;
  transform: scale(1.1);
  filter: drop-shadow(0 0 6px var(--drop-shadow-color));
}

.sell_card:not(hover) .img {
  transition: 0.2s;
  transform: scale(1);
}

.sell_card {
  padding: 10px 5px 16px;
  background: #15142a;
  border-radius: 5px;
  background: -o-linear-gradient(top, rgb(30 12 36 / 45%) 0, #15142a 100%);
  background: linear-gradient(180deg, rgb(30 12 36 / 45%) 0, #15142a 100%);
  cursor: pointer;
}

.sell_card.active {
  background: #15142a;
  border: 1.5px solid #882a73;
}

.sell_card:hover {
  background: #15142a;
}

.catalog-card {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid transparent;
}

.catalog-card__hours {
  font-size: 13px;
  line-height: 0;
  text-align: center;
  color: #8787ab;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.catalog-card__img {
  display: block;
  position: relative;
  width: 80px;
  margin: 0px;
}

.catalog-card2 .catalog-card__img {
  margin-top: 0;
}

.img-market {
  height: 22px;
  width: 22px;
}

.sell-card-title {
  line-height: 20px;
}

.catalog-card__title {
  padding: 0 2px;
}

.catalog-card__title {
  display: block;
  margin-top: 2px;
  font-weight: 600;
  font-size: 15px;
  line-height: 10px;
  color: #fff;
  text-align: start;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.catalog-card__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  line-height: 25px;
  color: #dd1b5f;
  margin-top: 10px;
}

@media screen and (min-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .sell_card {
    height: 100%;
  }
}
</style>
