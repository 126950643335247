<template>
  <div class="shimmer-container">
    <div>
      <div class="shimmer-name"></div>
      <div class="shimmer-name" style="width: 160px"></div>
    </div>

    <div class="shimmer-img"></div>
  </div>
</template>

<style scoped>
.shimmer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #21242c;
  border-radius: 0.4rem;
  margin-block: 0rem;
  padding: 0.6rem 1rem;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  gap: 5%;
  width: 100%;
}

.shimmer-img {
  border-radius: 0%;
  height: 30px;
  width: 30px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

.shimmer-name {
  height: 16px;
  width: 200px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

.shimmer-price {
  height: 20px;
  width: 80px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

@media only screen and (max-width: 745px) {
  .shimmer-img {
    height: 30px;
    width: 30px;
  }

  .shimmer-container {
    padding: 0.6rem 2.4rem;
    position: relative;
    transition: 0.3s;
    overflow: hidden;
    display: flex;
  }
}

@keyframes shimmer {
  to {
    background-position: 200% 0;
  }
}
</style>
