<template>
  <div class="padding mobile">
    <div class="mobile-text">
      <h5 class="text-white">Subscription Plans</h5>
    </div>
    <div class="f g5 align-center mt-3 mobile-center">
      <h6 class="text-white">Monthly</h6>
      <SwitchButton
        :model-value="isAnnually"
        @update:modelValue="handleToggle"
      ></SwitchButton>
      <h6 class="text-white">Annually</h6>
    </div>
    <div class="pricing-wrap" v-if="cards && cards.length > 0">
      <PricingCard v-for="(item, index) in cards" :key="index" :item="item" />
    </div>
  </div>
</template>
<script>
//import InfoModal from "../components/modal/InfoModal.vue";
import PricingCard from "../components/cards/PricingCard.vue";
import SwitchButton from "@/components/checkbox/SwitchButton.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isMobile: false,
      cards: [],
      searchQuery: "",
      modalMessage: "Price Successfully Updated",
      loading: true,
      filters: {
        periode: "monthly",
      },
      isAnnually: false,
    };
  },
  methods: {
    async getPlans() {
      await this.fetchSubscriptionPlans({ periode: this.filters.periode });
      const items = this.getSubscriptionPlans;
      if (items?.length == 0) this.cards = [];

      this.cards = items.map((e) => {
        return {
          buttonText: "SUBSCRIBE",
          ...e,
        };
      });
    },
    handleToggle(newValue) {
      this.isAnnually = newValue;
      this.filters.periode = this.isAnnually ? "annually" : "monthly";
    },
    ...mapActions("payments", ["fetchSubscriptionPlans"]),
  },
  computed: {
    ...mapGetters("payments", ["getSubscriptionPlans"]),
  },
  watch: {
    filters: {
      async handler() {
        await this.getPlans();
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    await this.getPlans();
  },
  components: {
    PricingCard,
    //InfoModal,
    SwitchButton,
  },
};
</script>
<style scoped>
@import "../assets/style/customTable.css";

h6 {
  margin: 0;
}

h5 {
  margin: 0;
}

.padding {
  padding-top: 2rem;
}

.pricing-wrap {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  padding: 1rem 0rem;
  padding-bottom: 6rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 728px) {
  .padding {
    padding-top: 1.5rem;
  }

  .mobile-text {
    text-align: center;
  }

  .mobile-center {
    justify-content: center;
  }

  .pricing-wrap {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    padding: 1rem 0rem;
    padding-bottom: 4rem;
    align-items: center;
    justify-content: center;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
