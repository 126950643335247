<template>
  <Bar
    id="bar"
    :options="isMobile ? mobileOptions : desktopOptions"
    :data="chartData"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

import { mapGetters } from "vuex";

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    labelName: String,
    data: Array,
  },
  computed: {
    ...mapGetters("ui", ["isMobile"]),
    chartData() {
      return {
        labels: this.data.map((item) => item.date),
        datasets: [
          {
            label: this.labelName,
            data: this.data.map((item) => item.value),
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0, 0, 0, 350);
              gradient.addColorStop(0, this.colors.purple.half);
              gradient.addColorStop(0.75, this.colors.purple.quarter);
              gradient.addColorStop(1, this.colors.purple.zero);
              return gradient;
            },
            borderColor: this.colors.purple.default,
            borderWidth: 1,
            borderRadius: 4,
          },
        ],
      };
    },
  },
  watch: {
    isMobile() {
      this.desktopOptions.maintainAspectRatio = !this.isMobile;
    },
  },
  data() {
    return {
      desktopOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 45,
              minRotation: 45,
              padding: 10,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 10 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },
        },
      },
      mobileOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 45,
              minRotation: 45,
              padding: 10,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 10 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },
        },
      },
      colors: {
        purple: {
          default: "rgba(149, 76, 233, 1)",
          half: "rgba(149, 76, 233, 0.5)",
          quarter: "rgba(149, 76, 233, 0.25)",
          zero: "rgba(149, 76, 233, 0)",
        },
        indigo: {
          default: "rgba(80, 102, 120, 1)",
          quarter: "rgba(80, 102, 120, 0.25)",
        },
      },
    };
  },
};
</script>
<style></style>
