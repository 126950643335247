import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/sales";

const sellMultipleItems = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/sell-items", {
            items: req.items,
            markets: req.markets,
            appid: req.appid
        });
        if (!response.data.success) return []
        return response.data;

    } catch (error) {
        return false;
    }
};

const addItem = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/add-sale", {
            item: req.item
        });

        return response.data.success;

    } catch (error) {
        return false;
    }
};

const saleProcess = async (req) => {
    try {

        const response = await apiInstance.post(API_URL + "/create-sales",
            req
        );

        if (!response || !response.data) return { success: false, code: '' };

        return response.data.success;

    } catch (error) {

        if (error?.response?.data?.code === 'INVALID_API') {
            return { success: false, code: 'INVALID_API' };
        }
        return { success: false, code: '' };
    }
};

const getSales = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/get-sales", {
            params: {
                currentPage: req.currentPage,
                pageSize: req.pageSize,
                sortBy: req.sortBy,
                sortOrder: req.sortOrder,
                searchQuery: req.searchQuery,
                appId: req.appId,
                purchaseZero: req.purchaseZero
            }
        }
        );

        if (response.data.success) {
            return response.data;
        }

        return [];

    } catch (error) {
        return [];
    }
};

const getTopSales = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/get-most-sold", {
            params: {
                limit: req.limit,
                appID: req.selectedAppID
            }
        }
        );

        if (!response.data.success) return []

        return response.data.items;
    } catch (error) {

        return [];
    }
};

const getHighestSales = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/get-highest", {
            params: {
                limit: req.limit,
                appID: req.selectedAppID
            }
        }
        );

        if (!response.data.success) return []

        return response.data.items;
    } catch (error) {

        return [];
    }
};

const getPeriodicSales = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/get-sales-periodic", {
            startDate: req.startDate,
            endDate: req.endDate,
        }
        );

        if (!response.data.success) return []
        return response.data.data;
    } catch (error) {

        return [];
    }
};

const getPeriodicProfits = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/get-sales-profit", {
            params: {
                startDate: req.startDate,
                endDate: req.endDate,
            }
        }
        );

        if (!response.data.success) return []
        return response.data.data;
    } catch (error) {

        return [];
    }
};

const updateBuyMarket = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/update-market", {
            item_id: req.item_id,
            market: req.market
        }
        );
        return response.data;
    } catch (error) {

        return [];
    }
};

const updateBuyPrice = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/update-price", {
            item_id: req.item_id,
            price: req.price
        }
        );
        return response.data.success;
    } catch (error) {

        return [];
    }
};

const getAllCounts = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/sale-counts", {
            params: {
                startDate: req.start,
                endDate: req.end,
            }
        }
        );
        if (!response.data.success) return false;
        return response.data.data;
    } catch (error) {

        return [];
    }
};

const getAllProfits = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/profits", {
            params: {
                startDate: req.start,
                endDate: req.end,
            }
        }
        );
        if (!response.data.success) return false;
        return response.data.data;
    } catch (error) {

        return [];
    }
};


const getAllInfo = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/sale-overview", {
            params: {
                startDate: req.start,
                endDate: req.end,
                appID: req.appID
            }
        }
        );

        if (!response.data.success) return false;
        return response.data.data;
    } catch (error) {

        return {
            periodic_sales: [],
            periodic_profits: [],
            sale_details: [],
        };
    }
};

const SaleService = {
    sellMultipleItems,
    getHighestSales,
    addItem,
    saleProcess,
    getSales,
    getAllProfits,
    updateBuyMarket,
    updateBuyPrice,
    getAllCounts,
    getMostlySold: getTopSales,
    getPeriodicSales,
    getPeriodicProfits,
    getAllInfo
};

export default SaleService;

