<template>
  <div class="item-detail-modal">
    <div class="modal-sale">
      <div class="modal-content-a">
        <span class="close" @click="closeModal">&times;</span>
        <h5 class="white-text">Add Item To Portfolio</h5>
        <hr class="my-4" />

        <div class="f g20 sb">
          <div class="item-left">
            <div class="grid-item" style="position: relative">
              <div>
                <label>{{ datas.item_name.placeHolder }}</label>
              </div>
              <div class="auto_wrap">
                <ListSearchVue
                  ref="listSearch"
                  @go-item="goLink"
                  :data="suggestions"
                  @handleSearch="handleSearch"
                  :holder="placeHolder"
                />
              </div>
            </div>
            <div class="f g10 align-center" v-if="datas.item_name.value">
              <div class="img-block">
                <img class="small-icon" :src="datas.item_name.image" />
              </div>
              <span class="name-val">{{ datas.item_name.value }}</span>
            </div>
            <div class="f g5">
              <div class="grid-item">
                <div>
                  <label>{{ datas.item_price.placeHolder }}</label>
                </div>
                <input
                  class="dark-input-div"
                  v-model="datas.item_price.value"
                  type="number"
                  name=""
                />
              </div>
              <div class="grid-item">
                <div>
                  <label>{{ datas.item_qty.placeHolder }}</label>
                </div>
                <input
                  class="dark-input-div"
                  v-model="datas.item_qty.value"
                  type="number"
                  name=""
                />
              </div>
            </div>

            <div class="grid-item">
              <div>
                <label>{{ datas.date.placeHolder }}</label>
              </div>
              <Datepicker format="yyyy-MM-dd" v-model="buyDate" />
            </div>
            <button class="custom_button mt-3" @click="addInvestment">
              <div v-if="this.loading" class="d-flex justify-content-center">
                <div
                  style="height: 30px; width: 30px"
                  class="loading-spinner"
                ></div>
              </div>
              <h6 v-if="!this.loading">Add</h6>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import ListSearchVue from "../shared/ListSearch.vue";
import csgo from "@/assets/images/csgo.png";
import dota from "@/assets/images/dota2.png";
import tf2 from "@/assets/images/tf2.png";
import rust from "@/assets/images/rust.png";
import buffIcon from "@/assets/marketIcons/buff_icon.png";
import csgotmIcon from "@/assets/marketIcons/csgotm_icon.png";
import MarketOptions from "@/core/constants.js";
import ItemService from "@/services/ItemService";

export default {
  data() {
    return {
      placeHolder: "Search an item",
      MarketOptions,
      loading: false,
      selectedBuyMarket: "Buff.163",
      selectedBuymarketIcon: buffIcon,
      selectedSellMarket: "MarketCSGO",
      selectedSellmarketIcon: csgotmIcon,
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        { name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf2, appID: 440 },
        { name: "Rust", icon: rust, appID: 252490 },
      ],
      selectedGame: "CS2",
      selectedGameIcon: csgo,
      datas: {
        item: null,
        item_name: {
          placeHolder: "Item Name",
          value: "",
          image: null,
        },
        item_price: {
          placeHolder: "Price",
          value: 0,
        },
        item_qty: {
          placeHolder: "Quantity",
          value: 0,
        },
        game: {
          placeHolder: "Game",
          value: "CS2",
        },
        date: {
          placeHolder: "Date",
          value: 0,
        },
      },
      buyDate: new Date(),
      suggestions: [],
      showModal: true,
      autoComplete: false,
      selectedItem: {
        name: "",
        app_id: 730,
        buy_market: "",
        sell_market: "",
        buy_price: 0,
        sell_price: 0,
        date: "",
        image: "",
      },
      searchLimit: 6,
      appID: 730,
      searchTerm: "",
    };
  },
  methods: {
    goLink(item) {
      this.datas.item_name.image = item.image;
      this.datas.item_name.value = item.name;
      this.datas.item = item;
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.isDropdownOpen = false;
    },
    async setMarket(market) {
      this.selectedBuyMarket = market.name;
      this.selectedBuymarketIcon = market.icon;
    },
    async setSellMarket(market) {
      this.selectedSellMarket = market.name;
      this.selectedSellmarketIcon = market.icon;
    },
    async handleSearch(search) {
      if (search === "") {
        this.suggestions = [];
        return;
      }

      this.searchTerm = search;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getItems();
      }, 1000);
    },
    async getItems() {
      const items = await ItemService.getAllItemImage({
        name: this.searchTerm,
        limit: this.searchLimit,
        game: this.appID,
      });

      if (!items) return;
      this.suggestions = items;
    },
    async addInvestment() {
      this.loading = true;
      
      if(!this.datas?.item) return this.loading = false;

      const item = {
        name: this.datas.item.name,
        app_id: this.datas.item.app_id,
        item_id: this.datas.item.item_id,
        buy_price: this.datas.item_price.value || 0,
        quantity: this.datas.item_qty.value || 0 ,
        buy_date: this.buyDate,
        type: this.datas.item.type,
      };

      this.$emit("addInvestment", item);
      this.loading = false;
    },
    closeModal(response) {
      if (!response) return this.$emit("closeModal", false);
      this.$emit("closeModal", response);
    },
  },
  async created() {
  },
  components: { Datepicker, ListSearchVue },
};
</script>

<style scoped>
.loading-spinner {
  width: 35px;
  height: 35px;
}

.small-icon {
  width: 100%;
}

.img-block {
  background-image: radial-gradient(
    circle,
    #33373a 0% 30%,
    #2a2c2f 30% 50%,
    #242628 50% 70%,
    #464b51 70% 90%,
    #242628 90% 100%
  );
  background-position: center;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  column-gap: 0.2rem;
  row-gap: 1rem;
}

.item-left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.dark-input-div {
  border-radius: 2px;
  font-size: 0.8rem;
  border-width: 0px 0px 1px;
  background-color: #212529;
  border-color: #6a717b;
  text-align: left;
  letter-spacing: 0.5px;
  color: beige;
  width: 100%;
  min-height: 30px;
  height: 39px;
}

.auto_wrap {
  width: 100%;
}

.grid-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.item-detail-modal {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.modal-sale {
  color: white;
  background-color: #191a19;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 600px;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 8px;
  display: flex;
  padding: 1rem 1.5rem;
}

.close {
  position: absolute;
  top: 0px;
  right: 25px;
  cursor: pointer;
  font-size: 35px;
}

label {
  font-weight: bold;
  font-size: 14px;
}

.name-val {
  font-size: 12px;
}

.custom_button {
  width: 100% !important;
}

@media screen and (max-width: 576px) {
  .modal-sale {
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
}
</style>
