import buffIcon from "../assets/marketIcons/buff_icon.png";
import csgotmIcon from "../assets/marketIcons/csgotm_icon.png";
import shadowIcon from "../assets/marketIcons/shadowpay_icon.png";
import portIcon from "../assets/marketIcons/skinport_icon.png";
import waxIcon from "../assets/marketIcons/waxpeer_icon.png";
import whiteIcon from "../assets/marketIcons/whitemarket_icon.png";
import steam from "../assets/images/steam-white.png";
import bitskins from "../assets/marketIcons/bitskins_icon.png";
import gamerpayIcon from "../assets/marketIcons/gamerpay_icon.png";
import buffmarketIcon from "../assets/marketIcons/buffmarket_icon.png";
import thunderIcon from "../assets/marketIcons/skinthunder.png";
import haloIcon from "../assets/marketIcons/haloskins_icon.png";
import csfloat from "../assets/marketIcons/csgofloat_icon.png";
import csdealsIcon from "../assets/marketIcons/csdeals_icon.png";
import lisskinsIcon from "../assets/marketIcons/lisskins_icon.png";
import krakIcon from "../assets/marketIcons/krakatoa_icon.png";
import swapIcon from "../assets/marketIcons/swapgg_icon.png";

const MarketOptions = [
    { id: 1, name: "Steam", icon: steam },
    { id: 2, name: "Buff.163", icon: buffIcon },
    { id: 3, name: "Waxpeer", icon: waxIcon },
    { id: 4, name: "MarketCSGO", icon: csgotmIcon },
    { id: 5, name: "Bitskins", icon: bitskins },
    { id: 6, name: "Skinport", icon: portIcon },
    { id: 7, name: "White.Market", icon: whiteIcon },
    { id: 8, name: "Shadowpay", icon: shadowIcon },
    { id: 9, name: "Gamerpay", icon: gamerpayIcon },
    { id: 10, name: "BuffMarket", icon: buffmarketIcon },
    { id: 11, name: "Skinthunder", icon: thunderIcon },
    { id: 12, name: "HaloSkins", icon: haloIcon },
    { id: 13, name: "CSFloat", icon: csfloat },
    { id: 15, name: "CSDeals", icon: csdealsIcon },
    { id: 18, name: "LisSkins", icon: lisskinsIcon },
    { id: 16, name: "Krakatoa", icon: krakIcon },
    { id: 17, name: "Swap.GG", icon: swapIcon },
];

export default MarketOptions;