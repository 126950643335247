<template>
  <div class="contents">
    <div class="f align-center sb item_row">
      <div class="f align-bottom skin a">
        <div class="f skin_main g20">
          <a
            style="cursor: pointer"
            @mousedown.middle="this.$emit('go-item', item)"
            @click="this.$emit('go-item', item)"
            class="thumb null"
          >
            <img
              :src="item.image"
              class="img-item"
              :alt="item.name"
              loading="lazy"
              :style="{ '--drop-shadow-color': item.rarity_color }"
            />
          </a>
          <div class="name ovh">
            <div class="f sb mb-2">
              <div class="f flex-column">
                <span class="mid-span bold" :style="{ color: typeColor }">{{
                  getType()
                }}</span>
                <span class="name-span" :style="{ color: item.rarity_color }">{{
                  item.short_name
                }}</span>
                <span class="mid-span" :style="{ color: 'white' }">{{
                  item.exterior
                }}</span>
              </div>

              <button
                @click="$emit('copyText', item.name)"
                class="btn_copyname mx-2"
              >
                <VueIcon height="18px" color="white" icon="bx:copy" />
              </button>
            </div>
            <div class="f align-bottom">
              <VueIcon height="20px" color="white" icon="mdi:steam" />
              <span :style="{ color: '#fff' }" class="text-white mx-2">
                $ {{ (item.referance_price / 1000).toFixed(2) }}
              </span>
            </div>
          </div>
          <button
            aria-label="Copy"
            class="btn_copyname btn-default p5 mr-5"
          ></button>
        </div>
      </div>
      <div class="market_part">
        <template v-if="item.buy_market">
          <span class="text-white small-font"
            >Bought:
            {{ !item.buy_market ? "" : item.buy_market.toUpperCase() }}</span
          >
        </template>
        <template v-else>
          <div class="f align-center g20">
            <span class="text-white mid-font">Bought From </span>
            <span @click="$emit('updateMarket', item)" class="action_btn">
              <a class="gray" href="#">Set : {{ selectedMarket }} </a>
            </span>
          </div>
        </template>
        <div class="f align-center g20 mt-2">
          <span class="text-white small-font">Sold At: </span>
          <img class="img-mid" :src="buy" />
        </div>
      </div>
      <div class="f align-bottom purchase_part">
        <template v-if="item.editingPrice">
          <div class="f align-bottom">
            <div class="mb-1">
              <span class="mid-font text-white bold py-1">Purchase: </span>
              <div class="inner-input-div">
                <input
                  v-model="newPrice"
                  type="number"
                  step="0.01"
                  min="0"
                  max="1000"
                  class="input-area-dark"
                />
              </div>
            </div>
            <button @click="updatePrice" class="edit-btn f align-center">
              <img class="small-img-button" :src="tickIcon" />
            </button>
          </div>
        </template>
        <template v-else>
          <div class="f sb align-bottom">
            <span class="mid-font gray bold">Purchase: </span>
            <span class="m-r-s text-white currency">
              &nbsp; ${{ item.buy_price / 1000 }}
            </span>
            <button
              @click="$emit('startEditingPrice', item)"
              class="edit-btn f align-center"
            >
              <img class="small-img-button" :src="editIcon" />
            </button>
          </div>
        </template>
      </div>
      <div class="f flex-column d">
        <div>
          <span class="small-font gray">
            {{ DateUtils.getDate(item.sale_date) }}</span
          >
        </div>
      </div>
      <div class="f flex-column e g5">
        <div class="f g5 align-bottom">
          <span class="small-font bold">Sold:</span>
          <span class="mid-font light-green bold">${{ (item?.sell_price / 1000).toFixed(2) }}</span>
        </div>
        <div
          class="profit-div"
          :style="{ background: item.profit > 0 ? '#31b76833' : '#ff38380d' }"
        >
          <div>
            <span class="small-font gray bold" :style="{ color: item.profit > 0 ? '#7CFC00' : '#ff3838' }">
              ${{ item.profit }}</span
            >&nbsp;
            <span class="small-font gray bold" :style="{ color: item.profit_rate > 0 ? '#7CFC00' : '#ff3838' }">
              (%{{ item.profit_rate }})</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import copy from "../../assets/images/copy-white.png";
import edit from "../../assets/images/edit.png";
import tick from "../../assets/images/tick-white.png";
import DateUtils from "@/utils/DateUtils";

export default {
  data() {
    return {
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      newPrice: this.item.newPrice,
      color: "white",
      typeColor: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    buy: {
      type: String,
      default: "",
    },
    selectedMarket: {
      type: String,
      default: "",
    },
  },
  methods: {
    updatePrice() {
      this.$emit("updatePrice", { ...this.item, newPrice: this.newPrice });
    },
    dayDiffColor() {
      const diff = this.item.day_difference;
      if (diff < 8) {
        return (this.color = "#31b768");
      }
      if (diff > 7 && diff < 15) {
        return (this.color = "#cf9948");
      }
      return (this.color = "#ff3838");
    },
    getType() {
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  created() {
    this.dayDiffColor();
  },
};
</script>

<style scoped>
.item_row {
  background: #21242c;
  border-radius: 0.2rem;
  margin-block: 0.5rem;
  padding: 0.5rem 2.4rem;
  transition: 0.3s;
  max-height: 9rem;
  min-height: 6rem;
  display: flex;
}

.mid-span {
  font-size: 13px;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.name-span {
  font-size: 14px;
  padding: 0;
}

.price-span {
  font-size: 14px;
}

.a {
  flex: 3;
}

.market_part {
  flex: 2;
}

.purchase_part {
  flex: 2;
}

.d {
  flex: 1;
}

.d {
  flex: 1;
}

.small-font {
  font-size: 14.5px;
}

.item_row:hover {
  background-color: #292b33;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

.img-xsmall {
  height: 25px;
  width: 25px;
}

.item_row:hover .img-item {
  transition: 0.2s;
  transform: scale(1.05);
  filter: drop-shadow(0px 0px 10px var(--drop-shadow-color));
}

.item_row:not(hover) .img-item {
  transition: 0.2s;
  transform: scale(1);
}

.img-item {
  height: 75px !important;
  width: 105px !important;
}

.edit-btn {
  background-color: #212121;
  border-radius: 6px;
  border: 1px solid rgb(100, 99, 99);
  height: 34px;
  width: 34px;
  color: white;
}

.edit-btn:hover {
  background-color: #626060;
  color: white;
}

.small-img-button {
  height: 20px;
  width: 20px;
}

.inner-input-div {
  height: 2rem;
  width: 90px;
}

.input-area-dark {
  background-color: black;
  color: white;
  font-weight: 600;
  width: 90%;
  min-width: 60px;
}

.profit-div {
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
}
</style>
