<template>
    <div v-if="showModal" class="modal">
        <div class="modal-content">
            <div class="drop-area" @dragover.prevent @drop="handleDrop">
                <p>File type must be CSV</p>
                <p>Headers: Category, Combined QTY, Item Name, Storage Name, Item Wear Name, Item Paint Wear</p>
                <p>Drop your file here</p>
                <p>File size must be less than 1 MB</p>
            </div>
            <br />
            <br />
            <button class="btn btn-danger" @click="closeModal">Close</button>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            showModal: false
        };
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        handleDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if (file) {
                this.$emit("fileDropped", file);
            }
            this.closeModal();
        }
    }
};
</script>
  
<style scoped>
@import '../../assets/style/buttons.css';

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 50%;
    height: 50%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

.drop-area {
    border: 2px dashed #ccc;
    padding: 60px;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 900px) {

    .modal-content {
        width: 75%;
        height: 60%;
        background-color: white;
        padding: 20px;
        border-radius: 8px;
    }

}

@media screen and (max-width: 600px) {

    .modal-content {
        width: 80%;
        height: 70%;
        background-color: white;
        padding: 20px;
        border-radius: 8px;
    }

}
</style>
  