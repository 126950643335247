<template>
    <div class="card_inner" :style="{ background: bgColor }">
        <div class="f sb">
            <div class="f g10" v-if="item.user.avatar">
                <div class="avatar_wrap">
                    <img class="avatar_icon circle" :src="item.user.avatar" />
                </div>
                <div>
                    <h6>{{ item.user.username }}</h6>
                    <div style="height: 5px;"></div>
                    <div class="value">
                        {{ item.values.total_value || '$ 0' }}
                    </div>
                </div>
            </div>
            <button @click="goExternalLink(item.user.profile)" style="background-color: transparent; border: none;">
                <VueIcon icon="mdi:steam" width="32px" color="white" />
            </button>
        </div>

        <div class="price_info_wrap">
            <div v-for="(element, index) in item.items" :key="index" class="price_info">
                <span>{{ element.name }}</span>
                <div class="f g5">
                    <span>{{ element.count }}</span>
                    <span style="color: rgb(76, 223, 153);">({{ element.value }})</span>
                </div>
            </div>
        </div>
        <div class="price_info">
            <span>Total</span>
            <div class="f g5">
                <span>{{item.values.total_count || 0}}</span>
                <span style="color: rgb(76, 223, 153);">({{ item.values.total_value || '$ 0' }})</span>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            bgColor: '#21242c'
        };
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: '#21242c'
        }
    },
    methods: {
        goExternalLink(link) {
            window.open(link, '_blank');
        },
        copyText(item) {
            this.$emit('copyText', item)
        },
        getType() {
            if (this.item.is_stattrak) {
                this.typeColor = '#CF6830';
                return 'StatTrak™';
            }
            if (this.item.is_souvenir) {
                this.typeColor = '#FFD601';
                return 'Souvenir';
            }

            this.typeColor = 'white';
            return this.item.type;
        }
    },
    created() {
        if (!this.backgroundColor) return;
        this.bgColor = this.backgroundColor;
    }
};
</script>

<style scoped>
.card_inner {
    display: flex;
    flex-direction: column;
    background: #21242c;
    color: white;
    border-radius: 0.25rem;
    padding: 1.5rem 1.5rem;
    border: 1px solid transparent;
    min-height: 250px;
    width: 100%;
    max-width: 400px;
}

.value {
    background-color: #2C3034;
    border-radius: 6px;
    padding: 0.2rem 0.5rem;
    color: rgb(76, 223, 153);
    font-weight: bold;
}

.price_info_wrap {
    margin-block: 25px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

span {
    font-size: 14px;
    color: white;
}

.price_info {
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    padding: 0.1rem 0.3rem;

}

.item_row:hover {
    background-color: #2b2c30;
}

.inner_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    gap: 10px;
}

.currency-icon {
    height: 80px;
    width: 90px;
}

.price_div {
    border-radius: 3px;
    border: 1px #4e4e85 solid;
    padding: 0.3rem 0.8rem;
    width: 140px;
}

.name_div img {
    width: 40px;
}

.mid_span {
    font-size: 2vh;
}

.bold_body {
    font-size: 2vh;
    font-weight: bold;
}

.avatar_icon {
    height: 45px;
    width: 45px;
}

.img-xsmall {
    height: 25px;
    width: 25px;
}

.responsive-title {
    font-size: 1.9vh;
    color: white;
}

.responsive-body {
    font-size: 1.7vh;
    color: white;
}
</style>