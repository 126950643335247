<template>
    <input @input="handle" v-model="query" :type="type" :step="step" :min="minimum" :max="maximum" class="dark_in"
        :style="{ backgroundColor: inputTheme.bg }" />
</template>

<script>
export default {
    props: {
        inputValue: {
            type: Number,
            default: 0
        },
        inputKey: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'text'
        },
        step: {
            type: Number,
            default: 1
        },
        minimum: {
            type: Number,
            default: 0
        },
        maximum: {
            type: Number,
            default: 1000000
        },
        theme: {
            type: String,
            default: 'dark'
        }
    },
    data() {
        return {
            query: "",
            inputTheme: {
                type: 'dark',
                bg: '#212529'
            }
        };
    },
    methods: {
        handle() {
            this.$emit('handleInput', this.type, this.inputKey, this.query);
        }
    },
    created() {
        this.query = this.inputValue;
        let bgColor = 'white';
        if (this.theme == 'dark') bgColor = '#212529';
        if (this.theme == 'bright') bgColor = 'white';
        this.inputTheme.bg = bgColor;
    }
};

</script>

<style>
input {
    width: 100%;
}

.dark_in {
    padding: 6px 12px;
    background: #212529;
    border: 1px solid rgb(60, 63, 68);
    border-radius: 2px;
    font-size: 14px;
    color: rgb(247, 248, 248);
    height: 35px;
    appearance: none;
    transition: border 0.15s ease 0s;
}

.dark_in:focus {
    outline: none;
    box-shadow: none;
    border-color: #8650ac;
    transition: 0.3s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 576px) {
    .search_bar_custom {
        width: 100%;
    }
}
</style>