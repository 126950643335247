<template>
  <div class="info-modal">
    <div class="info-modal-content">
      <p class="px-4 text">{{ message }}</p>
    </div>
    <div class="loading-bar">
      <div
        class="fill"
        :style="{
          backgroundColor: redColor,
          animationDuration: duration,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: false,
      default: "4s",
    },
  },
  data() {
    return {
      greenColor: "#76ff03",
      redColor: "rgba(153, 3, 3, 0.9)",
      greenAccent: "rgba(76, 175, 80, 0.95)",
      redAccent: "rgba(230, 62, 62, 0.95)",
    };
  },
};
</script>

<style scoped>
.info-modal {
  animation: fadeIn 0.3s;
  width: 400px;
  min-height: 90px;
  height: auto;
  position: fixed;
  top: 80px;
  right: 10px;
  background-color: rgba(230, 58, 58, 0.9);
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.info-modal-content {
    text-align: center;
    padding-top: 15px;
}

.text {
  color: white;
  padding-top: 8px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loading-bar {
  position: relative;
  height: 6px;
  background-color: #f0f0f0;
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #76ff03;
  animation: fillAnimation normal;
}

@keyframes fillAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
