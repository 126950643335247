import { apiInstance } from "@/utils/ApiUtil.js";
const API_URL = "init";

export const fetchInitialData = async () => {
    try {
        const response = await apiInstance.get(API_URL + "/user");
        if (!response.data.success || !response.data.user) return false;
        return response.data.user;
    } catch (error) {
        console.error(error)
        return false;
    }

};

export const inventoryValuation = async () => {
    try {

        const response = await apiInstance.get(API_URL + "/net-worth", {
            params: {
                game: 'csgo'
            }
        });
        return response.data.success;
    } catch (error) {
        console.log(error)
        return false;
    }
}

const InitService = {
    inventoryValuation,
    fetchInitialData,
}

export default InitService;
