<template>
  <div
    class="shimmer-container"
  >
    <div class="sub_item">
      <button @click="this.$emit('inspect', item.assetid, item.inspect_link )" class="btn_link">
        <VueIcon icon="mdi:external-link" width="18px" color="white" />
        <p>Inspect</p>
      </button>
      <button @click="this.$emit('go-item', item)" class="btn_link">
        <VueIcon icon="mdi:chart-line" width="18px" color="white" />
        <p>Search</p>
      </button>
    </div>
    <div
      class="shimmer-container-child"
      :style="{ '--drop-shadow-color': item.rarity_color }"
    >
      <div class="shimmer-img">
        <img v-if="item.image" class="active_avatar" :src="item.image" />
      </div>
      <div class="shimmer-name">
        <span class="name"> {{ item.market_hash_name }}</span>
      </div>
      <div class="shimmer-name">
        <span class="value">
          {{
            item.price
              ? (item.price / 1000).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : 0
          }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  components: {},
};
</script>

<style scoped>
.btn_link {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 0.3rem 1.4rem;
  border-radius: 2px;
}

.btn_link p {
  color: white;
  font-size: 12px;
  margin: 0px;
}

.btn_link:hover {
  transition: 0.2s;
  background-color: #4f3982;
}

.shimmer-container-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #21242c;
  border-radius: 0.4rem;
  margin-block: 0rem;
  padding: 0.6rem 0.6rem;
  transition: 0.3s;
  overflow: hidden;
  gap: 5px;
  border: 1px solid transparent;
  cursor: pointer;
}
.shimmer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #21242c;
  border-radius: 0.4rem;
  margin-block: 0rem;
  padding: 0.6rem 0.6rem;
  transition: 0.3s;
  overflow: hidden;
  gap: 5px;
  border: 1px solid transparent;
  cursor: pointer;
}

.sub_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  height: 50%;
  gap: 5px;
  z-index: 10;
  left: 50%;
  right: 0;
  left: 0;
  bottom: -50px;
  opacity: 0;
  background-color: #21242c;
  transition: 0.3s;
  padding: 1.5rem 0.3rem;
}

.shimmer-container:hover .sub_item {
  transition: 0.3s;
  opacity: 1;
  bottom: 0px;
}

.shimmer-container-child:hover {
  transition: 0.3s;
}

.shimmer-container-child:hover .shimmer-img {
  transition: 0.3s;
  filter: drop-shadow(0 0 10px var(--drop-shadow-color));
  transform: scale(1.14);
}

.shimmer-container-child:not(hover) .shimmer-img {
  transition: 0.3s;
  transform: scale(1);
}

.inspect-link {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
}

.shimmer-img {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shimmer-name {
  width: 100%;
  text-overflow: ellipsis;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shimmer-price {
  height: 20px;
  width: 80px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_avatar {
  cursor: pointer;
  height: 4rem;
  width: 5rem;
}

.name {
  font-size: 11.5px;
  color: white;
  text-align: center;
}

.value {
  font-size: 15px;
  font-weight: bold;
  color: rgb(76, 223, 153);
}

@media only screen and (max-width: 745px) {
  .shimmer-img {
    height: 60px;
    width: 60px;
  }

  .active_avatar {
    cursor: pointer;
    height: 70px;
    width: 85px;
  }
  .shimmer-container {
    padding: 0rem;
    position: relative;
    transition: 0.3s;
    min-height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}

@keyframes shimmer {
  to {
    background-position: 200% 0;
  }
}
</style>
