<template>
  <div class="empty_items g5">
    <h5 class="text-white">{{ title }}</h5>
    <VueIcon height="30px" color="white" icon="mingcute:sad-line" />
  </div>
</template>

<script>
export default {
    name:'EmptyItem',
    props: {
        title: {
            type: String,
            default: 'Items Not Found.'
        }
    },
};


</script>

<style scoped>
.empty_items {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21242c;
  height: 10vh;
  width: 100%;
  border-radius: 4px;
}
</style>
