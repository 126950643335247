<template>
  <div>
    <div class="overlay-bg" v-if="isSearchListOpen"></div>

    <div class="navbar">
      <div v-if="isMobile" class="menu-toggle" @click="toggleSidebarSection">
        <i
          class="bx"
          :class="isMenuOpened ? 'bx-menu-alt-right' : 'bx-menu'"
        ></i>
      </div>

      <div class="empty"></div>
      <div class="auto_wrap">
        <ListSearch
          ref="listSearch"
          @go-item="goLink"
          :data="suggestions"
          @handleSearch="handle"
          :holder="'Search an item...'"
        />
      </div>
      <div class="f align-center g10">
        <span v-if="loading" class="loader mx-3"></span>
        <div
          v-if="!loading && false"
          style="cursor: pointer"
          @click="this.$router.push('/payment')"
          class="balance_wrap"
        >
          <VueIcon icon="mdi:wallet-outline" width="18px" color="white" />
          <span class="responsive-title-font text-white">{{
            parseFloat(getUserBalance).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }}</span>
          <div class="circle">
            <VueIcon icon="ic:round-add-box" width="28px" color="#8f57d0" />
          </div>
        </div>
        <div v-if="!loading && getIsAuthenticated" class="login-user">
          <div>
            <div
              style="cursor: pointer"
              @click="this.$router.push('/user-details')"
              class="circle"
            >
              <img class="user-avatar" :src="getUserAvatar" alt="User-Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListSearch from "./ListSearch.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      searchLoading: false,
      searchTerm: "",
      searchLimit: 6,
      suggestions: [],
      game: "cs2",
      appID: 730,
    };
  },
  computed: {
    ...mapGetters("user", [
      "getUsername",
      "getUserAvatar",
      "getIsAuthenticated",
      "getUserBalance",
    ]),
    ...mapGetters("ui", ["isMenuOpened", "isMobile", "isSearchListOpen"]),
    ...mapGetters("items", ["getItemSuggestions"]),
  },
  methods: {
    ...mapActions("ui", [
      "toggleSidebar",
      "setGame",
      "checkMobile",
      "setListOpen",
    ]),
    ...mapActions("items", ["fetchItemSearch"]),
    toggleSidebarSection() {
      this.toggleSidebar();
    },
    async handle(search) {
      if (search === "") {
        this.suggestions = [];
        return;
      }

      this.searchLoading = true;

      clearTimeout(this.searchTimeout);
      this.searchTerm = search;
      this.searchTimeout = setTimeout(async () => {
        await this.fetchItemSearch({
          query: this.searchTerm,
          limit: this.searchLimit,
          game: this.appID,
        });

        this.suggestions = this.getItemSuggestions;

        if (this.searchTerm === "") {
          this.suggestions = [];
          this.searchLoading = false;
          return;
        }

        this.searchLoading = false;
      }, 1000);
      this.searchLoading = false;
    },
    handleWindowClick(event) {
      if (!this.$refs.listSearch.$el.contains(event.target)) {
        this.setListOpen(false);
        this.suggestions = [];
        return;
      }

      this.setListOpen(true);
    },
    goLink(item) {
      this.setListOpen(false);
      this.$router.push({
        name: "item",
        params: { game: item.game, slug: item.slug },
      });
    },
  },
  mounted() {
    this.checkMobile();
    document.addEventListener("click", this.handleWindowClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleWindowClick);
  },
  components: { ListSearch },
};
</script>

<style scoped>
.overlay-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Yarı saydam siyah arka plan */
  z-index: 999; /* Liste ve diğer elementlerin üstünde olacak şekilde ayarlayın */
}
.navbar {
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  background-color: #313156;
  padding: 0.4rem 1.5rem;
  min-height: 65px;
}

.balance_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0 0.25rem;
  height: 2.5rem;
  border-radius: 4px;
  border-color: #764aa8;
}

.balance_wrap:hover {
  transition: 0.2s;
  background-color: transparent;
}

.empty {
  display: block;
}

.auto_wrap {
  position: relative;
  z-index: 999;
  min-width: 50%;
  max-width: 600px;
}

.login-user {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  gap: 5px;
}

.login-user span {
  display: flex;
  align-items: center;
}

.login-user p {
  padding-top: 10px;
  padding-right: 10px;
}

.user-avatar {
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 50%;
  border: solid rgb(62, 58, 84) 2px;
}

.user-avatar:hover {
  transition: 0.4s;
  border-radius: 50%;
  border: solid rgb(183, 182, 182) 2px;
}

.menu-toggle {
  cursor: pointer;
}

.menu-toggle i {
  font-size: 30px;
  color: white;
}

@media (min-width: 576px) {
  .navbar {
    justify-content: space-between;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    justify-content: space-between;
    padding: 0.4rem 1.1rem;
    gap: 4px;
  }

  .login-user span {
    display: none;
    min-height: 3.1rem;
    min-width: 3.1rem;
    height: 3.1rem;
    width: 3.1rem;
  }

  .balance_wrap {
    display: none;
  }

  .auto_wrap {
    min-width: 62%;
    max-width: 250px;
  }

  .login-user {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
  }

  .user-avatar{
    margin-left: 0px;
    width: 2.4rem;
    height: 2.4rem;
  }

  .user-avatar:hover {
    transition: 0.4s;
  }

  .empty {
    display: none;
  }
}
</style>
