<template>
    <div ref="unbox" id="unbox-area" :class="{ 'fade-in': isCardListVisible }">
        <div class='selector'></div>
        <div v-if="items && items.length > 0" ref="cardList" class="cardList">
            <div v-for="(item, index) in rowContent" :key="index" class="sim-card"
                :style="{ backgroundColor: item.color ?? 'white' }">
                <img :src="item.image" alt="Card Image">
                <span class="mid-span">{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            rowCount: 12,
            rowContent: [],
            isCardListVisible: true,
            screenWidth: 1280
        };
    },
    methods: {
        go() {

        },
        openCase() {

        },
        fillRows() {
            this.rowContent = [];
            for (let index = 0; index < this.rowCount; index++) {
                for (let index = 0; index < this.items.length; index++) {
                    const element = this.items[index];
                    this.rowContent.push(element)
                }
            }

        },
        spinWheel(roll) {

            const wheel = document.querySelector('.cardList');
            const unboxWidth = document.getElementById('unbox-area');
            const offsetWidth = unboxWidth.offsetWidth / 2;

            const totalWidth = this.rowContent.length * 200 + 10 * this.rowContent.length;
            const itemLength = this.items.length;
            const randomVar = Math.floor(Math.random()*201) + 20
            const landingPosition = (((itemLength * 10) + roll) * (210)) - offsetWidth + randomVar

            if (wheel) {
                const root = document.documentElement;
                root.style.setProperty("--percent", `-${landingPosition}px`);
                wheel.style.width = `${totalWidth}px`;
                wheel.style.animation = 'slideRight 7s cubic-bezier(0.4, 0, 0.2, 1)';

                setTimeout(() => {
                    wheel.style.animation = 'none';
                }, 7000);
            }
        },
        getScreenWidth() {
            this.screenWidth = window.innerWidth;
        }

    },
    mounted() {
        this.getScreenWidth();
        window.addEventListener("resize", this.getScreenWidth);
        this.screenWidth = window.innerWidth;
    },
};

</script>

<style scope>
:root {
    --percent: 0px;
}

.fade-in {
    transition: opacity 0.5s;
    opacity: 1;
}

@keyframes slideRight {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(var(--percent));
    }
}


#unbox-area {
    height: 200px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #8650ac;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: hidden;
    opacity: 1;
}

.arrow-down {
    margin-left: calc(50% - 20px);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #f00;
}

.cardList {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    width: 100%;
    border: 2px solid black;
}

.sim-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 90%;
    width: 200px;
    min-width: 150px;
    padding: 5px;
    text-align: center;
}

.selector {
    width: 4px;
    background: rgb(224, 219, 57);
    left: 52%;
    height: 200px;
    position: absolute;
    z-index: 2;
}

.sim-card>img {
    width: 130px;
    height: 100px;
}

.card-slide-enter,
.card-slide-leave-to {
    margin-left: 0;
}
</style>