<template>
  <div class="custom-switch">
    <input
      type="checkbox"
      id="switch1"
      :disabled="disabled"
      v-model="localValue"
      @change="handleChange"
    />
    <label for="switch1"></label>
  </div>
</template>

<script>
export default {
  name: "SwitchButton",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.localValue = newValue;
    }
  },
  methods: {
    handleChange() {
      this.$emit("update:modelValue", this.localValue);
    },
  },
};
</script>

<style scoped>
.custom-switch label {
  background-color: #b32fe8;
}
</style>
