import ItemService from "@/services/ItemService";

const comparisonModule = {
  namespaced: true,
  state: {
    filterOptions: {},
    filters: {
      cs2: {
        game: 'cs2',
        appID: 730,
        minPrice: 0,
        maxPrice: 20000,
        minRoi: 0,
        maxRoi: 10000,
        qty: 0,
        volume: "0",
        type: [],
        exterior: [],
        rarity: [],
        extra: [],
        sourceMarkets: ["buff.163"],
        targetMarkets: ["marketcsgo"],
        pageSize: 10,
        currentPage: 1,
        sortBy: "profitRatio",
        sortOrder: "DESC",
        globalMinimum: {
          text: 'Global Minimum',
          isChecked: false
        }
      },
      dota2: {
        game: 'dota2',
        appID: 570,
        pageSize: 10,
        currentPage: 1,
        sortBy: "profitRatio",
        sortOrder: "DESC",
        minPrice: 0,
        maxPrice: 200000,
        minRoi: 0,
        maxRoi: 10000,
        qty: 0,
        volume: '0',
        type: [],
        exterior: [],
        rarity: [],
        extra: [],
        sourceMarkets: ["lisskins"],
        targetMarkets: ["marketcsgo"],
        globalMinimum: {
          text: 'Global Minimum',
          isChecked: false
        }
      },
      tf2: {
        game: 'tf2',
        appID: 440,
        currentPage: 1,
        minPrice: 0,
        maxPrice: 200000,
        minRoi: 0,
        maxRoi: 10000,
        qty: 0,
        volume: '0',
        type: [],
        exterior: [],
        rarity: [],
        extra: [],
        sourceMarkets: [],
        targetMarkets: [],
        globalMinimum: {
          text: 'Global Minimum',
          isChecked: false
        }
      },
      rust: {
        game: 'rust',
        currentPage: 1,
        minPrice: 0,
        maxPrice: 200000,
        minRoi: 0,
        maxRoi: 10000,
        qty: 0,
        volume: '0',
        type: [],
        exterior: [],
        rarity: [],
        extra: [],
        sourceMarkets: [],
        targetMarkets: [],
        globalMinimum: {
          text: 'Global Minimum',
          isChecked: false
        }
      }
    }
  },
  mutations: {
    setFilters(state, { game, filters }) {
      state.filters[game] = filters;
      localStorage.setItem('filters', JSON.stringify(state.filters));
    },
    loadFilters(state, game) {
      const savedFilters = JSON.parse(localStorage.getItem('filters')) || {};
      if (savedFilters[game]) {
        state.filters[game] = savedFilters[game];
      }
    },
    resetFilters(state, game) {
      const defaultFilters = {
        cs2: {
          game: 'cs2',
          appID: 730,
          minPrice: 0,
          maxPrice: 20000,
          minRoi: 0,
          maxRoi: 10000,
          qty: 0,
          volume: "0",
          type: [],
          exterior: [],
          rarity: [],
          extra: [],
          sourceMarkets: ["buff.163"],
          targetMarkets: ["marketcsgo"],
          pageSize: 10,
          currentPage: 1,
          sortBy: "profitRatio",
          sortOrder: "DESC",
          globalMinimum: {
            text: 'Global Minimum',
            isChecked: false
          }
        },
        dota2: {
          game: 'dota2',
          appID: 570,
          currentPage: 1,
          sortBy: "profitRatio",
          sortOrder: "DESC",
          minPrice: 0,
          maxPrice: 200000,
          minRoi: 0,
          maxRoi: 10000,
          qty: 0,
          volume: '0',
          type: [],
          exterior: [],
          rarity: [],
          extra: [],
          sourceMarkets: ["lisskins"],
          targetMarkets: ["marketcsgo"],
          miniumum: 0
        },
        tf2: {
          game: 'tf2',
          appID: 440,
          currentPage: 1,
          minPrice: 0,
          maxPrice: 200000,
          minRoi: 0,
          maxRoi: 10000,
          qty: 0,
          volume: '0',
          type: [],
          exterior: [],
          rarity: [],
          extra: [],
          sourceMarkets: [],
          targetMarkets: [],
          miniumum: 0
        },
        rust: {
          game: 'rust',
          currentPage: 1,
          minPrice: 0,
          maxPrice: 200000,
          minRoi: 0,
          maxRoi: 10000,
          qty: 0,
          volume: '0',
          type: [],
          exterior: [],
          rarity: [],
          extra: [],
          sourceMarkets: [],
          targetMarkets: [],
          miniumum: 0
        }
      };
      if (defaultFilters[game]) {
        state.filters[game] = defaultFilters[game];
        localStorage.setItem('filters', JSON.stringify(state.filters));
      }
    },
    setFilterOptions(state, { game, filters }) {
      try {
        let options = JSON.parse(localStorage.getItem('filterOptions')) || {};
        options[game] = filters;
        localStorage.setItem('filterOptions', JSON.stringify(options));
        state.filterOptions = options;
      } catch (error) {
        console.log(error)
      }
    },
    loadFilterOptionsFromLocal(state, game) {
      const options = JSON.parse(localStorage.getItem('filterOptions')) || {};
      if (options[game]) {
        state.filterOptions[game] = options[game];
      }
    }
  },
  actions: {
    initializeFilters({ commit }, game) {
      commit('loadFilters', game);
    },
    loadGameFilterOptions({ commit }, game) {
      commit('loadFilterOptionsFromLocal', game);
    },
    updateFilter({ commit }, { game, filters }) {
      commit('setFilters', { game, filters });
    },
    resetFilters({ commit }, game) {
      commit('resetFilters', game);
    },
    async ensureFilterOptions({ dispatch, commit }, payload) {
      const { game } = payload;
      const options = JSON.parse(localStorage.getItem('filterOptions')) || {};
      if (!options[game]) {
        await dispatch('fetchFilterOptions', payload);
      } else {
        commit('loadFilterOptionsFromLocal', game);
      }
    },
    async fetchFilterOptions({ commit }, payload) {
      try {
        const { appID, game } = payload;
        const data = await ItemService.getFilters({ appID: appID });
        commit('setFilterOptions', { game: game, filters: data });
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    }
  },
  getters: {
    getComparisonFilters: (state) => (game) => state.filters[game] || {},
    getFilterOptions: (state) => (game) => state.filterOptions[game] || {}
  }
}

export default comparisonModule;
