<template>
  <div class="background-small">
    <div class="row">
      <div class="col-xs-12 col-md-12 col-lg-12 g20 f align-center sb">
        <h4 class="text-white">{{ title }}</h4>
        <span v-if="infoLoading" class="loader"></span>
        <div class="f g20 align-center" v-else>
          <div class="responsive-title-font text-white">
            <h6 class="light-green bold">$ {{ stats.total }}</h6>
            <span> Total Withdrawal </span>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-div my-3">
      <div class="left">
        <div class="date-wrapper">
          <div class="date">
            <span class="responsive-title-font text-white">Date Range</span>
            <VueDatePicker
              v-model="date"
              range
              :partial-range="false"
              :transitions="false"
            />
          </div>
        </div>
        <div>
          <button class="update_button" @click="synchData">
            <VueIcon
              height="24px"
              color="white"
              icon="flowbite:refresh-outline"
            />
          </button>
        </div>
      </div>
      <div class="right">
        <IconDropdown
          :refFunction="setMarket"
          :options="marketOptions"
          :selectedItem="selectedMarket"
          :selectedIcon="selectedIcon"
        />
        <SortDropdown
          :order-by-function="setOrderBy"
          :options="orderOptions"
          :selectedItem="selectedOrder"
          :selectedIcon="selectedIcon"
          :selected-dir="selectedDirection"
          :order-dir-function="setOrderDir"
          :heightOption="'40px'"
        />
        <SelectorDropdown
          :refFunction="setSize"
          :options="sizeOptions"
          :selectedItem="selectedSize"
        />
      </div>
    </div>
    <div class="filter-div-mobile mb-3">
      <div class="f sb w100 align-end">
        <div class="date-wrapper">
          <div class="date">
            <span class="responsive-title-font text-white">Date Range</span>
            <VueDatePicker
              v-model="date"
              range
              :partial-range="false"
              :transitions="false"
            />
          </div>
        </div>
        <button class="update_button" @click="synchData">
          <VueIcon
            height="24px"
            color="white"
            icon="flowbite:refresh-outline"
          />
        </button>
      </div>
      <div class="f sb g5 w100">
        <IconDropdown
          :refFunction="setMarket"
          :options="marketOptions"
          :selectedItem="selectedMarket"
          :selectedIcon="selectedIcon"
        />
        <SelectorDropdown
          :refFunction="setSize"
          :options="sizeOptions"
          :selectedItem="selectedSize"
        />
        <SortDropdown
          :order-by-function="setOrderBy"
          :options="orderOptions"
          :selectedItem="selectedOrder"
          :selectedIcon="selectedIcon"
          :selected-dir="selectedDirection"
          :order-dir-function="setOrderDir"
          :heightOption="'40px'"
        />
      </div>

    </div>
    <div class="wrapper mb-5">
      <div>
        <div v-if="itemLoading" class="f flex-column g10">
          <ShimmerEffect v-for="index in 6" :key="index" />
        </div>
        <div v-if="!itemLoading && listedItems != []">
          <div class="contents" v-for="(item, index) in items" :key="index">
            <component
              :is="isMobile ? 'TableContentMobile' : 'TableContent'"
              @copy-text="copyText"
              :item="item"
            />
          </div>
        </div>
      </div>
      <div class="page_row pt-2 pb-3 f align-center">
        <PaginationRow
          :pageNumber="filterObject.pageNumber"
          :totalPages="totalPages"
          @update:pageNumber="filterObject.pageNumber = $event"
        />
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import InfoModal from "../components/modal/InfoModal.vue";
import SideBar from "@/components/shared/SideBar.vue";
import TransactionService from "@/services/TransactionService.js";

import TableContent from "@/components/transaction/TableContent.vue";
import TableContentMobile from "@/components/transaction/TableContentMobile.vue";

import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import DateUtils from "@/utils/DateUtils.js";
import edit from "../assets/images/edit.png";
import tick from "../assets/images/tick-white.png";

import allIcon from "../assets/images/all.png";
import csgotmIcon from "../assets/marketIcons/csgotm_icon.png";
import shadowIcon from "../assets/marketIcons/shadowpay_icon.png";
import waxIcon from "../assets/marketIcons/waxpeer_icon.png";

import btc from "../assets/images/bitcoin.png";
import usdt from "../assets/images/tether.png";

import fail from "../assets/images/cancel2.png";
import progress from "../assets/images/inprogress.png";
import success from "../assets/images/check2.png";

export default {
  data() {
    return {
      totalPages: 6,
      title: "Transaction History",
      stats: {
        total: 0,
      },
      loading: true,
      infoLoading: false,
      itemLoading: true,
      isDropdownOpen: false,
      editIcon: edit,
      tickIcon: tick,
      searchTimeout: null,
      showModal: false,
      filterObject: {
        pageSize: 10,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        market: "all",
      },
      marketOptions: [
        { name: "All", icon: allIcon },
        { name: "MarketCSGO", icon: csgotmIcon },
        { name: "Waxpeer", icon: waxIcon },
        { name: "Shadowpay", icon: shadowIcon },
      ],
      selectedIcon: allIcon,
      selectedMarket: "All",
      columns: ["Img", "Name", "Wax", "CSGOTM", "Shadow"],
      info: [],
      sizeOptions: ["10", "15", "25", "30"],
      selectedSize: "10",
      orderOptions: ["Price", "Date", "Name"],
      selectedOrder: "Date",
      selectedDirection: "DESC",
      date: new Date(),
    };
  },

  methods: {
    async setMarket(type) {
      this.selectedMarket = type.name;
      this.selectedIcon = type.icon;
      this.isDropdownOpen = false;
      this.filterObject.market = this.selectedMarket.toLocaleLowerCase();
      await this.getHistory();
    },
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
      await this.getHistory();
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
      await this.getHistory();
    },
    setType(type) {
      this.selectedGame = type.name;
      this.selectedIcon = type.icon;
      this.isDropdownOpen = false;
    },
    async setSize(size) {
      this.selectedSize = size;
      this.isDropdownOpen = false;
      this.filterObject.pageSize = parseInt(size) ?? 10;
      await this.getHistory();
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.modalMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async showInfoModal() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 1500);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async refreshData() {
      this.loading = true;
      await this.getHistory();
      this.loading = false;
    },
    async synchData() {
      this.itemLoading = true;

      await TransactionService.synchAll({
        init: DateUtils.getOnlyDate(this.date[0]),
        final: DateUtils.getOnlyDate(this.date[1]),
        order: "DESC",
      });

      await this.getHistory();

      this.itemLoading = false;
    },
    async getHistory() {
      this.itemLoading = true;

      const response = await TransactionService.getAll({
        sortBy: this.filterObject.orderBy,
        currentPage: this.filterObject.pageNumber,
        sortOrder: this.filterObject.orderDir,
        pageSize: this.filterObject.pageSize,
        market: this.filterObject.market,
      });

      if (!response || response.length === 0) return (this.itemLoading = false);

      const res = this.mapResponse(response.items);

      this.items = res;

      this.mapMarketValues(response.statistics);

      this.itemLoading = false;
    },
    mapResponse(response) {
      const newItems = response.map((e) => {
        const amount = e.netAmount;
        const icon = e.type === "bitcoin" ? btc : usdt;
        const type = e.type === "bitcoin" ? "BTC" : "TRC-20";
        const market = e.market;
        const wallet = e.wallet;
        const created = e.created;
        const status = e.status;
        let marketIcon = waxIcon;
        let statusIcon = success;
        if (market === "waxpeer") marketIcon = waxIcon;
        if (market === "shadow") marketIcon = shadowIcon;
        if (market === "marketcsgo") marketIcon = csgotmIcon;
        if (status === "executed" || status === "completed")
          statusIcon = success;
        if (status === "canceled") statusIcon = fail;
        if (status === "sent") statusIcon = progress;

        return {
          netAmount: amount,
          icon: icon,
          type: type,
          market: market,
          wallet: wallet,
          created: created,
          status: status,
          marketIcon: marketIcon,
          statusIcon: statusIcon,
        };
      });

      return newItems;
    },
    mapMarketValues(markets) {
      if (!markets) return (this.stats.total = 0);
      let total = 0;

      markets.forEach((e) => {
        total += e.value;
      });

      this.stats.total = total.toFixed(2);
    },
    setDateRange() {
      const endDate = new Date();
      const startDate = new Date(new Date().setDate(endDate.getDate() - 180));
      this.date = [startDate, endDate];
    },
  },
  async created() {
    try {
      this.refreshData();
      this.setDateRange();
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
    listedItems() {
      return this.items;
    },
    ...mapGetters("ui", ["isMobile"]),
  },
  watch: {
    "filterObject.pageNumber"() {
      this.getHistory();
    },
  },
  components: {
    InfoModal,
    SideBar,
    TableContent,
    TableContentMobile,
    ShimmerEffect,
    IconDropdown,
    SortDropdown,
    SelectorDropdown,
  },
};
</script>
<style scoped>
.update_button {
  border: 1px solid rgb(134, 80, 172);
  height: 34px;
  border-radius: 3px;
  width: 50px;
  background-color: #313156;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update_button:hover {
  background-color: rgb(147, 88, 189);
  transition: 0.2s;
}

.filter-div-mobile {
  display: none;
}

.filter-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-div .filter-left {
  display: flex;
  gap: 10px;
}

.filter-div .right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-icon {
  height: 20px;
  width: 20px;
}

.custom_button {
  width: 100px;
  background-color: #313156;
  border: 1px #8650ac solid;
}

.custom_button:hover {
  background-color: #8650ac;
}

.rotate-360 {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.selector-menu {
  width: 120px;
}

.selector-menu .select-btn {
  padding-left: 15px;
  padding-right: 15px;
}

.filter-div .left {
  padding-left: 0px;
  display: flex;
  align-items: flex-end;
}

.button_wrap {
  display: none;
}

@media screen and (max-width: 728px) {
  .filter-div {
    display: none;
  }

  .filter-div-mobile {
    display: flex;
    flex-direction: column;
  }

  .button_wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .date-wrapper {
    width: 80%;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
