<template>
  <div
    class="checbox-select-menu"
    :style="{ width: widthOption }"
    @click="toggleDropdown"
  >
    <div
      class="select-btn"
      :style="{
        background: backgroundColor,
        height: heightOption ? heightOption : '40px',
      }"
    >
      <span class="sBtn-text mobile">{{ type }}</span>
      <i
        class="i-arrow"
        :class="{
          'bx bx-chevron-down': !isDropdownOpen,
          'bx bx-chevron-up': isDropdownOpen,
        }"
      ></i>
    </div>
    <ul
      class="options"
      v-show="isDropdownOpen"
      :class="{ active: isDropdownOpen }"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        class="option"
        @click="setFunction($event, option)"
      >
        <input
          class="checkbox"
          type="checkbox"
          :id="'checkbox-' + option.name"
          v-model="option.isChecked"
        />
        <span class="option-text p-1">{{ option.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    selectedItem: Object,
    options: Array,
    refFunction: Function,
    type: String,
    backgroundColor: {
      type: String,
      required: false,
      default: "#313156",
    },
    widthOption: {
      type: String,
      required: false,
      default: "100px",
    },
    heightOption: {
      type: String,
      required: false,
      default: "40px",
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    setFunction(event, option) {
      event.stopPropagation();
      this.refFunction(option);
    },
  },
  created() {},
};
</script>
<style scoped>
.checkbox {
  width: 18px;
  accent-color: rgb(134, 80, 172);
  background-color: #212529;
  border: 1px solid red;
  border-color: red;
}

.extra-small-img {
  width: 25px;
  height: 25px;
  border-radius: 0px;
}

.checbox-select-menu {
  width: 100px;
  transition: 0.5s;
}

.checbox-select-menu .select-btn {
  display: flex;
  height: 40px;
  padding: 6px;
  border-radius: 1px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 500;
  color: white;
  transition: 0.5s;
  border: 1px rgb(134, 80, 172) solid;
}

.select-btn:hover {
  background-color: #8650ac;
}

.select-btn i {
  font-size: 25px;
  transition: 0.3s;
}

.checbox-select-menu.active .select-btn i {
  transform: rotate(-180deg);
  transition: 0.3s;
}

.checbox-select-menu .options {
  padding: 0.4rem 0.4rem;
  position: absolute;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
  max-height: 300px;
  background: #313156;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  border: 1px solid #8650ac;
  width: auto;
  overflow-y: auto;
}

.checbox-select-menu.active .options {
  display: block;
  z-index: 999;
  transition: 0.3s;
}

.options .option {
  display: flex;
  gap: 10px;
  height: 40px;
  cursor: pointer;
  padding: 0 8px;
  border-radius: 2px;
  align-items: center;
  background: #313156;
}

.options .option:hover {
  background-color: #8650ac;
}

.option img {
  height: 25px;
  width: 25px;
}

.option .option-text {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.checbox-select-menu {
  transition: 0.5s;
}

.i-arrow {
  transition: 2s ease-out;
}

.sBtn-text {
  color: white;
  font-size: 14px;
}

@media screen and (max-width: 576px) {
  .checbox-select-menu {
    width: auto;
    transition: 0.5s;
  }

  .checbox-select-menu .select-btn {
    gap: 5px;
  }

  .sBtn-text {
    font-size: 12px;
  }

  .option .option-text {
    font-size: 12px;
  }
}
</style>
