<template>
  <div class="search_bar_custom">
    <VueIcon height="24px" color="white" icon="bx:search" />
    <input
      v-model="search"
      @input="handle"
      type="text"
      :placeholder="holder"
      class="search_input"
    />
    <button class="clear_btn_custom" @click="clear">
      <VueIcon icon="ic:sharp-clear" width="24px" color="gray" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    holder: {
      type: String,
      default: "Search...",
    },
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    handle() {
      this.$emit("handleSearch", this.search);
    },
    clear() {
      this.search = " ";
      this.$emit("handleSearch", this.search);
    },
  },
};
</script>

<style>
.search_input {
  border-radius: 4px;
  font-size: 0.8rem;
  margin-left: 5px;
  border-width: 0px 0px 1px;
  background-color: #212529;
  text-align: left;
  letter-spacing: 0.5px;
  color: beige;
  width: 90%;
  border-bottom: none;
  min-height: 25px !important;
  height: 28px;
}

.clear_btn_custom {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: transparent;
  border: none;
}

.clear_btn_custom:hover {
  transform: scale(1.1);
  transition: 0.2s;
  background-color: transparent !important;
}

input:focus {
  outline: none;
}

.search_bar_custom {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 500px;
  padding-left: 10px;
  border-radius: 4px;
  background-color: #212529;
  border: 1.5px transparent solid;
  height: 40px;
  border: 1px transparent solid;
}

.search_bar_custom:focus-within {
  border: 1px rgb(134, 80, 172) solid;
}

.search_bar_custom:hover {
  border: 1px rgb(134, 80, 172) solid;
  transition: 0.4s;
}

.search_input {
  padding-left: 8px;
  outline-width: 0;
}

.remove {
  border: transparent;
  background-color: transparent;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .search_bar_custom {
    width: 100%;
  }
}
</style>
