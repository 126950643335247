import { createStore } from 'vuex';

import authModule from './modules/auth.js';
import userModule from './modules/user.js';
import comparisonModule from './modules/comparison.js';
import itemsModule from './modules/items.js';
import uiModule from './modules/ui.js';
import routerModule from './modules/router.js';
import paymentsModule from './modules/payments.js'

const store = createStore({
  modules: {
    auth: authModule,
    user: userModule,
    filters: comparisonModule,
    items: itemsModule,
    ui: uiModule,
    router: routerModule,
    payments:paymentsModule
  }
});

store.dispatch('filters/initializeFilters', 'cs2');

export default store;

