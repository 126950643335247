<template>
    <div class="item-detail-modal">
        <div class="modal-sale">
            <div class="modal-content-a">
                <span class="close" @click="closeModal">&times;</span>
                <h5 class="white-text">Sell Item</h5>
                <hr class="my-4">

                <div class="grid-container">
                    <div class="grid-item">
                        <div><label>{{ formData[0].placeholder }}</label></div>
                        <input @input="handleSearch" class="dark-input-div" v-model="formData[0].value" type="text"
                            name="">
                        <AutoComplete @selectImage="selectImage" v-if="autoComplete" :data="suggestions" />
                    </div>
                    <div class="grid-item">
                        <div><label>{{ formData[4].placeholder }}</label></div>
                        <IconDropdown :refFunction="setMarket" :options="MarketOptions" :selectedItem="selectedBuyMarket"
                            :selectedIcon="selectedBuymarketIcon" />
                    </div>
                    <div class="grid-item">
                        <div><label>{{ formData[1].placeholder }}</label></div>
                        <input class="dark-input-div" v-model="formData[1].value" type="number" name="">
                    </div>
                    <div class="grid-item">
                        <div><label>{{ formData[3].placeholder }}</label></div>
                        <IconDropdown :refFunction="setGame" :options="gameOptions" :selectedItem="selectedGame"
                            :selectedIcon="selectedGameIcon" />
                    </div>
                    <div class="grid-item">
                        <div><label>{{ formData[2].placeholder }}</label></div>
                        <input class="dark-input-div" v-model="formData[2].value" type="number" name="">
                    </div>

                    <div class="grid-item">
                        <div><label>{{ formData[5].placeholder }}</label></div>
                        <IconDropdown :refFunction="setSellMarket" :options="MarketOptions"
                            :selectedItem="selectedSellMarket" :selectedIcon="selectedSellmarketIcon" />
                    </div>
                    <div class="grid-item">
                        <div>
                            <div><label>{{ formData[6].placeholder }}</label></div>
                            <Datepicker class="dark-input-div" format="yyyy-MM-dd" v-model="saleDate" />
                        </div>
                    </div>

                </div>
                <button class="custom_button mt-4" @click="addSale">
                    <div v-if="this.loading" class="d-flex justify-content-center">
                        <div style="height: 30px; width: 30px;" class="loading-spinner"></div>
                    </div>
                    <h6 v-if="!this.loading">Add</h6>
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import IconDropdown from '../dropdownButtons/IconDropdown.vue';
import Datepicker from 'vue3-datepicker';
import AutoComplete from '@/components/autoComplete/AutoComplete.vue'

import csgo from '../../assets/images/csgo.png';
import dota from '../../assets/images/dota2.png';
import tf2 from '../../assets/images/tf2.png';
import rust from '../../assets/images/rust.png';
import buffIcon from '../../assets/marketIcons/buff_icon.png';
import csgotmIcon from '../../assets/marketIcons/csgotm_icon.png';
import SaleService from '@/services/SaleService';
import MarketOptions from '../../core/constants.js';
import moment from 'moment';
import ItemService from '@/services/ItemService';

export default {
    data() {
        return {
            MarketOptions,
            loading: false,
            selectedBuyMarket: 'Buff.163',
            selectedBuymarketIcon: buffIcon,
            selectedSellMarket: 'MarketCSGO',
            selectedSellMarketID: 4,
            selectedSellmarketIcon: csgotmIcon,
            gameOptions:
                [
                    { name: 'CS2', icon: csgo, appID: 730 },
                    { name: 'Dota2', icon: dota, appID: 570 },
                    { name: 'TF2', icon: tf2, appID: 440 },
                    { name: 'Rust', icon: rust, appID: 252490 }
                ],
            selectedGame: 'CSGO',
            selectedGameIcon: csgo,
            isGameOpen: false,
            isBuyOpen: false,
            isSellOpen: false,
            buttonSet: 'Sell Selected Skins',
            stack: [],
            formData: [
                { value: '', placeholder: 'Item Name' },
                { value: '', placeholder: 'Buy Price' },
                { value: '', placeholder: 'Sell Price' },
                { value: 'csgo', placeholder: 'Game' },
                { value: 'buff.163', placeholder: 'Buying Market' },
                { value: 'marketcsgo', placeholder: 'Selling Market' },
                { value: '', placeholder: 'Sale Date' }
            ],
            saleDate: new Date(),
            suggestions: [],
            showModal: true,
            autoComplete: false,
            selectedItem: {
                name: '',
                app_id: 730,
                buy_market: '',
                sell_market: '',
                buy_price: 0,
                sell_price: 0,
                date: '',
                image: '',
                sell_market_id:null
            }
        }
    },
    methods: {
        async setGame(type) {
            this.selectedGame = type.name;
            this.selectedGameIcon = type.icon;
            this.isDropdownOpen = false;
        },
        async setMarket(market) {
            this.selectedBuyMarket = market.name;
            this.selectedBuymarketIcon = market.icon;
            this.formData[4].value = market.name;
        },
        async setSellMarket(market) {
            this.selectedSellMarket = market.name;
            this.selectedSellmarketIcon = market.icon;
            this.selectedSellMarketID = market.id;
            this.formData[5].value = market.name;
        },
        toggleDropdown(str) {
            if (str === 'game') {
                this.isGameOpen = !this.isGameOpen;
            }
            if (str === 'buy') {
                this.isBuyOpen = !this.isBuyOpen;
            }
            if (str === 'sell') {
                this.isSellOpen = !this.isSellOpen;
            }
        },
        selectOption(type, option) {
            if (type === 'game') {
                this.selectedGame = option;
                this.formData[3].value = this.selectedGame.toLowerCase();
            }
            if (type === 'buy') {
                this.selectedBuyMarket = option;
                this.formData[4].value = this.selectedBuyMarket;
            }
            if (type === 'sell') {
                this.selectedSellMarket = option;
                this.formData[5].value = this.selectedSellMarket;
            }
        },
        async handleSearch() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.getImage();
            }, 1000);
        },
        async getImage() {
            const response = await ItemService.getOneItemImage({ name: this.formData[0].value, limit: 12 });
            this.suggestions = response;
            if (this.suggestions.length > 0) return this.autoComplete = true;
            return this.autoComplete = false;
        },
        async addSale() {
            this.loading = true;
            this.convertData(this.formData);
            if (this.selectedItem.name === '' | this.selectedItem.name === undefined) return this.loading = false;
            if (this.selectedItem.image === '' | this.selectedItem.image === undefined) return this.loading = false;
    
            const response = await SaleService.addItem({ item: this.selectedItem });
            this.loading = false;
            if (response) this.closeModal(response);
        },
        selectImage(item) {
            if (!item) return false;
            this.formData[0].value = item.name;
            this.selectedItem.name = item.name;
            this.selectedItem.image = item.image;
            this.autoComplete = false;
        },
        convertData(data) {
            this.selectedItem.name = data[0].value;
            this.selectedItem.app_id = data[3].value === 'csgo' ? 730 : 570
            this.selectedItem.buy_market = data[4].value
            this.selectedItem.sell_market = data[5].value
            this.selectedItem.sell_market_id = this.selectedSellMarketID;
            this.selectedItem.buy_price = parseFloat(data[1].value)
            this.selectedItem.sell_price = parseFloat(data[2].value)
            this.selectedItem.date = data[6].value;
        },
        closeModal(response) {
            if (!response) return this.$emit('closeModal', false);
            this.$emit('closeModal', response);
        },
    },
    async created() {
        this.formData[6].value = moment(this.date).format('YYYY-MM-DD');
    },
    components: { Datepicker, IconDropdown, AutoComplete }
};
</script>
  
<style>
.loading-spinner {
    width: 35px;
    height: 35px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    column-gap: 0.2rem;
    row-gap: 1rem;
}

.grid-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}


.item-detail-modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}

.modal-sale {
    color: white;
    background-color: #191a19;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 70%;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    border-radius: 8px;
    display: flex;
}

.close {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    font-size: 55px;
}

label {
    font-weight: bold;
    font-size: 14px;
}

@media screen and (max-width: 576px) {

    .modal-sale {
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }

}
</style>
  