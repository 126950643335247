<template>
  <div class="mobile-sidebar" :class="{ open: isMenuOpened }">
    <div class="mobile-header">
      <div class="f sb">
        <img
          v-if="menuLogo"
          id="logo_name"
          :src="menuLogo"
          alt="menu-logo"
          class="menu-logo icon"
        />
        <a class="logo_name" href="https://www.steamanalyzer.com/landing">
          {{ menuTitle }}
        </a>
        <div class="mx-3 menu-toggle" @click="toggleSidebar">
          <i
            class="bx"
            :class="isMenuOpened ? 'bx-menu-alt-right' : 'bx-menu'"
          ></i>
        </div>
      </div>
    </div>

    <div class="mobile-menu">
      <ul class="nav-list">
        <li v-for="menuItem in menuItems" :key="menuItem.route">
          <a
            class="mobile-anchor"
            @click="mobileMasterRoute(menuItem)"
            :style="{
              background: activeRoute === menuItem.link ? '#475186' : '',
            }"
          >
            <div class="f align-center g10">
              <img
                draggable="false"
                :src="menuItem.icon"
                :alt="menuItem.name"
              />
              <span class="mx-1 mobile-links-name">{{ menuItem.name }}</span>
            </div>
            <div class="f align-center" v-if="menuItem.subItems">
              <VueIcon
                :style="{
                  transform: showSubItems ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: '0.4s',
                }"
                icon="iconamoon:arrow-right-2-light"
                width="28px"
                color="white"
              />
            </div>
          </a>
          <div v-if="menuItem.subItems" class="mobile-sub-wrap">
            <div
              class="sub-menu"
              v-for="subItem in menuItem.subItems"
              :key="subItem.route"
              :class="{ 'sub-menu-active': showSubItems }"
            >
              <a
                class="mobile-anchor"
                @click="mobileSubRoute(subItem)"
                :style="{
                  background: activeRoute === subItem.link ? '#475186' : '',
                }"
              >
                <div class="f align-center g10">
                  <img
                    draggable="false"
                    :src="subItem.icon"
                    :alt="subItem.name"
                  />
                  <span class="mobile-links-name">{{ subItem.name }}</span>
                </div>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <div class="bottom">
        <button
          style="border: none; background-color: transparent"
          @click="logout()"
        >
          <div class="f sb">
            <img :src="logoutIcon" class="bx bx-log-out" alt="logout" />
            <span class="mx-2 mobile-links-name">Logout</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardIcon from "../../assets/images/dashboard.png";
import traderIcon from "../../assets/images/pie-chart.png";
import gunIcon from "../../assets/images/gun.png";
import transactions from "../../assets/images/transaction.png";
import userIcon from "../../assets/images/user.png";
import historyIcon from "../../assets/images/history.png";
import pieIcon from "../../assets/images/pie.png";
import investIcon from "../../assets/images/invest.png";
import bookmarkIcon from "../../assets/images/bookmark.png";
import rankIcon from "../../assets/images/ranking.png";

import csgoIcon from "../../assets/images/layers.png";
import inventoryIcon from "../../assets/images/menu.png";

import target from "../../assets/images/target.png";
import cartIcon from "../../assets/images/cart-white.png";
import LogoutService from "@/services/LogoutService";
import logoutIcon from "../../assets/images/logout-red.png";
import chartIcon from "../../assets/images/compare.png";
import marketplaceIcon from "../../assets/images/marketplace-white.png";
import barIcon from "../../assets/images/bar-chart.png";

import router from "../../router/index.js";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SidebarMobile",
  props: {
    //! Menu settings
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    menuTitle: {
      type: String,
      default: "Steam Analyzer",
    },
    menuLogo: {
      type: String,
      default: "",
    },
    menuIcon: {
      type: String,
      default: "bxl-c-plus-plus",
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: "250px",
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: "78px",
    },

    //! Menu items
    menuItems: {
      type: Array,
      default: () => [
        {
          link: "/user-details",
          route: "user",
          name: "User",
          tooltip: "User",
          icon: userIcon,
        },
        {
          link: "trader",
          route: "trader",
          name: "Trader PRO",
          tooltip: "Trader Tools",
          icon: traderIcon,
          subItems: [
            {
              link: "/dashboard",
              route: "dashboard",
              name: "Dashboard",
              tooltip: "Dashboard",
              icon: dashboardIcon,
            },
            {
              link: "/sales",
              route: "saleHistory",
              name: "Sales History",
              tooltip: "History",
              icon: historyIcon,
            },
            {
              link: "/list-items",
              route: "list-items",
              name: "List Items",
              tooltip: "Multiple Sale Tool",
              icon: csgoIcon,
            },
            {
              link: "/listed-items",
              route: "listed-items",
              name: "Listed Items",
              tooltip: "Listed Items",
              icon: cartIcon,
            },
            {
              link: "/target",
              route: "target",
              name: "Targets",
              tooltip: "Targets",
              icon: target,
            },
            {
              link: "/transactions",
              route: "transactions",
              name: "Transactions",
              tooltip: "Transaction",
              icon: transactions,
            },
            {
              link: "/analytics",
              route: "analytics",
              name: "Analytics",
              tooltip: "Analytics",
              icon: pieIcon,
            },
            {
              link: "/investments",
              route: "investments",
              name: "My Investments",
              tooltip: "Investments",
              icon: investIcon,
            },
          ],
        },
        {
          link: "/cs2/simulator",
          route: "simulator",
          name: "Case Simulator",
          tooltip: "simulator",
          icon: gunIcon,
        },
        {
          link: "/inventory",
          route: "inventory",
          name: "Inventory",
          tooltip: "Inventory Tool",
          icon: inventoryIcon,
        },
        {
          link: "/ranking",
          route: "ranking",
          name: "Inventory Rankings",
          tooltip: "Inventory Rankings",
          icon: rankIcon,
        },
        {
          link: "/comparison",
          route: "comparison",
          name: "Price Comparison",
          tooltip: "Profits",
          icon: chartIcon,
        },
        {
          link: "/trends",
          route: "trends",
          name: "Price Trends",
          tooltip: "Profits",
          icon: barIcon,
        },
        {
          link: "/watchlist",
          route: "watchlist",
          name: "Watchlist",
          tooltip: "Watchlist",
          icon: bookmarkIcon,
        },
        {
          link: "/marketplaces",
          route: "marketplaces",
          name: "Marketplaces",
          tooltip: "Marketplaces",
          icon: marketplaceIcon,
        },
      ],
    },

    //! Styles
    bgColor: {
      type: String,
      default: "#11101d",
    },
    secondaryColor: {
      type: String,
      default: "#1d1b31",
    },
    logoTitleColor: {
      type: String,
      default: "#fff",
    },
    iconsColor: {
      type: String,
      default: "#fff",
    },
    itemsTooltipColor: {
      type: String,
      default: "#e4e9f7",
    },
    searchInputTextColor: {
      type: String,
      default: "#fff",
    },
    menuItemsHoverColor: {
      type: String,
      default: "#475186",
    },
    menuItemsTextColor: {
      type: String,
      default: "#fff",
    },
    menuFooterTextColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      activeRoute: "",
      activeTopRoute: "",
      activeSubRoute: "",
      fullPath: null,
      activeLink: null,
      logoutIcon: logoutIcon,
      showSubItems: false,
      subRoutes: [
        "dashboard",
        "saleHistory",
        "list-items",
        "transactions",
        "listed-items",
        "analytics",
        "investments",
      ],
    };
  },
  methods: {
    ...mapActions("ui", ["toggleMobile", "toggleSidebar"]),
    async logout() {
      try {
        const response = await LogoutService.logoutConfig();
        if (response) return (window.location.href = "/");
      } catch (error) {
        alert("Error while logging out!");
      }
    },
    subIsActive() {
      const res = this.$router.currentRoute.value.name;
      this.currentRoute = res;

      this.showSubItems = false;
      if (!res) return;

      if (this.subRoutes.includes(this.currentRoute) && this.isMenuOpened) {
        this.showSubItems = true;
      } else {
        this.showSubItems = false;
      }

      if (this.isMobile) this.toggleMenuSidebar();

      this.activeRoute = this.currentRoute;
    },
    mobileMasterRoute(item) {
      if (item.route === "trader") {
        this.showSubItems = !this.showSubItems;
        return;
      }

      this.activeRoute = item.route;
      this.fullPath = item.link;

      if (item.route === this.$router.currentRoute.value.name) {
        this.toggleMenuSidebar();
        router.replace({ path: this.fullPath });
      } else {
        this.toggleMenuSidebar();
        router.push({ path: this.fullPath });
      }
    },
    mobileSubRoute(item) {
      this.showSubItems = false;
      this.activeRoute = item.route;
      this.fullPath = item.link;

      if (item.route === this.$router.currentRoute.value.name) {
        this.toggleMenuSidebar();
        router.replace({ path: this.fullPath });
      } else {
        this.toggleMenuSidebar();
        router.push({ path: this.fullPath });
      }
    },
    toggleMenuSidebar() {
      this.$store.dispatch("ui/toggleSidebar", this.isMenuOpened);
      if (!this.isMenuOpened) this.showSubItems = false;
    },
    adjustBodyPadding() {
      if (!this.isMobile) {
        window.document.body.style.paddingLeft =
          this.isMenuOpened && this.isPaddingLeft
            ? this.menuOpenedPaddingLeftBody
            : this.menuClosedPaddingLeftBody;
        return;
      }

      window.document.body.style.paddingLeft = this.isMenuOpened ? "0" : "0";
    },
    dontScroll(control) {
      const htmlElement = document.querySelector("html");

      if (control) return (htmlElement.style.overflowY = "hidden");
      return (htmlElement.style.overflowY = "auto");
    },
    setSubItems() {
      if (!this.isMenuOpened) this.showSubItems = false;
    },
    handleMenuToggle(isOpen) {
      if (isOpen && this.isMobile) {
        this.dontScroll(true);
      } else if (!isOpen && this.isMobile) {
        this.dontScroll(false);
      }
      this.adjustBodyPadding();
    },
    handleRouteChange(to) {
      this.currentRoute = to.path;
      if (!this.currentRoute) return;

      this.activeRoute = this.currentRoute;
      if (this.subRoutes.includes(this.currentRoute)) {
        this.showSubItems = false;
      }
    },
    setInitialSettings() {
      this.setSubItems();
      this.subIsActive();
      this.adjustBodyPadding();
    },
    ...mapActions("ui", ["checkMobile"]),
  },
  computed: {
    cssVars() {
      return {
        // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
        "--bg-color": this.bgColor,
        "--secondary-color": this.secondaryColor,
        "--logo-title-color": this.logoTitleColor,
        "--icons-color": this.iconsColor,
        "--items-tooltip-color": this.itemsTooltipColor,
        "--serach-input-text-color": this.searchInputTextColor,
        "--menu-items-hover-color": this.menuItemsHoverColor,
        "--menu-items-text-color": this.menuItemsTextColor,
        "--menu-footer-text-color": this.menuFooterTextColor,
      };
    },
    ...mapGetters("ui", ["isMobile", "isMenuOpened", "getRouteLoading"]),
  },
  watch: {
    isMenuOpened: "handleMenuToggle",
    isMobile: "adjustBodyPadding",
    $route(to) {
      this.handleRouteChange(to);
    },
  },
  mounted() {
    this.checkMobile();
  },
  created() {
    this.adjustBodyPadding();
    this.setSubItems();
    this.subIsActive();
  },
};
</script>

<style>
/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css");

body {
  transition: all 0.5s ease;
}

.sidebar-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-height: calc(100% - 60px);
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  width: 78px;
  background: var(--bg-color);
  /* padding: 6px 14px 0 14px; */
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.mobile {
  width: 100%;
}

.mobile-toggle {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 100;
}

.sidebar.mobile .mobile-toggle {
  display: block;
}

.sidebar.open {
  width: 250px;
}

/*Mobile Sidebar*/
.mobile-sidebar {
  background-color: #11101d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 0.25s;
  z-index: 999;
}

.mobile-sidebar::-webkit-scrollbar {
  display: none;
}

.mobile-sidebar.open {
  left: 0;
  overflow-y: auto;
}

.mobile-header {
  padding: 15px;
  background: #1d1b31;
  text-align: center;
}

.logo-details {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.logo-details img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.logo_name {
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.logo_name:hover {
  cursor: pointer;
  color: white;
}

.mobile-menu {
  margin-top: 10px;
}

.mobile-menu .nav-list {
  gap: 4px;
  padding: 5px 10px;
  margin: 0;
  list-style: none;
}

.mobile-menu ul li {
  min-height: 40px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  margin: 0px 0;
  list-style: none;
  cursor: pointer;
  position: relative;
}

.mobile-menu .mobile-anchor {
  justify-content: space-between;
  text-decoration: none;
  color: white;
  display: flex;
  min-height: 40px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
}

.mobile-sub-wrap {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding-left: 15px;
}

.mobile-menu .sub-menu {
  display: none;
  gap: 5px;
  padding-left: 20px;
  transition: 0.3s ease;
}

.mobile-menu .sub-menu-active {
  display: flex;
  transition: 0.4s;
}

.menu-toggle {
  cursor: pointer;
}

.menu-toggle i {
  font-size: 30px;
  color: white;
}

.mobile-menu img {
  height: 22px;
  width: 22px;
}

.mobile-links-name {
  font-size: 17px;
  color: white;
}

@media (max-width: 576px) {
  .sidebar li .tooltip {
    display: none;
  }

  .sidebar.open {
    width: 100%;
  }
}

.mobile-log-out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--secondary-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.mobile-log-out {
  width: 50px;
  background: var(--secondary-color);
  opacity: 0;
}

.mobile-log-out {
  opacity: 1;
}
</style>
