import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/listing";

export const getStatistics = async () => {
    try {
        const response = await apiInstance.get(API_URL + "/statistics");
        if (!response) return false;
        return response.data.info;
    } catch (error) {
        return false;
    }
};

export const getAll = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + `/all`, {
            params: {
                currentPage: req.currentPage,
                appid: req.appid,
                pageSize: req.pageSize,
                searchQuery: req.searchQuery,
                orderDir: req.dir,
                orderBy: req.orderBy,
                buyPriceZero:req.buyPriceZero
            }
        });

        if (!response.data.success) return false;
        return response.data.items;
    } catch (error) {
        return [];
    }
};

export const synchListings = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/synchronize", {
            params: {
                appid: req.appid
            }
        });
        return response.data.success;
    } catch (error) {
        return false;
    }
};

export const updatePrice = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + "/update-price", {
            item: req.listItem
        });

        if (!response?.data?.success) return false;
        return true

    } catch (error) {
        console.log(error)
        return false;
    }
};

export const lowerPrices = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + "/lower-price", {
            options: req.options,
            items: req.items,
            app_id: req.app_id
        });

        if (!response?.data?.success) return false;
        return true;

    } catch (error) {
        console.log(error)
        return false;
    }
};
const ListedItemService = {
    getStatistics,
    getAll,
    synchListings,
    updatePrice,
    lowerPrices
}

export default ListedItemService;