<template>
    <div @mouseenter="isActive = true" @mouseleave="isActive = false"
        class="catalog-card catalog-card2 sell_card select_item">
        <a class="catalog-card__img">
            <img class="img" :src="item.image">
        </a>
        <div class="catalog-card__title sell-card-title">
            <span :style="{ color: item.color ?? 'white' }" class="mid-span">{{ item.name }}</span>
        </div>
        <div>
            <button @click="openCase" class="open_case">Open Container</button>
        </div>
        <div @click="go" class="price">
            <div></div>
            <div class="f align-center">
                <img class="img-small" :src="steam" />
                <span class="f mid-span">
                    {{ item.price }} $
                </span>
            </div>
            <div>
                <VueIcon icon="iconamoon:arrow-right-2-light" width="28px" color="white" />
            </div>
        </div>
    </div>
</template>

<script>

import steam from '../../assets/images/steam-gray.png'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isActive: false,
            steam,
            marketCount: 10
        };
    },
    methods: {
        go() {
            this.$emit('go-item', this.item)
        },
        openCase() {
            this.$emit('open-case', this.item.caseID)
        }

    },
    created() {
    }
};
</script>
  
<style scoped>
p {
    margin-bottom: 0px;
}

.case {
    color: white;
}

.case:hover {
    color: gray;
    transition: 0.4s;
}

.flex-end {
    justify-content: flex-end;
}

.fg2 {
    flex-grow: 2;
}

.fg1 {
    flex-grow: 1;
}

.mid-span {
    font-size: 15px;
}

.img {
    min-width: 100%;
    max-width: 100%;
    min-height: 120px;
    height: auto;
}

.sell_card {
    border: 1px rgb(40, 40, 40) solid;
    padding: 10px 0 0px;
    background: #15142a;
    border-radius: 10px;
    background: -o-linear-gradient(top, rgb(30 12 36 / 45%) 0, #15142a 100%);
    background: linear-gradient(180deg, rgb(30 12 36 / 45%) 0, #15142a 100%);
    cursor: pointer;
}

.sell_card:hover {
    transition: 0.4s;
    background: #15142a;
    box-shadow: 0px 0px 12px 5px;
}

.sell_card:hover .img {
    transition: 0.4s;
    transform: scale(1.05);
}

.sell_card:not(hover) .img {
    transition: 0.4s;
    transform: scale(1);
}

.catalog-card {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    min-height: 20px;
    background-color: rgb(48, 47, 47);
}

.sell_card:hover .price {
    transition: 0.3s;
    background-color: #545454;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.catalog-card__img {
    display: block;
    position: relative;
    width: calc(100% - 60px);
    margin: 7px auto 0;
    padding-bottom: 5%;
}

.catalog-card2 .catalog-card__img {
    margin-top: 0;
}

.open_case {
    background-image: linear-gradient(to right, #25aae1, #166f44, #219f62, #3a7);
    box-shadow: 0 4px 10px 0 rgba(28, 103, 100, 0.75);
    padding: 4px 8px;
    width: 140px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin-top: 5px;
    height: auto;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 4px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.open_case:hover {
    transition: 0.3s;
    transform: translateY(-4px);
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.sell-card-title {
    line-height: 20px;
}

.catalog-card__title {
    padding: 0 5px;
}

.catalog-card__title {
    display: block;
    margin-top: 2px;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    color: #fff;
    text-align: center;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}


@media screen and (min-width: 1100px) {
    .sell_card {
        height: 100%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 1100px) {
    .sell_card {
        height: 100%;
    }
}

@media screen and (max-width: 576px) {
    .sell_card {
        height: 100%;
    }

    .mid-span {
        font-size: 16px;
    }

    p {
        font-size: 16px;
    }

    .catalog-card__img {
        display: block;
        position: relative;
        width: 130px;
        margin: 7px auto 0;
        padding-bottom: 5%;
    }

    .catalog-card {
        gap: 15px;
    }

}
</style>
  