<template>
    <div class="item_bar">
        <div>
            <h5>{{ header }}</h5>
        </div>
        <div class="price_info_wrap">
            <div v-for="(item, index) in items" :key="index">
                <div class="price_info">
                    <div>
                        <span class="name_span">{{ item.name }} </span>
                    </div>
                    <div>
                        <span v-if="item.name !== 'Price Trend'" class="value_span" :style="{ color: setColor(item) }">
                            {{ item.value }}
                        </span>
                        <span v-if="item.name === 'Price Trend'" class="value_span">
                            <VueIcon :color="setColor(item.value)" width="26px" height="26px" :icon="setTrend(item.value)"></VueIcon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            green: '#4CDF99',
            red: '#ff0f0f',
            white: 'white',
            stonks: 'Stonks',
            notStonks: 'Not Stonks',
        }
    },
    methods: {
        setColor(item) {
            if (item.type === 1) return this.white;
            return item.value > 0 ? this.green : this.red
        },
        setTrend(trend) {
            if (trend === 1) return 'ph:trend-up';
            if (trend === -1) return 'ph:trend-down';
            if (trend === 0) return 'material-symbols:trending-flat';
            return 'ph:trend-up';
        }
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        header: {
            type: String
        },
        trend: {
            type: String,
            required: true
        }
    },
    created() {

    }
};
</script>

<style scoped>
.bold {
    font-weight: 900
}

h5,
h6 {
    color: white;
    margin-bottom: 0;
}

.item_bar {
    width: 100%;
    height: 100%;
    background-color: #313155;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 3px;
}


.price_info_wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 5px;
}

.price_info {
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    padding: 0.1rem 0.3rem;
}

.price_info:hover .name_span,
.value_span {
    transition: 0.4s;
    color: white;
}

.price_info .name_span {
    font-size: 13px;
    font-weight: bold;
    color: #9f9d9d;
}

.price_info .value_span {
    font-size: 13px;
    font-weight: 900;
    color: white;
}


@media screen and (max-width: 769px) {

    .item_bar {
        padding: 1rem 1rem;
    }

    .price_info .value_span {
        font-size: 14px;
        font-weight: 900;
        color: white;
    }

}
</style>
