import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function exportPdf(items, userDetails) {
    const companyName = 'Profit Table';
    const customerName = userDetails.name.toUpperCase();
    const now = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const invoiceDate = now.toLocaleString('en-GB', options);
    const invoiceNumber = '1';

    const tableRows = items.map(item => {
        return [
            { text: item.name, color: item.rarity_color },
            '$ ' + item.steam_last_7d,
            item.cstm_volume,
            '$' + item.market_price,
            '$' + item.wax_price,
            { text: '%' + item.buff_market_ratio, color: item.buff_market_ratio > 0 ? 'green' : 'red' },
            { text: '%' + item.buff_wax_ratio, color: item.buff_wax_ratio > 0 ? 'green' : 'red' },
        ];
    });

    const pdfContent = [
        { text: companyName, style: 'header' },
        { text: `User: ${customerName}`, style: 'subtitle' },
        { text: `Date: ${invoiceDate}`, style: 'subtitle' },
        { text: `Invoice No: ${invoiceNumber}`, margin: [0, 0, 0, 20], style: 'subtitle' },
        {
            table: {
                headerRows: 1,
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    ['Name', 'Steam', 'Volume', 'Market', 'Wax', 'M(%)', 'W(%)'],
                    ...tableRows
                ]
            },
            style: 'table'
        }
    ];

    const docDefinition = {
        content: pdfContent,
        styles: {
            header: {
                fontSize: 20,
                bold: true,
                margin: [0, 0, 0, 10],
                color: '#333' // Başlık metin rengi
            },
            title: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                color: '#333' // Başlık metin rengi
            },
            subtitle: {
                fontSize: 14,
                margin: [0, 0, 0, 10],
                color: '#555' // Alt başlık metin rengi
            },
            table: {
                margin: [0, 10, 0, 10],
                fontSize: 12,
                bold: true,
            },
            total: {
                fontSize: 14,
                bold: true,
                margin: [0, 20, 0, 0],
                color: '#333' // Metin rengi
            }
        }
    };

    pdfMake.createPdf(docDefinition).download('fatura.pdf');
}

export async function exportPdfCustom(items, user) {
    const headerText = 'Investments';
    const userName = user.name;
    const now = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const invoiceDate = now.toLocaleString('en-GB', options);
    const invoiceNumber = '1';

    const tableRows = items.map(item => {
        return [
            { text: item.name },
            item.quantity,
            '$ ' + item.buy_price,
            '$ ' + item.total_buy,
            '$ ' + item.buff_price,
            { text: '$ ' + item.buff_multiple_profit, color: item.buff_multiple_profit > 0 ? 'green' : 'red' },
            { text: '$ ' + item.buff_total_sale },
            { text: '%' + item.buff_profit_ratio, color: item.buff_profit_ratio > 0 ? 'green' : 'red' },
            { text: item.buy_date },
        ];
    });

    const pdfContent = [
        { text: headerText, style: 'header' },
        { text: `User: ${userName}`, style: 'subtitle' },
        { text: `Date: ${invoiceDate}`, style: 'subtitle' },
        { text: `Invoice No: ${invoiceNumber}`, margin: [0, 0, 0, 20], style: 'subtitle' },
        {
            table: {
                headerRows: 1,
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    ['Name', 'Qty', 'Buy Price', 'Total Buy', 'Buff Price', 'Buff Profit', 'Total Sale', 'Ratio', 'Buy Date',],
                    ...tableRows
                ]
            },
            style: 'table'
        }
    ];

    const docDefinition = {
        content: pdfContent,
        styles: {
            header: {
                fontSize: 20,
                bold: true,
                margin: [0, 0, 0, 10],
                color: '#333' // Başlık metin rengi
            },
            title: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                color: '#333' // Başlık metin rengi
            },
            subtitle: {
                fontSize: 14,
                margin: [0, 0, 0, 10],
                color: '#555' // Alt başlık metin rengi
            },
            table: {
                margin: [0, 10, 0, 10],
                fontSize: 12,
                bold: true,
            },
            total: {
                fontSize: 14,
                bold: true,
                margin: [0, 20, 0, 0],
                color: '#333' // Metin rengi
            }
        }
    };

    pdfMake.createPdf(docDefinition).download(userName + '.pdf');
}




