<template>
  <div class="background-small">
    <div class="f sb mb-2">
      <div class="col-md-6 col-lg-9 col-xl-10 col-xxl-9 g20">
        <h4 class="text-white">{{ title }}</h4>
        <div class="f g10">
          <span class="title text-white mt-1 m-r-s">
            {{ total }}: {{ count }}
          </span>
          <span class="title text-white mt-1 m-r-s">
            {{ availableItems }}: {{ available }}
          </span>
          <span class="title text-white mt-1 m-r-s">
            {{ tradeLocked }}: {{ this.count - this.available }}
          </span>
        </div>
      </div>
      <div class="">
        <h6>Game</h6>
        <IconDropdown
          class="mt-1"
          :refFunction="setGame"
          :options="gameOptions"
          :selectedItem="selectedGame"
          :selectedIcon="selectedGameIcon"
        />
      </div>
    </div>
    <div class="row mb-3 sb">
      <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-12 g20">
        <span v-if="countLoading" class="loader"></span>
        <div class="valuation" v-else>
          <Grid :title="valuation" :data="getValuations" />
        </div>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 price_bar">
        <LineGraph
          :options="chartOption"
          @selectInterval="selectInterval"
          :interval="timeInterval"
          :header="'Valuation History'"
          :sub-header="''"
          :chart-data="valueGraph"
        />
      </div>
    </div>

    <div class="wrap_inventory">
      <div class="inventory_list_wrap">
        <div class="filter_wrap_mobile">
          <div>
            <CheckboxDropdown
              :options="typeOptions"
              :selected-item="selectedType"
              :background-color="'#212529'"
              :type="'Type'"
              :ref-function="setType"
              :width-option="'80px'"
            ></CheckboxDropdown>
          </div>
          <div>
            <CheckboxDropdown
              :options="exteriorOptions"
              :selected-item="selectedExterior"
              :background-color="'#212529'"
              :type="'Exterior'"
              :ref-function="setExterior"
            ></CheckboxDropdown>
          </div>
          <div>
            <button class="update_button" @click="createInventory">
              <VueIcon
                height="30px"
                color="white"
                icon="flowbite:refresh-outline"
              />
            </button>
          </div>
          <button
            @click="toggleFilter"
            style="background-color: transparent; border: none"
          >
            <VueIcon
              icon="mdi:information-outline"
              width="32px"
              color="white"
            />
          </button>
        </div>
        <div class="filters">
          <div class="search_tab">
            <div class="search_wrap">
              <SearchBar
                :holder="'Search for items...'"
                @handleSearch="handleSearch"
              ></SearchBar>
            </div>
          </div>
          <div class="filter_buttons">
            <div>
              <CheckboxDropdown
                :options="exteriorOptions"
                :selected-item="selectedExterior"
                :background-color="'#212529'"
                :type="'Exterior'"
                :ref-function="setExterior"
                :width-option="'auto'"
              ></CheckboxDropdown>
            </div>
            <div>
              <CheckboxDropdown
                :options="typeOptions"
                :selected-item="selectedType"
                :background-color="'#212529'"
                :type="'Type'"
                :ref-function="setType"
                :width-option="'70px'"
              ></CheckboxDropdown>
            </div>
            <div>
              <h6>Size</h6>
              <SelectorDropdown
                :background-color="'#212529'"
                :refFunction="setSize"
                :options="sizeOptions"
                :selectedItem="selectedSize"
                :key="'size'"
              />
            </div>
            <div>
              <h6>Display</h6>
              <SelectorDropdown
                :background-color="'#212529'"
                :refFunction="setDisplay"
                :options="displayOptions"
                :selectedItem="selectedDisplay"
                :key="'grid'"
              />
            </div>
            <div>
              <h6>Update</h6>
              <button class="update_button" @click="createInventory">
                <VueIcon
                  height="30px"
                  color="white"
                  icon="flowbite:refresh-outline"
                />
              </button>
            </div>
          </div>
        </div>

        <div v-if="loading && inventoryType == 'grid'" class="item_grid">
          <SkeletonCard
            style="min-height: 190px"
            ref="itemCard"
            v-for="index in 12"
            :key="index"
          />
        </div>

        <div v-if="!loading && inventoryType == 'grid'" class="item_grid">
          <InventoryItemCard
            v-for="item in getItems"
            :key="item.asset_id"
            :item="item"
          />
        </div>

        <div v-if="loading && inventoryType == 'list'" class="inventory_list">
          <ShimmerCard ref="itemCard" v-for="index in 12" :key="index" />
        </div>

        <div
          v-else-if="!loading && getItems.length == 0"
          class="inventory_list"
        >
          <div class="empty_inventory g5">
            <h5 class="text-white">Your inventory is empty.</h5>
            <VueIcon height="30px" color="white" icon="mingcute:sad-line" />
          </div>
        </div>

        <div v-if="!loading && inventoryType == 'list'" class="inventory_list">
          <InventoryCard
            ref="itemCard"
            v-for="(item, index) in getItems"
            @copyText="copy"
            @go-item="goItemDetail"
            :key="item.general_id + '-' + index"
            :item="item"
          />
        </div>

        <div class="page_row pt-2 pb-3 f align-center">
          <PaginationRow
            :pageNumber="page"
            :totalPages="totalPages"
            @update:pageNumber="page = $event"
          />
        </div>
      </div>
      <div class="item_overview_wrap">
        <h5 class="text-white">Overview</h5>
        <OverviewCard :item="inventoryOverview"></OverviewCard>
        <div style="width: 100%; direction: ltr">
          <h6 class="danger-text">
            Updated {{ DateUtils.getLocalDate(inventoryUpdateTime) }}
          </h6>
        </div>
      </div>
    </div>
  </div>

  <div v-if="mobileFilterOpen" class="bottom_modal">
    <div class="f sb align-center">
      <h5 class="text-white">Inventory Overview</h5>
      <div class="close_btn" @click="toggleFilter">&times;</div>
    </div>
    <div class="f align-center">
      <OverviewCard
        :background-color="'transparent'"
        :item="inventoryOverview"
      ></OverviewCard>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="copyMessage"
    @close="showModal = false"
  />
  <ErrorModal
    v-if="showErrorModal"
    :message="errorMessage"
    :duration="'3s'"
    @close="showErrorModal = false"
  />
</template>

<script>
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";

import SteamService from "@/services/SteamService";
import Grid from "@/components/valueBars/ValuationGrid.vue";
import LineGraph from "@/components/graphs/LineGraph.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import ShimmerCard from "@/components/listedItemComponents/ShimmerEffect.vue";
import SkeletonCard from "@/components/skeleton/ShimmerCard.vue";
import InventoryCard from "@/components/Inventory/InventoryCard.vue";
import InventoryItemCard from "@/components/Inventory/InventoryItemCard.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import OverviewCard from "@/components/Inventory/InventoryOverviewCard.vue";
import FileConverterService from "@/services/FileConverterService.js";
import CheckboxDropdown from "@/components/dropdownButtons/CheckboxDropdown.vue";
import csgo from "../assets/images/csgo.png";
import dota from "../assets/images/dota2.png";

import excelIcon from "../assets/images/xlsx.png";

import tf from "../assets/images/tf2.png";
import DateUtils from "@/utils/DateUtils.js";
import { mapGetters, mapActions } from "vuex";
import MarketOptions from "../core/constants.js";

export default {
  data() {
    return {
      inventoryType: "grid",
      MarketOptions,
      totalPages: 6,
      copyMessage: "Item name copied.",
      showModal: false,
      mobileFilterOpen: false,
      DateUtils,
      inventoryUpdateTime: "",
      tf,
      title: "My Inventory",
      listItems: "List Items On Marketplaces",
      warn: "You need to set your API keys for using item listing tool.",
      valuation: "Inventory Value",
      sellBtn: "Sell Items",
      selectAll: "Select All",
      availableItems: "Tradable Items",
      tradeLocked: "Locked Items",
      invValue: "Inventory Value",
      onlyTrade: "Not Listed Items Only",
      atLeastOne: "At Least One Market",
      locked: "Unlocked",
      total: "Total Items",
      errorMessage: "Try Again Later!",
      showErrorModal: false,
      infoArray: [
        { name: "Waxpeer", value: 0 },
        { name: "MarketCSGO", value: 0 },
        { name: "Shadowpay", value: 0 },
        { name: "Haloskins", value: 0 },
      ],
      typeOptions: [],
      selectedType: { name: "", isChecked: true },
      exteriorOptions: [],
      selectedExterior: { name: "", isChecked: true },
      exportOptions: [
        {
          name: "Excel",
          icon: excelIcon,
        },
      ],
      gridOptions: [
        {
          name: "List",
          icon: excelIcon,
        },
        {
          name: "Grid",
          icon: excelIcon,
        },
      ],
      selectedExportOption: "Excel",
      selectedExportIcon: excelIcon,
      selectedExteriorCode: "All",
      marketValues: [],
      loading: true,
      statsLoading: true,
      countLoading: false,
      isModalOpen: false,
      showInfoModal: false,
      switches: [
        {
          id: "not-listed-switch",
          label: "Show Not Listed Items",
          model: false,
        },
      ],
      sizeOptions: ["25", "30", "40", "50"],
      selectedSize: "30",
      displayOptions: ["Grid", "List"],
      selectedDisplay: "Grid",
      page: 1,
      pageSize: 25,
      items: [],
      steamItems: [],
      sellStack: [],
      groupedStack: [],
      count: 0,
      available: 0,
      value: 0,
      modalShowingTime: 3000,
      filters: {
        type: [],
        exterior: [],
        appID: 730,
      },
      inventoryOverview: {
        user: {
          total_value: 0,
          avatar: "",
          username: "",
        },
        values: {
          total_value: 0,
          total_count: 0,
        },
        items: [],
      },
      statistics: [],
      timeInterval: [
        { name: "7 D", code: "7_d", value: 7 },
        { name: "14 D", code: "14_d", value: 14 },
        { name: "30 D", code: "30_d", value: 30 },
        { name: "3 M", code: "3_m", value: 90 },
      ],
      chartOption: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 0,
              minRotation: 0,
              padding: 10,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 5 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
              fontSize: 20,
            },
          },
        },
      },
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        { name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf, appID: 440 },
      ],
      selectedGameIcon: csgo,
      selectedGame: "CS2",
      valueGraph: [],
      selectedInterval: 7,
      selectedMarket: "Buff.163",
      isAllSelected: false,
      appID: 730,
      game: "cs2",
      searchQuery: "",
    };
  },
  methods: {
    async selectOption(option) {
      this.selectedExportOption = option.name;
      this.selectedExportIcon = option.icon;
      this.isActive = false;
      return await this.exportFile();
    },
    async exportFile() {
      const items = await this.getExportData();

      if (!items) return false;
      const parsedData = this.parseData(items);

      await FileConverterService.exportFile(
        this.selectedExportOption,
        parsedData,
        {
          name: "Inventory",
        }
      );

      this.isActive = !this.isActive;
    },
    async getExportData() {
      try {
        const response = await SteamService.getGameInventory({
          appid: this.filters.appID,
          page: 1,
          pageSize: 1000,
          search: "",
          type: "All",
          exterior: "All",
        });

        if (response?.items?.length == 0) return false;
        return response.items;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    parseData(data) {
      if (data?.length == 0) return false;

      const newItems = data.map((element) => {
        return {
          game: element.game,
          name: element.market_hash_name,
          price: element.price / 1000,
        };
      });

      const sum = newItems.reduce((acc, item) => acc + item.price, 0);
      const totalItem = {
        game: "CS2",
        name: "Total Inventory Worth",
        price: `${(sum ?? 0).toFixed(2) || 0}`,
      };

      newItems.push(totalItem);

      return newItems;
    },
    toggleFilter() {
      if (!this.isMobile) return (this.isFilterOpen = !this.isFilterOpen);

      const htmlElement = document.querySelector("html");
      if (this.isMobile && this.mobileFilterOpen) {
        htmlElement.style.overflowY = "auto";
      }
      if (this.isMobile && !this.mobileFilterOpen) {
        htmlElement.style.overflowY = "hidden";
      }
      this.mobileFilterOpen = !this.mobileFilterOpen;
    },
    async setExterior(exterior) {
      const findExterior = this.exteriorOptions.find(
        (e) => e.name == exterior.name
      );

      if (!findExterior) return;
      findExterior.isChecked = !findExterior.isChecked;

      this.filters.exterior = this.exteriorOptions
        .filter((e) => e.isChecked)
        .map((el) => el.name);

      this.selectedExterior = findExterior;
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setType(type) {
      const findType = this.typeOptions.find((e) => e.name == type.name);

      if (!findType) return;
      findType.isChecked = !findType.isChecked;

      this.filters.type = this.typeOptions
        .filter((e) => e.isChecked)
        .map((el) => el.name);
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    goItemDetail(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    copy(text) {
      navigator.clipboard.writeText(text);
      this.copyMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.appID = type.appID;
      this.filters.appID = type.appID;
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setSize(size) {
      this.selectedSize = size;
      this.pageSize = parseInt(this.selectedSize);
      this.isDropdownOpen = false;
      this.getInventoryRecords();
    },
    async setDisplay(displ) {
      this.selectedDisplay = displ;
      this.inventoryType = displ.toLowerCase();
      this.isDropdownOpen = false;
    },
    async selectInterval(time) {
      if (!time) return;
      this.selectedInterval = time;
      await this.getInventoryHistory();
    },
    async getInventoryHistory() {
      const response = await SteamService.getValuationGraph({
        market: this.selectedMarket.toLowerCase(),
        interval: this.selectedInterval,
      });
      if (!response) return;

      this.valueGraph = response;
    },
    showErrModal() {
      this.showErrorModal = true;
      setTimeout(() => {
        this.showErrorModal = false;
      }, this.modalShowingTime);
    },
    loadingScreen() {
      this.loading = !this.loading;
    },
    async createInventory() {
      this.loading = true;
      const response = await this.createInventoryItems();

      if (!response) return (this.loading = false);

      await Promise.all([
        this.getInventoryRecords(),
        this.getStats(),
        this.getInventoryHistory(),
        this.getInventoryStatistics(),
      ]);
      this.loading = false;
    },
    async createInventoryItems() {
      try {
        const response = await SteamService.refreshInventory({
          appid: this.appID,
        });

        if (!response.success && response?.code === 429) {
          this.errorMessage = `You must wait for refreshing your inventory in about ${response.metaData.remaining_time} seconds.`;
          this.showErrModal();
          return false;
        }

        return response.success;
      } catch (error) {
        this.errorMessage = "Try again later!";
        this.showErrModal();
        return false;
      }
    },
    async getInventoryRecords() {
      this.loading = true;

      const response = await SteamService.getGameInventory({
        appid: this.filters.appID,
        page: this.page,
        pageSize: this.pageSize,
        search: this.searchQuery,
        type: this.filters.type,
        exterior: this.filters.exterior,
      });

      if (!response) {
        this.items = [];
        return (this.loading = false);
      }
      this.items = response.items;
      if (response.items[0] && response.items[0].updated_at)
        this.inventoryUpdateTime = "";

      this.inventoryUpdateTime = response.items[0].updated_at;
      if (!response.count) return (this.loading = false);
      this.loading = false;
    },
    async getInventoryStatistics() {
      this.statsLoading = true;

      const response = await SteamService.getInventoryOverview({
        appid: this.appID,
      });

      if (!response) {
        this.inventoryOverview.items = [];
        return (this.statsLoading = false);
      }
      this.inventoryOverview.items = response;
      this.statsLoading = false;
    },
    closeModal() {
      this.changeScrollStyle();
      this.isModalOpen = false;
    },
    openModal() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "hidden";
      this.isModalOpen = true;
    },
    changeScrollStyle() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "auto";
    },
    async getStats() {
      this.countLoading = true;
      const response = await SteamService.getStats({ appid: this.appID });

      if (!response) return (this.countLoading = false);

      this.count = response.total_count;
      this.available = response.tradable_count;
      this.mapValues(response.total_values);
      this.countLoading = false;
    },
    mapValues(response) {
      this.marketValues = response.map((e) => ({
        name: e.name,
        value: e.value,
        icon: this.getIcon(e.name),
      }));
    },
    getIcon(name) {
      const i = this.MarketOptions.find((e) => e.name === name);
      if (i != undefined) return i.icon;
    },
    sort() {
      const items = this.marketValues.sort((a, b) => {
        return b.value - a.value;
      });
      return items;
    },
    async handleSearch(search) {
      clearTimeout(this.searchTimeout);
      if (!search) return;
      this.searchQuery = search;
      this.searchTimeout = setTimeout(() => {
        this.getInventoryRecords();
      }, 1000);
    },
    setOverview() {
      this.inventoryOverview.user.avatar = this.getUserAvatar;
      this.inventoryOverview.user.username = this.getUsername;

      this.inventoryOverview.user.profile = this.getUserProfile;
      let total = null;

      if (this.appID == 730) {
        total = this.marketValues.find((e) => e.name == "Buff.163");
      }
      if (this.appID == 570) {
        total = this.marketValues.find((e) => e.name == "MarketCSGO");
      }
      this.inventoryOverview.values.total_count = this.count;
      const value = total && total.value ? total.value : 0;
      this.inventoryOverview.values.total_value = parseFloat(
        value
      ).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    async setupFilterOptions() {
      await this.ensureFilterOptions({ appID: this.appID, game: this.game });
      this.mapFilterTags(this.filterOptions.filters);
    },
    mapFilterTags(items) {
      if (!items || items.length == 0) return false;

      try {
        const listMappings = {
          Exterior: "exteriorOptions",
          Type: "typeOptions",
        };

        Object.values(listMappings).forEach((listName) => {
          this[listName] = null;
        });

        items.forEach((e) => {
          if (!e.items || e.items.length === 0) return;
          const listName = listMappings[e.name];
          this[listName] = null;

          if (listName) {
            this[listName] =
              e.items && e.items.length > 0
                ? e.items.map((item) => ({
                    name: item.name,
                    isChecked: false,
                    ...(e.name === "Rarity" || e.name === "Extra"
                      ? { color: item.color }
                      : {}),
                  }))
                : null;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    ...mapActions("filters", ["initializeFilters", "ensureFilterOptions"]),
  },
  computed: {
    ...mapGetters("ui", ["isMobile"]),
    ...mapGetters("filters", ["getFilterOptions"]),
    ...mapGetters("user", ["getUsername", "getUserAvatar", "getUserProfile"]),
    filterOptions() {
      return this.getFilterOptions(this.game);
    },
    getItems() {
      return this.items;
    },
    getValuations() {
      return this.sort();
    },
  },
  async created() {
    this.changeScrollStyle();
    await Promise.all([
      this.getStats(),
      this.getInventoryRecords(),
      this.getInventoryHistory(),
      this.getInventoryStatistics(),
    ]);
    await this.setupFilterOptions();
    this.setOverview();
  },
  watch: {
    page() {
      this.getInventoryRecords();
    },
  },
  components: {
    Grid,
    InventoryItemCard,
    LineGraph,
    SelectorDropdown,
    IconDropdown,
    ShimmerCard,
    InventoryCard,
    SearchBar,
    OverviewCard,
    CheckboxDropdown,
    SkeletonCard
  },
};
</script>

<style scoped>
@import "../assets/style/loadingScreen.css";
@import "../assets/style/inventoryGrid.css";

.item_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 15px;
}

.update_button {
  background-color: transparent;
  border: 1px solid rgb(134, 80, 172);
  height: 40px;
  border-radius: 4px;
  width: 100%;
  background-color: rgb(33, 37, 41);
}

.update_button:hover {
  background-color: rgb(147, 88, 189);
  transition: 0.2s;
}

.empty_inventory {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21242c;
  height: 80px;
  width: 100%;
  border-radius: 4px;
}

.bottom_modal {
  height: 85vh;
  background-color: #11101d;
  position: fixed;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  top: auto;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  animation: animatebottom 0.4s;
  z-index: 999;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.bottom_modal::-webkit-scrollbar {
  display: none;
}

.close_btn {
  font-size: 40px;
  cursor: pointer;
  color: red;
  transition: 0.4s;
}

.wrapper_value {
  gap: 30px;
  flex-wrap: wrap;
}

.inventory_list_wrap {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.search_tab {
  margin-bottom: 0;
  width: 60%;
  background-color: transparent;
}

.search_wrap {
  margin-bottom: 0;
  width: 100%;
  background-color: transparent;
}

.wrap_inventory {
  display: flex;
  gap: 2%;
  width: 100%;
}

.item_overview_wrap {
  width: 35%;
  padding: 1.5rem;
}

.inventory_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.page_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.danger-text {
  cursor: pointer;
}

.danger-text:hover {
  transition: 0.2s;
  color: #ffc965;
}

span {
  font-size: 1.7vh;
  font-weight: bold;
  color: white;
}

.small {
  font-size: 14px;
}

.value_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.valuation {
  gap: 5px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
}

.custom_button {
  background-color: #313156;
  width: auto;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
}

.filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  gap: 0.5rem;
}

h4,
h5 {
  margin-bottom: 0px;
}

h6 {
  font-size: 14px;
  margin-bottom: 0px;
  color: white;
}

@media screen and (min-width: 768px) {
  .custom {
    width: 180px;
    height: 45px;
    border: 1px solid rgb(134, 80, 172);
  }

  .custom:hover {
    background-color: rgb(134, 80, 172);
  }
}

.filter_wrap_mobile {
  display: none;
}

.filter_buttons {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

@media screen and (max-width: 1187px) {
  .custom {
    width: 100%;
    height: 45px;
  }

  .item_overview_wrap {
    display: none;
  }

  .filter_buttons {
    display: none;
  }

  .custom_button {
    background-color: #313156;
    max-width: 100%;
    height: 40px;
    width: 100% !important;
    padding: 0 15px;
    margin-block: 5px;
  }

  .mobile-text-div {
    gap: 5px;
  }

  .small {
    font-size: 11px;
  }

  .wrapper_value {
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .inventory_list_wrap {
    width: 100%;
    gap: 8px;
  }

  .filter_wrap_mobile {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
  }

  .search_tab {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}

@media screen and (max-width: 876px) {
  .item_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 676px) {
  .item_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 476px) {
  .item_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
