<template>
    <div class="auto_complete">
        <ul v-if="data.length > 0" class="auto_wrap">
            <li @click="select(item)" class="item" v-for="(item, index) in data" :key="index">
                <img class="small-icon" :src="item.image" />
                <span> {{ item.name }}</span>
            </li>
        </ul>
    </div>
</template>
  
<script>

export default {
    props: {
        data: {
            type: Array
        }
    },
    data() {
        return {
            loading: false,
            suggestions: []
        }
    },
    methods: {
        async select(item) {
            this.$emit('selectImage', item);
        },
    },
    components: {}
};
</script>
  
<style scoped>
.auto_complete {
    position: absolute;
    z-index: 5;
    height: 160px;
    background: gray;
    width: auto;
    overflow-y: auto;
    top: 80px;
}

.small-icon {
    width: 40px
}

.auto_wrap {
    list-style-type: none;
    margin: 0;
    padding: 0.2rem 0.2rem;
    background-color: #212529;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.auto_wrap span {
    list-style-type: none;
    margin: 0;
    font-size: 12px;
}

.item {
    padding: 0.3rem;
    border-radius: 3px;
    min-height: 30px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}


.item:hover {
    background-color: rgb(134, 80, 172);
}
</style>
  