import { apiInstance } from "../utils/ApiUtil.js";

const API_URL = "/lis";

const getProfits = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/profits",
            {
                items: req.items
            });
        if (!response.data.success) return []
        return response.data.items;
    } catch (error) {
        return [];
    }
};

const getBynogameProfits = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/bynogame",
            {
                params: {
                    limit: req.limit,
                    page: req.page,
                    price_from:req.price_from
                }
            });
        if (!response.data.success) return []
        return response.data.items;
    } catch (error) {
        return [];
    }
};

const LisService = {
    getProfits,
    getBynogameProfits
}

export default LisService;