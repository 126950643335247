const uiModule = {
  namespaced: true,
  state: () => ({
    isMobile: false,
    isMenuOpened: false,
    routeLoading: false,
    isMobileSize: false,
    isSearchListOpen:false
  }),
  mutations: {
    setMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    toggleSidebar(state) {
      state.isMenuOpened = !state.isMenuOpened;
    },
    setRouteLoading(state, loading) {
      state.routeLoading = loading;
    },
    setSearchOpen(state, open) {
      state.isSearchListOpen = open;
    },
  },
  actions: {
    setListOpen(context, open) {
      context.commit('setSearchOpen', open);
    },
    toggleMobile(context) {
      context.commit('setMobile', !context.state.isMobile);
    },
    toggleSidebar(context) {
      context.commit('toggleSidebar');
    },
    toggleRouteLoading(context, loading) {
      context.commit('setRouteLoading', loading);
    },
    checkMobile(context) {
      const handleResize = () => {
        const isMobile = window.innerWidth <= 576;
        context.commit('setMobile', isMobile);
      };
      
      handleResize();
      
      window.addEventListener('resize', handleResize);
    },
  },
  getters: {
    isMobile: (state) => state.isMobile,
    isMenuOpened: (state) => state.isMenuOpened,
    getRouteLoading: (state) => state.routeLoading,
    isMobileSize: (state) => state.isMobileSize,
    isSearchListOpen: (state) => state.isSearchListOpen,
  }
};

export default uiModule;
