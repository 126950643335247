<template>
  <div class="modal-backdrop" v-if="show">
    <div class="selection-modal-content">
      <div v-if="!loading">
        <h2 class="modal-title">{{ title }}</h2>
        <h6 class="info-title">
          Options are applied to all selected items! Please be careful.
        </h6>
        <div class="f sb">
          <div>
            <h6 class="mb-3">Markets</h6>
            <ul class="option-list">
              <li
                v-for="option in options"
                :key="option.id"
                class="option-item"
              >
                <input
                  type="checkbox"
                  class="one"
                  :value="option.name"
                  v-model="option.isChecked"
                  :id="'market-' + option.id"
                />
                <label class="two" :for="'market-' + option.id">
                  {{ option.name }}
                  <img :src="option.icon" alt="" class="icon" />
                </label>
              </li>
            </ul>
          </div>

          <div class="price-options">
            <h6 class="mb-3">Pricing Options</h6>
            <ul class="option-list">
              <li
                v-for="option in pricingOptions"
                :key="option.id"
                class="option-item"
              >
                <input
                  type="radio"
                  :value="option"
                  v-model="selectedPriceOption"
                  :id="'pricing-option-' + option.id"
                  @change="selectPriceOption(option)"
                />
                <label :for="'pricing-option-' + option.id">
                  {{ option.name }}
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn cancel-btn" @click="cancel">Cancel</button>
          <button class="btn confirm-btn" @click="confirm">Confirm</button>
        </div>
      </div>
      <div v-else class="loading_content">
        <h5 class="text-white">Your items are being updated.</h5>
        <h5 class="text-white mb-5">Please Wait...</h5>
        <div class="loader-bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Select",
    },
    options: {
      type: Array,
      default: () => [],
    },
    pricingOptions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPriceOption: null,
    };
  },
  methods: {
    selectPriceOption(option) {
      this.selectedPriceOption = option;
    },
    confirm() {
      const selectedOptions = {
        priceMarkets: this.options
          .filter((option) => option.isChecked)
          .map((option) => option.name),

        priceOptions: this.selectedPriceOption
          ? this.selectedPriceOption.code
          : null,
      };
      this.$emit("confirm", selectedOptions);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  created() {
    this.selectedPriceOption = this.pricingOptions[0];
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 0.5rem;
}

.loader-bar {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#8650ac 0 0);
  background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}

@keyframes l1 {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
  33% {
    background-size: 20% 10%, 20% 100%, 20% 100%;
  }
  50% {
    background-size: 20% 100%, 20% 10%, 20% 100%;
  }
  66% {
    background-size: 20% 100%, 20% 100%, 20% 10%;
  }
  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
}

.selection-modal-content {
  background: #191a19;
  padding: 2rem 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  max-width: 550px;
  width: 100%;
  height: 100%;
  animation: fadeIn 0.3s ease;
}

.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  color: rgb(255, 255, 255);
  text-align: start;
}

.icon {
  width: 25px;
}

.two {
  width: 130px;
  justify-content: space-between;
  display: flex;
}

label {
  color: white;
}

h6 {
  color: white;
}

.info-title {
  font-size: 14px;
  color: orange;
  margin-bottom: 1rem !important;
}

.option-list {
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.option-item {
  display: flex;
  align-items: center;
}

.option-item input[type="checkbox"] {
  margin-right: 10px;
  width: 20px;
}

input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #a641ff;
  outline: none;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.2s ease;
}

input[type="radio"]:checked {
  background-color: #a641ff;
}

input[type="radio"]:checked::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selected-option {
  margin-top: 20px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.selected-option {
  margin-top: 20px;
  font-weight: bold;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10%;
  width: 100%;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s;
}

.cancel-btn {
  background: #f44336;
  color: white;
  width: 45%;
}

.cancel-btn:hover {
  background: #d32f2f;
}

.confirm-btn {
  background: #4caf50;
  color: white;
  width: 45%;
}

.confirm-btn:hover {
  background: #388e3c;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 576px) {
  .modal-backdrop {
    background: rgba(0, 0, 0, 0.9);
  }

  .selection-modal-content {
    padding: 2rem 2rem;
    max-height: 420px;
    max-width: 550px;
    width: 90%;
  }

  .btn {
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
