<template>
  <li @click="this.$emit('go-link', item)" class="w-100 d-flex">
    <div class="d-flex w-100 align-items-center sb g8">
      <div class="text-center align-center f flex-start g10 w-100">
        <img
          v-if="item.image"
          class="icon-mid"
          :src="item.image"
          :style="{ '--drop-shadow-color': item.color }"
        />
        <div
          class="color_wall"
          :style="{ backgroundColor: item.color || 'white' }"
        ></div>
        <div class="f flex-column als fls text_over g5 w100">
          <h6 class="gray" :style="{ color: typeColor || 'white' }">
            {{ getType(item.type) }}
          </h6>
          <h6 :style="{ fontWeight: 'bold' }" class="bold">
            {{ item.short_name }}
          </h6>
          <h6 :style="{ fontWeight: '400' }" class="bold">
            {{ item.exterior }}
          </h6>
          <div class="f sb w100 align-bottom">
            <div class="f align-bottom g5">
              <button class="btn_copyname mt-1">
                <VueIcon height="20px" color="white" icon="mdi:steam" />
              </button>
              <h6
                :class="{
                  red: item.change_ratio < 0,
                  green: item.change_ratio > 0,
                }"
              >
                {{
                  item.price
                    ? (item.price / 1000).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : "No Data"
                }}
              </h6>
            </div>

            <h6
              v-if="type !== 'popular'"
              :class="{
                red: item.change_ratio < 0,
                green: item.change_ratio > 0,
              }"
            >
              {{
                item.price
                  ? (item.price_change / 1000).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : "No Data"
              }}
              |
              {{
                item.price
                  ? (item.change_ratio / 1000).toLocaleString("en-US", {
                      style: "percent",
                      currency: "USD",
                    })
                  : "No Data"
              }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: Object,
    padding: String,
    type: String,
  },
  data() {
    return {
      typeColor: "white",
    };
  },
  methods: {
    getType() {
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
  },
  created() {},
};
</script>

<style scoped>
h6 {
  margin: 0 !important;
  font-size: 13px;
  text-overflow: ellipsis;
}

.red {
  color: #ff0f0f;
}

h5 {
  margin: 0;
  font-size: 14px;
  text-overflow: ellipsis;
}

.color_wall {
  height: 80px;
  width: 5px;
  border-radius: 2px;
}

.icon-mid {
  height: 50px;
  width: 60px;
}

.text_over {
  text-overflow: ellipsis;
  text-align: start;
}

.rank_div {
  font-size: 18px;
  font-weight: 900;
  color: #e0e005;
  position: absolute;
  left: 0;
}

li {
  padding: 0.3rem 0.4rem;
  position: relative;
}

li img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.1s ease-in-out;
}

li:hover {
  cursor: pointer;
}

li:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  filter: drop-shadow(0 0 11px var(--drop-shadow-color));
}

@media screen and (max-width: 576px) {
  h6 {
    font-size: 13px;
  }
}
</style>
