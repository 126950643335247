<template>
    <div class="tooltip-wrapper" @mouseenter="showTooltip" @mouseleave="hideTooltip">
      <slot></slot>
      <div v-if="visible" class="tooltip-content" :style="tooltipStyles">
        {{ text }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ToolTip",
    props: {
      text: {
        type: String,
        required: true
      },
      position: {
        type: String,
        default: "top"
      }
    },
    data() {
      return {
        visible: false
      };
    },
    computed: {
      tooltipStyles() {
        switch (this.position) {
          case "bottom":
            return { top: "100%", left: "50%", transform: "translateX(-50%)", marginTop: "10px" };
          case "left":
            return { right: "100%", top: "50%", transform: "translateY(-50%)", marginRight: "10px" };
          case "right":
            return { left: "100%", top: "50%", transform: "translateY(-50%)", marginLeft: "10px" };
          default:
            return { bottom: "100%", left: "50%", transform: "translateX(-50%)", marginBottom: "10px" };
        }
      }
    },
    methods: {
      showTooltip() {
        this.visible = true;
      },
      hideTooltip() {
        this.visible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-content {
    position: absolute;
    background-color: #1a1c2a;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1000;
    font-size: 14px;
    pointer-events: none;
  }
  </style>
  