<template>
  <div class="background-small">
    <div class="row">
      <h4 class="text-white">Global Inventory Rankings</h4>
    </div>

    <div class="info_div my-4">
      <div v-if="statLoading" class="info_div">
        <ShimmerInfoCard v-for="index in 4" :key="index"></ShimmerInfoCard>
      </div>

      <InfoCard
        v-else
        v-for="(item, index) in stats"
        :key="index"
        :item="item"
      ></InfoCard>
    </div>

    <div>
      <div class="f g20 pt-2">
        <h5 class="text-white">Last Checked Inventories</h5>
        <VueIcon
          icon="ph:clock-counter-clockwise"
          width="28px"
          color="#e0e005"
        />
      </div>

      <div class="latest_users_div mt-2 mb-4">
        <div v-if="latestLoading" class="f sb">
          <ShimmerInfoCard v-for="index in 4" :key="index"></ShimmerInfoCard>
        </div>
        <LatestUserCard
          v-else
          v-for="(item, index) in latestUsers"
          :key="index"
          :item="item"
          @go="goExternalLink"
          @goUser="goInventory"
        ></LatestUserCard>
      </div>
    </div>
    <div class="main_wrap">
      <div class="left_wrap">
        <div class="top_inventory_wrap">
          <h5 class="text-white mb-2">Top 5</h5>
          <div class="top_wrap">
            <div v-if="mostExpensiveLoading" class="top_wrap">
              <ShimmerInfoCard
                v-for="index in 5"
                :key="index"
              ></ShimmerInfoCard>
            </div>
            <RankRow
              v-else
              v-for="item in mostExpensiveUsers"
              :item="item"
              @goLink="goExternalLink"
              @goUser="goInventory"
              :key="item.steam_id"
            >
            </RankRow>
          </div>
        </div>
      </div>
      <div class="right_wrap">
        <h5 class="text-white mb-2">Inventories</h5>
        <div class="f g10 my-2">
          <SearchBar
            @handleSearch="handleSearch"
            :holder="'Search Steam ID, user name...'"
          ></SearchBar>
          <button class="update_button" @click="getRanks">Search</button>
        </div>
        <div v-if="loading" class="inventory_grid">
          <div v-for="index in filterObject.pageSize" :key="index">
            <ShimmerCard />
          </div>
        </div>
        <div v-else-if="!loading && items.length == 0" class="inventory_list">
          <div class="empty_inventory g5">
            <h5 class="text-white">Not Found.</h5>
            <VueIcon height="30px" color="white" icon="mingcute:sad-line" />
          </div>
        </div>
        <div v-else class="inventory_grid">
          <UserCard
            v-for="item in items"
            :key="item.steam_id"
            :item="item"
            @goLink="goExternalLink"
            @goUser="goInventory"
          />
        </div>
        <div class="page_row py-3 f align-center">
          <PaginationRow
            :pageNumber="filterObject.pageNumber"
            :totalPages="totalPages"
            @update:pageNumber="filterObject.pageNumber = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import ContainerCard from "@/components/cards/ContainerCard.vue";
import ShimmerCard from "@/components/skeleton/ShimmerCard.vue";
import RankRow from "@/components/ranking/RankRow.vue";
import UserCard from "@/components/ranking/UserInventoryCard.vue";
import InfoCard from "@/components/ranking/InfoCard.vue";
import LatestUserCard from "@/components/ranking/LatestUserCard.vue";
import ShimmerInfoCard from "@/components/skeleton/ShimmerInfoCard.vue";
import SearchBar from "@/components/shared/SearchBar.vue";

import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import InventoryService from "@/services/InventoryService";
import DateUtils from "@/utils/DateUtils.js";
import steamIcon from "../assets/images/steam-white.png";

export default {
  data() {
    return {
      totalPages: 6,
      loading: true,
      mostExpensiveLoading: true,
      statLoading: true,
      itemLoading: true,
      latestLoading: true,
      isDropdownOpen: false,
      steamIcon: steamIcon,
      pageNumbers: [1, 2, 3, 4, 5, 6],
      isNewInventory: false,
      filterObject: {
        pageSize: 28,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        appID: 730,
        search: "",
      },
      orderOptions: ["Date", "Price", "Name"],
      selectedOrder: "Date",
      selectedDirection: "DESC",
      items: [],
      caseCount: null,
      items: [],
      selectedItem: {},
      mostExpensiveUsers: [],
      stats: [
        {
          title: "Total Value",
          value: "$100,000,000",
          icon: "ph:currency-circle-dollar",
        },
        {
          title: "Average Value",
          value: "",
          icon: "mdi:graph-line",
        },
        { title: "Average Item Count", value: "40", icon: "ph:stack" },
        {
          title: "Unique Inventories",
          value: "",
          icon: "ph:user",
        },
      ],
      latestUsers: [],
    };
  },
  methods: {
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async getMostExpensive() {
      this.mostExpensiveLoading = true;
      const response = await InventoryService.getInventoryRanking({
        search: this.filterObject.search,
        appid: this.filterObject.appID,
        page: this.filterObject.pageNumber,
        pageSize: 5,
        orderBy: "price",
        dir: "desc",
      });
      this.mostExpensiveUsers = response;
      this.mostExpensiveLoading = false;
    },
    async getRanks() {
      this.loading = true;
      const response = await InventoryService.getInventoryRanking({
        search: this.filterObject.search,
        appid: this.filterObject.appID,
        page: this.filterObject.pageNumber,
        pageSize: this.filterObject.pageSize,
        orderBy: "price",
        dir: "desc",
      });
      this.items = response;
      this.loading = false;
    },
    async getLatest() {
      this.latestLoading = true;
      const response = await InventoryService.getLatestLookups({
        appid: this.filterObject.appID,
        page: this.filterObject.pageNumber,
        pageSize: 8,
        orderBy: "price",
        dir: "desc",
      });
      this.latestUsers = response;
      this.latestLoading = false;
    },
    async getStats() {
      this.statLoading = true;

      const response = await InventoryService.getStatistics({
        appid: this.filterObject.appID,
      });
      this.stats = response;
      this.statLoading = false;
    },
    handleSearch(search) {
      this.filterObject.search = search;
    },
    getData() {
      Promise.all([this.getMostExpensive(), this.getRanks(), this.getLatest()]);
    },
    goExternalLink(name) {
      window.open(name, "_blank");
    },
    goInventory(steamid) {
      const routeData = this.$router.resolve({
        name: "public-inventory",
        params: { steamid: steamid },
      });
      window.open(routeData.href, "_blank");
    },
  },
  async created() {
    this.getData();
    this.getStats();
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  watch: {
    "filterObject.pageNumber"() {
      this.getRanks();
    },
  },
  components: {
    UserCard,
    SearchBar,
    ContainerCard,
    ShimmerCard,
    SortDropdown,
    RankRow,
    InfoCard,
    LatestUserCard,
    ShimmerInfoCard,
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

h3 {
  margin-bottom: 0;
}

.main_wrap {
  display: flex;
  min-height: 70vh;
  gap: 2%;
}

.info_div {
  width: 100%;
  gap: 2.5%;
  height: 100px;
  display: flex;
}

.latest_users_div {
  width: 100%; /* Ensure the container takes the full width */ /* Set the gap between flex items */
  display: flex; /* Use flexbox layout */
  min-height: 100px; /* Set a minimum height */
  flex-direction: row; /* Ensure items are aligned in a row */
  flex-wrap: wrap; /* Prevent items from wrapping into columns */
  justify-content: flex-start; /* Align items to the start of the container */
  align-items: center;
}

.empty_inventory {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21242c;
  height: 80px;
  width: 100%;
  border-radius: 4px;
}

.left_wrap {
  width: 25%;
  margin-bottom: 50px;
}

.right_wrap {
  width: 75%;
}

.top_wrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.inventory_grid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(6, 135px);
  grid-gap: 15px;
  align-items: stretch;
  width: 100%;
}

.drop_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 5px;
  align-items: stretch;
}

.latest-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 100%;
  width: auto;
  min-width: 100px;
  padding: 5px;
  border: 1px transparent solid;
  text-align: center;
}

.update_button {
  border: 1px solid rgb(134, 80, 172);
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: rgb(33, 37, 41);
  color: white;
}

.update_button:hover {
  background-color: rgb(147, 88, 189);
  transition: 0.2s;
}

.latest-card:hover {
  box-shadow: 0px 0px 12px 10px;
  transition: 0.3s;
}

.latest-card > img {
  width: 80px;
  height: 70px;
}

.mid-span {
  font-size: 12px;
}

@media screen and (max-width: 1300px) {
  .inventory_grid {
    grid-template-columns: repeat(6, 125px);
  }
}

@media screen and (max-width: 1200px) {
  .main_wrap {
    flex-direction: row;
    gap: 25px;
  }

  .left_wrap {
    width: 25%;
  }
  .right_wrap {
    width: 75%;
  }

  .inventory_grid {
    grid-template-columns: repeat(5, 135px);
  }
}

@media screen and (max-width: 1000px) {
  .main_wrap {
    flex-direction: column;
    gap: 25px;
  }

  .left_wrap,
  .right_wrap {
    width: 100%;
  }

  .inventory_grid {
    grid-template-columns: repeat(5, 155px);
  }
}

@media screen and (max-width: 890px) {
  .inventory_grid {
    grid-template-columns: repeat(4, 180px);
  }
}

@media screen and (max-width: 728px) {
  .main_wrap {
    gap: 20px;
  }

  .update_button {
    font-size: 12px;
  }

  .latest_users_div {
    width: 100%; /* Ensure the container takes the full width */ /* Set the gap between flex items */
    gap: 10px;
    justify-content: space-between;
  }

  .info_div {
    width: 100%;
    gap: 10px;
    height: auto;
    flex-direction: column;
  }

  .left_wrap {
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }

  .inventory_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    align-items: stretch;
  }

  .sort-select-menu {
    width: 100%;
    transition: 0.5s;
  }

  .mobile-width {
    width: 100%;
  }

  .sort-select-menu {
    width: auto !important;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
