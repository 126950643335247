<template>
  <div class="row padding">
    <div class="f sb align-bottom">
      <div>
        <h5 class="text-white">Payment Methods</h5>
      </div>
    </div>
    <div class="mt-2 payment_pool">
      <div
        @click="setMethod(item)"
        v-for="(item, index) in paymentMethods"
        :key="index"
        class="payment_card"
        :class="{ active: item.code === selectedCode }"
      >
        <VueIcon :icon="item.image" width="45px" :color="item.color" />
        <p class="text-white">{{ item.name }}</p>
      </div>
    </div>
  </div>
  <div class="row pt-3 mt-2 flex-column" v-if="isSelectedPayment">
    <div class="col-xs-12 col-md-12 col-lg-12 g20 f align-bottom sb">
      <h6 class="text-white">Deposit {{ selectedName }}</h6>
    </div>
    <div class="f g20 input_wrapper mt-2">
      <InputField
        @handleInput="handleInput"
        :holder="'Enter amount'"
      ></InputField>
    </div>
    <div class="input_wrapper">
      <button :disabled="disableBtn" @click="checkout" class="checkout">
        <div v-if="checkoutLoading" class="loading-spinner loading_spin"></div>
        <span v-else>{{ buttonText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import InputField from "../components/shared/InputField.vue";
import PaymentService from "../services/PaymentService.js";

export default {
  data() {
    return {
      isMobile: false,
      title: "Payments",
      selectedCode: null,
      selectedName: null,
      disableBtn: true,
      checkoutLoading: false,
      isSelectedPayment: false,
      buttonText: "Checkout",
      cryptoObject: {
        amount: 0,
        currency: "usd",
      },

      paymentMethods: [
        {
          name: "Cryptocurrency",
          image: "logos:bitcoin",
          color: "white",
          code: "crypto",
        },
        {
          name: "Items",
          image: "ph:suitcase-light",
          color: "white",
          code: "item",
        },
        /* {
          name: "Stripe",
          image: "bi:stripe",
          color: "#635BFF",
          code: "stripe",
        },*/
      ],
    };
  },
  methods: {
    setMethod(item) {
      this.isSelectedPayment = true;
      if (this.selectedCode == item.code) {
        this.selectedCode = null;
        this.selectedName = null;
        this.isSelectedPayment = false;
      } else {
        this.selectedCode = item.code;
        this.selectedName = item.name;
      }

      if (item.code == "item") {
        this.isSelectedPayment = false;
        this.$router.push({ name: "itempay" });
      }
    },
    async checkout() {
      if (this.selectedCode == "stripe") {
        return true;
      } else if (this.selectedCode == "crypto") {
        this.checkoutLoading = true;

        const res = await PaymentService.depositCryptoBalance({
          amount: this.cryptoObject.amount,
          currency: this.cryptoObject.currency,
        });

        if (!res) {
          return (this.checkoutLoading = false);
        }
        this.checkoutLoading = false;
        this.buttonText = "Redirecting to payment provider...";
        await new Promise((resolve) => setTimeout(resolve, 3000));
        window.open(res);
        this.buttonText = "Checkout";
      }
    },
    handleInput(number) {
      if (!number) return (this.disableBtn = true);
      this.cryptoObject.amount = number;
      this.disableBtn = false;
    },
  },
  components: {
    InputField,
  },
};
</script>

<style scoped>
h5 {
  margin: 0;
}

p {
  margin: 0;
  font-size: 13px;
}

.padding {
  padding-top: 2rem;
}

.checkout {
  border: none;
  background-color: #212529;
  color: white;
  border: 1px solid #454545;
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_spin {
  width: 26px;
  height: 26px;
  aspect-ratio: 1;
}

.checkout:hover {
  background-color: #454545;
}

.payment_pool {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.input_wrapper {
  width: 300px;
}

.payment_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;
  border: 1px solid #954ce9;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
}

.payment_card:hover {
  transition: 0.3s;
  cursor: pointer;
}

.active {
  background-color: #844dc2;
}

@media screen and (max-width: 728px) {
  .padding {
    padding-top: 1.5rem;
  }

  .payment_pool {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .checkout {
    max-width: 100%;
    width: 100%;
  }

  .payment_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 150px;
    border: 1px solid #954ce9;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  .input_wrapper {
    width: 100%;
  }
}
</style>
