<template>
  <div @click="this.$emit('go-user', item.steam_id)" class="shimmer-container">
    <div class="f g20 sb blur_steam">
        <VueIcon icon="iconamoon:arrow-right-2-light" width="28px" color="white" />
    </div>
    <img v-if="item.image" class="active_avatar" :src="item.image" />
    <div>
      <div class="shimmer-name">
        <span class="name gray"> {{ item.name }}</span>
      </div>
      <div class="shimmer-name">
        <span class="value"> {{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  components: {},
};
</script>

<style scoped>
.shimmer-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #21242c;
  border-radius: 0.2rem;
  margin-block: 0rem;
  padding: 0.2rem 0.3rem;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  gap: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  width: 150px;
  min-height: 75px;
  margin: 0.4rem;
}

.blur_steam {
    display: none;
    transition: 0.2s;
}

.shimmer-container:hover {
  transition: 0.1s;
  background-color: #2a2a49;
  transition: 0.3s;
  border: 2px solid #5a3d92;
  backdrop-filter: blur(5px); 
}

.shimmer-container:hover .blur_steam {
    display: flex;
    position: absolute;
    right: 1px;
    filter: none;
    transition: 0.2s;
}

.rank {
  font-size: 18px;
  font-weight: 900;
  color: #e0e005;
  position: absolute;
  left: 5px;
  top: 5px;
}

.active_avatar {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: white;
}

.shimmer-img {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shimmer-name {
  width: 100%;
  text-overflow: ellipsis;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shimmer-price {
  height: 20px;
  width: 80px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  font-size: 12px;
  text-align: center;
}

.value {
  font-size: 12px;
  font-weight: bold;
  color: rgb(76, 223, 153);
}

@media only screen and (max-width: 576px) {
  .shimmer-container {
    width: 47%;
    min-height: 70px;
    margin: 0rem;
  }
}

@keyframes shimmer {
  to {
    background-position: 200% 0;
  }
}
</style>
