import { apiInstance } from "@/utils/ApiUtil";
const API_URL = "/portfolio";

const getLast = async () => {
    const response = await apiInstance.get(API_URL + "/last");
    if (!response.data.success) return false;
    return response.data.data;
};

const portfolioGraph = async (req) => {

    try {

        const response = await apiInstance.get(API_URL + "/graph", {
            params: {
                interval: req.interval,
            }
        });
        if (!response.data.success) return false;
        return response.data.data;

    } catch (error) {
        console.error(error);
        return false;
    }

};

const addPortfolio = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + "/", parseObject(req));
        if (!response) return false;

        return response.data.success;
    } catch (error) {
        return false;
    }

};

export const parseObject = (req) => {
    return {
        investment: req.investments,
        stocks: req.stocks,
        cash: req.cash,
        crypto: req.crypto,
        bank: req.bank,
        buff: req.buff163,
        waxpeer: req.waxpeer,
        marketcsgo: req.marketcsgo,
        shadowpay: req.shadowpay,
        skinland: req.skinland,
        lisskins: req.lisskins,
        dmarket: req.dmarket,
        csfloat: req.csfloat,
        white_market: req.white_market,
        gamerpay: req.gamerpay,
        csgo_inventory: req.csgo_inventory,
        dota_inventory: req.dota2_inventory,
        update_time: req.update_time
    }
};

const PortolioService = {
    getLast,
    portfolioGraph,
    addPortfolio
}

export default PortolioService;


