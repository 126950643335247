<template>
  <div class="shimmer-container-small f sb" :style="{backgroundColor: bgColor}">
    <div class="f g10 flex-start" style="width: 100%">
      <div class="shimmer-img"></div>
      <div style="width: 80%">
        <div class="shimmer-name"></div>
        <div class="shimmer-price"></div>
        <div class="f sb">
          <div class="shimmer-name"></div>
          <div class="shimmer-mid-up"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '#21242c'
    }
  }
};

</script>

<style scoped>
.shimmer-container-small {
  border-radius: 0.3rem;
  padding: 0.2rem 0.1rem;
  position: relative;
  transition: 0.3s;
  max-height: 9rem;
  min-height: 3rem;
  overflow: hidden;
}

.shimmer-img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 0px;
}

.shimmer-name {
  height: 16px;
  width: 90px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 5px;
}

.shimmer-price {
  height: 16px;
  width: 130px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 5px;
}

.shimmer-mid {
  height: 20px;
  width: 140px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 5px;
}

.shimmer-mid-up {
  height: 15px;
  width: 130px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 5px;
}

@media only screen and (max-width: 745px) {
  .second {
    display: none;
  }

  .fifth {
    display: none;
  }

  .fourth {
    display: none;
  }

  .third {
    display: none;
  }

  .shimmer-img {
    height: 60px;
    width: 60px;
  }

  .shimmer-container-small {
    padding: 0.3rem 0.2rem;
    position: relative;
    transition: 0.3s;
    max-height: 9rem;
    min-height: 3rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 746px) and (max-width: 1240px) {
  .fifth {
    display: none;
  }

  .fourth {
    display: none;
  }
}

@keyframes shimmer {
  to {
    background-position: 200% 0;
  }
}
</style>
