<template>
  <div class="background-small">
    <div class="row py-3 f sb">
      <div class="col-md-6 col-lg-5 col-xl-7 col-xxl-7 g20">
        <h4 class="text-white">{{ listItems }}</h4>
      </div>
    </div>
    <div class="w100 f g5 summary">
      <div class="f g20" style="align-items: flex-end">
        <h6>Your Offer</h6>
        <h6>{{ sellStack.length }}</h6>
      </div>
      <div class="f g20 align-center">
        <button @click="clear" class="clear_btn">Clear</button>
        <div class="f g10" style="align-items: flex-end">
          <h6>
            {{
              totalPrice
                ? totalPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : "$ 0"
            }}
          </h6>
          <VueIcon
            icon="material-symbols:shopping-bag-outline"
            width="24px"
            color="white"
          />
        </div>
      </div>
    </div>
    <div class="w100 f g5 selected_items">
      <div
        v-if="!sellStack || sellStack.length == 0"
        class="w100 f align-center justify-center"
      >
        <h5 class="text-white">Please, select items from your inventory.</h5>
      </div>

      <div v-if="sellStack.length > 0" class="selected_items_wrap">
        <DepositCard
          ref="itemCard"
          v-for="item in sellStack"
          :key="item.asset_id"
          :item="item"
          @calculateSum="sumPrice"
          @removeItem="remove"
        />
      </div>
    </div>

    <div class="row f sb my-3">
      <div class="f sb">
        <div>
          <p
            style="cursor: pointer"
            @click="this.$router.push({ name: 'user' })"
            class="danger-text"
          ></p>
        </div>
        <div class="f g10">
          <button class="custom_button" @click="createInventory">
            <VueIcon
              height="30px"
              color="white"
              icon="flowbite:refresh-outline"
            />
          </button>
          <SelectorDropdown
            v-if="!isMobile"
            :refFunction="setSize"
            :options="sizeOptions"
            :selectedItem="selectedSize"
          />
          <IconDropdown
            :refFunction="setGame"
            :options="gameOptions"
            :selectedItem="selectedGame"
            :selectedIcon="selectedGameIcon"
          />
        </div>
      </div>
    </div>
    <div class="main-wrapper">
      <div v-if="loading" class="sail__grid skin__scroll">
        <ShimmerCard
          style="min-height: 190px"
          ref="itemCard"
          v-for="index in 12"
          :key="index"
        />
      </div>

      <div v-else class="sail__grid skin__scroll">
        <ItemCard
          ref="item_card"
          v-for="item in getItems"
          :key="item.asset_id"
          :item="item"
          @calculateSum="sumPrice"
        />
      </div>

      <div class="page_row pt-2 pb-3 f align-center">
        <PaginationRow
          :pageNumber="page"
          :totalPages="totalPages"
          @update:pageNumber="page = $event"
        />
      </div>

      <div class="row f py-3 g20 fe mx-3">
        <button
          :disabled="getItems === undefined || getItems.length === 0"
          @click="initiateDepositProcess"
          class="button btn-secondary custom"
        >
          {{ sellBtn }}
        </button>
        <button
          :disabled="getItems === undefined || getItems.length === 0"
          @click="addAllToStack"
          class="button btn-secondary custom"
        >
          {{ selectAll }}
        </button>
      </div>
    </div>
    <ErrorModal
      v-if="showErrorModal"
      :message="errorMessage"
      @close="showErrorModal = false"
      :duration="'4s'"
    />
    <OfferModal
      v-if="isModalOpen"
      :loading="tradeLoading"
      :trade-object="tradeObject"
      :duration="tradeObject.duration"
      :remaining-time="tradeObject.remainingTime"
      :modalOpen="isModalOpen"
      @closeModal="closeModal"
    ></OfferModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import ItemCard from "@/components/depositItem/ItemCard.vue";
import DepositCard from "@/components/depositItem/DepositCard.vue";
import OfferModal from "@/components/depositItem/TradeOfferModal.vue";

import SteamService from "@/services/SteamService.js";
import PaymentService from "@/services/PaymentService.js";
import ErrorModal from "@/components/modal/ErrorModal.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import ShimmerCard from "@/components/skeleton/ShimmerCard.vue";

import csgo from "../assets/images/csgo.png";
//import dota from "../assets/images/dota2.png";
import tf from "../assets/images/tf2.png";
export default {
  data() {
    return {
      totalPages: 6,
      title: "My Inventory",
      listItems: "Deposit Items",
      warn: "You need to set your API keys for using item listing tool.",
      sellBtn: "Deposit",
      selectAll: "Select All",
      errorMessage: "Try Again Later!",
      showErrorModal: false,
      loading: true,
      isModalOpen: false,
      tradeLoading: false,
      showInfoModal: false,
      sizeOptions: ["30", "40", "50"],
      selectedSize: "30",
      page: 1,
      pageSize: 30,
      items: [],
      modalShowingTime: 4000,
      isMobile: false,
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        //{ name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf, appID: 440 },
      ],
      selectedGameIcon: csgo,
      selectedGame: "CS2",
      selectedInterval: 7,
      isAllSelected: false,
      appID: 730,
      filters: {
        type: [],
        exterior: [],
        appID: 730,
      },
      totalPrice: 0,
      refs: null,
      tradeObject: {
        order_number: "",
        bot_name: "",
        bot_level: "",
        bot_registration: "",
        bot_avatar:
          "https://avatars.akamai.steamstatic.com/e6a55bcf8b80d691024435f92d572bda8a7020ae_full.jpg",
        message: "",
        amount: 0,
        duration: "600s",
        created_at: "",
        remainingTime: 10 * 60,
      },
      countdownInterval: null,
      counterTick: 1000,
    };
  },
  methods: {
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        if (this.tradeObject.remainingTime > 0) {
          this.tradeObject.remainingTime--;
        } else {
          this.closeModal();
          clearInterval(this.tradeObject.remainingTime);
        }
      }, this.counterTick);
    },
    updateTradeObject(newValues) {
      this.tradeObject.order_number = `#${newValues.order_number}`;
      this.tradeObject.message = newValues.message;
      this.tradeObject.bot_avatar = newValues.bot_info.bot_avatar;
      this.tradeObject.bot_name = newValues.bot_info.name;
      this.tradeObject.bot_level = newValues.bot_info.bot_level;
      this.tradeObject.bot_registration = newValues.bot_info.bot_registration;
      this.tradeObject.amount = (newValues.amount / 1000).toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        }
      );
      const createdAt = new Date(newValues.created_at).getTime();
      const now = new Date().getTime();
      const elapsedTime = Math.floor((now - createdAt) / 1000);
      this.tradeObject.remainingTime = Math.max(10 * 60 - elapsedTime, 0);
    },
    sumPrice() {
      const localItems = JSON.parse(localStorage.getItem("depositItems"));

      if (!localItems || localItems.length == 0) {
        this.addItemToStack();
        return (this.totalPrice = 0);
      }

      this.totalPrice = localItems.reduce(
        (total, input) => total + input.price / 1000,
        0
      );

      this.addItemToStack();
    },
    remove(assetID) {
      if (!assetID) return;

      const selectedItems = JSON.parse(
        localStorage.getItem("depositItems") || "[]"
      );
      const itemIndex = selectedItems.findIndex(
        (item) => item.asset_id === assetID
      );

      if (itemIndex !== -1) {
        selectedItems.splice(itemIndex, 1);
      }

      localStorage.setItem("depositItems", JSON.stringify(selectedItems));
      this.checkAllActive();
    },
    clear() {
      localStorage.setItem("depositItems", JSON.stringify([]));
      this.sellStack = [];
      this.checkAllActive();
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.filters.appID = type.appID;
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setSize(size) {
      this.selectedSize = size;
      this.pageSize = parseInt(this.selectedSize);
      this.isDropdownOpen = false;
      this.getInventoryRecords();
    },
    async selectInterval(time) {
      if (!time) return;
      this.selectedInterval = time;
      await this.getInventoryHistory();
    },
    checkMobile() {
      const screenWidth = window.innerWidth;
      const mobileWidthThreshold = 1033;
      this.isMobile = screenWidth < mobileWidthThreshold;
    },
    showErrModal() {
      this.showErrorModal = true;
      setTimeout(() => {
        this.showErrorModal = false;
      }, this.modalShowingTime);
    },
    loadingScreen() {
      this.loading = !this.loading;
    },
    async createInventory() {
      this.loading = true;
      await this.createInventoryItems();
      await this.getInventoryRecords();
      this.loading = false;
    },
    async synchMarketItems() {
      this.loading = true;

      await this.getStats();
      await this.getInventoryRecords();

      return (this.loading = false);
    },
    async createInventoryItems() {
      try {
        const response = await SteamService.refreshInventory({
          appid: this.filters.appID,
        });

        if (!response.success) {
          if (response.code === 429) {
            this.errorMessage = `You must wait for refreshing your inventory for about ${response.metaData.remaining_time} seconds.`;
            return this.showErrModal();
          }
        }
      } catch (error) {
        this.errorMessage = "Try again later!";
        return this.showErrModal();
      }
    },
    async getInventoryRecords() {
      this.loading = true;

      const response = await SteamService.getGameInventory({
        appid: this.filters.appID,
        page: this.page,
        pageSize: this.pageSize,
        search: "",
        type: this.filters.type,
        exterior: this.filters.exterior,
      });

      if (!response) {
        this.items = [];
        return (this.loading = false);
      }
      this.items = response.items;

      this.loading = false;
    },
    async addItemToStack() {
      this.loading = true;
      const locals = localStorage.getItem("depositItems");
      this.sellStack = JSON.parse(locals);
      this.loading = false;
    },
    addAllToStack() {
      this.isAllSelected = !this.isAllSelected;

      if (this.isAllSelected) {
        localStorage.setItem("depositItems", JSON.stringify(this.getItems));
      } else {
        localStorage.setItem("depositItems", JSON.stringify([]));
      }

      this.$refs.itemCard.forEach((item) => item.checkActive());
    },
    async initiateDepositProcess() {
      const locals = localStorage.getItem("depositItems");
      const items = JSON.parse(locals);

      if (!items || items.length == 0) return;

      this.isModalOpen = true;
      this.tradeLoading = true;

      const assetids = items.map((e) => {
        return {
          assetid: e.asset_id,
          appid: e.app_id,
          item_id: e.ID,
          price: e.price || 0,
        };
      });

      const appid = this.filters.appID;
      const tradelink = this.getTradelink;

      const res = await PaymentService.depositItemBalance({
        appid: appid,
        tradelink: tradelink,
        assetids: assetids,
        amount: this.totalPrice || 0,
      });

      if (!res.success) {
        this.errorMessage = `${res.message}`;
        this.isModalOpen = false;
        this.tradeLoading = false;
        this.countdownInterval = null;
        this.showErrModal();
        return;
      }

      this.tradeLoading = false;
      this.updateTradeObject(res.data);
      this.startCountdown();
    },
    closeModal() {
      this.changeScrollStyle();
      this.isModalOpen = false;
    },
    openModal() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "hidden";
      this.isModalOpen = true;
    },
    changeScrollStyle() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "auto";
    },
    async getStats() {
      this.countLoading = true;
      const response = await SteamService.getStats({ appid: this.appID });

      if (!response) return (this.countLoading = false);

      this.count = response.total_count;
      this.available = response.tradable_count;
      this.countLoading = false;
    },
    checkAllActive() {
      if (!this.$refs.item_card || this.$refs.item_card.length == 0) return;
      this.$refs.item_card.forEach((item) => {
        item.checkActive();
      });
    },
    clearInterval() {
      clearInterval(this.countdownInterval);
    },
  },
  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  computed: {
    getItems() {
      return this.items;
    },
    ...mapGetters(["getTradelink"]),
  },
  watch: {
    page() {
      this.getInventoryRecords();
    },
  },
  async created() {
    try {
      this.changeScrollStyle();
      this.clear();
      await Promise.all([this.getStats(), this.getInventoryRecords()]);
    } catch (error) {
      console.log(error);
    }
  },

  components: {
    ItemCard,
    ErrorModal,
    SelectorDropdown,
    IconDropdown,
    ShimmerCard,
    DepositCard,
    OfferModal,
  },
};
</script>

<style scoped>
@import "../assets/style/loadingScreen.css";
@import "../assets/style/inventoryGrid.css";

.clear_btn {
  background-color: transparent;
  width: 100px;
  color: #cf6d6b;
  border: transparent;
  font-size: 16px;
  height: 35px;
  font-weight: bold;
  border-radius: 4px;
}

.clear_btn:hover {
  transition: 0.3s;
  background-color: rgb(252, 153, 152, 0.2);
  border-radius: 3px;
}

.selected_items {
  display: flex;
  gap: 5px;
  background-color: #313156;
  min-height: 25vh;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2.6rem;
  gap: 5px;
  background-color: #1f1f35;
  min-height: 40px;
  border-radius: 4px;
}

.summary:hover {
  transition: 0.4s;
  background-color: #313156;
}

.selected_items_wrap {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  background-color: #313156;
  min-height: 20vh;
  max-height: 30vh;
  border-radius: 4px;
  overflow-y: scroll;
}

.skin__scroll {
  height: 70vh;
  overflow: auto;
  background: #313156;
  padding: 10px;
  border-radius: 6px;
}

.wrapper_value {
  gap: 30px;
  flex-wrap: wrap;
}

.page_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.danger-text {
  cursor: pointer;
}

.danger-text:hover {
  transition: 0.2s;
  color: #ffc965;
}

span {
  font-size: 1.7vh;
  font-weight: bold;
  color: white;
}

.small {
  font-size: 14px;
}

.value_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.valuation {
  gap: 5px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
}

.custom_button {
  background-color: #313156;
  max-width: 100%;
  width: 60px !important;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  border: 1px solid rgb(134, 80, 172);
}

h4,
h6 {
  margin-bottom: 0px;
}

h6 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px !important;
}

@media screen and (min-width: 768px) {
  .custom {
    width: 180px;
    height: 45px;
    border: 1px solid rgb(134, 80, 172);
  }

  .custom:hover {
    background-color: rgb(134, 80, 172);
  }
}

@media screen and (max-width: 768px) {
  .custom {
    width: 100%;
    height: 45px;
  }

  .custom_button {
    background-color: #313156;
    max-width: 100%;
    height: 40px;
    width: 100% !important;
    padding: 0 15px;
    margin-block: 0px;
  }

  .mobile-text-div {
    gap: 5px;
  }

  .small {
    font-size: 11px;
  }

  .wrapper_value {
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .danger-text {
    cursor: pointer;
    font-size: 11px;
  }

  h5 {
    font-size: 14px;
  }

  .selected_items_wrap {
    padding: 0.4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    background-color: #313156;
    min-height: 20vh;
    max-height: 30vh;
    border-radius: 4px;
    overflow-y: scroll;
  }

  .summary {
    padding: 0.5rem 1rem;
  }

  .summary h6 {
    font-size: 12px;
  }

  .clear_btn {
    font-size: 13px;
  }

  .g20 {
    gap: 8px;
  }
}
</style>
@/services/PaymentService.js
