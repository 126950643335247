<template>
  <div
    @mouseenter="isActive = true"
    @mouseleave="isActive = false"
    :style="{
      borderColor: isActive ? item.color : 'transparent',
      color: isActive ? item.color : 'transparent',
    }"
    class="catalog-card catalog-card2 sell_card"
  >
    <a
      v-if="item.caseImage"
      @click="this.$emit('go-case', item.caseName, item.caseID)"
      class="f case align-center g10 mt-1"
    >
      <img class="img-case" alt="No Image" :src="item.caseImage" />
      <p class="">{{ item.caseName }}</p>
    </a>
    <div
      @click="this.$emit('go-item', item)"
      class="f flex-column align-center g10 space-between"
    >
      <a class="catalog-card__img">
        <img class="img" loading="lazy" :src="item.image" />
      </a>
      <div
        class="f align-center justify-center rarity"
        :style="{ backgroundColor: item.color }"
      >
        <span class="mid-span">{{ item.rarity }} {{ item.type }}</span>
      </div>
      <div class="catalog-card__title sell-card-title">
        <span class="mid-span">{{ item.shortName }}</span>
      </div>
      <div class="f steam-price center g5">
        <VueIcon height="20px" color="white" icon="mdi:steam" />
        <span class="mid-span light-green">{{
          item.steamPrice
            ? (item.steamPrice / 1000).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : "$0"
        }}</span>
      </div>
      <div class="price">
        <div class="f flex-column flex-end fg1 g5">
          <span class="f flex-end mid-span"
            >{{ item.offerCount }} + Listings</span
          >
          <span class="f flex-end mid-span"
            >On {{ marketCount }} + Markets</span
          >
        </div>
        <div class="fg1">
          <VueIcon
            icon="iconamoon:arrow-right-2-light"
            width="28px"
            color="white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steam from "../../assets/images/steam-gray.png";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      steam,
      marketCount: 15,
    };
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
  font-size: 14px;
}

.case {
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
}

.case:hover {
  color: gray;
  transition: 0.4s;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.fg2 {
  flex-grow: 2;
}

.fg1 {
  flex-grow: 1;
}

.sm-gap {
  margin-left: 6px;
}

.mid-span {
  font-size: 13px;
  padding: 0;
  color:white
}

.img-case {
  width: 50px;
  height: 30px;
  min-width: 0%;
}

.img-small {
  width: 30px;
  height: 30px;
  min-width: 0%;
}

.img {
  min-width: 100%;
  max-width: 100%;
  min-height: 120px;
  height: auto;
}

.sell_card {
  padding: 10px 0 0px;
  background: #15142a;
  border-radius: 10px;
  background: -o-linear-gradient(top, rgb(30 12 36 / 45%) 0, #15142a 100%);
  background: linear-gradient(180deg, rgb(30 12 36 / 45%) 0, #15142a 100%);
  cursor: pointer;
  border: 1px solid transparent;
}

.sell_card:hover {
  transition: 0.4s;
  background: #15142a;
  box-shadow: 0px 0px 12px 5px;
}

.catalog-card {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.catalog-card__hours {
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  color: #8787ab;
}

.rarity {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  min-height: 20px;
  padding: 4px 8px;
}

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
  min-height: 20px;
  background-color: rgb(48, 47, 47);
}

.sell_card:hover .price {
  background-color: #545454;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.catalog-card__img {
  display: block;
  position: relative;
  width: calc(100% - 60px);
  margin: 7px auto 0;
  padding-bottom: 5%;
}

.catalog-card2 .catalog-card__img {
  margin-top: 0;
}

.img-market {
  height: 25px;
  width: 25px;
  max-width: 25px;
  min-width: 25px;
}

.sell-card-title {
  line-height: 20px;
}

.catalog-card__title {
  padding: 0 5px;
}

.catalog-card__title {
  display: block;
  margin-top: 2px;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.catalog-card__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  line-height: 25px;
  color: #dd1b5f;
  margin-top: 10px;
}

@media screen and (min-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .sell_card {
    height: 100%;
  }

  .mid-span {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  .img-case {
    width: 35px;
  }
}
</style>
