import { apiInstance } from "@/utils/ApiUtil";
const API_URL = "/marketplace";

const getAll = async () => {
    const response = await apiInstance.get(API_URL + "/all");
    if (!response.data.success) return false;
    return response.data.data;
};

const MarketplaceService = {
    getAll,
}

export default MarketplaceService;


