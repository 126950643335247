import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/inventory";


export const getInventory = async (req) => {
    try {

        const response = await apiInstance.post(API_URL + `/fetch-inventory`, {
            appid: req.appid,
            page: req.page,
            pageSize: req.pageSize
        });

        if (!response.data.success) return false;
        if (response.data.count < 1) return false;
        return response.data;
    } catch (error) {

        return [];
    }
};

export const getGameInventory = async (req) => {
    try {

        const response = await apiInstance.post(API_URL + `/inventory`, {
            appid: req.appid,
            page: req.page,
            pageSize: req.pageSize,
            search: req.search,
            type: req.type,
            exterior: req.exterior
        });

        if (!response.data.success) return false;
        if (response.data.count < 1) return false;
        return response.data;
    } catch (error) {

        return [];
    }
};

export const getInventoryRanking = async (req) => {
    try {

        const response = await apiInstance.get(API_URL + `/ranking`, {
            params: {
                appid: req.appid,
                page: req.page,
                pageSize: req.pageSize,
                orderBy: req.orderBy,
                dir: req.dir,
                search: req.search
            }
        });
        if (!response.data.success) return [];
        if (response.data.count == 0) return [];
        return response.data.items;
    } catch (error) {
        console.log(error)
        return [];
    }
};

export const getUserInventory = async (req) => {
    try {

        const response = await apiInstance.get(API_URL + `/user-inventory`, {
            params: {
                steamid: req.steamID,
                appid: req.appid,
                page: req.page,
                pageSize: req.pageSize,
                search: req.search,
                type: req.type,
                exterior: req.exterior
            }
        });
        if (!response.data.success) return [];
        if (response.data.count == 0) return [];
        return response.data.items;
    } catch (error) {
        console.log(error)
        return [];
    }
};

export const getLatestLookups = async (req) => {
    try {

        const response = await apiInstance.get(API_URL + `/latest`, {
            params: {
                appid: req.appid,
                page: req.page,
                pageSize: req.pageSize,
                orderBy: req.orderBy,
                dir: req.dir,
            }
        });
        if (!response.data.success) return [];
        if (response.data.count == 0) return [];
        return response.data.items;
    } catch (error) {
        console.log(error)
        return [];
    }
};

export const getStatistics = async (req) => {
    try {

        const response = await apiInstance.get(API_URL + `/stats`, {
            params: {
                appid: req.appid,
            }
        });
        if (!response.data.success) return false;
        if (!response.data) return false;
        return response.data.data;
    } catch (error) {
        console.log(error)
        return false;
    }
};

export const createInventory = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + `/save-inventory`, {
            params: {
                appid: req.appid,
                steamid: req.steamid
            }
            
        });

        if (!response.data.success) return false;
        return response.data;
    } catch (error) {
        if (error.response.status === 429) {
            return { success: false, code: error.response.status, metaData: error.response.data }
        }
        return false;
    }
};

export const getInventoryOverview = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + `/inventory-statistic`, {
            params: {
                appid: req.appid,
                steam_id: req.steamID
                
            }
        });

        if (!response.data.success) return false;
        return response.data.items;
    } catch (error) {

        return false;
    }
};

export const getUser = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + `/user`, {
            params: {
                steam_id: req.steamID
            }
        });

        if (!response.data.success) return false;
        return response.data.user;
    } catch (error) {

        return false;
    }
};

export const getValuationGraph = async (req) => {

    try {
        const response = await apiInstance.get(API_URL + "/graph", {
            params: {
                interval: req.interval,
                steam_id: req.steamID
            }
        });

        if (!response.data.success) return [];
        return response.data.data;
    } catch (error) {
        return [];
    }

};

const InventoryService = {
    createInventory,
    getInventoryRanking,
    getStatistics,
    getLatestLookups,
    getUserInventory,
    getInventoryOverview,
    getUser,
    getValuationGraph
}

export default InventoryService;

