const state = {
    title: 'Steam Analyzer'
};

const mutations = {
    setTitle(state, title) {
        state.title = title || 'Steam Analyzer';
    }
};

const actions = {
    updateTitle({ commit }, title) {
        commit('setTitle', title);
        document.title = title || 'Steam Analyzer';
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
