<template>
    <div class="item_bar">
        <div>
            <h5 style="font-weight: bold;">{{ header }}</h5>
        </div>
        <div class="price_info_wrap">
            <div v-for="(item, index) in items" :key="index">
                <div class="price_info" v-if="item.name !== 'Description'">
                    <span class="name_span">{{ item.name }} </span>
                    <span class="value_span" :style="{ color: item.color ?? 'white' }">
                        {{ item.value || 'No Data' }}
                    </span>
                </div>
            </div>
            <div class="price_info flex-column" style="text">
                <span class="name_span">Description</span>
                <span class="value_span" :style="{ color: 'white', direction: 'ltr' }">
                    {{ description }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            green: '#4CDF99',
            red: '#ff0f0f',
            description: ''
        }
    },
    methods: {
        select() {
            const desc = this.items.find(e => e.name === 'Description');
            if(desc) this.description = desc.value;
        }
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        header: {
            type: String
        },
    },
    created() {
        this.select();
    }
};
</script>

<style scoped>
.bold {
    font-weight: 900
}

h5,
h6 {
    color: white;
    margin-bottom: 0;
}

.item_bar {
    width: 100%;
    height: 100%;
    background-color: #313155;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 3px;
}


.price_info_wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 5px;
}

.price_info {
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    padding: 0.1rem 0.3rem;
}

.price_info:hover .name_span,
.value_span {
    transition: 0.4s;
    color: white;
    text-wrap: wrap;
}

.price_info .name_span {
    font-size: 14px;
    font-weight: bold;
    color: #9f9d9d;
}

.price_info .value_span {
    font-size: 13px;
    font-weight: 600;
    color: white;
    direction: rtl;
}

@media screen and (max-width: 769px) {

    .item_bar {
        width: 100%;
        height: 100%;
        background-color: #313155;
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 3px;
    }

    .price_info {
        display: flex;
        justify-content: space-between;
        border-radius: 3px;
        padding: 0.1rem 0rem;
    }

    .price_info .value_span {
        font-size: 13px;
        font-weight: 600;
        color: white;
        direction: rtl;
    }

}
</style>
