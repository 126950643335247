<template>
  <div :class="{ overlay: modalOpen }">
    <div class="modal-a" :class="{ active: modalOpen }">
      <div v-if="!loading" class="modal-content-a">
        <span class="close" @click="closeModalTop('close')">&times;</span>
        <div class="f align-center justify-center mobile_text">
          <h5 class="bold">Trade Offer is Ready!</h5>
        </div>
        <div class="py-1">
          <h6>Order {{ tradeObject.order_number }}</h6>
          <p class="orange">Always verify the trade!</p>
        </div>
        <div class="first_bar">
          <div class="bot_bar">
            <div>
              <h6 class="bold mb-2" style="font-size: 13px">
                Check the Steam Mobile App
              </h6>
              <p class="gray">
                Open the Steam Mobile App and find trade offer in confirmations.
              </p>
            </div>
          </div>
          <div class="bot_details">
            <div class="left">
              <h6 class="bold" style="font-size: 12px">Bot Details</h6>
              <div class="f g10 mt-2">
                <p class="bold">{{ tradeObject.bot_name }}</p>
                <div class="circle_bot">
                  <p style="font-size: 10px">{{ tradeObject.bot_level }}</p>
                </div>
              </div>
              <span class="f g5">
                <p class="gray">Steam Join Date:</p>
                <p class="orange">{{ tradeObject.bot_registration }}</p>
              </span>
            </div>
            <div class="right">
              <img class="bot_avatar" :src="tradeObject.bot_avatar" />
            </div>
          </div>
        </div>

        <div class="f sb bar message_bar">
          <div>
            <h6 class="bold mb-2" style="font-size: 13px">
              Trade Message : {{ tradeObject.message }}
            </h6>
            <p class="gray">
              Please verify the trade message before confirmation.
            </p>
          </div>
          <div
            class="f align-center flex-column justify-center amount"
            style="padding-right: 70px"
          >
            <p style="font-size: 13px" class="green bold">
              + {{ tradeObject.amount }}
            </p>
            <p class="silver">Amount</p>
          </div>
        </div>

        <div class="text-align bar load_bar">
          <h6 class="bold mb-2" style="font-size: 34px">
            {{ formattedTime }}
          </h6>
          <p class="gray">
            Please accept the trade within 10 minutes. Otherwise trade offer
            will be canceled.
          </p>
          <p class="gray">
            Once the offer has been confirmed, your balance will be updated. You can check the status of the trade offer <a href="/payment/transaction" target="_blank"  class="orange" style="font-size:14px">here</a> .
          </p>
        </div>
        <div class="loading-bar">
          <div
            class="fill"
            :style="{
              backgroundColor: purpleColor,
              animationDuration: duration,
            }"
          ></div>
        </div>
      </div>
      <div v-else class="trade_loading f flex-column text-align justify-center align-center g20">
        <span class="close" @click="closeModalTop('close')">&times;</span>
        <div class="dot_loader"></div>
        <h5>Please wait, trade offer is being created.</h5>
      </div>
    </div>
  </div>
</template>

<script>
import thrashIcon from "../../assets/images/trash.png";

export default {
  props: {
    tradeObject: {
      type: Object,
      required: true,
    },
    duration: {
      type: String,
      required: false,
      default: "4s",
    },
    remainingTime: {
      type: Number,
      required: true,
    },
    modalOpen: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      defautl: true
    }
  },
  components: {},
  data() {
    return {
      purpleColor: "#764aa8",
      thrashIcon,
      green: "#30BC91",
      markets: [],
    };
  },
  methods: {
    goLink(name, appid) {
      let game = "cs2";
      if (appid == 570) game = "dota2";
      const routeData = this.$router.resolve({
        name: "item",
        params: { name: name, game: game },
      });
      window.open(routeData.href, "_blank");
    },
    async closeModalTop(response) {
      this.$emit("show-message", response);
      this.$emit("closeModal");
    },
  },
  created() {},
  computed: {
    itemArray() {
      return this.items;
    },
    formattedTime() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
  },
};
</script>

<style scoped>
.custom-switch label {
  background-color: #b32fe8;
}

.amount {
  padding-right: 70px;
}

.custom-checkboxes {
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  border: 1px solid gray;
}

.text-align {
  text-align: center;
}

.first_bar {
  display: flex;
  gap: 20px;
  height: 90px;
}

.bar {
  height: 90px;
  width: 100%;
  background-color: rgb(33, 36, 44);
  border-radius: 6px;
  padding: 1rem;
}

.bot_bar {
  height: 90px;
  width: 60%;
  background-color: rgb(33, 36, 44);
  border-radius: 6px;
  padding: 1rem;
}

.bot_details {
  height: 100%;
  width: 40%;
  background-color: rgb(33, 36, 44);
  border-radius: 6px;
  padding: 1rem;
  display: flex;
}

.bot_details .left {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  min-width: auto;
}

.bot_details .right {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.bot_avatar {
  border-radius: 4px;
  width: 60px;
  aspect-ratio: 1;
}

.circle_bot {
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid red;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.gray {
  color: gray;
}

.silver {
  color: rgb(174, 174, 174);
}

.bold {
  font-weight: bold;
}

p {
  font-size: 11px;
  margin: 0;
}

.orange {
  color: orange;
}

a {
  text-decoration: none;
}

h6,
h5 {
  margin-bottom: 0;
}

a:hover {
  cursor: pointer;
}

.img-small {
  width: 22px;
  height: 22px;
  min-width: 0%;
}

.mid-span {
  font-size: 14px;
  font-weight: 500;
}

.qty {
  display: flex;
  justify-content: center;
  height: 28px;
  width: 28px;
  background-color: #333333;
  color: white;
  border-radius: 50%;
}

.btn-secondary {
  height: 50px;
  width: 250px;
}

.square {
  height: 50px;
  width: 50px;
}

.sml {
  height: 20px;
  width: 20px;
}

.item-img {
  height: 75px;
  width: 100px;
}

.purple {
  color: #aa5cff;
}

.custom-checkbox input {
  width: 0px;
}

.di {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.modal-a {
  color: white;
  background-color: rgb(0, 0, 0);
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 75%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 6px;
  border: 1px solid rgb(81, 81, 81);

  /* Enable vertical scrolling */
}

.modal-a.active {
  display: flex;
}

.modal-content-a {
  background-color: #191a19;
  padding: 2rem;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.close {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  font-size: 32px;
  color: rgb(195, 192, 192);
}

.close:hover {
  color: #f44336;
}

.loading-bar {
  position: relative;
  height: 12px;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 6px;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #76ff03;
  animation: fillAnimation normal;
}

.load_bar {
  height:120px;
}

@keyframes fillAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .modal-content-a {
    padding: 1.5rem;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .modal-a {
    color: white;
    background-color: rgb(0, 0, 0);
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 80%;
    overflow-y: none;
    border-radius: 10px;
  }

  .first_bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
  }

  .bot_bar,
  .bot_details {
    width: 100%;
  }

  .bar {
    height: auto;
  }

  .message_bar {
    flex-direction: column;
  }

  .amount {
    padding-right: 0px !important;
  }

  .mobile_text {
    justify-content: flex-start;
  }

  .bot_details .right {
    width: 40%;
    align-items: flex-end;
  }

  .close {
    right: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 12px;
  }

  p {
    font-size: 10px;
  }
}
</style>
