<template>
  <div class="sidebar" :class="{ open: isMenuOpened }" :style="cssVars">
    <div class="logo-details" style="margin: 6px 14px 0 14px">
      <img v-if="menuLogo" :src="menuLogo" alt="menu" class="menu-logo icon" />
      <a
        href="https://www.steamanalyzer.com/landing"
        id="logo_name"
        class="logo_name"
      >
        {{ menuTitle }}
      </a>
      <i
        class="bx"
        :class="isMenuOpened ? 'bx-menu-alt-right' : 'bx-menu'"
        id="btn"
        @click="toggleMenuSidebar"
      />
    </div>
    <div class="sidebar-wrap">
      <div id="my-scroll">
        <ul class="nav-list">
          <li v-for="menuItem in menuItems" :key="menuItem.route">
            <a
              @mousedown.middle="handleMasterMenuRoute(menuItem, 'new_tab')"
              @click="handleMasterMenuRoute(menuItem)"
              :style="{
                background: activeRoute === menuItem.link ? '#475186' : '',
              }"
            >
              <div class="f g10">
                <img
                  draggable="false"
                  :src="menuItem.icon"
                  :alt="menuItem.name"
                />
                <span class="links_name">{{ menuItem.name }}</span>
              </div>
              <div v-if="menuItem.subItems">
                <VueIcon
                  :style="{
                    transform: showSubItems ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: '0.4s',
                  }"
                  icon="iconamoon:arrow-right-2-light"
                  width="28px"
                  color="white"
                />
              </div>
            </a>
            <div v-if="showSubItems">
              <div
                class="sub-menu"
                v-for="subItem in menuItem.subItems"
                :key="subItem.route"
                :class="{ 'sub-menu-active': showSubItems }"
              >
                <a
                  @mousedown.middle="handleSubRoute(subItem, 'new_tab')"
                  @click="handleSubRoute(subItem)"
                  :style="{
                    background: activeRoute === subItem.link ? '#475186' : '',
                  }"
                >
                  <div class="f g10">
                    <img
                      draggable="false"
                      :src="subItem.icon"
                      :alt="menuItem.name"
                    />
                    <span class="links_name">{{ subItem.name }}</span>
                  </div>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="profile">
        <button class="logout_btn" @click="logout()" id="logout_button">
          <i class="bx bx-log-out" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardIcon from "../../assets/images/dashboard.png";
import traderIcon from "../../assets/images/pie-chart.png";
import gunIcon from "../../assets/images/gun.png";
import transactions from "../../assets/images/transaction.png";
import userIcon from "../../assets/images/user.png";
import historyIcon from "../../assets/images/history.png";
import pieIcon from "../../assets/images/pie.png";
import investIcon from "../../assets/images/invest.png";
import bookmarkIcon from "../../assets/images/bookmark.png";
import rankIcon from "../../assets/images/ranking.png";

import csgoIcon from "../../assets/images/layers.png";
import inventoryIcon from "../../assets/images/menu.png";

import target from "../../assets/images/target.png";
import cartIcon from "../../assets/images/cart-white.png";
import LogoutService from "@/services/LogoutService";
import logoutIcon from "../../assets/images/logout-red.png";
import chartIcon from "../../assets/images/compare.png";
import marketplaceIcon from "../../assets/images/marketplace-white.png";
import barIcon from "../../assets/images/bar-chart.png";

import router from "../../router/index.js";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SidebarMenu",
  props: {
    //! Menu settings
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    menuTitle: {
      type: String,
      default: "Steam Analyzer",
    },
    menuLogo: {
      type: String,
      default: "",
    },
    menuIcon: {
      type: String,
      default: "bxl-c-plus-plus",
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: "250px",
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: "78px",
    },

    //! Menu items
    menuItems: {
      type: Array,
      default: () => [
        {
          link: "/user-details",
          route: "user",
          name: "User",
          tooltip: "User",
          icon: userIcon,
        },
        {
          link: "trader",
          route: "trader",
          name: "Trader PRO",
          tooltip: "Trader Tools",
          icon: traderIcon,
          subItems: [
            {
              link: "/dashboard",
              route: "dashboard",
              name: "Dashboard",
              tooltip: "Dashboard",
              icon: dashboardIcon,
            },
            {
              link: "/sales",
              route: "saleHistory",
              name: "Sales History",
              tooltip: "History",
              icon: historyIcon,
            },
            {
              link: "/list-items",
              route: "list-items",
              name: "List Items",
              tooltip: "Multiple Sale Tool",
              icon: csgoIcon,
            },
            {
              link: "/listed-items",
              route: "listed-items",
              name: "Listed Items",
              tooltip: "Listed Items",
              icon: cartIcon,
            },
            {
              link: "/target",
              route: "target",
              name: "Targets",
              tooltip: "Targets",
              icon: target,
            },
            {
              link: "/transactions",
              route: "transactions",
              name: "Transactions",
              tooltip: "Transaction",
              icon: transactions,
            },
            {
              link: "/analytics",
              route: "analytics",
              name: "Analytics",
              tooltip: "Analytics",
              icon: pieIcon,
            },
            {
              link: "/investments",
              route: "investments",
              name: "My Investments",
              tooltip: "Investments",
              icon: investIcon,
            },
          ],
        },
        {
          link: "/cs2/simulator",
          route: "simulator",
          name: "Case Simulator",
          tooltip: "simulator",
          icon: gunIcon,
        },
        {
          link: "/inventory",
          route: "inventory",
          name: "Inventory",
          tooltip: "Inventory Tool",
          icon: inventoryIcon,
        },
        {
          link: "/ranking",
          route: "ranking",
          name: "Inventory Rankings",
          tooltip: "Inventory Rankings",
          icon: rankIcon,
        },
        {
          link: "/comparison",
          route: "comparison",
          name: "Price Comparison",
          tooltip: "Profits",
          icon: chartIcon,
        },
        {
          link: "/trends",
          route: "trends",
          name: "Price Trends",
          tooltip: "Profits",
          icon: barIcon,
        },
        {
          link: "/watchlist",
          route: "watchlist",
          name: "Watchlist",
          tooltip: "Watchlist",
          icon: bookmarkIcon,
        },
        {
          link: "/marketplaces",
          route: "marketplaces",
          name: "Marketplaces",
          tooltip: "Marketplaces",
          icon: marketplaceIcon,
        },
      ],
    },

    //! Styles
    bgColor: {
      type: String,
      default: "#11101d",
    },
    secondaryColor: {
      type: String,
      default: "#1d1b31",
    },
    logoTitleColor: {
      type: String,
      default: "#fff",
    },
    iconsColor: {
      type: String,
      default: "#fff",
    },
    itemsTooltipColor: {
      type: String,
      default: "#e4e9f7",
    },
    searchInputTextColor: {
      type: String,
      default: "#fff",
    },
    menuItemsHoverColor: {
      type: String,
      default: "#475186",
    },
    menuItemsTextColor: {
      type: String,
      default: "#fff",
    },
    menuFooterTextColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      activeRoute: "",
      activeTopRoute: "",
      activeSubRoute: "",
      fullPath: null,
      activeLink: null,
      logoutIcon: logoutIcon,
      showSubItems: false,
      allRoutes: [
        "user",
        "dashboard",
        "saleHistory",
        "transactions",
        "listed-items",
        "list-items",
        "analytics",
        "investments",
        "simulator",
        "profitBoard",
        "profitBoardDota",
        "calculation",
        "csgo-inventory",
        "watchlist",
      ],
      subRoutes: [
        "dashboard",
        "saleHistory",
        "list-items",
        "transactions",
        "listed-items",
        "analytics",
        "investments",
      ],
    };
  },
  methods: {
    ...mapActions("ui", ["toggleSidebar"]),
    async logout() {
      try {
        const response = await LogoutService.logoutConfig();
        if (response) return (window.location.href = "/");
      } catch (error) {
        alert("Error while logging out!");
      }
    },
    subIsActive() {
      const res = this.$router.currentRoute.value.name;
      this.currentRoute = res;

      this.showSubItems = false;
      if (!res) return;

      if (this.subRoutes.includes(this.currentRoute) && this.isMenuOpened) {
        this.showSubItems = true;
      } else {
        this.showSubItems = false;
      }

      this.activeRoute = this.currentRoute;
    },
    handleSubRoute(item, type) {
      this.showSubItems = false;
      this.activeRoute = item.route;
      this.fullPath = item.link;
      this.subIsActive();

      if (
        type == "new_tab" &&
        item.route === this.$router.currentRoute.value.name
      ) {
        router.replace({ path: this.fullPath });
        return;
      }
      if (type == "new_tab") {
        const routeData = this.$router.resolve({
          path: this.fullPath,
        });
        window.open(routeData.href, "_blank");
        return;
      }

      if (item.route === this.$router.currentRoute.value.name) {
        router.replace({ path: this.fullPath });
      } else {
        router.push({ path: this.fullPath });
      }
    },
    handleMasterMenuRoute(item, type) {
      if (item.route === "trader" && !this.isMenuOpened) return false;

      if (item.route === "trader" && this.isMenuOpened) {
        this.showSubItems = !this.showSubItems;
        return;
      }

      this.activeRoute = item.route;
      this.fullPath = item.link;

      if (
        type == "new_tab" &&
        item.route === this.$router.currentRoute.value.name
      ) {
        router.replace({ path: this.fullPath });
        return;
      }
      if (type == "new_tab") {
        const routeData = this.$router.resolve({
          path: this.fullPath,
        });
        window.open(routeData.href);
        return;
      }

      if (item.route === this.$router.currentRoute.value.name) {
        router.replace({ path: this.fullPath });
      } else {
        router.push({ path: this.fullPath });
      }
    },
    toggleMenuSidebar() {
      this.$store.dispatch("ui/toggleSidebar", this.isMenuOpened);
      if (!this.isMenuOpened) this.showSubItems = false;
    },
    adjustBodyPadding() {
      window.document.body.style.paddingLeft =
        this.isMenuOpened && this.isPaddingLeft
          ? this.menuOpenedPaddingLeftBody
          : this.menuClosedPaddingLeftBody;
    },
    setSubItems() {
      if (!this.isMenuOpened) this.showSubItems = false;
    },
    handleMenuToggle() {
      this.adjustBodyPadding();
    },
    handleRouteChange(to) {
      this.currentRoute = to.path;
      if (!this.currentRoute) return;

      this.activeRoute = this.currentRoute;
      if (this.subRoutes.includes(this.currentRoute)) {
        this.showSubItems = false;
      }
    },
    setInitialSettings() {
      this.setSubItems();
      this.subIsActive();
      this.adjustBodyPadding();
    },
  },
  computed: {
    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--secondary-color": this.secondaryColor,
        "--logo-title-color": this.logoTitleColor,
        "--icons-color": this.iconsColor,
        "--items-tooltip-color": this.itemsTooltipColor,
        "--serach-input-text-color": this.searchInputTextColor,
        "--menu-items-hover-color": this.menuItemsHoverColor,
        "--menu-items-text-color": this.menuItemsTextColor,
        "--menu-footer-text-color": this.menuFooterTextColor,
      };
    },
    ...mapGetters("ui", ["isMenuOpened", "getRouteLoading"]),
  },
  watch: {
    isMenuOpened: "handleMenuToggle",
    $route(to) {
      this.handleRouteChange(to);
    },
  },
  created() {
    this.adjustBodyPadding();
    this.setSubItems();
    this.subIsActive();
  },
};
</script>

<style>
/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css");

body {
  transition: all 0.5s ease;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  margin-top: 20px;
  padding-left: 10px;
}

.logout_btn {
  color: rgb(192, 59, 59);
  background-color: transparent;
  border: rgb(215, 18, 18) ne;
  height: 30px;
  display: flex;
  align-items: center;
  width: 50px;
  border: none;
  transition: 0.4s;
}

.logout_btn:hover {
  color: rgb(255, 0, 0);
}

.menu-logo {
  width: 30px;
  margin: 0 10px 0 10px;
}

.sidebar-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-height: calc(100% - 60px);
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  width: 78px;
  background: var(--bg-color);
  /* padding: 6px 14px 0 14px; */
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: var(--logo-title-color);
  padding-left: 15px;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  display: none;
  transition: all 0.3s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
  display: block;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: var(--icons-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.nav-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 0px;
  overflow: visible;
  gap: 2px;
}

.sub-menu {
  display: none;
  margin-top: 5px;
  gap: 2px;
  padding-left: 25px;
  transition: 0.3s ease;
}

.sub-menu li {
  height: 35px;
}

.sub-menu-active {
  display: flex;
  transition: 0.4s;
}

.sidebar li {
  min-height: 45px;
  margin: 0px 0;
  list-style: none;
  cursor: pointer;
  position: relative;
}

.list-icon {
  height: 18px;
  width: 18px;
}

.sidebar li a {
  display: flex;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  padding-right: 10px;
  padding-left: 18px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--bg-color);
}

.sidebar li a:hover {
  background: var(--menu-items-hover-color);
}

.sidebar li a .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: "#white";
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar div.profile {
  position: relative;
  height: 60px;
  width: 78px;
  /* left: 0;
    bottom: 0; */
  padding: 10px 14px;
  background: var(--secondary-color);
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open div.profile {
  width: 250px;
}

.sidebar div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar div img {
  height: 24px;
  width: 24px;
  object-fit: cover;
}

.sidebar div.profile .name,
.sidebar div.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: var(--menu-footer-text-color);
  white-space: nowrap;
}

.sidebar div.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--secondary-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: var(--secondary-color);
  opacity: 0;
}

.sidebar.open .profile:hover #log_out {
  opacity: 1;
}

.sidebar.open .profile #log_out:hover {
  opacity: 1;
  color: red;
}

.sidebar .profile #log_out:hover {
  color: red;
}

.my-scroll-active {
  overflow-y: auto;
}

#my-scroll {
  margin: 6px 8px 0 8px;
  overflow-y: auto;
  height: calc(100% - 60px);
}

#my-scroll::-webkit-scrollbar {
  display: none;
}

.logo-details {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.logo-details img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.logo_name {
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.logo_name:hover {
  cursor: pointer;
  color: white;
}

.menu-toggle {
  cursor: pointer;
}

.menu-toggle i {
  font-size: 30px;
  color: white;
}

@media (max-width: 576px) {
  .sidebar li .tooltip {
    display: none;
  }

  .sidebar.open {
    width: 100%;
  }
}
</style>
