<template>
    <div class="not-found">
        <h1>{{ title }}</h1>
        <h3 class="my-1">{{ sub }}</h3>
        <router-link to="/dashboard" class="back-to-home">Back To Home Page</router-link>
    </div>
</template>
<script>
export default {
  data() {
    return {
      title: "404",
      sub: 'Page Not Found',
    };
  },
};
</script>
  
<style scoped>


.back-to-home {
  display: block;
  margin-top: 20px;
  color: #fff; /* İsteğe bağlı olarak buton rengini değiştirebilirsin */
  text-decoration: none;
  font-size: 1.2em;
}

.back-to-home:hover {
  text-decoration: underline;
}

.not-found {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    gap: 10px;
    padding: 20px;
    background-color: #4e4e90;
    color: #fff;
    height: 100vh;
}

h1 {
    font-size: 10rem;
}

p {
    font-size: 1.2em;
}
</style>
  