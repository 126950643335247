<template>
    <div class="shimmer-container">
        <div class="shimmer-img"></div>
        <div class="shimmer-name"></div>
        <div class="shimmer-name"></div>
        <div class="shimmer-price"></div>
    </div>
</template>
  
<style scoped>
.shimmer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #21242c;
    border-radius: 0.4rem;
    margin-block: 0.4rem;
    padding: 0.6rem 0.5rem;
    position: relative;
    transition: 0.3s;
    overflow: hidden;
    
}

.shimmer-img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    margin-bottom: 10px;
}

.shimmer-name {
    height: 16px;
    width: 100%;
    border-radius: 4px;
    background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    margin-bottom: 10px;
}

.shimmer-price {
    height: 16px;
    width: 80px;
    border-radius: 4px;
    background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    margin-bottom: 10px;
}

@media only screen and (max-width:745px) {
    .shimmer-img {
        height: 60px;
        width: 60px;
    }

    .shimmer-container {
        padding: 0.6rem 1rem;
        position: relative;
        transition: 0.3s;
        min-height: 250px;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }


}


@keyframes shimmer {
    to {
        background-position: 200% 0;
    }
}
</style>
  