<template>
  <div class="background-small">
    <div v-if="loading" class="loading-screen">
      <DotLoader :loading="loading"></DotLoader>
    </div>
    <div v-else class="page_wrap">
      <div class="left_side">
        <PriceStatistics
          v-if="priceStats && priceStats.length > 0"
          :trend="trend"
          :header="'Price Statistics'"
          :items="this.priceStats"
        />
        <ItemDetail :header="'About Skin'" :items="infos" />
        <CommunityVote
          @set-vote="setVote"
          :rating="rating"
          :header="'Vote Item'"
        />
      </div>
      <div class="right_side">
        <div class="right_inner_wrap">
          <div class="mid_bar">
            <FilterBar :count="marketInfos.length" :item="item" />
            <div class="price-bar">
              <TransitionGroup name="fade" mode="out-in">
                <PriceBar
                  v-for="(item, index) in marketInfos"
                  :key="index"
                  :item="item"
                  :index="index"
                />
              </TransitionGroup>
            </div>
          </div>
          <div class="right_section">
            <ItemBar
              @go-item="goItem"
              @go-collection="goCollection"
              @copy-text="copyText"
              :prices="lowestPrices"
              :item="itemInfo"
            />
          </div>
        </div>
        <div class="graph_wrap">
          <PriceHistory
            @selectInterval="selectInterval"
            :interval="timeInterval"
            :header="'Price History'"
            :chartData="priceHistory"
          />
        </div>
      </div>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
</template>

<script>
import ItemBar from "@/components/item/ItemBar.vue";
import PriceBar from "@/components/item/PriceBar.vue";
import FilterBar from "@/components/item/FilterBar.vue";
import CommunityVote from "@/components/item/CommunityVote.vue";
import PriceStatistics from "@/components/item/PriceStatistics.vue";
import ItemDetail from "@/components/item/ItemDetail.vue";
import PriceHistory from "@/components/item/PriceHistory.vue";
import InfoModal from "@/components/modal/InfoModal.vue";

import ItemService from "@/services/ItemService.js";

import MarketOptions from "@/core/constants";

export default {
  components: {
    ItemBar,
    PriceBar,
    FilterBar,
    CommunityVote,
    PriceStatistics,
    ItemDetail,
    PriceHistory,
    InfoModal,
  },
  data() {
    return {
      MarketOptions,
      item: {},
      itemInfo: {},
      lowestPrices: [],
      priceStats: [],
      infos: [
        { name: "Category", value: "", color: "white" },
        { name: "Exterior", value: "", color: "white" },
        { name: "Rarity", value: "", color: "#D32DE5" },
        { name: "Collection", value: "", color: "white" },
        { name: "StatTrak™", value: "Not Available", color: "#CF6830" },
        { name: "Souvenir", value: "Not Available", color: "#FFD601" },
        { name: "Description", value: "", color: "white" },
      ],
      priceHistory: [],
      priceLoading: true,
      timeInterval: [
        { name: "7 D", code: "7_d", value: 7 },
        { name: "14 D", code: "14_d", value: 14 },
        { name: "30 D", code: "30_d", value: 30 },
        { name: "3 M", code: "3_m", value: 90 },
      ],
      selectedInterval: 7,
      trend: "stable",
      loading: true,
      page: 1,
      pageSize: 12,
      items: [],
      filterObject: {
        totalCount: 0,
        totalAmount: 0,
      },
      ascending: true,
      rating: {
        totalVote: 0,
        value: 0,
      },
      showModal: false,
      modalMessage: "Item name copied.",
      priceInfos: [],
      marketInfos: [],
      itemName: "",
      appID: null,
      selectedVote: null,
      voteLoading: true,
      selectedMarketId: 1,
      appid: null,
      itemSlug: "",
    };
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.modalMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    sortByPrice() {
      this.ascending = !this.ascending;
    },
    convertMarketArray(marketInfo) {
      if (!marketInfo.price_info || !marketInfo.market_item_url) return;
      const pricesArray = marketInfo.price_info;
      const Urls = marketInfo.market_item_url;

      let referancePrice =
        pricesArray.find((e) => e.markets.slug == "steam") || 0;
      let refPrice = null;
      if (referancePrice) {
        refPrice = referancePrice.price || 0;
      } else {
        referancePrice = pricesArray.find((e) => e.markets.slug == "buff") || 0;
        refPrice = referancePrice.price;
      }

      pricesArray.forEach((item) => {
        const link = Urls.find((e) => e.market_id == item.markets.id);
        if (link && link.url) item.link = link.url;
        else item.link = "";
      });

      this.marketInfos = pricesArray.map((item) => ({
        name: item.markets.name,
        icon: this.MarketOptions.find((e) => e.name == item.markets.name).icon,
        value: item.price / 1000,
        stock: item.quantity || item.volume,
        discount: refPrice
          ? (((item.price - refPrice) / refPrice) * 100).toFixed(1)
          : "0",
        link: item.link,
      }));
    },
    mapLowestPrices(prices) {
      if (!prices && prices.length === 0) return;
      this.lowestPrices = prices.map((item) => ({
        itemID: item.ID,
        name: item.name,
        slug: item.slug,
        shortName: item.shortName,
        exterior: item.exterior,
        isStattrak: item.is_stattrak,
        isSouvenir: item.is_souvenir,
        value: item.min_price,
      }));
    },
    mapPriceStats() {
      if (!this.priceInfos || this.priceInfos.length === 0) return;
      this.priceStats = this.priceInfos;
    },
    getMarketName(name) {
      const foundItem = this.MarketOptions.find((e) => e.code === name);

      if (foundItem) {
        return foundItem.name;
      } else {
        console.error(`Market name not found for code: ${name}`);
        return "Steam";
      }
    },
    getAppID() {
      const app = this.appID;
      if (app === "cs2") return (this.appid = 730);
      if (app === "dota2") return (this.appid = 570);
    },
    async getRecords() {
      this.loading = true;
      const response = await ItemService.getOneItem({
        slug: this.itemSlug,
        game: this.appid,
      });

      if (!response) return (this.loading = false);
      this.priceInfos = response.priceStats;
      this.itemInfo = response.itemInfo;
      this.itemName = this.itemInfo.name;

      this.mapPriceStats();
      if (this.itemInfo) this.mapAboutSkin(this.itemInfo);
      if (response.marketInfo) this.convertMarketArray(response.marketInfo);
      if (response?.lowestPrices?.length > 0)
        this.lowestPrices = response.lowestPrices;

      this.loading = false;
    },
    mapAboutSkin(itemInfo) {
      this.infos.forEach((info) => {
        const key = info.name.toLowerCase();

        if (info.name === "StatTrak™") {
          info.value = itemInfo["is_stattrak"] ? "Available" : "Not Available";
        }
        if (info.name === "Souvenir") {
          info.value = itemInfo["is_souvenir"] ? "Available" : "Not Available";
        }
        if (info.name === "Category") {
          info.value = itemInfo["type"];
        }
        if (info.name === "Collection") {
          info.value = itemInfo["item_set"];
        }

        if (Object.prototype.hasOwnProperty.call(itemInfo, key)) {
          info.value = itemInfo[key];
          if (key === "rarity") {
            info.color = itemInfo["color"];
          }
        }
      });
    },
    async selectInterval(time) {
      if (!time) return;
      this.selectedInterval = time;
      await this.getPriceHistory();
    },
    async getPriceHistory() {
      this.priceLoading = true;

      if (this.appID == "dota2") this.selectedMarketId = 4;

      const response = await ItemService.getPriceHistory({
        slug: this.itemSlug,
        interval: this.selectedInterval,
        game: this.appid,
        market: this.selectedMarketId,
      });
      if (!response) return (this.loading = false);

      this.priceHistory = response;

      this.priceLoading = false;
    },
    async setVote(vote) {
      if (vote === undefined) return;
      this.selectedVote = vote;
      await this.voteItem();
    },
    async voteItem() {
      this.voteLoading = true;
      const response = await ItemService.voteItem({
        itemID: this.itemInfo.item_id,
        vote: this.selectedVote,
        game: this.appid,
      });
      if (!response) return (this.voteLoading = false);
      await this.getVote();
      this.priceLoading = false;
    },
    async getVote() {
      this.voteLoading = true;

      const response = await ItemService.getItemVote({
        itemID: this.itemInfo.item_id ?? -1,
        game: this.appid,
      });
      if (!response) {
        this.rating.value = 0;
        this.rating.totalVote = 0;

        this.voteLoading = false;
        return;
      }

      this.rating.value = response.rating;
      this.rating.totalVote = response.count;

      this.voteLoading = false;
    },
    async goItem(item) {
      this.$router.replace({ name: "item", params: { slug: item.slug } });
      this.itemSlug = item.slug;
      await this.getRecords();
      await this.getPriceHistory();
      await this.getVote();
    },
    async goCollection(id, collection) {
      this.$router.push({
        name: "collection",
        params: { name: collection },
        query: { id: id },
      });
    },
    setScreenSize() {
      this.$store.dispatch("ui/checkMobile");
    },
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener("resize", this.setScreenSize);
  },
  async created() {
    this.itemSlug = this.$route.params.slug;
    this.appID = this.$route.params.game;
    this.getAppID();
    await this.getRecords();
    await this.getPriceHistory();
    await this.getVote();
    this.$store.dispatch("router/updateTitle", this.itemName);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

h5 {
  color: white;
}

.graph_wrap {
  width: 100%;
}

.page_wrap {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1540px;
  min-width: 100%;
}

.left_side {
  width: 20%;
  min-width: 20%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.right_side {
  min-width: 80%;
  width: 80%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.right_inner_wrap {
  display: flex;
  gap: 15px;
}

.mid_bar {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.price-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.right_section {
  width: 30%;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

@media screen and (max-width: 1096px) {
  .page_wrap {
    flex-direction: column-reverse;
    padding: 1rem 0.5rem;
    gap: 10px;
  }

  .left_side {
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 10px;
  }

  .right_side {
    width: 100%;
    gap: 10px;
  }

  .graph_wrap {
    width: 100%;
    min-height: 380px;
  }

  .price-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 769px) {
  .background-small {
    padding-top: 1.3rem !important;
    padding-bottom: 1.5rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    height: auto;
  }

  .right_section {
    width: 100%;
  }

  .page_wrap {
    padding: 0rem;
  }

  .right_inner_wrap {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    width: 100%;
  }

  .mid_bar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .graph_wrap {
    width: 100%;
    min-height: 380px;
    height: 420px;
  }

  .left_side {
    width: 100%;
    height: 100%;
    gap: 10px;
    flex-direction: column;
  }

  .price-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}
</style>
