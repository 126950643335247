import ItemService from "@/services/ItemService";

const itemsModule = {
  namespaced: true,
    state: () => ({
      itemSuggestions: [],
      collection: {},
      rareItems: [],
    }),
    mutations: {
      setSuggestions(state, setSuggestions) {
        state.itemSuggestions = setSuggestions;
      },
      setCollection(state, collection) {
        state.collection = collection;
      },
      setRareItems(state, rareItems) {
        state.rareItems = rareItems;
      }
    },
    actions: {
      async fetchItemSearch(context, object) {
        try {
          const data = await ItemService.getAllItemImage({ name: object.query, limit: object.limit, game: object.game });
          context.commit('setSuggestions', data);
        } catch (error) {
          console.log('error');
        }
      },
      async setCollectionData(context, collection) {
        try {
          context.commit('setCollection', collection);
        } catch (error) {
          console.log('error');
        }
      },
      async setRareData(context, rareItems) {
        try {
          context.commit('setRareItems', rareItems);
        } catch (error) {
          console.log('error');
        }
      },
    },
    getters: {
      getItemSuggestions: (state) => state.itemSuggestions,
      getCollection: (state) => state.collection,
      getRareItems: (state) => state.rareItems,
    }
  };
  
  export default itemsModule;
  