<template>
    <div :class="colSize" :style="{ margin: cardMargin }">
        <div class="card m-b-20 rounded custom-card" :class="cardHeight">
            <h5 class="mt-0 header-title mb-3 p-3">{{ head }}</h5>
            <div class="chart-container">
                <component :is="currentChartType" :data="chartdata" :labelName="labelName" />
            </div>
        </div>
    </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import LineChart from '@/components/charts/LineChart';

export default {
    props: {
        head: String,
        colSize: String,
        cardMargin: String,
        chartdata: Object,
        initialChartType: String,
        cardHeight: String,
        labelName: String,
    },
    components: {
        BarChart,
        LineChart,
    },
    data() {
        return {
            currentChartType: this.initialChartType,
        };
    },
    methods: {
        changeChartType(newType) {
            this.currentChartType = newType;
        },
    },
}
</script>

<style scoped>
.custom-card {
    background-color: #313156;
    padding: 5px;
    min-height: 300px;
    color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;


}

.chart-container {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 576px) {
    .chart-container {
        min-height: 350px;
    }

}
</style>