<template>
  <div class="f align-center sb item_row">
    <div class="inner">
      <div class="currency-div">
        <img :src="item.icon" class="currency-icon" :alt="item.name" loading="lazy" />
        <span class=" bold">
          {{ item.type }}
        </span>
      </div>
      <div class="market-div">
        <img :src="item.marketIcon" class="small-icon" :alt="item.name" loading="lazy" />
        <span class=" bold">
          {{ item.market }}
        </span>
      </div>
      <div class="name">
        <span class=" bold">
          {{ item.wallet }}
        </span>
        <button @click="$emit('copyText', item.name)" class="btn_copyname mx-2">
          <VueIcon height="22px" color="white" icon="bx:copy" />
        </button>
      </div>
      <div class="name">
        <span :style="{ color: '#FF3838' }" class=" bold">
          - {{ item.netAmount }} $
        </span>
      </div>
      <div class="name">
        <span class=" bold">
          {{ DateUtils.getDate(item.created) }}
        </span>
      </div>
      <div class="name">
        <img :src="item.statusIcon" class="status-icon" :alt="item.status" loading="lazy" />
        <div style="width: 10px;"></div>
        <span :style="{ color: '#24D700' }" class=" bold">
          {{ item.status }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DateUtils from "@/utils/DateUtils";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {

  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
};
</script>

<style scoped>
.item_row {
  background: #21242c;
  border-radius: 0.4rem;
  margin-bottom: 0.4rem;
  padding: 0.5rem 2.4rem;
  transition: 0.3s;
  max-height: 9rem;
  min-height: 6rem;
}

span {
  font-size: 1.8vh;
}

.item_row:hover {
  background-color: #2c2c48;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

.currency-icon {
  height: 50px;
  width: 50px;
}

.status-icon {
  height: 30px;
  width: 30px;
}


.inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
}

.name {
  display: flex;
  align-items: center;
}

.currency-div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.market-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.market-div img {
  width: 30px;
}
</style>
