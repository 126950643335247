<template>
  <div class="contents">
    <div class="f align-center item_card_row">
      <div class="f skin_main g20">
        <a
          style="cursor: pointer"
          @mousedown.middle="
            this.$emit('go-item', item)
          "
          @click="this.$emit('go-item', item)"
          class="thumb null"
        >
          <div class="thumb_shadow" style="color: rgb(235, 75, 75)"></div>
          <img
            :src="item.icon_url"
            class="img-item"
            :alt="item.market_hash_name"
            loading="lazy"
            :style="{ '--drop-shadow-color': item.rarity_color }"
          />
        </a>
      </div>
      <div class="item_info_div">
        <div class="f flex-column flex-start">
          <span
            class="mid_span hidden_mobile"
            :style="{ color: item.rarity_color || 'white' }"
            >{{ item.rarity }}</span
          >
          <span class="mid_span" :style="{ color: typeColor }">
            {{ getType() }}</span
          >

          <span class="mid_span" :style="{ color: 'white' }">{{
            item.short_name
          }}</span>

          <div class="mobile_price_hidden">
            <span class="mid_span">{{ item.exterior }}</span>

            <div
              style="cursor: pointer"
              @click="goExternalLink(item.url)"
              class="f g5 align-bottom"
            >
              <button class="btn_copyname mt-1">
                <VueIcon height="20px" color="white" icon="mdi:steam" />
              </button>
              <span :style="{ color: '#fff' }" class="text-white price_font">
                $
                {{
                  item.price
                    ? (item.price / 1000).toFixed(2).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : "$0"
                }}
              </span>
            </div>
          </div>
        </div>
        <button
          @click="$emit('copyText', item.market_hash_name)"
          class="btn_copyname mx-2"
        >
          <VueIcon height="22px" color="white" icon="bx:copy" />
        </button>
      </div>

      <div class="exterior">
        <span class="mid_span">{{ item.exterior }}</span>
      </div>

      <div class="price_div">
        <div
          style="cursor: pointer"
          @click="goExternalLink(item.url)"
          class="f g5 align-bottom"
        >
          <button class="btn_copyname mt-1">
            <VueIcon height="20px" color="white" icon="mdi:steam" />
          </button>
          <span :style="{ color: '#fff' }" class="text-white price_font">
            $
            {{
              item.price
                ? (item.price / 1000).toFixed(2).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : "$0"
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import copy from "../../assets/images/copy-white.png";
import edit from "../../assets/images/edit.png";
import tick from "../../assets/images/tick-white.png";
import DateUtils from "@/utils/DateUtils";

export default {
  data() {
    return {
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      newPrice: this.item.newPrice,
      color: "white",
      typeColor: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    buy: {
      type: String,
      default: "",
    },
    selectedMarket: {
      type: String,
      default: "",
    },
  },
  methods: {
    updatePrice() {
      this.$emit("updatePrice", { ...this.item, newPrice: this.newPrice });
    },
    getType() {
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™ " + this.item.type;
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir " + this.item.type;
      }

      this.typeColor = "white";
      return this.item.type;
    },
    goExternalLink(link) {
      window.open(link, "_blank");
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
};
</script>

<style scoped>
.item_card_row {
  background: #21242c;
  display: grid;
  grid-template-columns: 130px 270px 120px 100px;
  border-radius: 0.5rem;
  margin-block: 0rem;
  padding: 0.5rem 2rem;
  position: relative;
  transition: 0.3s;
  max-height: 9rem;
  min-height: 6rem;
  border: 1px solid transparent;
}

.item_card_row:hover {
  border-color: #3a3e4a;
  cursor: pointer;
}

.mid_span {
  font-size: 0.8rem;
}

.price_font {
  font-size: 0.85rem;
  font-weight: bold;
}

.bold {
  font-weight: 900;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

.img-xsmall {
  height: 21px;
  width: 21px;
}

.price_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.item_card_row:hover .img-item {
  transition: 0.2s;
  transform: scale(1.05);
  filter: drop-shadow(0 0 11px var(--drop-shadow-color));
}

.item_card_row:not(hover) .img-item {
  transition: 0.2s;
  transform: scale(1);
}

.img-item {
  height: 70px !important;
  width: 95px !important;
}

.edit-btn {
  background-color: #212121;
  border-radius: 6px;
  border: 1px solid rgb(100, 99, 99);
  height: 34px;
  width: 34px;
  color: white;
}

.edit-btn:hover {
  background-color: #626060;
  color: white;
}

.small-img-button {
  height: 20px;
  width: 20px;
}

.inner-input-div {
  height: 2rem;
  width: 90px;
}

.input-area-dark {
  background-color: black;
  color: white;
  font-weight: 600;
  width: 90%;
  min-width: 60px;
}

.mobile_price_hidden {
  display: none;
}

.item_info_div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1080px) {
  .item_card_row {
    background: #21242c;
    display: grid;
    grid-template-columns: 90px 200px;
    border-radius: 0.5rem;
    margin-block: 0rem;
    padding: 0.5rem 0.5rem;
    position: relative;
    transition: 0.3s;
    max-height: auto;
    min-height: 7rem;
  }

  .mobile_price_hidden {
    display: block;
    align-items: center;
    justify-content: center;
  }

  .price_div {
    display: none;
  }

  .exterior {
    display: none;
  }

  .img-item {
    height: 60px !important;
    width: 80px !important;
  }

  .item_info_div {
    gap: 0px;
  }

  .hidden_mobile {
    display: none;
  }
}
</style>
