import axios from "axios";
//import store from '../store/store'
import LogoutService from "@/services/LogoutService";

let baseURL = process.env.VUE_APP_MODE === 'test' ? process.env.VUE_APP_TEST_BASE_URL : process.env.VUE_APP_PRODUCTION_BASE_URL;

export const apiInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

apiInstance.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            const response = await LogoutService.logoutConfig();
            if (response) return window.location.href = '/';
        }
        return Promise.reject(error);
    }
);
