<template>
  <div
    @click="this.$emit('go-user', item.steam_id)"
    class="shimmer-container"
  >
    <span class="rank"># {{ item.rank }}</span>
    <span class="country"> {{ item.country }}</span>
    <div class="shimmer-img">
      <img v-if="item.image" class="active_avatar" :src="item.image" />
    </div>
    <div class="shimmer-name">
      <span class="name"> {{ item.name }}</span>
    </div>
    <div class="shimmer-name">
      <span class="value"
        > {{ item.value ? (item.value/1000).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }) : 0 }}</span
      >
    </div>
    <div class="shimmer-price"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  components: {},
};
</script>

<style scoped>
.shimmer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #21242c;
  border-radius: 0.4rem;
  margin-block: 0rem;
  padding: 0.6rem 0.6rem;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  gap: 5px;
  border: 2px solid transparent;
  cursor: pointer;
}

.shimmer-container:hover {
  transition: 0.1s;
  background-color: #2a2a49;
  transition: 0.3s;
  border: 2px solid #5a3d92;
}

.rank {
  font-size: 18px;
  font-weight: 900;
  color: #e0e005;
  position: absolute;
  left: 5px;
  top: 5px;
}

.country {
  font-size: 12px;
  font-weight: 900;
  color: #e0e005;
  position: absolute;
  right: 5px;
  top: 5px;
}


.shimmer-img {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shimmer-name {
  width: 100%;
  text-overflow: ellipsis;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shimmer-price {
  height: 20px;
  width: 80px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_avatar {
  cursor: pointer;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: white;
}

.name {
  font-size: 11.5px;
  color: white;
  text-align: center;
}

.value {
  font-size: 15px;
  font-weight: bold;
  color: rgb(76, 223, 153);
}

@media only screen and (max-width: 745px) {
  .shimmer-img {
    height: 60px;
    width: 60px;
  }

  .shimmer-container {
    padding: 0.6rem 2.4rem;
    position: relative;
    transition: 0.3s;
    min-height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}

@keyframes shimmer {
  to {
    background-position: 200% 0;
  }
}
</style>
