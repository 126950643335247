<template>
  <div class="background-small">
    <div class="row">
      <h4 class="text-white">{{ title }}</h4>
    </div>
    <div class="row my-1">
      <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-12 g20">
        <span v-if="valueLoading" class="loader"></span>
        <div v-else>
          <div class="f g20 my-2 mobile_stat">
            <div>
              <h6 class="text-white">{{ totalInvest }} $</h6>
              <h6 style="color: #954ce9" class="">Total Investment</h6>
            </div>
            <div>
              <h6
                class="bold"
                :style="{ color: totalProfit >= 0 ? '#4ddf9a' : 'red' }"
              >
                {{ totalProfit }} $
              </h6>
              <h6 style="color: #954ce9">Profit</h6>
            </div>
            <div>
              <h6
                class="bold"
                :style="{ color: getROI >= 0 ? '#4ddf9a' : 'red' }"
              >
                {{ getROI }} %
              </h6>
              <h6 style="color: #954ce9">ROI</h6>
            </div>
          </div>
          <ValuationGrid :title="'Valuation'" :data="marketValues" />
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 price_bar">
        <LineGraph
          :options="chartOption"
          @selectInterval="selectInterval"
          :interval="timeInterval"
          :header="'Valuation History (' + selectedMarket + ')'"
          :sub-header="''"
          :chart-data="valueGraph"
        />
      </div>
    </div>
    <div class="f sb align-bottom my-3 filters">
      <div class="left">
        <SearchBar @handleSearch="handleSearch"></SearchBar>
      </div>

      <div class="right f g10">
        <div class="f flex-column als">
          <h6 class="text-white mb-1">{{ providerTitle }}</h6>
          <IconDropdown
            :refFunction="setMarket"
            :options="MarketOptions"
            :selectedItem="selectedMarket"
            :selectedIcon="selectedIcon"
          />
        </div>
        <div class="f flex-column als">
          <h6 class="text-white mb-1">{{ exportTitle }}</h6>
          <IconDropdown
            :refFunction="selectOption"
            :options="exportOptions"
            :selectedItem="selectedExportOption"
            :selectedIcon="selectedExportIcon"
          />
        </div>
        <div class="f flex-column als">
          <h6 class="text-white mb-1">{{ importTitle }}</h6>
          <button class="custom_button" @click="openModal">
            <VueIcon
              height="27px"
              color="white"
              icon="mdi:file-upload-outline"
            />
          </button>
        </div>
        <div class="f flex-column als">
          <h6 class="text-white mb-1">{{ addItem }}</h6>
          <button
            class="custom_button"
            id="addBtn"
            @click="showItemModal = true"
          >
            <VueIcon height="27px" color="white" icon="mdi:add" />
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div v-if="loading" class="loading-screen">
          <DotLoader :loading="loading" :width="'50px'" />
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th v-for="column in columns" :key="column">
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading && tableItems && tableItems.length > 0">
              <tr
                @click="fillObject(item)"
                v-for="(item, index) in tableItems"
                :key="index"
              >
                <td @click="goLink(item)" style="cursor: pointer">
                  <img
                    v-if="item.img && !item.img.toUpperCase().includes('NULL')"
                    class="img"
                    :src="item.img"
                  />
                  <img
                    v-else
                    class="img"
                    src="../assets/items/csgo/blood.png"
                  />
                </td>
                <td>
                  <div class="sb f">
                    {{ item.name }}
                    <button
                      @click="copyText(item.name)"
                      aria-label="Copy"
                      class="btn_copyname"
                    >
                      <VueIcon height="20px" color="white" icon="bx:copy" />
                    </button>
                  </div>
                </td>
                <td>{{ item.quantity }}</td>
                <td>$ {{ item.buy_price }}</td>
                <td>$ {{ item.price }}</td>
                <td
                  class="bold"
                  :style="{ color: item.multiple_profit > 0 ? 'green' : 'red' }"
                >
                  $ {{ item.multiple_profit.toFixed(2) }}
                </td>
                <td
                  class="bold"
                  :style="{ color: item.ratio > 0 ? 'green' : 'red' }"
                >
                  {{ item.ratio ? item.ratio.toFixed(2) : 0 }} %
                </td>
                <td>{{ item.date }}</td>
                <td>
                  <div
                    style="width: 100%"
                    class="f align-center justify-center"
                  >
                    <button
                      @click="
                        removeItem(
                          item.app_id,
                          item.item_id,
                          item.investment_id
                        )
                      "
                      class="btn_copyname"
                    >
                      <VueIcon
                        height="24px"
                        color="red"
                        icon="lets-icons:remove-light"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page_row pt-2 pb-3 f align-center">
          <PaginationRow
            :pageNumber="pageNumber"
            :totalPages="totalPages"
            @update:pageNumber="pageNumber = $event"
          />
        </div>
      </div>
    </div>
  </div>
  <DragDropFileModal
    ref="modal"
    @fileDropped="handleFileDrop"
  ></DragDropFileModal>
  <InfoModal
    v-if="showModal"
    :message="infoMessage"
    @close="showModal = false"
  />

  <AddInvestmentModalVue
    v-if="showItemModal"
    @closeModal="closeModal"
    @addInvestment="addInvestItem"
  ></AddInvestmentModalVue>
</template>
<script>
import ValuationGrid from "@/components/valueBars/ValuationGrid.vue";
import LineGraph from "@/components/graphs/LineGraph.vue";

import InvestmentService from "@/services/InvestmentService";
import FileConverterService from "@/services/FileConverterService.js";
import DragDropFileModal from "@/components/modal/DragDropFileModal.vue";
import InfoModal from "../components/modal/InfoModal.vue";
import {
  csvToJSON,
  filterData,
  filterSelectedHeaders,
} from "../services/FileService.js";
import csvIcon from "../assets/images/csv.png";
import excelIcon from "../assets/images/xlsx.png";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import AddInvestmentModalVue from "@/components/modal/AddInvestmentModal.vue";
import buffIcon from "../assets/marketIcons/buff_icon.png";

import MarketOptions from "@/core/constants";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      MarketOptions,
      totalPages: 6,
      statsLoading: true,
      loading: false,
      showModal: false,
      showItemModal: false,
      providerTitle: "Price Provider",
      exportTitle: "Export",
      importTitle: "Import",
      addItem: "Add Item",
      infoMessage: "Items added successfully",
      searchTimeout: null,
      searchTerm: "",
      title: "My Investments",
      items: [],
      pageNumber: 1,
      pageSize: 10,
      values: null,
      startDate: new Date(),
      endDate: "",
      columns: [
        "Img",
        "Name",
        "Qty.",
        "Buy Price",
        "Price",
        "Net Profit",
        "ROI",
        "Buy Date",
        "Remove",
      ],
      inputs: [
        { label: "Name", value: "" },
        { label: "Buying Price", value: 0 },
        { label: "Quantity", value: 1 },
        { label: "Category", value: "" },
      ],
      isDropdownOpen: false,
      selectedIcon: buffIcon,
      selectedMarket: "Buff.163",
      selectedId: 0,
      averageRoi: 0,
      totalProfit: 0,
      totalInvest: 0,
      totalSale: 0,
      isActive: false,
      selectedExportOption: "Excel",
      selectedExportIcon: excelIcon,
      exportOptions: [
        {
          name: "Excel",
          icon: excelIcon,
        },
        {
          name: "CSV",
          icon: csvIcon,
        },
      ],
      ROI: 0,
      timeInterval: [
        { name: "7 D", code: "7_d", value: 7 },
        { name: "14 D", code: "14_d", value: 14 },
        { name: "30 D", code: "30_d", value: 30 },
        { name: "3 M", code: "3_m", value: 90 },
      ],
      chartOption: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 0,
              minRotation: 0,
              padding: 10,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 5 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
              fontSize: 20,
            },
          },
        },
      },
      selectedInterval: 7,
      valueGraph: [],
      marketValues: [],
      valueLoading: true,
      game: "cs2",
    };
  },
  methods: {
    async addInvestItem(item) {
      if (!item) return (this.loading = false);
      const response = await InvestmentService.addItem({
        name: item.name,
        buyPrice: item.buy_price,
        quantity: item.quantity,
        category: item.type,
        buyDate: item.buy_date,
        item_id: item.item_id,
      });

      if (!response) {
        this.toggleModal(`Error.`);
        return;
      }

      this.toggleModal(`${item.name} added to your portfolio.`);
    },
    async removeItem(appID, itemID, investmentID) {
      const res = await InvestmentService.deleteItem({
        appID: appID,
        itemID: itemID,
        investmentID: investmentID,
      });

      if (!res) return this.toggleModal("Error ocurred while deleting item");
      this.toggleModal("Successfully deleted.");
      await this.getInvestments();
    },
    closeModal() {
      this.showItemModal = false;
    },
    async selectInterval(time) {
      if (!time) return;
      this.selectedInterval = time;
      await this.getValueGraph();
    },
    toggleExportDropdown() {
      this.isActive = !this.isActive;
    },
    async selectOption(option) {
      this.selectedExportOption = option.name;
      this.selectedExportIcon = option.icon;
      this.isActive = false;
      return await this.exportFile();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async setMarket(market) {
      this.selectedMarket = market.name;
      this.selectedIcon = market.icon;
      this.columns[4] = this.selectedMarket + " Price";
      this.isDropdownOpen = false;
      await this.updateData();
    },
    openModal() {
      this.$refs.modal.openModal();
    },
    async handleSearch(term) {
      clearTimeout(this.searchTimeout);
      this.searchTerm = term;
      this.searchTimeout = setTimeout(() => {
        this.getInvestments();
      }, 1000);
    },
    async handleFileDrop(file) {
      if (file.type === "text/csv") {
        if (file.size > 1 * 1024 * 1024) {
          alert("File size larger than 1MB! Size must less than 1MB.");
          return;
        }

        this.loading = true;
        const json = await csvToJSON(file);
        const filtered = await filterData(json);
        const finaldData = await filterSelectedHeaders(filtered);

        if(!finaldData) return false;

        const response = await InvestmentService.importCSV({
          items: finaldData,
        });

        if (response.success) {
          await this.getInvestments();
          await this.infoModalToggle(response, " items added successfully!");
          this.loading = false;
        }

        this.loading = false;
      } else {
        alert("File type must be CSV");
        return;
      }
    },
    async exportFile() {
      this.loading = true;
      const items = await this.getExportData();
      if (!items) return false;

      const parsedData = this.parseData(items);

      await FileConverterService.exportFile(
        this.selectedExportOption,
        parsedData,
        {
          name: "Investments",
        }
      );

      this.loading = false;
      this.toggleExportDropdown();
    },
    async getExportData() {
      try {
        return await InvestmentService.exportAll({
          pageSize: 500,
          sortBy: "profit",
          sortOrder: "DESC",
          market: this.selectedMarket,
        });
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    parseData(data) {
      if (!data || data.length == 0) return false;

      const newArray = [];

      data.forEach((element) => {
        const insertElement = {
          name: element.name,
          qty: element.quantity,
          market_name: "",
          buy_price: element.buy_price,
          sell_price: 0,
          profit: 0,
          ratio: 0,
          buy_date: element.buy_date,
        };

        if (element.marketPrices && element.marketPrices.length > 0) {
          insertElement.market_name = element.marketPrices[0].market_name;
          insertElement.profit = element.marketPrices[0].profit;
          insertElement.ratio = element.marketPrices[0].ratio;
          insertElement.sell_price = element.marketPrices[0].price;
        }

        newArray.push(insertElement);
      });
      return newArray;
    },
    async infoModalToggle(info, msg) {
      this.showModal = true;
      this.infoMessage = info.inserted_row_count + msg;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async getInvestments() {
      const response = await InvestmentService.getAll({
        currentPage: this.pageNumber,
        pageSize: this.pageSize,
        sortBy: "profit",
        sortOrder: "DESC",
        priceFrom: 0,
        priceTo: 100000000,
        market: this.selectedMarket.toLowerCase(),
        search: this.searchTerm,
      });

      this.items = response;
    },
    async fillObject(item) {
      this.selectedId = item.id;
      this.inputs[0].value = item.name;
      this.inputs[1].value = item.buy_price;
      this.inputs[2].value = item.quantity;
      this.inputs[3].value = item.category;
    },
    async fetchAnalysis() {
      this.statsLoading = true;
      this.statsLoading = false;
    },
    async fetchTotalSale() {
      this.statsLoading = true;
      this.statsLoading = false;
    },
    async fetchTotalInvest() {
      this.statsLoading = true;
      const response = await InvestmentService.getTotalInvest();
      if (!response) return (this.statsLoading = false);

      this.totalInvest = response.total_investment;
      this.statsLoading = false;
    },
    async getValueGraph() {
      const response = await InvestmentService.getGraph({
        market: this.selectedMarket.toLowerCase(),
        interval: this.selectedInterval,
      });
      if (!response) return;
      this.valueGraph = response;
    },
    async fetchTotalProfit() {
      this.statsLoading = true;
      const response = await InvestmentService.getTotalProfit({
        market: this.selectedMarket.toLowerCase(),
      });
      if (!response) return (this.statsLoading = false);
      this.totalProfit = response.total_profit;
      this.statsLoading = false;
    },
    async getValuations() {
      this.valueLoading = true;
      const response = await InvestmentService.getValuations();
      if (!response) return (this.valueLoading = false);

      this.marketValues = response;
      this.marketValues = response.map((e) => ({
        name: e.name,
        value: e.value,
        icon: this.getIcon(e.name),
      }));
      this.valueLoading = false;
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.infoMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    toggleModal(msg) {
      this.infoMessage = msg || "";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async updateData() {
      this.loading = true;
      await Promise.all([
        this.getInvestments(),
        this.fetchTotalInvest(),
        this.fetchTotalProfit(),
        this.getValueGraph(),
        this.getValuations(),
      ]);
      this.loading = false;
    },
    setScreenSize() {
      this.$store.dispatch("ui/checkMobile");
    },
    getIcon(name) {
      const i = this.MarketOptions.find((e) => e.name === name);
      if (i != undefined) return i.icon;
    },
    sort() {
      const items = this.marketValues.sort((a, b) => {
        return b.value - a.value;
      });
      return items;
    },
    goLink(item) {
      const route = this.$router.resolve({
        name: "item",
        params: { game: item.game, slug: item.slug },
      });
      window.open(route.href, "_blank");
    },
  },
  async created() {
    await this.updateData();
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener("resize", this.setScreenSize);
  },
  watch: {
    pageNumber() {
      this.getInvestments();
    },
  },
  computed: {
    getROI() {
      return ((this.totalProfit / this.totalInvest) * 100).toFixed(2);
    },
    tableItems() {
      return this.items;
    },
    ...mapGetters(["getGame", "getAppID"]),
  },
  components: {
    LineGraph,
    DragDropFileModal,
    InfoModal,
    IconDropdown,
    SearchBar,
    ValuationGrid,
    AddInvestmentModalVue,
  },
};
</script>
<style scoped>
@import "../assets/style/buttons.css";
@import "../assets/style/dropDowns.css";

h6 {
  margin: 0px 0px 0px 0px;
}

h4 {
  margin-bottom: 0;
}

.filters {
  padding: 0;
}

.left {
  width: 50%;
  padding: 0;
}

.right {
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  gap: 4px;
}

.inp-div-full {
  width: 100%;
  height: 40px;
}

.custom_button {
  padding: 0;
  border: 1px rgb(134, 80, 172) solid;
  height: 40px;
  border-radius: 1px;
  width: 60px !important;
}

tr:hover .img {
  transition: 0.4s;
  transform: scale(1.1);
}

tr:not(:hover) .img {
  transition: 0.4s;
  transform: scale(1);
}

.img {
  width: 70px;
  height: 50px;
}

tr > td {
  font-size: 15px;
}

th {
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (max-width: 1100px) {
  .filters {
    padding: 0;
    flex-direction: column-reverse;
    gap: 0.8rem;
  }

  .filters .left,
  .right {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 576px) {
  .mobile_stat {
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }

}
</style>
