const authModule = {
  namespaced: true,
  state: () => ({
    token: null,
    authenticated: false,
  }),
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setAuth(state, isAuth) {
      state.authenticated = isAuth;
    },
  },
  actions: {
    async setIsAuthenticated(context, isAuth) {
      try {
        context.commit('setAuth', isAuth);
      } catch (error) {
        context.commit('setAuth', false);
        console.log('error');
      }
    },
  },
  getters: {
    getToken: (state) => state.token,
    getIsAuthenticated: (state) => state.authenticated,
  }
};

export default authModule;
