<template>
  <div class="f flex-column item_row">
    <div class="f align-bottom skin">
      <div class="f skin_main g20">
        <a
          style="cursor: pointer"
          @click="this.$emit('go-item', item)"
          class="thumb null"
        >
          <div class="thumb_shadow" style="color: rgb(235, 75, 75)"></div>
          <img
            :src="item.image"
            class="img-item-mid"
            :alt="item.name"
            loading="lazy"
          />
        </a>
        <div class="name ovh">
          <div class="f sb mb-2">
            <span
              :style="{ color: '#' + item.rarity_color }"
              class="responsive-title"
            >
              {{ item.name }}
            </span>
            <button
              class="btn_copyname mx-2"
              @click="$emit('copyText', item.name)"
            >
              <VueIcon height="22px" color="white" icon="bx:copy" />
            </button>
          </div>
          <div class="f align-bottom">
            <VueIcon height="20px" color="white" icon="mdi:steam" />
            <span
              :style="{ color: '#' + item.rarity_color }"
              class="text-white mx-2"
            >
              $ {{ item.referancePrice }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="item.buyEditingPrice">
      <div class="f sb">
        <div>
          <span>Buy Price</span>
          <div class="f">
            <div class="inner-input-div">
              <input
                type="number"
                v-model="buyPrice"
                step="0.01"
                min="0"
                max="1000"
                class="input-area-dark"
                @keyup.enter="updatePrice('steam')"
              />
            </div>
            <button
              class="edit-btn f align-center"
              @click="updatePrice('steam')"
            >
              ✔️
            </button>
          </div>
        </div>
        <div class="f flex-column">
          <span
            >Listing Date: {{ DateUtils.getOnlyDate(item.created_time) }}</span
          >
          <span
            >Since:
            {{ DateUtils.getDateDiff(item.created_time, currentTime) }}
            days</span
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div class="f sb align-bottom g1">
        <div class="f sb align-bottom">
          <div>
            <span>Buy Price:</span>
            <span> $ {{ item.buy_price / 1000 }} </span>
          </div>
          <div class="mx-1">
            <button
              class="edit-btn f align-center"
              @click="startEditingPrice(item, 'steam', null)"
            >
              ✏️
            </button>
          </div>
        </div>
        <span>Listed on: {{ DateUtils.getOnlyDate(item.created_at) }}</span>
        <span
          >Since:
          {{ DateUtils.getDateDiff(item.created_at, currentTime) }}
          days</span
        >
      </div>
    </template>
    <hr class="line" />
    <div v-if="item?.markets?.length > 0" class="edit-section">
      <div
        v-for="(market, index) in item.markets"
        :key="index"
        class="f flex-column g0"
      >
        <div>
          <span>Market: {{ market.market_name }}</span>
        </div>

        <div>
          <span>Min: ${{ (market.market_price / 1000).toFixed(2) }}</span>
        </div>
        <div>
          <span class="mid-font gray bold"
            >Profit:
            <span
              :style="{
                color: market.profit > 0 ? '#7CFC00' : 'red',
              }"
            >
              ${{ (market.profit || 0).toFixed(2) }}</span
            >
          </span>
        </div>
        <div>
          <template v-if="market.editing_price">
            <div class="f align-bottom">
              <div class="inner-input-div">
                <input
                  v-model="newPrice"
                  type="number"
                  step="0.01"
                  min="0"
                  max="1000"
                  class="input-area-dark"
                  @keyup.enter="updatePrice(market)"
                />
              </div>
              <button
                class="edit-btn f align-center"
                :disabled="market.is_listed == 0"
                @click="updatePrice(market)"
              >
                ✔️
              </button>
            </div>
          </template>
          <template v-else>
            <div class="f sb align-bottom">
              <span>Price: $ {{ market.sale_price / 1000 }} </span>
              <div class="mx-1">
                <button
                  class="edit-btn f align-center"
                  :disabled="market.is_listed == 0"
                  @click="startEditingPrice(item, market.slug, market)"
                >
                  ✏️
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import csgotmIcon from "@/assets/marketIcons/csgotm_icon.png";
import shadowIcon from "@/assets/marketIcons/shadowpay_icon.png";
import waxIcon from "@/assets/marketIcons/waxpeer_icon.png";
import float from "../../assets/marketIcons/float_icon.png";
import haloIcon from "../../assets/marketIcons/haloskins_icon.png";
import DateUtils from "@/utils/DateUtils";
import moment from "moment";

export default {
  data() {
    return {
      steamIcon: steamIcon,
      newPrice: 0,
      buyPrice: this.item.buy_price / 1000,
      currentTime: moment(),
      markets: ["waxpeer", "shadowpay", "csgotm", "haloskins"],
      marketMapping: [
        { name: "Steam", code: "steam", icon: steamIcon },
        { name: "Waxpeer", code: "waxpeer", icon: waxIcon },
        { name: "MarketCSGO", code: "market", icon: csgotmIcon },
        { name: "Shadowpay", code: "shadowpay", icon: shadowIcon },
        // { name: 'Gamerpay', code: 'gamerpay', icon: gamerpayIcon },
        //{ name: 'SkinThunder', code: 'skinthunder', icon: thunderIcon },
        { name: "HaloSkins", code: "haloskins", icon: haloIcon },
        { name: "CSFloat", code: "csfloat", icon: float },
      ],
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    buy: {
      type: String,
      default: "",
    },
    selectedMarket: {
      type: String,
      default: "",
    },
    marketIcons: {
      type: Array,
      default: null,
    },
  },
  methods: {
    getMarketIcon(name) {
      const res = this.marketMapping.find((e) => e.name == name);
      if (!res) return null;
      return res.icon;
    },
    updatePrice(market) {
      if (market === "steam") {
        const market = { market_id: 1, slug: "steam" };
        return this.$emit("updatePrice", this.item, this.buyPrice, market);
      }

      return this.$emit("updatePrice", this.item, market.newPrice, market);
    },
    startEditingPrice(item, marketName, market) {
      if (marketName === "steam") {
        const market = { market_id: 1, slug: "steam" };
        return this.$emit("startEditingPrice", item, marketName, market);
      }
      return this.$emit("startEditingPrice", item, marketName, market);
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 976px) {
  .g0 {
    gap: 0px;
  }
  .edit-section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
  }

  .img-item-mid {
    height: 70px;
    width: 80px;
  }

  span {
    font-size: 1.8vh;
    color: white;
  }

  .item_row:hover {
    background-color: #2b2e38;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
  }

  .img-item {
    height: 60px !important;
    width: 75px !important;
  }

  .img-xsmall {
    width: 20px;
    height: 20px;
  }

  .img-mid {
    height: 24px !important;
    width: 24px !important;
  }

  .line {
    border: 1.4px white solid;
  }

  .responsive-title {
    font-size: 12px;
    font-weight: bold;
    color: white;
  }

  .img-item-mid {
    height: 70px;
    width: 80px;
  }

  .btn_copyname {
    border: 0;
    padding: 0;
    border-radius: 0.4rem;
    outline: none;
    background: rgba(0, 0, 0, 0);
    font-size: inherit;
    line-height: 1.2;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
  }

  .date-container {
    display: flex;
    align-items: center;
  }

  .edit-btn {
    background-color: #212121;
    border-radius: 1px;
    border: 1px solid rgb(100, 99, 99);
    height: 25px;
    width: 25px;
    font-size: 13px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-btn:hover {
    background-color: #626060;
    color: white;
  }

  .small-img-button {
    height: 15px;
    width: 15px;
  }

  .inner-input-div {
    height: 2rem;
    width: 90px;
  }

  .input-area-dark {
    background-color: black;
    color: white;
    font-weight: 600;
    width: 90%;
    min-width: 60px;
  }

  .item_row {
    background: #21242c;
    border-radius: 0.3rem;
    margin-block: 0.4rem;
    padding: 1.5rem 1.4rem;
    position: relative;
    transition: 0.3s;
    max-height: 24rem;
    min-height: 8rem;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
  }

  .img-xsmall {
    height: 25px;
    width: 25px;
  }
}

@media only screen and (max-width: 576px) {
  .img-item-mid {
    height: 70px;
    width: 80px;
  }

  span {
    font-size: 11.5px;
    color: white;
    font-weight: 500;
  }

  .item_row:hover {
    background-color: #2b2e38;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
  }

  .img-item {
    height: 60px !important;
    width: 75px !important;
  }

  .item_row {
    background: #21242c;
    border-radius: 0.2rem;
    margin-block: 0.4rem;
    padding: 1rem 1rem;
    position: relative;
    transition: 0.3s;
    max-height: 100%;
    min-height: 8rem;
  }
}
</style>
