import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/transaction";

export const getAll = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/all", {
            params: {
                sortBy: req.sortBy,
                currentPage: req.currentPage,
                sortOrder: req.sortOrder,
                pageSize: req.pageSize,
                market: req.market
            }
        });

        return response.data;
    } catch (error) {
        return [];
    }
};

export const synchAll = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + "/synch", {
            params: {
                initialDate: req.init,
                finalDate: req.final,
                orderBy: req.order
            }
        });

        return response.data;
    } catch (error) {
        return [];
    }
};


const TransactionService = {
    getAll,
    synchAll
};

export default TransactionService;
