import csv2json from '../../node_modules/csvjson-csv2json/csv2json.js';

export const parseCSVToJSON = (csvData) => {
  const jsonData = csv2json(csvData, { parseNumbers: true, parseJSON: true });
  return jsonData;
};

export const filterSelectedHeaders = (jsonData) => {

  try {
    const selectedHeaders = ['Category', 'Combined QTY', 'Item Name', 'Storage Name', 'Item Wear Name', 'Item Paint Wear'];

    const newJSONData = jsonData.map((item) => {
      const newItem = {};
      for (const header in item) {
        if (selectedHeaders.includes(header)) {
          newItem[header] = item[header];
        }
      }
      return newItem;
    });

    return newJSONData;
  } catch (error) {
    console.error(error)
    return false;
  }


};

export const filterData = (data) => {
  const jsonData = data.filter(x => x['Storage Name'] !== '#Inventory');
  return jsonData;
};

export const csvToJSON = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target.result;
      const jsonData = parseCSVToJSON(csvData);
      resolve(jsonData);
    };

    reader.onerror = (event) => {
      reject(event.target.error);
    };

    reader.readAsText(file);
  });
};