<template>
  <div class="background-small">
    <div class="row">
      <div class="f sb">
        <div>
          <h4 class="text-white">{{ title }}</h4>
        </div>
        <div class="f g10">
          <div class="f flex-column g5">
            <span class="mid_title text-white">{{ titles.gameTitle }}</span>
            <IconDropdown
              :refFunction="setGame"
              :options="gameOptions"
              :selectedItem="selectedGame"
              :selectedIcon="selectedGameIcon"
            />
          </div>
          <div class="f flex-column g5">
            <span class="mid_title text-white">Size</span>
            <SelectorDropdown
              :refFunction="setSize"
              :options="sizeOptions"
              :selectedItem="selectedSize"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="f my-5 trend_div">
      <div
        v-for="(trendItem, objectIndex) in trendObjects"
        :key="objectIndex"
        class="f flex-column sb als"
        style="width: 100%"
      >
        <div class="list_card_div">
          <div class="f sb align-center">
            <div class="f sb align-center g10">
              <h5 class="text-white">{{ trendItem.title }}</h5>
              <VueIcon
                :icon="trendItem.icon"
                width="32px"
                :color="trendItem.color"
              />
            </div>
            <div>
              <div class="tabs">
                <div
                  v-for="(tab, index) in trendItem.interval"
                  :key="index"
                  @click="
                    setTab(
                      tab,
                      trendItem.interval,
                      trendItem.type,
                      trendItem.filters,
                      trendItem.items,
                      objectIndex
                    )
                  "
                  :class="{
                    'tab-custom': true,
                    'active-tab': tab.isSelected,
                  }"
                >
                  <span class="interval">{{ tab.title }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="trendItem.loading" class="f flex-column g10">
            <ShimmerCard v-for="index in 5" :key="index" />
          </div>
          <div v-else class="f flex-column g10">
            <ItemListCard
              v-for="(item, index) in trendItem.items"
              :key="index"
              :item="item"
              :type="trendItem.type"
              @go-link="goLink"
            ></ItemListCard>
          </div>
        </div>
      </div>
    </div>
    <div class="main_wrap" :style="{ gap: isFilterOpen ? '1%' : '5px' }">
      <div
        v-show="!isMobile"
        class="price_filters"
        :class="{ hidden_block: !isFilterOpen }"
      >
        <div class="f sb filter_header w100">
          <h5 class="text-white" style="margin-bottom: 0">
            {{ titles.filter }}
          </h5>
          <button @click="toggleFilter" class="filter_toggle_wrap">
            <VueIcon
              style="cursor: pointer"
              icon="mdi:filter-outline"
              width="24px"
              color="white"
            >
            </VueIcon>
          </button>
        </div>

        <div class="f flex-column g5 w100">
          <h6 class="text-white">{{ titles.prices }}</h6>
          <div class="f sb g5">
            <div class="f flex-column w100">
              <span>{{ fields[0].header }}</span>
              <input
                v-model="filters.minPrice"
                :type="fields[0].inputType"
                :step="fields[0].step"
                :min="fields[0].min"
                :max="fields[0].max"
                class="dark_in"
              />
            </div>
            <div class="f flex-column w100">
              <span>{{ fields[1].header }}</span>
              <input
                v-model="filters.maxPrice"
                :type="fields[0].inputType"
                :step="fields[1].step"
                :min="fields[1].min"
                :max="fields[1].max"
                class="dark_in"
              />
            </div>
          </div>
        </div>
        <div class="f flex-column w100 g5">
          <h6 class="text-white">{{ fields[5].header }}</h6>
          <SelectorDropdown
            :background-color="'#212529'"
            :refFunction="fields[5].setFunction"
            :options="fields[5].options"
            :selectedItem="fields[5].selectedItem"
          />
        </div>
        <div class="f flex-column g5 w100">
          <CollapseList
            @check="setExterior"
            :refFunction="toggleCollapse"
            :is-open="exteriorCollapse"
            :type="'exterior'"
            :title="'Exterior'"
            :options="exteriorList"
          />
        </div>
        <div class="f flex-column g5 w100">
          <CollapseList
            @check="setRarity"
            :refFunction="toggleCollapse"
            :is-open="rarityCollapse"
            :type="'rarity'"
            :title="'Rarity'"
            :options="rarityList"
          />
        </div>
        <div class="f flex-column g5 w100">
          <CollapseList
            @check="setExtra"
            :refFunction="toggleCollapse"
            :is-open="extraCollapse"
            :type="'extra'"
            :title="'Extra'"
            :options="extraList"
          />
        </div>

        <button class="material_btn" @click="resetFilters">
          Reset Filters
        </button>
      </div>
      <div class="content_wrap" :style="{ width: getWidth }">
        <div class="tab">
          <div class="f align-center g10 w100">
            <div
              style="cursor: pointer"
              v-if="
                (!isFilterOpen && !isMobile) || (!mobileFilterOpen && isMobile)
              "
              @click="toggleFilter"
              class="filter_toggle"
            >
              <VueIcon icon="bi:filter" width="24px" color="white"></VueIcon>
            </div>
            <SearchBar class="my-2" @handleSearch="handleSearch"></SearchBar>
          </div>
          <div v-show="!isMobile" class="f g10 align-center">
            <h6 class="text-white">Sort</h6>
            <SortDropdown
              :order-by-function="setOrderBy"
              :options="orderOptions"
              :selectedItem="selectSortBy"
              :selected-dir="selectSortOrder"
              :order-dir-function="setOrderDir"
            />
          </div>
        </div>
        <div v-if="loading" class="f flex-column g10">
          <ShimmerEffect v-for="index in filters.pageSize" :key="index" />
        </div>
        <div
          v-if="!loading && items && items.length > 0"
          class="f flex-column g5"
        >
          <div v-show="!isMobile" class="table_header">
            <div
              v-for="(item, index) in columns"
              :key="index"
              class="table_header_item"
            >
              {{ item }}
            </div>
          </div>
          <component
            v-for="(item, index) in items"
            :key="index"
            :is="isMobile ? 'MobileCard' : 'DesktopCard'"
            @copyText="copyText"
            @addWatchlist="addToWatchlist"
            @go-item="goLink"
            @externalLink="goExternal"
            :item="item"
          />
        </div>
        <div class="page_row pt-4 pb-4 f align-center">
          <PaginationRow
            :pageNumber="filters.currentPage"
            :totalPages="totalPages"
            @update:pageNumber="filters.currentPage = $event"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="mobileFilterOpen" class="bottom_modal">
    <div class="f sb align-center">
      <h6>Filters</h6>
      <div class="close_btn" @click="toggleFilter">&times;</div>
    </div>
    <div class="row align-center my-3">
      <div>
        <h6 class="text-white">Sort</h6>
        <SortDropdown
          :order-by-function="setOrderBy"
          :options="orderOptions"
          :selectedItem="selectSortBy"
          :selected-dir="selectSortOrder"
          :order-dir-function="setOrderDir"
        />
      </div>
    </div>

    <div class="f flex-column g10">
      <div class="f flex-column g5 w100">
        <div class="f sb g5">
          <div class="f flex-column w100">
            <h6>Price From</h6>
            <input
              v-model="filters.minPrice"
              :type="fields[0].inputType"
              :step="fields[0].step"
              :min="fields[0].min"
              :max="fields[0].max"
              class="dark_in"
            />
          </div>
          <div class="f flex-column w100">
            <h6>Price To</h6>
            <input
              v-model="filters.maxPrice"
              :type="fields[0].inputType"
              :step="fields[1].step"
              :min="fields[1].min"
              :max="fields[1].max"
              class="dark_in"
            />
          </div>
        </div>
      </div>

      <div class="f flex-column w100 g5">
        <h6 class="text-white">{{ fields[5].header }}</h6>
        <SelectorDropdown
          :background-color="'#212529'"
          :refFunction="fields[5].setFunction"
          :options="fields[5].options"
          :selectedItem="fields[5].selectedItem"
        />
      </div>
      <div class="f flex-column g5 w100">
        <CollapseList
          @check="setExterior"
          :refFunction="toggleCollapse"
          :is-open="exteriorCollapse"
          :type="'exterior'"
          :title="'Exterior'"
          :options="exteriorList"
        />
      </div>
      <div class="f flex-column g5 w100">
        <CollapseList
          @check="setRarity"
          :refFunction="toggleCollapse"
          :is-open="rarityCollapse"
          :type="'rarity'"
          :title="'Rarity'"
          :options="rarityList"
        />
      </div>
      <div class="f flex-column g5 w100">
        <CollapseList
          @check="setExtra"
          :refFunction="toggleCollapse"
          :is-open="extraCollapse"
          :type="'extra'"
          :title="'Extra'"
          :options="extraList"
        />
      </div>

      <button class="material_btn" @click="resetFilters">Reset Filters</button>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="copyMessage"
    @close="showModal = false"
  />
  <ItemModal v-if="selectedItem" :item="selectedItem" @close="closeItemModal" />
</template>

<script>
import WatchlistService from "../services/WatchlistService.js";
import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";
import ShimmerCard from "@/components/skeleton/ShimmerCardSmall.vue";

import SearchBar from "@/components/shared/SearchBar.vue";
import InfoModal from "../components/modal/InfoModal.vue";
import ItemModal from "../components/modal/ItemModal.vue";
import ItemService from "../services/ItemService.js";
import upIcon from "../assets/images/asc.png";
import downIcon from "../assets/images/desc.png";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import CollapseList from "@/components/dropdownButtons/CollapseList.vue";
import MobileCard from "@/components/trend/TrendCardMobile.vue";
import DesktopCard from "@/components/trend/TrendCard.vue";
import IconDropdown from "@/components/dropdownButtons/IconDropdown.vue";
import csgo from "../assets/images/csgo.png";
import dota from "../assets/images/dota2.png";
import tf2 from "../assets/images/tf2.png";
import rust from "../assets/images/rust.png";

import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import DateUtils from "@/utils/DateUtils";
import ItemListCard from "@/components/trend/ItemListCard.vue";

import MarketOptions from "@/core/constants.js";

export default {
  data() {
    return {
      MarketOptions,
      totalPages: 6,
      isRotated: false,
      title: "Price Trends",
      sizeOptions: ["10", "15", "25", "30"],
      titles: {
        prices: "Price",
        filter: "Filters",
        gameTitle: "Game",
        priceTitle: "Price",
        type: "Type",
        exteriorTitle: "Exterior",
        volumeTitle: "Volume",
        minPrice: "Min Price",
        maxPrice: "Max Price",
      },
      filters: {
        appID: 730,
        minPrice: 0,
        maxPrice: 20000000,
        type: "All",
        exterior: [],
        rarity: [],
        extra: [],
        pageSize: 10,
        currentPage: 1,
        sortBy: "price",
        sortOrder: "DESC",
      },
      rarityList: [
        { label: "Extraordinary", checked: false, color: "#EB4B4B" },
        { label: "Master", checked: false, color: "#EA4C4B" },
        { label: "Covert", checked: false, color: "#EA4C4B" },
        { label: "Base Grade", checked: false, color: "#B0C2DA" },
        { label: "Consumer Grade", checked: false, color: "#B0C2DA" },
        { label: "Contraband", checked: false, color: "#E4AE3A" },
        { label: "Exotic", checked: false, color: "#D32DE5" },
        { label: "Superior", checked: false, color: "#D32DE5" },
        { label: "Classified", checked: false, color: "#D32DE5" },
        { label: "Remarkable", checked: false, color: "#8846FF" },
        { label: "Exceptional", checked: false, color: "#8846FF" },
        { label: "Restricted", checked: false, color: "#8846FF" },
        { label: "High Grade", checked: false, color: "#4A69FE" },
        { label: "Distinguished", checked: false, color: "#4A69FE" },
        { label: "Mil-Spec Grade", checked: false, color: "#4A69FE" },
        { label: "Industrial Grade", checked: false, color: "#5D98D9" },
      ],
      extraList: [
        { label: "StatTrak™", checked: false, color: "#F79407" },
        { label: "Without StatTrak™", checked: false, color: "#F79407" },
        { label: "Souvenir", checked: false, color: "rgb(255, 214, 1)" },
        {
          label: "Without Souvenir",
          checked: false,
          color: "rgb(255, 214, 1)",
        },
      ],
      exteriorList: [
        { label: "Factory New", checked: false },
        { label: "Minimal Wear", checked: false },
        { label: "Field-Tested", checked: false },
        { label: "Well-Worn", checked: false },
        { label: "Battle-Scarred", checked: false },
        { label: "Not Painted", checked: false },
      ],
      columns: ["Image", "Name", "7D AVG.", "14D AVG.", "30D AVG.", "Graph"],
      typeOptions: [
        "All",
        "Agent",
        "Collectible",
        "Collection",
        "Container",
        "Equipment",
        "Gift",
        "Glove",
        "Graffiti",
        "Key",
        "Kit",
        "Knife",
        "Machine Gun",
        "Music Kit",
        "Music Kit Box",
        "Patch",
        "Rifle",
        "Shotgun",
        "Knife",
        "Sticker",
        "Pistol",
        "SMG",
        "Tool",
        "Tag",
        "Souvenir",
      ],
      exteriorOptions: [
        "All",
        "Factory-New",
        "Minimal-Wear",
        "Field-Tested",
        "Well-Worn",
        "Battle-Scarred",
      ],
      fields: [],
      items: [],
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        { name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf2, appID: 440 },
        { name: "Rust", icon: rust, appID: 252490 },
      ],
      tabs: [
        { title: "7D", values: [], padding: "0px" },
        { title: "14D", values: [], padding: "0px" },
        { title: "1M", values: [], padding: "0px" },
      ],
      orderOptions: ["Price", "Name"],
      isPageRefreshed: true,
      isFilterOpen: true,
      rarityCollapse: false,
      extraCollapse: false,
      exteriorCollapse: false,
      loading: true,
      trendsLoading: true,
      isDropdownOpen: false,
      selectedItem: null,
      showModal: false,
      copyMessage: "Item name copied successfully",
      searchTimeout: null,
      searchTerm: "",
      selectedSize: "10",
      selectedTargetMarket: "MarketCSGO",
      selectedGame: "CS2",
      selectedType: "All",
      selectedExterior: "All",
      selectedExteriorCode: "All",
      sortOrder: "DESC",
      selectedSourceMarket: "Buff",
      selectSortBy: "Price",
      selectSortOrder: "DESC",
      orderIcon: downIcon,
      selectedGameIcon: csgo,
      isMobile: false,
      contentWrapWidth: "85%",
      mobileFilterOpen: false,
      appID: 730,
      pageNumbers: [1, 2, 3, 4, 5, 6],
      popularityInterval: 1,
      trendObjects: [
        {
          title: "Rising",
          type: "increase",
          items: [],
          loading: true,
          filters: {
            appID: 730,
            minPrice: 0,
            maxPrice: 20000000,
            pageSize: 5,
            currentPage: 1,
            type: "increase",
            sortBy: "price",
            sortOrder: "DESC",
            interval: 1,
          },
          interval: [
            {
              title: "1D",
              number: 1,
              isSelected: true,
            },
            {
              title: "7D",
              number: 7,
              isSelected: false,
            },
            {
              title: "1M",
              number: 30,
              isSelected: false,
            },
          ],
          icon: "ant-design:rise-outlined",
          color: "#7cfc00",
        },
        {
          title: "Falling",
          type: "fall",
          items: [],
          loading: true,
          filters: {
            appID: 730,
            minPrice: 0,
            maxPrice: 20000000,
            pageSize: 5,
            currentPage: 1,
            interval: 1,
            type: "fall",
            sortBy: "price",
            sortOrder: "ASC",
          },
          interval: [
            {
              title: "1D",
              number: 1,
              isSelected: true,
            },
            {
              title: "7D",
              number: 7,
              isSelected: false,
            },
            {
              title: "1M",
              number: 30,
              isSelected: false,
            },
          ],
          icon: "ant-design:fall-outlined",
          color: "#ff0f0f",
        },
        {
          title: "Popular",
          type: "popular",
          items: [],
          loading: true,
          filters: {
            appID: 730,
            minPrice: 0,
            maxPrice: 20000000,
            pageSize: 5,
            currentPage: 1,
            interval: 1,
            type: "popular",
            sortBy: "volume",
            sortOrder: "DESC",
          },
          interval: [
            {
              title: "1D",
              number: 1,
              isSelected: true,
            },
            {
              title: "7D",
              number: 7,
              isSelected: false,
            },
            {
              title: "1M",
              number: 30,
              isSelected: false,
            },
          ],
          icon: "mdi:flame",
          color: "#F75904",
        },
      ],
    };
  },
  methods: {
    async setTab(tab, intervals, type, filters, items, index) {
      if (!tab) return items;
      intervals.forEach((e) => (e.isSelected = false));
      tab.isSelected = true;

      this.trendObjects[index].loading = true;
      if (type == "popular") {
        filters.interval = tab.number;
        items = await this.getPopularity(filters);
      }
      if (type == "increase" || type == "fall") {
        filters.interval = tab.number;
        items = await this.priceItemTrends(filters);
      }
      this.trendObjects[index].loading = false;
    },
    async addToWatchlist(name) {
      const response = await WatchlistService.addWatchlist({
        appid: 730,
        market_hash_name: name,
      });

      if (response) this.showInfoModal("Item Added to your watchlist", 5000);
      else this.showInfoModal("Item already in your watchlist.", 5000);
    },
    async showInfoModal(msg, interval) {
      this.showModal = true;
      this.copyMessage = msg;
      setTimeout(() => {
        this.showModal = false;
      }, interval || 5000);
    },
    async setOrderDir(isAscOrder) {
      this.filters.sortOrder = this.selectSortOrder = isAscOrder
        ? "ASC"
        : "DESC";
    },
    async setOrderBy(option) {
      const sort = option.toLowerCase();
      this.filters.sortBy = sort;
      this.selectSortBy = option;
      console.log(sort, this.selectSortBy, this.filters.sortBy);
    },
    toggleCollapse(collapse) {
      if (collapse === "rarity")
        return (this.rarityCollapse = !this.rarityCollapse);
      if (collapse === "extra")
        return (this.extraCollapse = !this.extraCollapse);
      if (collapse === "exterior")
        return (this.exteriorCollapse = !this.exteriorCollapse);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    showItemModal(item) {
      this.selectedItem = item;
    },
    closeItemModal() {
      this.selectedItem = null;
    },
    copyText(item) {
      const textToCopy = item;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.copyMessage = "Item name copied successfully";
          this.showModal = true;

          setTimeout(() => {
            this.showModal = false;
          }, 2500);
        })
        .catch((error) => {
          this.copyMessage = "Error copying text" + error;
        });
    },
    async handleSearch(search) {
      clearTimeout(this.searchTimeout);
      this.searchTerm = search;
      this.searchTimeout = setTimeout(() => {
        this.getItemsByQuery();
      }, 1000);
    },
    setOrder() {
      this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
      this.orderIcon = this.sortOrder === "DESC" ? downIcon : upIcon;
    },
    async getItemsByQuery() {
      this.loading = true;
      this.items = await ItemService.getPriceTrends({
        game: this.filters.appID,
        currentPage: this.filters.currentPage,
        pageSize: this.filters.pageSize,
        sortBy: this.filters.sortBy,
        sortOrder: this.filters.sortOrder,
        searchQuery: this.searchTerm,
        priceFrom: this.filters.minPrice * 1000,
        priceTo: this.filters.maxPrice * 1000,
        minROI: this.filters.minRoi,
        maxROI: this.filters.maxRoi,
        quantity: this.filters.qty,
        volume: parseInt(this.filters.volume),
        type: this.filters.type,
        exterior: this.filters.exterior,
        rarity: this.filters.rarity,
        extra: this.filters.extra,
        source: this.filters.sourceMarkets,
        target: this.filters.targetMarkets,
      });
      this.mapPrices();
      this.loading = false;
    },
    async getPopularity(filters) {
      const res = await ItemService.getPopularItems({
        game: filters.appID,
        currentPage: filters.currentPage,
        pageSize: filters.pageSize,
        sortBy: filters.sortBy,
        sortOrder: filters.sortOrder,
        interval: filters.interval,
        type: filters.type,
      });

      if (!res) return [];
      return res;
    },
    async priceItemTrends(filters) {
      const res = await ItemService.getItemTrends({
        game: filters.appID,
        currentPage: filters.currentPage,
        pageSize: filters.pageSize,
        sortBy: filters.sortBy,
        sortOrder: filters.sortOrder,
        interval: filters.interval,
        type: filters.type,
      });

      if (!res) return [];
      return res;
    },
    mapPrices() {
      if (!this.items && this.items.length == 0) return;

      this.items.forEach((e) => {
        e.change7D = { value: 0, change: 0 };
        e.change14D = { value: 0, change: 0 };
        e.change30D = { value: 0, change: 0 };

        if (!e.item_detail.prices || e.item_detail.prices.length == 0) return;
        const prices = e.item_detail.prices;
        const currentPrice = prices[0].price;

        const theFirst = Math.floor(prices.length / 3); // index 2
        const firstValue = prices[theFirst];
        const ratio7D = this.calculateChange(currentPrice, firstValue.price);
        e.change7D = {
          value: ((currentPrice - firstValue.price) / 1000).toFixed(2),
          change: ratio7D,
        };

        //7D change
        const theMiddle = Math.floor(prices.length / 2); // index 2
        const middleValue = prices[theMiddle];
        const ratio14D = this.calculateChange(currentPrice, middleValue.price);
        e.change14D = {
          value: ((currentPrice - middleValue.price) / 1000).toFixed(2),
          change: ratio14D,
        };

        //30D change
        const lastElement = prices[prices.length - 1];
        const ratio30D = this.calculateChange(currentPrice, lastElement.price);
        e.change30D = {
          value: ((currentPrice - lastElement.price) / 1000).toFixed(2),
          change: ratio30D,
        };

        e.item_detail.prices = e.item_detail.prices.map((el) => {
          return {
            value: el.price / 1000,
            date: DateUtils.getOnlyDate(el.update_date),
          };
        });
      });
    },
    calculateChange(current, before) {
      return (((current - before) / before) * 100).toFixed(2);
    },
    async setType(type) {
      this.filters.type = type;
      this.fields[5].selectedItem = type;
      this.isDropdownOpen = false;
    },
    async setVolume(volume) {
      this.filters.volume = volume;
      this.fields[5].selectedItem = volume;
      this.isDropdownOpen = false;
    },
    async setSize(size) {
      this.selectedSize = size;
      this.filters.pageSize = size;
      this.isDropdownOpen = false;
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.filters.appID = type.appID;
      this.trendObjects[0].filters.appID = type.appID;
      this.trendObjects[1].filters.appID = type.appID;
      this.trendObjects[2].filters.appID = type.appID;
      this.getTrendings();
      this.isDropdownOpen = false;
    },
    async setExterior(exterior) {
      const findExt = this.exteriorList.find((e) => e.label === exterior);
      findExt.checked = !findExt.checked;

      this.filters.exterior = this.exteriorList
        .filter((e) => e.checked)
        .map((e) => e.label);
      this.isDropdownOpen = false;
    },
    async setRarity(name) {
      const findRarity = this.rarityList.find((e) => e.label === name);
      findRarity.checked = !findRarity.checked;

      this.filters.rarity = this.rarityList
        .filter((e) => e.checked)
        .map((e) => e.label);
    },
    async setExtra(name) {
      const finExtra = this.extraList.find((e) => e.label === name);
      finExtra.checked = !finExtra.checked;

      this.filters.extra = this.extraList
        .filter((e) => e.checked)
        .map((e) => e.label);
    },
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    goExternal(link) {
      window.open(link, "_blank");
    },
    toggleFilter() {
      if (!this.isMobile) return (this.isFilterOpen = !this.isFilterOpen);

      const htmlElement = document.querySelector("html");
      if (this.isMobile && this.mobileFilterOpen) {
        htmlElement.style.overflowY = "auto";
      }
      if (this.isMobile && !this.mobileFilterOpen) {
        htmlElement.style.overflowY = "hidden";
      }
      this.mobileFilterOpen = !this.mobileFilterOpen;
    },
    updateLocalFilters() {
      localStorage.setItem("comparisonFilters", JSON.stringify(this.filters));
    },
    mapFilters() {
      if (this.filters.rarity.length > 0) {
        for (let index = 0; index < this.filters.rarity.length; index++) {
          const filter = this.filters.rarity[index];

          for (let index = 0; index < this.rarityList.length; index++) {
            const element = this.rarityList[index];
            if (filter === element.label) element.checked = true;
          }
        }
      }

      if (this.filters.exterior.length > 0) {
        for (let index = 0; index < this.filters.exterior.length; index++) {
          const filter = this.filters.exterior[index];

          for (let index = 0; index < this.exteriorList.length; index++) {
            const element = this.exteriorList[index];
            if (filter === element.label) element.checked = true;
          }
        }
      }

      if (this.filters.type) {
        this.fields[6].selectedItem = this.filters.type;
      }
    },
    setFilters() {
      this.fields = [
        {
          title: this.titles.minPrice,
          header: "From",
          value: this.filters.minPrice,
          type: "number",
          inputType: "number",
          step: 0.01,
          min: 0,
          max: 1000000,
        },
        {
          title: this.titles.maxPrice,
          header: "To",
          value: this.filters.maxPrice,
          type: "number",
          inputType: "number",
          step: 0.01,
          min: 0,
          max: 1000000,
        },
        {
          title: this.titles.minROI,
          header: "Minimum",
          value: this.filters.minRoi,
          type: "number",
          inputType: "number",
          step: 0.01,
          min: 0,
          max: 10000,
        },
        {
          title: this.titles.maxROI,
          header: "Maximum",
          value: this.filters.maxRoi,
          type: "number",
          inputType: "number",
          step: 0.01,
          min: 0,
          max: 10000,
        },
        {
          title: this.titles.qty,
          header: "",
          value: this.filters.qty,
          type: "number",
          inputType: "number",
          step: 0.01,
          min: 0,
          max: 1000,
        },
        {
          title: this.titles.type,
          header: "Type",
          options: this.typeOptions,
          selectedItem: this.selectedType,
          setFunction: this.setType,
        },
        {
          title: this.titles.exteriorTitle,
          header: "Exterior",
          options: this.exteriorOptions,
          setFunction: this.setExterior,
        },
      ];
    },
    resetFilters() {
      this.filters = {
        appID: 730,
        minPrice: 0,
        maxPrice: 20000,
        minRoi: 0,
        maxRoi: 10000,
        qty: 0,
        volume: "0",
        type: "All",
        exterior: [],
        rarity: [],
        extra: [],
        sourceMarkets: ["buff"],
        targetMarkets: ["haloskins"],
        pageSize: 10,
        currentPage: 1,
        sortBy: "profitRatio",
        sortOrder: "DESC",
      };
    },
    getFilters() {
      const filters = JSON.parse(
        localStorage.getItem("comparisonFilters") || null
      );
      if (!filters) return;
      this.filters = filters;
      this.mapFilters();
    },
    checkMobile() {
      const screenWidth = window.innerWidth;
      const mobileWidthThreshold = 576;
      this.isMobile = screenWidth < mobileWidthThreshold;
    },
    async getTrendings() {
      this.trendsLoading = true;

      await Promise.all([
        (this.trendObjects[0].items = await this.priceItemTrends(
          this.trendObjects[0].filters
        )),
        (this.trendObjects[1].items = await this.priceItemTrends(
          this.trendObjects[1].filters
        )),
        (this.trendObjects[2].items = await this.getPopularity(
          this.trendObjects[2].filters
        )),
      ]);

      this.trendsLoading = false;
      this.trendObjects[0].loading = false;
      this.trendObjects[1].loading = false;
      this.trendObjects[2].loading = false;
    },
  },
  async created() {
    this.isPageRefreshed = true;
    this.setFilters();
    this.getFilters();
    this.isPageRefreshed = false;
    this.getTrendings();
  },
  computed: {
    getWidth() {
      if (!this.isMobile && !this.isFilterOpen) return "100%";
      if (!this.isMobile && this.isFilterOpen) return "85%";
      if (this.isMobile) return "100%";
      return "100%";
    },
    DateUtils() {
      return DateUtils;
    },
  },
  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  watch: {
    filters: {
      async handler() {
        this.updateLocalFilters();

        if (!this.isPageRefreshed) {
          clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.getItemsByQuery();
          }, 1000);
        }
      },
      deep: true,

      immediate: true,
    },
  },
  components: {
    SortDropdown,
    InfoModal,
    MobileCard,
    CollapseList,
    ItemModal,
    SelectorDropdown,
    SearchBar,
    IconDropdown,
    DesktopCard,
    ItemListCard,
    ShimmerEffect,
    ShimmerCard,
  },
};
</script>

<style scoped>
.trend_div {
  display: flex;
  justify-content: space-between;
  min-height: 60vh;
  gap: 3%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.tab-custom {
  border: none;
  padding: 0px;
  text-align: center;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 35px;
  border: 0.5px solid transparent;
  border: 1px solid #934be6;
  border-radius: 2px;
  font-size: 12px;
}

.tab-custom:hover {
  background-color: #934be6;
  transition: 0.3s;
}

.tab-custom.active-tab {
  background-color: #934be6;
}

.interval {
  font-size: 12px;
  font-weight: 400;
}

.table_header {
  display: grid;
  grid-template-columns: 1.2fr 2fr 1fr 1fr 1fr 2fr;
  background-color: #313156;
  color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 0.9rem 0.8rem;
  gap: 20px;
}

.list_card_div {
  display: flex;
  flex-direction: column;
  background: #313156;
  color: white;
  border-radius: 0.25rem;
  padding: 1.2rem 1.6rem;
  min-height: 250px;
  gap: 0.6rem;
  min-height: 600px;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.bottom_modal {
  height: 77vh;
  background-color: #11101d;
  position: fixed;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  top: auto;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.bottom_modal::-webkit-scrollbar {
  display: none;
}

.filter_toggle_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  width: 40px;
  height: 35px;
  border-radius: 6px;
  background-color: transparent;
}

.filter_toggle_wrap:hover {
  border: 1px solid rgb(134, 80, 172);
  background-color: rgb(134, 80, 172);
}

.filter_toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: auto;
  height: 40px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgb(134, 80, 172);
}

.filter_toggle:hover {
  background-color: rgb(134, 80, 172);
  transition: 0.3s;
}

.material_btn {
  width: 100%;
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 3px;
  padding: 0 15px;
  border-radius: 4px;
  background: transparent;
  line-height: 1.15;
  font-size: 14px;
  height: 36px;
  word-spacing: 0px;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  min-width: 64px;
  border: 1.5px solid white;
  text-align: center;
  transition: background 280ms cubic-bezier(0.4, 0, 0.2, 1);
  color: white;
}

.material_btn:hover {
  transition: 0.3s;
  border-color: rgb(134, 80, 172);
}

.close_btn {
  font-size: 40px;
  cursor: pointer;
  color: red;
  transition: 0.4s;
}

.main_wrap {
  display: flex;
  justify-content: center;
  width: calc(100% - 0.3rem);
}

.content_wrap {
  width: 85%;
  transition: 0.3s;
}

.price_filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 70vh;
  max-width: 1560px;
  gap: 18px;
  background-color: #313156;
  padding: 1rem 0.8rem;
  border-radius: 4px;
  width: 15%;
  transition: 0.3s;
}

.hidden_block {
  margin-left: -15%;
  visibility: hidden;
  display: none;
  transition: 0.3s;
}

.filter_header {
  width: 100%;
  border-bottom: 1px solid rgb(134, 80, 172);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.tab {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #313156;
  overflow: hidden;
  border-radius: 4px;
  color: white;
  margin-bottom: 0.5rem;
  margin-top: 0px;
  border-bottom: none;
}

.mid_title {
  font-size: bold;
  font-size: 13px;
}

input {
  width: 100%;
}

.dark_in {
  padding: 6px 12px;
  background: #212529;
  border: 1px solid rgb(60, 63, 68);
  border-radius: 6px;
  font-size: 14px;
  color: rgb(247, 248, 248);
  height: 35px;
  appearance: none;
  transition: border 0.15s ease 0s;
}

.dark_in:focus {
  outline: none;
  box-shadow: none;
  border-color: #8650ac;
  transition: 0.3s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.icon-select-menu {
  width: 180px !important;
}

.selector-menu .select-btn {
  height: 30px !important;
  padding: 5px !important;
}

.selector-menu .select-btn {
  height: 35px;
  background-color: red !important;
}

h5 {
  margin-bottom: 0;
}

@media screen and (max-width: 1070px) {
  .content_wrap {
    width: 100%;
  }

  .trend_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 70vh;
    gap: 20px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .list_card_div {
    display: flex;
    flex-direction: column;
    background: #313156;
    color: white;
    border-radius: 0.25rem;
    padding: 0.6rem 0.8rem;
    min-height: 250px;
    gap: 0.6rem;
    height: 100%;
    width: 100%;
  }

  .px-4 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
}

@media screen and (max-width: 576px) {
  h5 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
  }

  .icon-select-menu {
    width: 145px !important;
  }

  .selector-menu .select-btn {
    height: 30px !important;
    padding: 5px !important;
  }

  .content_wrap {
    width: 100%;
    transition: 0.3s;
  }
}
</style>
