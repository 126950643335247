<template>
    <div class="history_bar">
        <div>
            <h5 style="margin-bottom: 10px;">{{ header }}</h5>
        </div>
        <div class="interval_wrap">
            <div @click="setTab(item)" v-for="(item, index) in interval" :key="index"
                :class="{ 'interval_info': true, 'active': item.code === selectedTab }">
                <span> {{ item.name }}</span>
            </div>
        </div>
        <div style="height: 100%;">
            <LineChart :data="chartData" :labelName="'Price'" :chartOption="options ?? desktopOptions"></LineChart>
        </div>
    </div>
</template>

<script>
import LineChart from '../charts/LineChart.vue';

export default {
    components: { LineChart },
    data() {
        return {
            green: '#4CDF99',
            red: '#ff0f0f',
            selectedTab: '7_d',
            desktopOptions: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: 'white', beginAtZero: true,
                            maxRotation: 45,
                            minRotation: 45,
                            padding: 10
                        }
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        ticks: { color: 'white', beginAtZero: true, padding: 10 },
                    },

                },
                plugins: {
                    legend: {
                        labels: {
                            color: 'white',
                            fontSize: 20
                        },
                    }
                }
            },
        }
    },
    methods: {
        setTab(item) {
            this.selectedTab = item.code;
            this.$emit('selectInterval', item.value)
        }
    },
    props: {
        header: {
            type: String
        },
        interval: {
            type: Array,
            required: true
        },
        chartData: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            required: false
        }
    },
};
</script>

<style scoped>

h5,
h6 {
    color: white;
    margin-bottom: 0;
}

.history_bar {
    width: 100%;
    height: 100%;
    background-color: #313155;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 3px;
}

.interval_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    border: 1px solid #954CE9;
    border-radius: 3px;
    min-height: 40px;
    max-width: 300px;
}

.interval_info {
    width: 100%;
    height: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.active {
    background-color: #954CE9;
    transition: 0.4s;
}

@media screen and (max-width: 769px) {

    .interval_info {
        min-height: 25px;
    }

    .history_bar {
    width: 100%;
    height: 100%;
    background-color: #313155;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 3px;
}

}
</style>
