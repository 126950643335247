<template>
    <div class="catalog-card">
        <div class="catalog-card__img">
            <img class="img" draggable="false" :src="item.image">
        </div>
        <div class="f flex-column flex-start">
            <h3 class="text-white">{{ item.name }}</h3>
            <p>{{ item.name }} contains {{ item.count }} different items.</p>
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    methods: {

    },
    created() {
    }
};
</script>
  
<style scoped>
p {
    margin-bottom: 0;
}

.flex-start {
    justify-content: flex-start;
}


img {
    min-width: 100%;
    max-width: 100%;
    height: auto;   
}

.catalog-card {
    
    color: white;
    border-radius: 4px;
    width: 95%;
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 1rem 1rem;
}


a {
    text-decoration: none;
    cursor: pointer;
}

.catalog-card__img {
    display: block;
    position: relative;
    width: 160px
}



@media screen and (max-width: 950px) {
    .catalog-card {
        width: 90%;
        flex-direction: column;
    }
}

@media screen and (max-width: 576px) {

    h3 {
        font-size: 18px;
        margin-bottom: 6px;
        font-weight: bold;
    }

    p {
        font-size: 16px;
    }

    .catalog-card {
        padding: 0.5rem 1rem;
         gap: 10px;
    }
}
</style>
  