import PaymentService from "@/services/PaymentService.js";

const paymentsModule = {
  namespaced: true,
    state: () => ({
      subscriptionPlans: [],
    }),
    mutations: {
      setSubsPlans(state, subscriptionPlans) {
        state.subscriptionPlans = subscriptionPlans;
      }
    },
    actions: {
      async fetchSubscriptionPlans(context, object) {
        try {
          const data = await PaymentService.getSubscriptionPlans({ periode: object.periode});
          context.commit('setSubsPlans', data);
        } catch (error) {
          console.log('error');
          context.commit('setSubsPlans', []);
        }
      },
    },
    getters: {
      getSubscriptionPlans: (state) => state.subscriptionPlans,
    }
  };
  
  export default paymentsModule;
  