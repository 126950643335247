<template>
    <div :class="{ overlay: modalOpen }">
        <div class="modal-a" :class="{ active: modalOpen }">
            <div v-if="!loading" class="modal-content-a">
                <div class="f sb py-1">
                    <div class="f align-center g10">
                        <h5 class="white-text">Sell {{ items.length }} Items <br /></h5>
                        <div class="f mx-4 align-center">
                            <h6>Group Items</h6>
                            <div class="custom-switch mx-2">
                                <input type="checkbox" id="switch1" v-model="isGrouped" />
                                <label for="switch1"></label>
                            </div>
                        </div>
                    </div>
                    <span class="close" @click="closeModalTop('close')">&times;</span>
                </div>
                <div class="custom-checkboxes align-center">
                    <span class="gray py-1">Markets:</span>
                    <label v-for="item in checkboxes" :key="item.id" class="custom-checkbox align-center">
                        <img class="img-small" :src="item.icon" />
                        <input type="checkbox" v-model="item.isChecked" />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <hr />
                <div v-if="isGrouped" class="item-container">
                    <div v-for="item in groupedStack" :key="item.item_id">
                        <div class="f align-center sb g10">
                            <div class="f align-bottom">
                                <div class="f align-center qty">
                                    <span class="mid-span">x{{ item.quantity }}</span>
                                </div>
                                <a @click="goLink(item.items[0])" target="_blank"
                                    rel="noopener noreferrer">
                                    <img class="item-img" :src="item.items[0].image" alt="null">
                                </a>
                            </div>
                            <div>
                                <span class="responsive-body">{{ item.items[0].name }}</span>
                                <div class="f align-bottom steam-price py-2">
                                    <VueIcon height="20px" color="white" icon="mdi:steam" />
                                    <span class="mx-1">${{ item.items[0].referance_price / 1000 }}</span>
                                </div>
                            </div>
                            <button @click="removeItem(item)" class="button btn-secondary square">
                                <img class="sml" src="../../assets/images/trash.png">
                            </button>
                        </div>
                        <div class="f align-bottom mt-3">
                            <div class="f align-bottom g20">
                                <span>{{ sellPriceTitle }}</span>
                                <input v-model="item.items[0].sell_price" class="dark-input-div mx-2" type="number"
                                    name="">
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>

                <div class="item-container" v-else>
                    <div v-for="item in itemArray" :key="item.item_id">
                        <div>
                            <div class="f align-center sb g10">
                                <div class="f align-bottom">
                                    <a @click="goLink(item)" target="_blank" rel="noopener noreferrer">
                                        <img class="item-img" :src="item.image" alt="null">
                                    </a>
                                </div>
                                <div>
                                    <span class="responsive-body">{{ item.name }}</span>
                                    <div class="f align-bottom steam-price align-center py-2">
                                        <VueIcon height="20px" color="white" icon="mdi:steam" />
                                        <span class="mx-1">${{ item.referance_price / 1000 }}</span>
                                    </div>
                                </div>
                                <button @click="removeItem(item)" class="button btn-secondary square">
                                    <img class="sml" src="../../assets/images/trash.png">
                                </button>
                            </div>
                        </div>
                        <div class="f align-bottom mt-2">
                            <div class="f align-bottom g20">
                                <span>{{ sellPriceTitle }}</span>
                                <input v-model="item.sell_price" class="dark-input-div" type="number" name="">
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <div class="mt-1">
                    <button @click="putOnSale" class="button black-button custom">
                        {{ this.buttonSet }}
                    </button>
                </div>
            </div>
            <div v-if="loading" class="lottie-area">
                <h6 class="center">{{ listTitle }}</h6>
                <h6 class="center">{{ wait }}</h6>
                <Vue3Lottie :animationData="playIcon" :height="150" :width="350" :speed="1.3" :scale="1" />
            </div>
        </div>
    </div>
</template>

<script>
import SaleService from '@/services/SaleService.js';
import waxIcon from '../../assets/marketIcons/waxpeer_icon.png';
import csgotmIcon from '../../assets/marketIcons/csgotm_icon.png';
import shadowIcon from '../../assets/marketIcons/shadowpay_icon.png';
import buffIcon from '../../assets/marketIcons/buff_icon.png';
import thunderIcon from '../../assets/marketIcons/skinthunder.png';
import haloIcon from '../../assets/marketIcons/haloskins_icon.png';
import csfloat from '../../assets/marketIcons/csgofloat_icon.png';

import playJSON from "../../assets/lottieFiles/multi-progress.json";

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        groupedStack: {
            type: Array,
            required: true
        },
        modalOpen: {
            type: Boolean,
            required: true
        },
        appID: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            listTitle: 'Items Are Being Listed On Markets',
            wait: 'Please Wait',
            buttonSet: 'Sell Selected Skins',
            sellPriceTitle: 'Sell Price',
            afterFee: 'After Fee',
            isGrouped: false,
            afterMarket: 0,
            afterShadow: 0,
            loading: false,
            markets: [],
            test: false,
            playIcon: playJSON,
            checkboxes: [
                { id: 0, name: 'MarketCSGO', isChecked: false, icon: csgotmIcon },
                { id: 1, name: 'Waxpeer', isChecked: false, icon: waxIcon },
                { id: 2, name: 'Shadowpay', isChecked: false, icon: shadowIcon },
                { id: 3, name: 'CSFloat', isChecked: false, icon: csfloat },
                { id: 4, name: 'Skinthunder', isChecked: false, icon: thunderIcon },
                //{ id: 3, name: 'HaloSkins', isChecked: true, icon: haloIcon },
            ],
            marketIcons: {
                waxpeer: waxIcon,
                csgotm: csgotmIcon,
                shadowpay: shadowIcon,
                csfloat: csfloat,
                buff: buffIcon,
                haloksins: haloIcon
            },
            lottieWidth: 70,

        }
    },
    methods: {
        async closeModalTop(response) {
            this.$emit('updateItems');
            this.$emit('show-message', response);
            this.$emit('closeModal');
        },
        removeItem(item) {
            this.$emit('item-remove', item.item_id);
            this.closeModalTop('remove');
        },
        async putOnSale() {
            this.loading = true;
            let response = false;
            this.clearMarkets();
            this.setMarkets();

            if (this.isGrouped) {
                const convertedItems = await this.convertStackedItems();
                const sellItems = convertedItems.map(item => ({ general_id: item.general_id, id: item.ID, item_id: item.item_id, token: item.haloskins_token, price: item.sell_price * 1000 }));
                response = await SaleService.sellMultipleItems({ items: sellItems, markets: this.markets, appid: this.appID });
            } else {
                const sellItems = this.items.map(item => ({ general_id: item.general_id, id: item.ID, item_id: item.item_id, token: item.haloskins_token, price: item.sell_price * 1000 }));
                response = await SaleService.sellMultipleItems({ items: sellItems, markets: this.markets, appid: this.appID });
            }

            if (response.success) localStorage.setItem('selectedItems', JSON.stringify([]));

            this.loading = false;
            if (!response.success) return this.closeModalTop(false);
            return this.closeModalTop(response.metadata);
        },
        setMarkets() {
            const data = this.checkboxes;

            if (data[0].isChecked) {
                this.markets[0] = data[0].name.toLowerCase();
            }
            if (data[1].isChecked) {
                this.markets[1] = data[1].name.toLowerCase();
            }
            if (data[2].isChecked) {
                this.markets[2] = data[2].name.toLowerCase();
            }
            if (data[3].isChecked) {
                this.markets[3] = data[3].name.toLowerCase();
            }

            if (data[4].isChecked) {
                this.markets[4] = data[4].name.toLowerCase();
            }
            /*
            if (data[4].isChecked) {
                this.markets[4] = data[4].name.toLowerCase();
            }*/
        },
        clearMarkets() {
            this.markets = [];
        },
        async convertStackedItems() {
            const items = [];
            for (const group of this.groupedStack) {
                for (const steamPrice of group.items) {
                    items.push({
                        name: group.name,
                        slug:group.item_slug,
                        game:group.game,
                        quantity: 1,
                        sellPrice: group.sell_price,
                        ...steamPrice
                    });
                }
            }
            return items;
        },
        findPrice(item, market) {
            if (!item || !item.price_info || item.price_info.length == 0) return 0;
            const price = item.price_info.find(e => e.name === market);
            if (!price) return 0;
            return price.price.toFixed(2);
        },
        goLink(item) {
            const routeData = this.$router.resolve({ name: 'item', params: { slug: item.item_slug, game: item.game } });
            window.open(routeData.href, '_blank');
        },
    },
    created() {
        this.clearMarkets();
    },
    computed: {
        itemArray() {
            return this.items;
        }
    }
};
</script>

<style>
h5 {
    margin-bottom: 0;
}

.purple {
    flex-wrap: wrap;
}

.img-small {
    width: 16px;
    height: 16px;
}

.mid-span {
    font-size: 10px;
    font-weight: 500;
}

span {
    font-size: 1.7vh;
    font-weight: bold;
    color: white;
}

.btn-secondary {
    height: 40px;
    width: 40px;
    padding: 2px;
}

.black-button {
    background-color: #3d3e3d;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: none;
}

.black-button:hover {
    background-color: #303130;
}

.item-container {
    overflow: auto;
    height: 65%;
}

.sml {
    height: 20px;
    width: 20px;
}

.item-img {
    height: 50px;
    width: 60px;
}

.custom {
    border: 1px solid rgb(134, 80, 172);
}

.custom:hover {
    background-color:  rgb(134, 80, 172);
}


.custom-checkbox input {
    width: 0px;
}

.checkmark {
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid #4caf50;
    border-radius: 50%;
    margin-right: 4px;
    background-color: #fff;
}

.modal-a {
    color: white;
    background-color: rgb(0, 0, 0);
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    border-radius: 8px;
    overflow: hidden;
    /* Enable vertical scrolling */
}

.modal-a.active {
    display: flex;
}

.modal-content-a {
    background-color: #191a19;
    padding: 18px;
    border-radius: 8px;
    height: 100%;
    width: 100%;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 35px;
}

.close:hover {
    color: #F44336
}

label {
    font-weight: bold;
}

input {
    width: 120px;
    height: 30px;
    padding: 8px;
    border-radius: 2px;
}

input:active {
    border: none;
}

@media only screen and (max-width: 900px) {

    .lottie-area {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

}
</style>