<template>
  <div class="pricing-card" :style="{ height: item.height }">
    <div v-if="item.extra" class="extra-info">{{ item.extra }}</div>
    <div v-if="item.extra" class="extra-info-icon"></div>
    <div class="f flex-column g20">
      <div>
        <h4>
          {{ item.name }}
        </h4>
      </div>
      <div>
        <ul>
          <li v-for="(item, index) in item.descriptions" :key="index">
            <VueIcon height="16px" color="white" icon="mdi:success" />
            {{ item }}
          </li>
        </ul>
      </div>
    </div>

    <div class="price">
      <span class="price-cur">{{ item.price }}</span>
      <span class="price-period">{{ item.periode }}</span>
    </div>
    <button class="btn-pricing">{{ item.buttonText }}</button>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.pricing-card {
  position: relative;
  width: 240px;
  min-height: 380px;
  border-radius: 6px;
  background-color: #00000063;
  border: 1px solid transparent;
  padding: 2.5rem 1.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-card:hover {
  transition: 0.2s;
  cursor: pointer;
  background-color: #441fff45;
  border: 1px solid #5b28f3;
}

ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0.4rem;
}

li {
  list-style: none;
  font-size: 12px;
  color: #a1a1a1;
}

.price {
  position: absolute;
  bottom: 70px;
  left: 1.5rem;
}

.price-cur {
  font-size: 26px;
  font-weight: bold;
  color: white;
}

.price-period {
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.extra-info {
  position: absolute;
  top: 5px;
  right: 5px;
  width: auto;
  background-color: #954ce9;
  padding: 0.2rem 0.8rem;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
}

.extra-info-icon {
  position: absolute;
  top: 0px;
  left: 0px;
}

.btn-pricing {
  background-image: linear-gradient(
    to right,
    #954ce9 0%,
    #7d32d2 51%,
    #5b28f3 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
  height: 35px;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  color: white;
  border-radius: 4px;
  font-size: 12px;
}

.btn-pricing:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .pricing-card {
    width: 90%;
  }
}
</style>
