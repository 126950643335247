<template>
  <div class="item_bar">
    <div class="f sb">
      <h5 :style="{ color: item.color }">{{ item.name }}</h5>
      <button @click="$emit('copyText', item.name)" class="btn_copyname">
        <VueIcon height="20px" color="white" icon="bx:copy" />
      </button>
    </div>
    <div class="img_wrap">
      <img class="game_icon" style="width: 30px; height: 30px" :src="gameIcon" />
      <img class="item_image" :src="item.image" />
    </div>
    <div>
      <div class="inspect_wrap">
        <a class="collection" :href="steamLink" target="_blank" rel="nofollow">
          Steam Market
          <VueIcon height="20px" width="20px" icon="mdi:steam"></VueIcon>
        </a>
      </div>
      <div v-if="item.item_collection" class="inspect_wrap">
        <a class="collection" @click="
          this.$emit(
            'go-collection',
            item.item_collection.id,
            item.item_collection.name
          )
          ">
          {{ item.item_collection.name }}
          <img :src="item.item_collection.image" style="height: 30px; width: 35px" />
        </a>
      </div>
    </div>

    <div v-if="this.item.short_exterior">
      <h6>Exterior</h6>
      <div class="exterior_wrap">
        <div v-for="(item, index) in exteriors" :key="index" :class="{
          exterior_info: true,
          active: item === this.item.short_exterior,
        }">
          <span> {{ item }}</span>
        </div>
      </div>
    </div>
    <div class="price_info_wrap" v-if="prices && prices.length > 0">
      <h6 style="margin-bottom: 8px">Lowest Prices</h6>
      <div @click="goItem(item)" v-for="(item, index) in prices" :key="index"
        :class="{ price_info: true, active: item.name === this.item.name }">
        <span :style="{ color: chooseColor(item) }">{{ getPrefix(item) }} {{ item.exterior }}
        </span>
        <span class="bold">
          {{ item.price ?
            (item.price / 1000).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) : 'No Data'
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import cs2 from "../../assets/images/csgo.png";
import dota2 from "../../assets/images/dota2.png";
import tf2 from "../../assets/images/dota2.png";

export default {
  data() {
    return {
      cs2,
      dota2,
      tf2,
      gameIcon: null,
      exteriors: ["BS", "WW", "FT", "MW", "FN"],
      colors: {
        stattrak: "#CF6931",
        normal: "white",
        souvenir: "#D8B92A",
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    prices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    chooseColor(item) {
      if (item.is_souvenir) return this.colors.souvenir;
      if (item.is_stattrak) return this.colors.stattrak;
      return this.colors.normal;
    },
    getPrefix(item) {
      if (item.is_souvenir) return "Souvenir ";
      if (item.is_stattrak) return "StatTrak™ ";
      return "";
    },
    goItem(item) {
      return this.$emit("goItem", item);
    },
    setIcon(appID) {
      this.gameIcon = null;
      if (appID == 730) this.gameIcon = cs2;
      if (appID == 570) this.gameIcon = dota2;
      if (appID == 440) this.gameIcon = tf2;
    },
  },

  computed: {
    steamLink() {
      return `https://steamcommunity.com/market/listings/${this.item.app_id}/${this.item.name}`;
    },
  },
  created() {
    this.setIcon(this.item.app_id);
  },
};
</script>

<style scoped>
.bold {
  font-weight: 700;
}

h5,
h6 {
  color: white;
  margin-bottom: 0;
}

.collection {
  font-size: 11px;
}

.item_bar {
  width: 100%;
  background-color: #313155;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 3px;
}

.item_image {
  width: 75%;
  min-width: 120px;
  min-height: 150px;
  pointer-events: none;
}

.img_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.game_icon {
  position: absolute;
  bottom: 5px;
  left: 10px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.inspect_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  gap: 5px;
  color: white;
}

.inspect_wrap a {
  color: rgb(158, 156, 156);
  text-decoration: none;
  font-size: 14px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.inspect_wrap a:hover {
  color: rgb(218, 216, 216);
  transition: 0.3s;
}

.exterior_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border: 1px solid #5a3d92;
  margin-block: 10px;
  border-radius: 3px;
  min-height: 30px;
}

.exterior_info {
  width: 100%;
  height: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  background-color: #944ce9;
}

.price_info_wrap {
  display: flex;
  flex-direction: column;
}

.price_info {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.6rem;
  border-radius: 3px;
}

.price_info:hover {
  background-color: #944ce9;
  transition: 0.4s;
  cursor: pointer;
}

.price_info span {
  font-size: 13px;
  font-weight: 600;
}

@media screen and (max-width: 1096px) {
  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 0.75rem;
  }

  .item_bar {
    padding: 1.5rem 1rem;
  }

  .price_info {
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 0.4rem;
    border-radius: 3px;
    font-size: 15px;
  }

  .price_info span {
    font-size: 13px;
    font-weight: 600;
  }

  .price_info_wrap {
    padding: 0rem 0rem;
    gap: 8px;
  }
}
</style>
