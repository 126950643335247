import * as XLSX from 'xlsx'
import { convertToCSV, downloadCSV } from '../utils/fileUtil.js';
import {exportPdfCustom} from '../utils/invoiceGenerator.js';

async function exportExcel(data, user) {
    if(data?.length == 0) return false; 

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${user.name}.xlsx`);
    return true;
}

async function exportCSV(data, user) {
    const csv = await convertToCSV(data);
    await downloadCSV(csv, user.name);
    return true;
}

async function exportPdf(data, user) {
    await exportPdfCustom(data, user);
    return true;
}
async function exportFile(type, data, user) {
    if (type === 'Excel') {
        return await exportExcel(data, user);
    }
    if (type === 'CSV') {
        return await exportCSV(data, user);
    }
    if (type === 'PDF') {
        return await exportPdf(data, user);
    }
    return false;
}

const FileConverterService = {
    exportFile
}

export default FileConverterService;