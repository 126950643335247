<template>
  <div class="background-small">
    <div v-if="loading" class="loading-screen">
      <DotLoader :loading="loading" :width="'50px'" />
    </div>
    <div v-if="true" class="main-wrapper">
      <div class="mb-1 top_filter_title">
        <h4 class="text-white">Dashboard</h4>
        <div class="f g5">
          <div>
            <h6 class="responsive-title-font text-white mb-2">Game</h6>
            <IconDropdown
              :refFunction="setGame"
              :options="gameOptions"
              :selectedItem="selectedGame"
              :selectedIcon="selectedGameIcon"
            />
          </div>
          <div>
            <h6 class="responsive-title-font text-white mb-2">
              Time Periode / Days
            </h6>
            <SelectorDropdown
              :refFunction="setDate"
              :options="timePeriods"
              :selectedItem="selectedTimePeriod"
            />
          </div>
        </div>
      </div>

      <div class="row" v-if="cardArray && cardArray.length > 0">
        <div
          v-for="(card, cardIndex) in cardArray"
          :key="cardIndex"
          class="col-xl-4 col-lg-4 col-md-12 col-sm-12 h-100 my-2"
        >
          <div class="card-sale">
            <h5 class="py-2">{{ card.title }}</h5>
            <div class="tabs" v-if="card?.dataArray?.length > 0">
              <div
                v-for="(tab, index) in card.dataArray"
                :key="index"
                @click="card.setSelected(index, card.title, tab.title)"
                :class="{
                  'tab-custom': true,
                  'active-tab': card.selectedIndex === index,
                }"
              >
                <div class="f flex-column fls">
                  <img style="width: 22px" :src="tab.icon" />
                </div>
              </div>
            </div>
            <DasbhoardCard
              :head="card.dataArray[card.selectedIndex].title"
              :value="card.dataArray[card.selectedIndex].value"
              col-size="col-12"
              :percentage="card.dataArray[card.selectedIndex].ratio || null"
              :iconPath="card.dataArray[card.selectedIndex].icon"
              :periode="selectedTimePeriod"
              :percentageSymbol="true"
              :dollarSymbol="card.dollarSymbol"
            >
            </DasbhoardCard>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <ChartCard
          colSize="col-xs-12 col-sm-6 col-md-6 col-lg-6 my-2"
          head="Sales Overview"
          initialChartType="BarChart"
          cardHeight="h-100"
          :chartdata="chartData"
          labelName="Sale"
        />
        <ChartCard
          colSize="col-xs-12 col-sm-6 col-md-6 mb-lg-6 my-2"
          head="Profits Overview"
          initialChartType="LineChart"
          cardHeight="h-100"
          :chartdata="chartProfitData"
          labelName="Profit"
        />
      </div>
      <div class="row my-3">
        <ChartCard
          colSize="col-xs-12 col-sm-6 col-md-6 col-lg-6 my-2"
          :head="'Market Sales' + ' (Last ' + selectedTimePeriod + ' days)'"
          initialChartType="BarChart"
          cardHeight="h-100"
          :chartdata="saleByMarketChartData"
          labelName="Sale"
        />
        <ChartCard
          colSize="col-xs-12 col-sm-6 col-md-6 mb-lg-6 my-2"
          :head="
            'Marketplace Profits' + ' (Last ' + selectedTimePeriod + ' days)'
          "
          initialChartType="BarChart"
          cardHeight="h-100"
          :chartdata="profitByMarketChartData"
          labelName="Profit"
        />
      </div>
      <div class="row my-3 pb-3">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="card-sale h-100">
            <div class="p-2 pb-0 card-header">
              <div class="d-flex justify-content-between">
                <h5>
                  Last
                  {{ this.saleHistory ? this.saleHistory?.length : "0" }} Sales
                </h5>
              </div>
            </div>
            <LatestSales @go-item="goLink" :items="saleHistory"></LatestSales>
          </div>
        </div>
        <div class="col-lg-6 h-100">
          <div class="card-sale">
            <div class="tabs mb-2">
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                @click="selectTab(index)"
                :class="{
                  'tab-custom': true,
                  'active-tab': activeTab === index,
                }"
              >
                <h6>{{ tab.title }}</h6>
                <div v-if="activeTab === index"></div>
              </div>
            </div>

            <div class="content">
              <component
                @go-link="goLink"
                :is="tabs[activeTab].component"
                :sold="tabs[activeTab].values"
                :padding="tabs[activeTab].padding"
              ></component>
            </div>
            <div class="f flex-end" style="width: 100%">
              <a href="sales" class="link_yellow">
                See All Sales
                <VueIcon height="24px" icon="iconamoon:arrow-right-2-light" />
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { markRaw } from "vue";

import SideBar from "@/components/shared/SideBar.vue";
import ChartCard from "@/components/customCards/ChartCard.vue";
import SaleService from "@/services/SaleService";
import SoldCard from "@/components/customCards/SoldCard.vue";
import DasbhoardCard from "@/components/customCards/DasbhoardCard.vue";
import moment from "moment";

//import TargetComponent from "@/components/dashboard/TargetComponent.vue";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import allIcon from "../assets/images/all.png";

import LatestSales from "@/components/dashboard/LatestSales.vue";
import csgo from "../assets/images/csgo.png";
import dota from "../assets/images/dota2.png";
import tf2 from "../assets/images/tf2.png";
import rust from "../assets/images/rust.png";

import MarketOptions from "@/core/constants";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      MarketOptions,
      isMobile: false,
      SoldCard: markRaw(SoldCard),
      activeTab: 0,
      cardArray: [],
      tabs: [
        { title: "Most Sold", component: SoldCard, values: [], padding: "0px" },
        { title: "Top Sales", component: SoldCard, values: [], padding: "0px" },
      ],
      saleIndex: 0,
      profitIndex: 0,
      countIndex: 0,
      activeSaleTab: 0,
      initialDate: "",
      currentTime: "",
      startPrev: "",
      endPrev: "",
      searchQuery: "",
      loading: true,
      waxTotal: 0,
      marketTotal: 0,
      waxProfit: 0,
      marketProfit: 0,
      shadowProfit: 0,
      skinthunderProfit: 0,
      totalProfit: 0,
      waxCount: 0,
      marketCount: 0,
      shadowCount: 0,
      skinthunderCount: 0,
      skinthunderTotal: 0,
      totalCount: 0,
      totalSale: 0,
      generalData: {},
      timePeriods: ["1", "7", "14", "30", "60", "90", "180", "360", "720"],
      selectedTimePeriod: "7",
      nowTime: moment().add(1, "days").format("YYYY-MM-DD") || new Date(),
      chartData: [],
      chartProfitData: [],
      saleByMarketChartData: [],
      profitByMarketChartData: [],
      sold: [],
      highestSales: [],
      saleHistory: [],
      saleArray: [],
      profitArray: [],
      countArray: [],
      targetObject: {
        currentSale: 0,
        saleTarget: 0,
        currentProfit: 0,
        profitTarget: 0,
      },
      targets: {
        saleTarget: 10000,
        profitTarget: 1000,
      },
      gameOptions: [
        { name: "All", icon: allIcon, appID: 0 },
        { name: "CS2", icon: csgo, appID: 730 },
        { name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf2, appID: 440 },
        { name: "Rust", icon: rust, appID: 252490 },
      ],
      finalCounts: {
        totalSale: 0,
        totalProfit: 0,
        totalCount: 0,
      },
      selectedGameIcon: allIcon,
      selectedGame: "All",
      selectedAppID: 0,
    };
  },
  computed: {
    ...mapGetters("user", ["getUsername", "getUserAvatar"]),
  },
  methods: {
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.selectedAppID = type.appID;
      this.isDropdownOpen = false;
      await this.initialData();
    },
    async setSelectedTab(index, type, marketName) {
      const data = this.cardArray.find((e) => e.title == type);
      data.selectedIndex = index;
      return marketName;
    },
    getRatioProfit(num1, num2) {
      if (!num1 || !num2) return "0";
      return ((parseFloat(num1) / parseFloat(num2)) * 100).toFixed(2);
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async setDate(date) {
      this.selectedTimePeriod = date.toString();
      this.startDate = moment()
        .subtract(parseInt(date), "d")
        .format("YYYY-MM-DD");
      this.initialDate = moment()
        .subtract(parseInt(date), "d")
        .format("MM-DD-YYYY");
      await this.updateInfo();
    },
    async updateInfo() {
      this.loading = true;
      await this.getOverallData();
      this.parseGeneralData();

      this.loading = false;
    },
    async getSaleHistory() {
      let response = await SaleService.getSales({
        currentPage: 1,
        pageSize: 8,
        sortBy: "date",
        sortOrder: "DESC",
        searchQuery: "",
        appId: 730,
      });
      this.saleHistory = response.items;
    },
    async getOverallData() {
      this.generalData = await SaleService.getAllInfo({
        start: this.startDate,
        end: this.nowTime,
        appID: this.selectedAppID,
      });
    },
    setTargetInfo() {
      if (!this.generalData) return;
      if (!this.generalData.sale_details) return;

      const { sale_details } = this.generalData;
      const sumReducer = (property) =>
        sale_details.reduce((total, { [property]: value }) => total + value, 0);

      let divider = 30 / parseInt(this.selectedTimePeriod);

      this.targetObject.currentSale = sumReducer("sale") / divider;
      this.targetObject.saleTarget = this.targets.saleTarget / divider;
      this.targetObject.currentProfit = sumReducer("profit") / divider;
      this.targetObject.profitTarget = this.targets.profitTarget / divider;
    },
    parseGeneralData() {
      this.chartData = this.generalData.periodic_sales;
      this.chartProfitData = this.generalData.periodic_profits;

      this.setTargetInfo();
      this.mapMarketSales();
      this.mapProfitData();
      this.setCardArray();
    },
    mapMarketSales() {
      const data = this.generalData.sale_details;
      if (!data) return;

      this.saleByMarketChartData = data.map((e) => ({
        date: e.market,
        value: e.sale,
      }));
    },
    mapProfitData() {
      const data = this.generalData.sale_details;
      if (!data) return;

      this.profitByMarketChartData = data.map((e) => ({
        date: e.market,
        value: e.profit,
      }));
    },
    async initialData() {
      this.loading = true;
      this.startDate = moment().subtract(7, "d").format("YYYY-MM-DD");
      this.currentTime = moment().format("MM-DD-YYYY hh:ss");
      this.initialDate = moment().subtract(7, "d").format("MM-DD-YYYY");

      await Promise.all([
        this.getOverallData(),
        SaleService.getMostlySold({ limit: 8, appID: this.selectedAppID }).then(
          (mostlySoldData) => {
            if (mostlySoldData !== undefined) {
              this.tabs[0].values = mostlySoldData;
            }
          }
        ),
        SaleService.getHighestSales({
          limit: 8,
          appID: this.selectedAppID,
        }).then((highestSalesData) => {
          if (highestSalesData !== undefined) {
            this.tabs[1].values = highestSalesData;
          }
        }),
      ]);

      this.parseGeneralData();
      this.getSaleHistory();

      this.loading = false;
    },
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    setScreenSize() {
      this.$store.dispatch("ui/checkMobile");
    },
    getRouteParams() {
      const game = this.$route.query.game;
      if (!game) this.$route.query.game = "cs2";
    },
    parseDollar(num) {
      if (typeof num !== "number" || isNaN(num)) return "0";

      return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    setInfo(type, iconPath) {
      if (!this.generalData || !this.generalData.sale_details) return;

      const formatValue = (value) => {
        return type !== "count"
          ? this.parseDollar(value) || ""
          : value.toString();
      };

      const dataArray = this.generalData.sale_details.map((detail) => {
        const { market, [type]: value = 0, sale = 0 } = detail;
        const item = this.MarketOptions.find(
          (e) => e.name.toLowerCase() == market.toLowerCase()
        );

        return {
          title: market,
          value: formatValue(value),
          number: value,
          iconPath: iconPath,
          icon: item.icon,
          ratio: type === "profit" ? this.calculateProfitRatio(value, sale) : "0",
        };
      });

      const totalNumber = dataArray.reduce(
        (total, { number }) => total + number,
        0
      );

      dataArray.unshift({
        title: "Total",
        value: formatValue(totalNumber),
        number: totalNumber,
        iconPath: iconPath,
        icon: iconPath,
        ratio:
          type === "profit" ? this.calculateTotalProfitRatio(totalNumber) : 0,
      });

      return dataArray;
    },
    calculateProfitRatio(profit, sales) {
      if (!sales || !profit) return "0";
      return ((profit / sales) * 100).toFixed(2);
    },
    calculateTotalProfitRatio(totalProfit) {
      const totalSales = this.generalData.sale_details.reduce(
        (total, { sale = 0 }) => total + sale,
        0
      );
      return this.calculateProfitRatio(totalProfit, totalSales);
    },
    setCardArray() {
      const types = [
        {
          title: "Sales",
          type: "sale",
          dollarSymbol: true,
          iconPath: allIcon,
        },
        {
          title: "Profits",
          type: "profit",
          dollarSymbol: true,
          iconPath: allIcon,
        },
        {
          title: "Sale Counts",
          type: "count",
          dollarSymbol: false,
          iconPath: allIcon,
        },
      ];

      this.cardArray = types.map(({ title, type, dollarSymbol, iconPath }) => {
        const dataArray = this.setInfo(type, iconPath);
        return {
          title,
          dataArray,
          selectedIndex: 0,
          dollarSymbol,
          setSelected: (index, type, marketName) =>
            this.setSelectedTab(index, type, marketName),
        };
      });
    },
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener("resize", this.setScreenSize);
  },
  async created() {
    this.getRouteParams();
    await this.initialData();
    this.setCardArray();
  },
  components: {
    SideBar,
    IconDropdown,
    SelectorDropdown,
    ChartCard,
    SoldCard,
    DasbhoardCard,
    LatestSales,
  },
};
</script>
<style scoped>
@import "../assets/style/style.css";
@import "../assets/style/dashboard.css";

.top_filter_title {
  display: flex;
  justify-content: space-between;
}

h5 {
  margin-bottom: 0;
}

.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  height: 33px;
  width: 100%;
  border: 1px #934be6 solid;
  display: flex;
  justify-content: space-between;
  gap: 0.1rem;
  border-radius: 2px;
  padding: 0.1rem;
}

.tab-custom {
  border: none;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-radius: 2px;
}

.tab-custom:hover {
  background-color: #934be6;
  transition: 0.3s;
}

.tab-custom.active-tab {
  background-color: #934be6;
}

.button-group .circle {
  border-radius: 50%;
}

.button-group .non-padding {
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px 0px;
}

.tab-but {
  position: relative;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  transition: 0.4s;
  color: white;
  font-size: 16px;
}

.active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #8952e7;
  transition: 0.4s;
}

.g3 {
  gap: 3px;
}

.tabs h6 {
  font-size: 13px;
}

@media screen and (max-width: 576px) {
  .drop_date {
    width: 130px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .tab-but {
    font-size: 13px;
  }

  .tabs h6 {
    font-size: 13px;
  }

  .top_filter_title {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .top_filter_title div {
    width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .tab-but:hover {
    transition: 0.4s;
    background-color: #8952e7;
    transition: 0.3s;
  }

  .drop_date {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
