<template>
    <div @click="go" @mouseenter="isActive = true" @mouseleave="isActive = false"
        :style="{ borderColor: isActive ? color : 'transparent', color: isActive ? color : 'transparent' }"
        class="catalog-card catalog-card2 sell_card select_item">
        <div class="image_wrapper">
            <a v-for="(index) in 3" :key="index" class="catalog-card__img">
                <img class="img" :src="items[index].image">
            </a>
        </div>

        <div class="price">
            <div class="f align-center space-between">
                <span class="f mid-span flex-end">
                    {{ items.length }} {{ isRare ? rareText : skinText }} Skins
                </span>
                <div class="scroll-arrow"></div>
            </div>
        </div>
    </div>
</template>

<script>

import steam from '../../assets/images/steam-gray.png'

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            isActive: false,
            steam,
            marketCount: 10,
            item: {},
            color: 'rgb(235, 75, 75)',
            isRare: true,
            rareText: 'Rare Special',
            skinText: ' '
        };
    },
    methods: {
        go() {
            this.$emit('check-rares', this.isRare)
            this.isRare = !this.isRare;
        }

    },
};
</script>
  
<style scoped>
p {
    margin-bottom: 0px;
}


.fg1 {
    flex-grow: 1;
}

.sm-gap {
    margin-left: 6px;
}

.mid-span {
    font-size: 15px;
}

.img {
    min-width: 120px;
    max-width: 120px;
    height: auto;
}

.sell_card {
    padding: 10px 0 0px;
    background: #15142a;
    border-radius: 10px;
    background: -o-linear-gradient(top, rgb(30 12 36 / 45%) 0, #15142a 100%);
    background: linear-gradient(180deg, rgb(30 12 36 / 45%) 0, #15142a 100%);
    cursor: pointer;
    border: 1px solid transparent;
}

.sell_card:hover {
    transition: 0.4s;
    background: #15142a;
    box-shadow: 0px 0px 12px 5px;
}

.catalog-card {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 8px;
    padding-top: 8px;
    min-height: 20px;
    background-color: rgb(48, 47, 47);
}

.sell_card:hover .price {
    background-color: #545454;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.catalog-card__img {
    display: block;
}

.image_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.catalog-card2 .catalog-card__img {
    margin-top: 0;
}

.scroll-arrow {
    width: 12px;
    height: 12px;
    transform: rotate(-45deg);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    animation: arrow-wave 1s infinite;
    animation-direction: alternate;
    opacity: 0;
    /* Initial opacity */

    &:nth-child(1) {
        animation-delay: 0.1s;
    }

    &:nth-child(2) {
        animation-delay: 0.2s;
    }

    &:nth-child(3) {
        animation-delay: 0.3s;
    }
}

.scroll-arrow:hover {
    animation: arrow-wave-hover 1s infinite;
}

@keyframes arrow-wave {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes arrow-wave-hover {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

@media screen and (min-width: 1100px) {
    .sell_card {
        height: 100%;
    }
}

@media only screen and (min-width: 576) and (max-width: 1100px) {
    .sell_card {
        height: 100%;
    }
}

@media screen and (max-width: 576px) {
    .sell_card {
        height: 100%;
    }

    .mid-span {
        font-size: 16px;
    }

    p {
        font-size: 16px;
    }
}
</style>
  