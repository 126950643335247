<template>
  <div class="p-2 card-body">
    <ul class="d-grid gap-3" :style="{ padding: padding }">
      <li
        @click="this.$emit('go-link', item)"
        v-for="(item, index) in sold"
        :key="index"
        class="w-100 d-flex"
      >
        <div class="d-flex w-100 align-items-center p-1">
          <div class="text-center me-0">
            <img v-if="item.image" class="icon-mid mx-3" :src="item.image" />
          </div>
          <div class="d-flex w-100 flex-column sb">
            <h6 class="text-white">{{ item.name }}</h6>
            <div class="f sb">
              <span :style="{ color: getColor(item.profit) }" class="font-weight-bold"
                >{{ item.sale_count ?? 1 }} Sold
              </span>
              <span :style="{ color: getColor(item.profit) }" class="font-weight-bold">
                {{ item.profit >= 0 ? "+" : "" }}${{ item.profit }} ({{
                  item.ratio
                }}%)</span
              >
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { shallowRef } from "vue";

export default {
  props: {
    sold: shallowRef(Array),
    padding: {
      type: String,
      default: "1rem",
    },
  },
  data() {
    return {};
  },
  methods: {
    getColor(value) {
        if(!value) return 'white';
        return value >= 0 ? '#AAF683' : 'red'
    }
  },
};
</script>

<style scoped>
h6 {
  margin-bottom: 0;
}

.icon-mid {
  height: 50px;
  width: 50px;
}

.card-body li img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.card-body li:hover {
  transition: 0.4s;
  background-color: #1f1f38;
  border-radius: 4px;
  cursor: pointer;
}

.card-body li:hover img {
  -webkit-transform: scale(1.35);
  transform: scale(1.35);
}

h6 {
  font-size: 15px;
}

@media screen and (max-width: 576px) {
  h6 {
    font-size: 13px;
  }
}
</style>
