import * as cheerio from 'cheerio';

/* eslint-disable */
export function extractItems(html) {
    try {

        const $ = cheerio.load(html);
        let items = [];

        $('.item_csgo').each((index, element) => {
            const item = {};
            const $element = $(element);

            item.link = $element.data('link');
            item.id = $element.data('id');
            item.hold = $element.find('.info-item.hold').text().trim();
            item.stattrak = $element.find('.info-item.stattrak').text().trim();
            item.condition = $element.find('.info-item').eq(2).text().trim();
            item.price = $element.find('.info-item').eq(3).text().trim();
            item.img = $element.find('.image').attr('src').trim();
            item.name = $element.find('.name-inner').text().trim();
            item.exterior = $element.find('.name-exterior').text().trim();
            item.itemPrice = $element.find('.price').text().trim();
            item.discount = $element.find('.steam-price-discount').text().trim();

            items.push(item);
        });
        return JSON.stringify(items, null, 2);

    } catch (error) {
        console.log(error)
        return false;
    }
}

