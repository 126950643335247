<template>
    <div class="valuation">
        <h6 class="text-white">{{ title }}</h6>
        <div class="f wrapper_value">
            <div class="value_wrap" v-for="(item, index) in data" :key="index">
                <img :src="item.icon" style="width: 22px; height: 22px;" />
                <div class="f flex-column">
                    <span class="small"> {{ item.name }}
                    </span>
                    <span class="small"> $ {{ item.value }} 
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {

        }
    },
    props: {
        data: {
            type: Array,
            required: false,
        },
        title: {
            type: String,
            default: ''
        }
    },
};
</script>

<style scoped>

.small {
    font-size: 14px;
}

.wrapper_value {
    gap: 30px;
    flex-wrap: wrap;
}

.value_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.valuation {
    gap: 5px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    background-color: #313155;
    padding: 1rem 1rem;
}


@media screen and (max-width: 768px) {

    .small {
        font-size: 11px;
    }

    .wrapper_value {
        gap: 10px;
        justify-content: space-between;
        flex-wrap: wrap;
    }

}
</style>
