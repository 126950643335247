<template>
  <div class="background-small">
    <div class="row py-3 px-2 f align-center" style="justify-content: center">
      <CollectionInfo :item="caseInfo">
        <p v-if="caseInfo.releaseDate">
          This case was released on {{ caseInfo.releaseDate }}.
        </p>
        <button @click="goSimulator" class="open_case">Open Container</button>
      </CollectionInfo>
    </div>
    <div class="wrapper px-3">
      <div v-if="itemLoading" class="f flex-column g10">
        <ShimmerEffect v-for="index in 6" :key="index" />
      </div>
      <div>
        <div class="collection_grid py-2">
          <RareCard
            @check-rares="check"
            v-if="rareItems && rareItems.length > 0"
            :items="rareItems"
          >
          </RareCard>
          <CaseCard
            @go-item="goItem"
            v-for="item in items"
            :key="item.name"
            :item="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import CaseCard from "@/components/cards/CaseCard.vue";
import CollectionInfo from "@/components/collection/CollectionInfo.vue";
import RareCard from "@/components/cards/RareCard.vue";

import ItemService from "@/services/ItemService.js";

import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";

import DateUtils from "@/utils/DateUtils.js";
import steamIcon from "../assets/images/steam-white.png";

export default {
  data() {
    return {
      title: "Collection",
      stats: {
        total: 0,
      },
      caseInfo: {},
      items: [],
      rareItems: [],
      loading: true,
      itemLoading: true,
      isDropdownOpen: false,
      steamIcon: steamIcon,
      filterObject: {
        pageSize: 10,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        market: "all",
      },
      orderOptions: ["Price", "Name", "Rarity"],
      selectedOrder: "Price",
      selectedDirection: "DESC",
      caseName: "",
      caseID: null,
      tempArray: [],
      isRareView: false,
    };
  },
  methods: {
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
      this.sortItems();
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    formatItems(items) {
      let result = [];

      items
        .filter((skin) => skin.isNormal === 1)
        .forEach((skin) => {
          const newItem = {
            name: skin.name,
            shortName: skin.shortName,
            image: skin.image,
            rarity: skin.rarity,
            game: skin.game,
            slug:skin.slug,
            type: skin.type,
            color: skin.color,
            isSouvenir: false,
            isStattrak: false,
            isNormal: skin.isNormal,
            prices: {
              stattrakMinPrice: 0,
              stattrakMaxPrice: 0,
              souvenirMinPrice: 0,
              souvenirMaxPrice: 0,
              normalMinPrice: skin.minPrice,
              normalMaxPrice: skin.maxPrice,
            },
          };
          result.push(newItem);
        });

      //ST and Souvenir items
      const odds = items.filter((skin) => skin.isNormal === 0);

      result.forEach((element) => {
        const souvenirItem = odds.find(
          (e) => e.shortName === element.shortName && e.isSouvenir === 1
        );

        if (souvenirItem) {
          element.isSouvenir = true;
          element.prices.souvenirMinPrice = souvenirItem.minPrice;
          element.prices.souvenirMaxPrice = souvenirItem.maxPrice;
        }

        const stItem = odds.find(
          (e) => e.shortName === element.shortName && e.isStattrak === 1
        );

        if (stItem) {
          element.isStattrak = true;
          element.prices.stattrakMinPrice = stItem.minPrice;
          element.prices.stattrakMaxPrice = stItem.maxPrice;
        }
      });
      this.items = result;
    },
    formatRareItems(items) {
      if (!items || items.length === 0) return;
      let result = [];

      items
        .filter((skin) => skin.isNormal === 1)
        .forEach((skin) => {
          const newItem = {
            name: skin.name,
            shortName: skin.shortName,
            image: skin.image,
            rarity: skin.rarity,
            type: skin.type,
            color: skin.color,
            slug:skin.slug,
            game:skin.game,
            isStattrak: false,
            isNormal: skin.isNormal,
            prices: {
              stattrakMinPrice: 0,
              stattrakMaxPrice: 0,
              normalMinPrice: skin.minPrice,
              normalMaxPrice: skin.maxPrice,
            },
          };
          result.push(newItem);
        });

      const odds = items.filter((skin) => skin.isNormal === 0);

      result.forEach((element) => {
        const stItem = odds.find((e) => e.shortName && e.isStattrak === 1);

        if (stItem) {
          element.isStattrak = true;
          element.prices.stattrakMinPrice = stItem.minPrice;
          element.prices.stattrakMaxPrice = stItem.maxPrice;
        }
      });

      this.rareItems = result;
    },
    async getItems() {
      this.itemLoading = true;
      const response = await ItemService.getCaseItems({ caseID: this.caseID });

      if (!response) return;
      this.formatItems(response.items.skins);
      this.formatRareItems(response.items.knives);
      this.caseInfo.count = this.items.length;
      this.itemLoading = false;
    },
    async getCaseInfo() {
      const response = await ItemService.getItemCaseInfo({
        caseID: this.caseID,
      });
      if (!response) return;

      this.caseInfo = response;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 1000;
    },
    async getData() {
      Promise.all([this.getCaseInfo(), this.getItems()]);
    },
    goItem(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    goSimulator() {
      this.$router.push({
        name: "simulator",
        params: { id: this.caseID },
      });
    },
    check(rareToggle) {
      if (rareToggle) {
        this.isRareView = true;
        this.tempArray = this.items;
        this.items = this.rareItems;
        this.rareItems = this.tempArray;
      } else {
        this.isRareView = false;
        this.rareItems = this.items;
        this.items = this.tempArray;
      }
    },
  },
  async created() {
    try {
      this.caseName = this.$route.params.name;
      this.caseID = this.$route.query.id;
      await this.getData();
      this.$store.dispatch('router/updateTitle', this.caseName);
    } catch (err) {
      console.error(err);
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
    
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  components: {
    CaseCard,
    RareCard,
    CollectionInfo,
    ShimmerEffect,
    SortDropdown,
  },
};
</script>
<style scoped>
@import "../assets/style/customTable.css";

p {
  margin-bottom: 0;
}

.collection_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.open_case {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    rgb(38, 133, 92)
  );
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  padding: 4px 8px;
  width: 140px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  margin-top: 5px;
  height: 40px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 4px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.open_case:hover {
  transition: 0.3s;
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 728px) {
  .filter-div-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mid-span {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
