<template>
    <div class="info-modal">
        <div class="fdcs" v-if="message.length === 0">
            <h6 class="text bold">Error While Listing Items</h6>
        </div>
        <div v-else class="fdcs">
            <h6 class="text bold">Successfull Listings</h6>
            <div class="f g10" v-for="(item, index) in message" :key="index">
                <span class="text market-name"> {{ item.name }}  </span>
                <span class="text">{{ item.value }}</span>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        message: {
            type: Array,
            required: true,
        },
    },
};
</script>
  
<style scoped>

.market-name {
    width: 90px;
}
.fdcs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.info-modal {
    width: 280px;
    padding: 1rem 1.8rem;
    height: auto;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(76, 175, 80, 1);
    color: white;
    border-radius: 4px;
}

.info-modal-content {
    display: flex;
    justify-content: center;
    height: 100%;
}

.text {
    color: white;
}

.loading-bar {
    position: relative;
    height: 10px;
    background-color: #f0f0f0;
    overflow: hidden;
}

.bold {
    font-weight: bold;
}

.fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #76ff03;
    animation: fillAnimation 1.5s infinite;
}

@keyframes fillAnimation {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
</style>
  