import { apiInstance } from "@/utils/ApiUtil";

const API_URL = "/watchlist";

const addWatchlist = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/", {
      appid: req.appid,
      market_hash_name: req.market_hash_name,
    });

    return response.data.success

  } catch (error) {
    return false;
  }
};

const getWatchlist = async (req) => {

  try {
    
    const response = await apiInstance.post(API_URL + "/all", {
      appid: req.appid,
      currentPage: req.currentPage,
      pageSize: req.pageSize,
      sortBy: req.sortBy,
      sortOrder: req.sortOrder,
      volume: req.volume,
      selectedType: req.selectedType,
      search: req.search
    });

    if (response.data.success) {
      return response.data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

const fetchWatchItem = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/get", {
      market_hash_name: req.market_hash_name,
      app_id: req.app_id
    });
    return response.data.success;
  } catch (error) {
    return false;
  }
};

const deleteWatchlist = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + "/delete", {
      appid: req.appid,
      market_hash_name: req.market_hash_name,
    });
    return response.data.success;
  } catch (error) {
    return false;
  }
};

const WatchlistService = {
  addWatchlist,
  getWatchlist,
  fetchWatchItem,
  deleteWatchlist,
};

export default WatchlistService;
