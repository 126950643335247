<template>
  <div
    @click="go"
    @mouseenter="isActive = true"
    @mouseleave="isActive = false"
    :style="{
      borderColor: isActive ? item.color : 'transparent',
      color: isActive ? item.color : 'transparent',
    }"
    class="catalog-card catalog-card2 sell_card select_item"
  >
    <a class="catalog-card__img">
      <img class="img" :src="item.image" />
    </a>
    <div
      class="f align-center justify-center rarity"
      :style="{ backgroundColor: item.color }"
    >
      <span class="mid-span">{{ item.rarity }} {{ item.type }}</span>
    </div>
    <div class="catalog-card__title sell-card-title">
      <span class="mid-span">{{ item.shortName }}</span>
    </div>
    <div class="price">
      <div class="f flex-column align-center fg1">
        <span v-if="item.isNormal" class="f mid-span">
          Normal {{ formatCurrency(item.prices.normalMinPrice) }} -
          {{ formatCurrency(item.prices.normalMaxPrice) }}
        </span>
        <span
          v-if="item.isStattrak"
          class="f mid-span"
          :style="{ color: '#CF6830' }"
        >
          StatTrak {{ formatCurrency(item.prices.stattrakMinPrice) }} -
          {{ formatCurrency(item.prices.stattrakMaxPrice) }}
        </span>
        <span
          v-if="item.isSouvenir"
          class="f mid-span"
          :style="{ color: '#D8B92A' }"
        >
          Souvenir {{ formatCurrency(item.prices.souvenirMinPrice) }}-
          {{ formatCurrency(item.prices.souvenirMaxPrice) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import steam from "../../assets/images/steam-gray.png";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      steam,
      marketCount: 10,
    };
  },
  methods: {
    go() {
      this.$emit("go-item", this.item);
    },
    formatCurrency(price) {
      if (!price) return "$0";
      return (price / 1000).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  created() {},
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
}

.case {
  color: white;
}

.case:hover {
  color: gray;
  transition: 0.4s;
}

.flex-end {
  justify-content: flex-end;
}

.fg2 {
  flex-grow: 2;
}

.fg1 {
  flex-grow: 1;
}

.sm-gap {
  margin-left: 6px;
}

.mid-span {
  font-size: 13px;
}

.img-small {
  width: 25px;
  height: 25px;
  min-width: 0%;
}

.img {
  min-width: 100%;
  max-width: 100%;
  min-height: 120px;
  height: auto;
}

.sell_card {
  padding: 10px 0 0px;
  background: #15142a;
  border-radius: 10px;
  background: -o-linear-gradient(top, rgb(30 12 36 / 45%) 0, #15142a 100%);
  background: linear-gradient(180deg, rgb(30 12 36 / 45%) 0, #15142a 100%);
  cursor: pointer;
  border: 1px solid transparent;
}

.sell_card:hover {
  transition: 0.4s;
  background: #15142a;
  box-shadow: 0px 0px 12px 5px;
}

.catalog-card {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.rarity {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  min-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
  min-height: 20px;
  background-color: rgb(48, 47, 47);
}

.sell_card:hover .price {
  background-color: #545454;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.catalog-card__img {
  display: block;
  position: relative;
  width: calc(100% - 60px);
  margin: 7px auto 0;
  padding-bottom: 5%;
}

.catalog-card2 .catalog-card__img {
  margin-top: 0;
}

.img-market {
  height: 25px;
  width: 25px;
  max-width: 25px;
  min-width: 25px;
}

.sell-card-title {
  line-height: 20px;
}

.catalog-card__title {
  padding: 0 5px;
}

.catalog-card__title {
  display: block;
  margin-top: 2px;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.catalog-card__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  line-height: 25px;
  color: #dd1b5f;
  margin-top: 10px;
}

@media screen and (min-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1100px) {
  .sell_card {
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .sell_card {
    height: 100%;
  }

  .mid-span {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }
}
</style>
