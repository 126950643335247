<template>
  <div class="login-background">
    <div class="login-content">
      <h2 class="text-center mb-5 text-white">Steam Analyzer</h2>
      <hr />
      <div class="text-center button-content">
        <div class="btn-group-vertical">
          <button @click="steamLogin" class="btn-pers">
            <VueIcon width="48px" color="black" icon="mdi:steam" />
          </button>
          <h5 class="text-center mt-4 text-white">Login Via Steam</h5>
        </div>
      </div>
      <div class="text-center f flex-column mb-3">
        <span class="bold-text">{{ this.copyright }}</span>
        <span class="bold-text">{{ this.year }}</span>
        <span class="bold-text">{{ this.sub }}</span>
      </div>
      <hr />
      <div class="d-flex justify-content-center gap-5">
        <a target="_blank" rel="noreferrer noopener" :href="twitterRef">
          <VueIcon
            class="animate"
            height="40px"
            width="35px"
            color="white"
            icon="pajamas:twitter"
          />
        </a>
        <a target="_blank" rel="noreferrer noopener" :href="discordRef">
          <VueIcon
            class="animate"
            height="40px"
            width="40px"
            color="white"
            icon="pajamas:discord"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/AuthService.js";
import linked from "../assets/images/linkedin.png";
import git from "../assets/images/github.png";

export default {
  data() {
    return {
      copyright: "Steam Analyzer ©",
      sub: "All rights reserved.",
      year: "2023-2024",
      linkedIcon: linked,
      gitIcon: git,
      discordRef: "https://discord.gg/nfCfpE3d6V",
      twitterRef: "https://x.com/steamanalyzer",
    };
  },
  methods: {
    async steamLogin() {
      await AuthService.loginThroughSteam();
    },
  },
  created() {
    window.document.body.style.paddingLeft = "0";
  },
};
</script>

<style>
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.login-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35%;
  min-width: 300px;
  height: auto;
  transform: translate(-50%, -50%);
  border: none;
  padding: 3rem 3rem;
  border-radius: 8px;
  background: #0d131e;
}

.bold-text {
  font-weight: 400;
  font-size: 15px;
  color: white;
}

.btn-pers {
  height: 4.5rem;
  width: 8rem;
  background-color: #9d9dec;
}

.animate {
  transition: transform ease 300ms;
}

.animate:hover {
  transform: translate(0, -5px);
}

@media screen and (max-width: 850px) {
  .login-content {
    height: auto;
  }
}

@media screen and (min-width: 1305px) {
  .button-content {
    margin-bottom: 15%;
  }
}

@media screen and (max-width: 849px) {
  .button-content {
    margin-bottom: 20%;
  }

  .bold-text {
    font-weight: 400;
    font-size: 12px;
    color: white;
  }
}

@media screen and (max-width: 440px) {
  .button-content {
    height: 45%;
    margin-bottom: 0%;
  }

  .login-content {
    height: auto;
  }
}
</style>
