let baseURL = process.env.VUE_APP_MODE === 'test' ? process.env.VUE_APP_TEST_BASE_URL : process.env.VUE_APP_PRODUCTION_BASE_URL;
const redirectUri = baseURL + 'auth/steam-login';

const loginThroughSteam = async () => {
    const steamOpenIDParams = new URLSearchParams({
        'openid.ns': 'http://specs.openid.net/auth/2.0',
        'openid.mode': 'checkid_setup',
        'openid.return_to': redirectUri,
        'openid.realm': redirectUri,
        'openid.identity': 'http://specs.openid.net/auth/2.0/identifier_select',
        'openid.claimed_id': 'http://specs.openid.net/auth/2.0/identifier_select'
    });
    window.location.href = 'https://steamcommunity.com/openid/login?' + steamOpenIDParams.toString();
};

const AuthService = {
    loginThroughSteam
}

export default AuthService;