<template>
  <div class="background-small">
    <div class="f sb top-section">
      <div>
        <h4 class="text-white">{{ listItems }}</h4>
        <div class="f g10">
          <h6 class="text-white mt-1">{{ total }}: {{ count }}</h6>
          <h6 class="text-white mt-1">{{ availableItems }}: {{ available }}</h6>
          <h6 class="text-white mt-1">
            {{ tradeLocked }}: {{ this.count - this.available }}
          </h6>
        </div>
      </div>
      <div class="f g5 align-bottom">
        <IconDropdown
          class="mt-1"
          :refFunction="setGame"
          :options="gameOptions"
          :selectedItem="selectedGame"
          :selectedIcon="selectedGameIcon"
          :height-option="'35px'"
        />
        <SelectorDropdown
          v-if="!isMobile"
          :custom-height="'35px'"
          :refFunction="setSize"
          :options="sizeOptions"
          :selectedItem="selectedSize"
        />
        <button
          v-if="!isMobile"
          class="custom_button"
          @click="synchMarketItems"
        >
          <VueIcon
            height="24px"
            color="white"
            icon="flowbite:refresh-outline"
          />
        </button>
      </div>
    </div>
    <div class="row f sb py-1 block-mobile">
      <div class="f sb align-bottom">
        <div>
          <p
            style="cursor: pointer"
            @click="this.$router.push({ name: 'user' })"
            class="danger-text"
          >
            {{ warn }}
          </p>
        </div>
        <div class="f g10"></div>
      </div>
    </div>
    <div class="mobile-filters">
      <div v-for="(dropdown, index) in filterArray" :key="index">
        <div v-if="dropdown.isMobile">
          <h6 class="text-white mb-1">{{ dropdown.mobileLabel }}</h6>
          <CheckboxDropdown
            :options="dropdown.options"
            :selected-item="dropdown.selectedItem"
            :ref-function="dropdown.refFunction"
            :type="dropdown.mobileType"
            :width-option="'100%'"
            :height-option="'35px'"
          ></CheckboxDropdown>
        </div>
      </div>

      <button class="custom_button" @click="synchMarketItems">
        <VueIcon height="24px" color="white" icon="flowbite:refresh-outline" />
      </button>
    </div>
    <hr class="gray-line" style="border-color: #4c4cef" />
    <div class="main-wrapper mt-2">
      <div class="filters">
        <div v-for="(dropdown, index) in filterArray" :key="index">
          <h6 class="text-white mb-1">{{ dropdown.label }}</h6>
          <CheckboxDropdown
            :options="dropdown.options"
            :selected-item="dropdown.selectedItem"
            :ref-function="dropdown.refFunction"
            :type="dropdown.type"
            :width-option="'90%'"
          ></CheckboxDropdown>
        </div>
      </div>
      <div class="content">
        <div v-if="loading" class="sail__grid skin__scroll">
          <ShimmerCard v-for="index in 12" :key="index" />
        </div>

        <div v-else class="sail__grid skin__scroll">
          <ItemCard
            ref="itemCard"
            v-for="item in getItems"
            :key="item.asset_id"
            :item="item"
          />
        </div>

        <div class="page_row pt-2 pb-3 f align-center">
          <PaginationRow
            :pageNumber="filters.page"
            :totalPages="totalPages"
            @update:pageNumber="filters.page = $event"
          />
        </div>
        <div class="row f py-3 g20 fe mx-3">
          <button
            :disabled="getItems === undefined || getItems.length === 0"
            @click="addItemToStack"
            class="button btn-secondary custom"
          >
            {{ sellBtn }}
          </button>
          <button
            :disabled="getItems === undefined || getItems.length === 0"
            @click="addAllToStack"
            class="button btn-secondary custom"
          >
            {{ selectAll }}
          </button>
        </div>
      </div>
    </div>

    <component
      v-if="!loading && items"
      :is="isMobile ? 'MultipleSaleModalMobile' : 'MultipleSaleModal'"
      :appID="this.filters.appID"
      @item-remove="removeItem"
      @show-message="showMessage"
      :items="sellStack"
      :groupedStack="groupedStack"
      @updateItems="getInventoryRecords"
      :modalOpen="isModalOpen"
      @closeModal="closeModal"
    />
    <SaleInfoModal
      v-if="showInfoModal"
      :message="infoArray"
      @close="isModalOpen"
    />
    <ErrorModal
      v-if="showErrorModal"
      :message="errorMessage"
      @close="showErrorModal = false"
    />
  </div>
</template>

<script>
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import CheckboxDropdown from "@/components/dropdownButtons/CheckboxDropdown.vue";
import ItemCard from "@/components/customCards/ItemCard.vue";
import SaleInfoModal from "@/components/modal/SaleInfoModal.vue";
import MultipleSaleModal from "@/components/modal/MultipleSaleModal.vue";
import MultipleSaleModalMobile from "@/components/modal/MultipleSaleModalMobile.vue";
import SteamService from "@/services/SteamService";
import ListedItems from "@/services/ListedItemService";
import ErrorModal from "@/components/modal/ErrorModal.vue";
import Grid from "@/components/valueBars/ValuationGrid.vue";
import LineGraph from "@/components/graphs/LineGraph.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import ShimmerCard from "@/components/skeleton/ShimmerCard.vue";

import csgo from "../assets/images/csgo.png";
//import dota from "../assets/images/dota2.png";
import MarketOptions from "@/core/constants";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      MarketOptions,
      totalPages: 6,
      title: "My Inventory",
      listItems: "List Items On Marketplaces",
      warn: "You need to set your API keys for using item listing tool.",
      valuation: "Inventory Value",
      sellBtn: "Sell Items",
      selectAll: "Select All",
      availableItems: "Tradable Items",
      tradeLocked: "Locked Items",
      onlyTrade: "Not Listed Items Only",
      atLeastOne: "At Least One Market",
      locked: "Unlocked",
      total: "Total Items",
      errorMessage: "Try Again Later!",
      showErrorModal: false,
      infoArray: [],
      loading: true,
      countLoading: false,
      isModalOpen: false,
      showInfoModal: false,
      filterArray: [],
      marketHideOptions: [
        { name: "Waxpeer", isChecked: false, id: 3 },
        { name: "MarketCSGO", isChecked: false, id: 4 },
        { name: "Shadowpay", isChecked: false, id: 8 },
        { name: "Skinthunder", isChecked: false, id: 11 },
        { name: "CSFloat", isChecked: false, id: 13 },
      ],
      typeOptions: [],
      exteriorOptions: [],
      rarityOptions: [],
      selectedType: { name: "", isChecked: true },
      selectedExterior: { name: "", isChecked: true },
      selectedRarity: { name: "", isChecked: true },
      selectedHideOption: { name: "", isChecked: true },
      sizeOptions: ["30", "40", "50", "100", "200"],
      selectedSize: "30",
      pageSize: 30,
      items: [],
      sellStack: [],
      groupedStack: [],
      count: 0,
      available: 0,
      value: 0,
      modalShowingTime: 8000,
      isMobile: false,
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        //{ name: "Dota2", icon: dota, appID: 570 },
      ],
      filters: {
        pageSize: 30,
        page: 1,
        appID: 730,
        hideOptions: [],
        type: [],
        exterior: [],
        rarity: [],
      },
      selectedGameIcon: csgo,
      selectedGame: "CS2",
      valueGraph: [],
      selectedInterval: 7,
      isAllSelected: false,
      appID: 730,
    };
  },
  methods: {
    async setHideOption(type) {
      const findType = this.marketHideOptions.find((e) => e.name == type.name);

      if (!findType) return;
      findType.isChecked = !findType.isChecked;

      this.filters.hideOptions = this.marketHideOptions
        .filter((e) => e.isChecked)
        .map((el) => el.id);
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setTypeOption(type) {
      const findType = this.typeOptions.find((e) => e.name == type.name);

      if (!findType) return;
      findType.isChecked = !findType.isChecked;

      this.filters.type = this.typeOptions
        .filter((e) => e.isChecked)
        .map((el) => el.name);
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setExteriorOption(type) {
      const findType = this.exteriorOptions.find((e) => e.name == type.name);

      if (!findType) return;
      findType.isChecked = !findType.isChecked;

      this.filters.exterior = this.exteriorOptions
        .filter((e) => e.isChecked)
        .map((el) => el.name);
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setRarityOption(type) {
      const findType = this.rarityOptions.find((e) => e.name == type.name);

      if (!findType) return;
      findType.isChecked = !findType.isChecked;

      this.filters.rarity = this.rarityOptions
        .filter((e) => e.isChecked)
        .map((el) => el.name);
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.filters.appID = type.appID;
      this.isDropdownOpen = false;
      await this.getInventoryRecords();
    },
    async setSize(size) {
      this.selectedSize = size;
      this.filters.pageSize = parseInt(this.selectedSize);
      this.isDropdownOpen = false;
      this.getInventoryRecords();
    },
    checkMobile() {
      const screenWidth = window.innerWidth;
      const mobileWidthThreshold = 1033;
      this.isMobile = screenWidth < mobileWidthThreshold;
    },
    showErrModal() {
      this.showErrorModal = true;
      setTimeout(() => {
        this.showErrorModal = false;
      }, this.modalShowingTime);
    },
    loadingScreen() {
      this.loading = !this.loading;
    },
    async synchMarketItems() {
      this.loading = true;
      const response = await ListedItems.synchListings({
        appid: this.filters.appID,
      });
      if (!response) return (this.loading = false);
      await this.getStats();
      await this.getInventoryRecords();

      return (this.loading = false);
    },
    async getInventoryRecords() {
      this.loading = true;

      const response = await SteamService.getInventory({
        appid: this.filters.appID,
        page: this.filters.page,
        pageSize: this.filters.pageSize,
        excludeMarkets: this.filters.hideOptions,
        type: this.filters.type,
        exterior: this.filters.exterior,
        rarity: this.filters.rarity,
      });

      if (!response) {
        this.items = [];
        return (this.loading = false);
      }

      this.items = response.items;
      if (!response.count) return (this.loading = false);
      this.loading = false;
    },
    async addItemToStack() {
      this.loading = true;
      try {
        const locals = localStorage.getItem("selectedItems");
        this.sellStack = JSON.parse(locals);
        if (!this.sellStack || this.sellStack.length == 0)
          return (this.loading = false);

        this.groupedStack = await this.groupItemsByName(this.sellStack);

        this.sellStack.forEach((e) => {
          e.sell_price = e.referance_price ? e.referance_price / 1000 : 0;
        });

        this.openModal();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    addAllToStack() {
      this.isAllSelected = !this.isAllSelected;

      if (this.isAllSelected) {
        localStorage.setItem("selectedItems", JSON.stringify(this.getItems));
      } else {
        localStorage.setItem("selectedItems", JSON.stringify([]));
      }

      this.$refs.itemCard.forEach((item) => item.checkActive());
    },
    removeItem(id) {
      let localItems = JSON.parse(localStorage.getItem("selectedItems"));
      const indexToRemove = localItems.findIndex(
        (localItem) => localItem.assetid === id
      );

      if (indexToRemove !== -1) {
        localItems.splice(indexToRemove, 1);
        localStorage.setItem("selectedItems", JSON.stringify(localItems));
        this.addItemToStack();
      }
    },
    async groupItemsByName(items) {
      const groups = {};
      items.forEach((item) => {
        const name = item.name;
        if (!groups[name]) {
          groups[name] = {
            name: name,
            slug: item.item_slug,
            game: item.game,
            quantity: 1,
            sell_price: 0,
            items: [item],
          };
        } else {
          groups[name].quantity += 1;
          groups[name].sell_price = 0;
          groups[name].items.push(item);
        }
      });
      return Object.values(groups);
    },
    closeModal() {
      this.changeScrollStyle();
      this.isModalOpen = false;
    },
    openModal() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "hidden";
      this.isModalOpen = true;
    },
    showMessage(response) {
      if (response === "close") {
        this.infoArray = [];
        return;
      }

      if (!response) return (this.infoArray = []);

      this.infoArray = Object.entries(response).map(([name, value]) => {
        return { name, value };
      });

      this.showSaleInfoModal();
      this.getInventoryRecords();
    },
    async showSaleInfoModal() {
      this.showInfoModal = true;
      setTimeout(() => {
        this.showInfoModal = false;
      }, this.modalShowingTime);
    },
    changeScrollStyle() {
      const htmlElement = document.querySelector("html");
      htmlElement.style.overflowY = "auto";
    },
    async getStats() {
      this.countLoading = true;
      const response = await SteamService.getStats({
        appid: this.filters.appID,
      });

      if (!response) return (this.countLoading = false);

      this.count = response.total_count;
      this.available = response.tradable_count;
      this.countLoading = false;
    },
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.item_slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    mapFilterTags(items) {
      if (!items || items.length == 0) return false;

      try {
        const listMappings = {
          Exterior: "exteriorOptions",
          Type: "typeOptions",
          Rarity: "rarityOptions",
        };

        Object.values(listMappings).forEach((listName) => {
          this[listName] = null;
        });

        items.forEach((e) => {
          if (!e.items || e.items.length === 0) return;
          const listName = listMappings[e.name];
          this[listName] = null;

          if (listName) {
            this[listName] =
              e.items && e.items.length > 0
                ? e.items.map((item) => ({
                    name: item.name,
                    isChecked: false,
                    ...(e.name === "Rarity" || e.name === "Extra"
                      ? { color: item.color }
                      : {}),
                  }))
                : null;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async setupFilterOptions() {
      await this.ensureFilterOptions({ appID: this.appID, game: this.game });
      this.mapFilterTags(this.filterOptions.filters);
      this.setupFilterArray();
    },
    setupFilterArray() {
      this.filterArray = [
        {
          label: "Exclude items listed on",
          mobileLabel: "Exclude Items",
          options: this.marketHideOptions,
          selectedItem: this.selectedHideOption,
          refFunction: this.setHideOption,
          type: "Select Market",
          mobileType: "Market",
          isMobile: true,
        },
        {
          label: "Type",
          mobileLabel: "Type",
          options: this.typeOptions,
          selectedItem: this.selectedType,
          refFunction: this.setTypeOption,
          type: "Select Type",
          mobileType: "Type",
          isMobile: true,
        },
        {
          label: "Exterior",
          mobileLabel: "Exterior",
          options: this.exteriorOptions,
          selectedItem: this.selectedExterior,
          refFunction: this.setExteriorOption,
          type: "Select Exterior",
          mobileType: "Exterior",
          isMobile: true,
        },
        {
          label: "Rarity",
          options: this.rarityOptions,
          selectedItem: this.selectedRarity,
          refFunction: this.setExteriorOption,
          type: "Select Rarity",
          isMobile: false,
        },
      ];
    },
    ...mapActions("filters", ["initializeFilters", "ensureFilterOptions"]),
  },
  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  computed: {
    getItems() {
      return this.items;
    },
    filterOptions() {
      return this.getFilterOptions(this.game);
    },
    ...mapGetters("filters", ["getFilterOptions"]),
  },
  async created() {
    this.changeScrollStyle();
    await Promise.all([this.getStats(), this.getInventoryRecords()]);
    await this.setupFilterOptions();
  },
  watch: {
    "filters.page"() {
      this.getInventoryRecords();
    },
  },
  components: {
    ItemCard,
    MultipleSaleModal,
    MultipleSaleModalMobile,
    SaleInfoModal,
    ErrorModal,
    Grid,
    LineGraph,
    SelectorDropdown,
    IconDropdown,
    ShimmerCard,
    CheckboxDropdown,
  },
};
</script>

<style scoped>
@import "../assets/style/loadingScreen.css";
@import "../assets/style/inventoryGrid.css";

p {
  margin: 0;
}

.wrapper_value {
  gap: 30px;
  flex-wrap: wrap;
}

.main-wrapper {
  display: flex;
  gap: 1rem;
}

.top-section {
  display: flex;
  justify-content: space-between;
}

.filters {
  width: 20%;
  display: flex;
  flex-direction: column;

  gap: 1rem;
  padding: 0rem 0rem;
  max-height: 400px;
}

.content {
  width: 80%;
}

.page_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.danger-text {
  cursor: pointer;
}

.danger-text:hover {
  transition: 0.2s;
  color: #ffc965;
}

span {
  font-size: 1.7vh;
  font-weight: bold;
  color: white;
}

.small {
  font-size: 14px;
}

.value_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.valuation {
  gap: 5px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
}

.custom_button {
  background-color: #313156;
  max-width: 50px;
  height: 35px;
  width: 40px;
  border-radius: 1px;
  padding: 0;
  margin-block: 0;
  border: 1px solid rgb(134, 80, 172);
  display: flex;
  align-items: center;
  justify-content: center;
}

h4,
h6 {
  margin-bottom: 0px;
}

.mobile-filters {
  display: none;
}

@media screen and (min-width: 768px) {
  .custom {
    width: 180px;
    height: 45px;
    border: 1px solid rgb(134, 80, 172);
    background-color: rgb(49, 49, 86);
  }

  .custom:hover {
    background-color: rgb(134, 80, 172);
  }
}

@media screen and (max-width: 768px) {
  .custom {
    width: 100%;
    height: 45px;
    background-color: rgb(49, 49, 86);
  }

  .block-mobile {
    display: none;
  }

  .filters {
    display: none;
  }

  .mobile-filters {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-block: 0.8rem;
  }

  h6 {
    font-size: 12px;
  }

  .content {
    width: 100%;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0.8rem;
  }

  .custom_button {
    background-color: #313156;
    height: 35px;
    width: 50px;
    padding: 0;
    margin-block: 0;
  }

  .mobile-text-div {
    gap: 5px;
  }

  .small {
    font-size: 11px;
  }

  .wrapper_value {
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .danger-text {
    cursor: pointer;
    font-size: 11px;
  }
}

</style>
