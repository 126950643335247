
export async function convertToCSV(data) {

  let header = 'Name, Buy Price, Qty, Market, Price, Profit, Ratio, Mass Profit' + '\n';

  const rows = data.map(item => {
    const baseValues = `${item.name}, ${item.buy_price}, ${item.quantity}`;

    if (item.marketPrices && item.marketPrices.length > 0) {
      const marketPricesValues = item.marketPrices.map(mp => `${mp.market_name},${mp.price},${mp.profit},${mp.ratio},${(mp.profit * item.quantity).toFixed(2)}`).join(',');
      return `${baseValues},${marketPricesValues}`;
    } else {
      return `${baseValues},N/A,N/A,N/A,N/A,N/A`;
    }
  });

  // Tüm satırları birleştirerek CSV oluşturma
  const csvContent = header + rows.join('\n');
  return csvContent;
}

export function parseData(data){
  if(!data || data.length == 0) return false;

  const newArray = [];

  data.forEach(element => {

    const insertElement = {
      name: element.name,
      qty: element.quantity,
      market_name: '',
      buy_price: element.buy_price,
      sell_price: 0,
      profit: 0,
      ratio: 0,
      buy_date: element.buy_date,
    }

    if(element.marketPrices && element.marketPrices.length > 0) {
      insertElement.market_name = element.marketPrices[0].market_name;
      insertElement.profit = element.marketPrices[0].profit;
      insertElement.ratio = element.marketPrices[0].ratio;
      insertElement.sell_price = element.marketPrices[0].price;
    }

    newArray.push(insertElement);

  });
  return newArray;
}

export async function downloadCSV(data, filename) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.style.display = 'none';
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
