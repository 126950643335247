<template>
  <div class="contents">
    <div class="f flex-column item_row">
      <div class="f align-bottom skin w100">
        <div class="f skin_main g10">
          <a
            style="cursor: pointer"
            @click="this.$emit('go-item', item)"
            class="thumb null"
          >
            <img
              :src="item.image"
              class="img-item"
              :alt="item.name"
              loading="lazy"
              :style="{ '--drop-shadow-color': item }"
            />
          </a>
          <div class="name ovh">
            <div class="f sb mb-2">
              <div class="f flex-column">
                <span class="mid-span bold" :style="{ color: typeColor }">{{
                  getType()
                }}</span>
                <span class="name-span" :style="{ color: item.rarity_color }">{{
                  item.short_name
                }}</span>
                <span class="mid-span" :style="{ color: 'white' }">{{
                  item.exterior
                }}</span>
              </div>
              <button
                @click="$emit('copyText', item.name)"
                class="btn_copyname mx-2"
              >
                <VueIcon height="22px" color="white" icon="bx:copy" />
              </button>
            </div>
            <div class="f align-center">
              <VueIcon height="20px" color="white" icon="mdi:steam" />
              <span
                :style="{ color: '#fff' }"
                class="responsive-title bold mx-2"
              >
                $ {{ (item.referance_price / 1000).toFixed(2) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr class="line" :style="{ color: item.rarity_color }" />
      <div class="f sb mt-1 g10">
        <div>
          <template v-if="item.buy_market">
            <div class="f sb">
              <span
                >Bought From:
                {{ item.buy_market == undefined ? "" : item.buy_market }}</span
              >
            </div>
          </template>
          <template v-else>
            <div class="f align-bottom sb">
              <div class="f align-bottom">
                <span>Bought From: </span>
                <span @click="$emit('updateMarket', item)" class="action_btn">
                  <a href="#">Set: {{ selectedMarket }} </a>
                </span>
              </div>
            </div>
          </template>

          <div class="f align-center sb mt-2">
            <span
              >Sold At:
              {{ item.sell_market == undefined ? "" : item.sell_market }}</span
            >
          </div>

          <div class="f align-center mt-3">
            <template v-if="item.editingPrice">
              <div class="f align-bottom">
                <div class="mb-1">
                  <span>Purchase </span>
                  <div class="inner-input-div mt-1">
                    <input
                      v-model="newPrice"
                      type="number"
                      step="0.01"
                      min="0"
                      max="1000"
                      class="input-area-dark"
                    />
                  </div>
                </div>
                <button @click="updatePrice" class="edit-btn">
                  <img class="small-img-button" :src="tickIcon" />
                </button>
              </div>
            </template>
            <template v-else>
              <div class="f sb align-center">
                <span>Purchase </span>
                <span class="m-r-s text-white currency">
                  &nbsp; ${{ item.buy_price / 1000 }}
                </span>
                <button
                  @click="$emit('startEditingPrice', item)"
                  class="edit-btn"
                >
                  <img class="small-img-button" :src="editIcon" />
                </button>
              </div>
            </template>
          </div>
        </div>
        <div class="f flex-column g5">
          <span> {{ DateUtils.getDate(item.sale_date) }}</span>
          <span
            >Sale Price: ${{ ((item.sell_price || 0) / 1000).toFixed(2) }}</span
          >
          <div>
            <span>Profit: </span>
            <span :style="{ color: item.profit_rate > 0 ? '#7CFC00' : 'red' }"
              >${{ item.profit }} ({{ item.profit_rate }}%)</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import copy from "../../assets/images/copy-white.png";
import edit from "../../assets/images/edit.png";
import tick from "../../assets/images/tick-white.png";
import DateUtils from "@/utils/DateUtils";

export default {
  data() {
    return {
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      newPrice: this.item.newPrice,
      typeColor: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    buy: {
      type: String,
      default: "",
    },
    selectedMarket: {
      type: String,
      default: "",
    },
  },
  methods: {
    updatePrice() {
      this.$emit("updatePrice", { ...this.item, newPrice: this.newPrice });
    },
    getType() {
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 966px) {
  .mid-span {
    margin: 0;
    padding: 0;
  }

  span {
    font-size: 13px;
    color: white;
  }

  .item_row {
    background: #21242c;
    border-radius: 0.2rem;
    margin-block: 0.6rem;
    padding: 1rem 0.8rem;
    position: relative;
    transition: 0.3s;
    max-height: 25rem;
  }

  .item_row:hover {
    background-color: #3a3e4a;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
  }

  .img-xsmall {
    width: 20px;
    height: 20px;
  }

  .img-icon {
    height: 18px;
    width: 18px;
  }

  .img-icon:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .action_btn > a {
    margin-top: 0;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-weight: bold;
    width: 100px;
    padding: 2px 2px;
  }

  .edit-btn {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    border: 2px solid;
  }

  .edit-btn {
    background-color: #212121;
    border-radius: 6px;
    border: 1px solid rgb(100, 99, 99);
    height: 34px;
    width: 34px;
    color: white;
  }

  .edit-btn:hover {
    background-color: #626060;
    color: white;
  }

  .small-img-button {
    height: 20px;
    width: 20px;
  }

  .inner-input-div {
    height: 2rem;
    width: 90px;
  }

  .input-area-dark {
    background-color: black;
    color: white;
    font-weight: 600;
    width: 90%;
    min-width: 60px;
  }

  .img-item {
    height: 60px !important;
    width: 90px !important;
    filter: drop-shadow(0px 0px 30px var(--drop-shadow-color));
  }
}

.responsive-title {
  font-size: 13px;
  color: white;
}

.responsive-body {
  font-size: 1.7vh;
  color: white;
}
</style>
