<template>
  <div class="background-small">
    <div class="py-4" style="min-height: 450px; width: 100%">
      <LineGraph
        :options="chartOption"
        @selectInterval="selectInterval"
        :interval="timeInterval"
        :header="'Valuation History'"
        :sub-header="''"
        :chart-data="valueGraph"
      />
    </div>

    <div class="main_wrap mt-4">
      <div class="left_wrap">
        <h4 class="text-white mb-2">Overview</h4>
        <div class="item_overview_wrap">
          <OverviewCard :item="inventoryOverview"></OverviewCard>
        </div>
      </div>
      <div class="right_wrap">
        <h4 v-if="!userLoading" class="text-white mb-2">
          {{ inventoryOverview.user.username }}'s Inventory
        </h4>
        <div class="f g10 my-2">
          <SearchBar
            @handleSearch="handleSearch"
            :holder="'Search item name...'"
          ></SearchBar>
          <button class="update_button" @click="getInventory">Search</button>
        </div>
        <div v-if="loading" class="inventory_grid">
          <div v-for="index in 18" :key="index">
            <ShimmerCard />
          </div>
        </div>
        <div v-else-if="!loading && items.length == 0" class="inventory_list">
          <div class="empty_inventory g5">
            <h5 class="text-white">Not Found.</h5>
            <VueIcon height="30px" color="white" icon="mingcute:sad-line" />
          </div>
        </div>
        <div v-else class="inventory_grid">
          <ItemCard
            v-for="item in items"
            :key="item.assetid"
            :item="item"
            @goItem="goItem"
            @inspect="inpectLink"
          />
        </div>
        <div class="page_row py-3 f align-center">
          <PaginationRow
            :pageNumber="filterObject.pageNumber"
            :totalPages="totalPages"
            @update:pageNumber="filterObject.pageNumber = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import ContainerCard from "@/components/cards/ContainerCard.vue";
import ShimmerCard from "@/components/skeleton/ShimmerCard.vue";
import RankRow from "@/components/ranking/RankRow.vue";
import ItemCard from "@/components/publicInventory/ItemCard.vue";
import InfoCard from "@/components/ranking/InfoCard.vue";
import LatestUserCard from "@/components/ranking/LatestUserCard.vue";
import ShimmerInfoCard from "@/components/skeleton/ShimmerInfoCard.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import OverviewCard from "@/components/Inventory/InventoryOverviewCard.vue";
import LineGraph from "@/components/graphs/LineGraph.vue";

import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import InventoryService from "@/services/InventoryService";
import DateUtils from "@/utils/DateUtils.js";
import steamIcon from "../assets/images/steam-white.png";

export default {
  data() {
    return {
      totalPages: 6,
      graphLoading: true,
      loading: true,
      mostExpensiveLoading: true,
      userLoading: true,
      itemLoading: true,
      latestLoading: true,
      isDropdownOpen: false,
      steamIcon: steamIcon,
      isNewInventory: false,
      filterObject: {
        pageSize: 30,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "date",
        appID: 730,
        search: "",
        steamID: "",
        type: "",
        exterior: "",
      },
      inventoryUpdateTime: "",
      orderOptions: ["Date", "Price", "Name"],
      selectedOrder: "Date",
      selectedDirection: "DESC",
      items: [],
      caseCount: null,
      selectedItem: {},
      mostExpensiveUsers: [],
      stats: [
        {
          title: "Total Value",
          value: "$100,000,000",
          icon: "ph:currency-circle-dollar",
        },
        {
          title: "Average Value",
          value: "",
          icon: "mdi:graph-line",
        },
        { title: "Average Item Count", value: "40", icon: "ph:stack" },
        {
          title: "Unique Inventories",
          value: "",
          icon: "ph:user",
        },
      ],
      inventoryOverview: {
        user: {
          total_value: 0,
          avatar: "",
          username: "",
        },
        values: {
          total_value: 0,
          total_count: 0,
        },
        items: [],
      },
      latestUsers: [],
      steamID: "",
      valueGraph: [],
      selectedInterval: 7,
      timeInterval: [
        { name: "7 D", code: "7_d", value: 7 },
        { name: "14 D", code: "14_d", value: 14 },
        { name: "30 D", code: "30_d", value: 30 },
        { name: "3 M", code: "3_m", value: 90 },
      ],
      chartOption: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "white",
              beginAtZero: true,
              maxRotation: 0,
              minRotation: 0,
              padding: 10,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: { color: "white", beginAtZero: true, padding: 5 },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
              fontSize: 20,
            },
          },
        },
      },
    };
  },
  methods: {
    inpectLink(assetID, inspectlink) {
      if (!inspectlink) return;

      const updatedTemplate = inspectlink
        .replace(/%owner_steamid%/, this.steamID)
        .replace(/%assetid%/, assetID);
      this.goExternalLink(updatedTemplate);
    },
    async getInventoryHistory() {
      this.graphLoading = true;
      const response = await InventoryService.getValuationGraph({
        steamID: this.steamID,
        interval: this.selectedInterval,
      });
      if (!response) return (this.graphLoading = false);

      this.valueGraph = response;
      this.graphLoading = false;
    },
    async selectInterval(time) {
      if (!time) return;
      this.selectedInterval = time;
      await this.getInventoryHistory();
    },
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async getInventory() {
      this.loading = true;
      const response = await InventoryService.getUserInventory({
        search: this.filterObject.search,
        appid: this.filterObject.appID,
        page: this.filterObject.pageNumber,
        steamID: this.steamID,
        pageSize: this.filterObject.pageSize,
        type: this.filterObject.type,
        exterior: this.filterObject.exterior,
      });

      if (!response) return (this.loading = false);
      this.items = response;

      this.loading = false;
    },
    async getUserInfo() {
      this.userLoading = true;
      const response = await InventoryService.getUser({
        steamID: this.steamID,
      });

      if (!response) return;

      this.inventoryOverview.user.avatar = response.image;
      this.inventoryOverview.user.username = response.name;
      this.inventoryOverview.user.profile = response.profile_url;
      this.userLoading = false;
    },
    mapOverview() {
      if (
        !this.inventoryOverview.items ||
        this.inventoryOverview.items.length == 0
      )
        return;

      let totalCount = 0;
      let totalValue = 0;
      for (const item of this.inventoryOverview.items) {
        totalCount += item.count;
        totalValue += item.price;
      }

      this.inventoryOverview.values.total_count = totalCount || 0;
      this.inventoryOverview.values.total_value = parseFloat(
        totalValue || 0
      ).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    async getInventoryStatistics() {
      this.statsLoading = true;

      const response = await InventoryService.getInventoryOverview({
        appid: this.filterObject.appID,
        steamID: this.steamID,
      });

      if (!response) {
        this.inventoryOverview.items = [];
        return (this.statsLoading = false);
      }
      this.inventoryOverview.items = response;
      this.mapOverview();
      this.statsLoading = false;
    },
    handleSearch(search) {
      this.filterObject.search = search;
    },
    getData() {
      Promise.all([
        this.getInventory(),
        this.getUserInfo(),
        this.getInventoryHistory(),
      ]);
    },
    goExternalLink(name) {
      window.open(name, "_blank");
    },
    goItem(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
  },
  async created() {
    try {
      this.steamID = this.$route.params.steamid;
      this.getData();
      await this.getInventoryStatistics();
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  watch: {
    "filterObject.pageNumber"() {
      this.getInventory();
    },
  },
  components: {
    SearchBar,
    ContainerCard,
    ShimmerCard,
    SortDropdown,
    RankRow,
    InfoCard,
    LatestUserCard,
    ShimmerInfoCard,
    ItemCard,
    OverviewCard,
    LineGraph,
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

h3 {
  margin-bottom: 0;
}

.main_wrap {
  display: flex;
  min-height: 70vh;
  gap: 2%;
}

.item_overview_wrap {
  max-width: 400px;
}

.info_div {
  width: 100%;
  gap: 2.5%;
  height: 100px;
  display: flex;
}

.latest_users_div {
  width: 100%; /* Ensure the container takes the full width */ /* Set the gap between flex items */
  display: flex; /* Use flexbox layout */
  min-height: 100px; /* Set a minimum height */
  flex-direction: row; /* Ensure items are aligned in a row */
  flex-wrap: wrap; /* Prevent items from wrapping into columns */
  justify-content: flex-start; /* Align items to the start of the container */
  align-items: center;
}

.empty_inventory {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21242c;
  height: 80px;
  width: 100%;
  border-radius: 4px;
}

.left_wrap {
  width: 25%;
  margin-bottom: 50px;
}

.right_wrap {
  width: 75%;
}

.top_wrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.inventory_grid {
  display: grid;
  grid-template-columns: repeat(6, 135px) !important;
  grid-gap: 10px;
  align-items: stretch;
  width: 100%;
  position: relative;
}

.drop_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 5px;
  align-items: stretch;
}

.latest-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 100%;
  width: auto;
  min-width: 100px;
  padding: 5px;
  border: 1px transparent solid;
  text-align: center;
}

.update_button {
  border: 1px solid rgb(134, 80, 172);
  height: 44px;
  border-radius: 4px;
  width: 80px;
  background-color: rgb(33, 37, 41);
  color: white;
}

.update_button:hover {
  background-color: rgb(147, 88, 189);
  transition: 0.2s;
}

.latest-card:hover {
  box-shadow: 0px 0px 12px 10px;
  transition: 0.3s;
}

.latest-card > img {
  width: 80px;
  height: 70px;
}

.mid-span {
  font-size: 12px;
}

@media screen and (max-width: 1300px) {
  .inventory_grid {
    grid-template-columns: repeat(6, 125px);
  }
}

@media screen and (max-width: 1200px) {
  .main_wrap {
    flex-direction: row;
    gap: 25px;
  }

  .left_wrap {
    width: 25%;
  }
  .right_wrap {
    width: 75%;
  }

  .inventory_grid {
    grid-template-columns: repeat(5, 135px);
  }
}

@media screen and (max-width: 1000px) {
  .main_wrap {
    flex-direction: column;
    gap: 25px;
  }

  .left_wrap,
  .right_wrap {
    width: 100%;
  }

  .inventory_grid {
    grid-template-columns: repeat(5, 155px);
  }
}

@media screen and (max-width: 890px) {
  .inventory_grid {
    grid-template-columns: repeat(4, 180px);
  }
}

@media screen and (max-width: 728px) {
  .main_wrap {
    gap: 20px;
  }

  .latest_users_div {
    width: 100%; /* Ensure the container takes the full width */ /* Set the gap between flex items */
    gap: 10px;
    justify-content: space-between;
  }

  .info_div {
    width: 100%;
    gap: 10px;
    height: auto;
    flex-direction: column;
  }

  .left_wrap {
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }

  .inventory_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    align-items: stretch;
  }

  .sort-select-menu {
    width: 100%;
    transition: 0.5s;
  }

  .mobile-width {
    width: 100%;
  }

  .sort-select-menu {
    width: auto !important;
  }
}

@media screen and (max-width: 576px) {
  .inventory_grid {
    display: grid;
    grid-template-columns: repeat(2, 180px) !important;
    grid-gap: 10px;
    align-items: stretch;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
