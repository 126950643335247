<template>
  <div class="shimmer-container f sb">
    <div class="f sb g20">
      <div class="shimmer-img"></div>
      <div class="f flex-column flex-start justify-center">
        <div class="shimmer-name"></div>
        <div class="shimmer-price"></div>
      </div>
    </div>
    <div class="second">
      <div class="shimmer-mid"></div>
      <div class="shimmer-mid"></div>
    </div>

    <div class="f align-center third">
      <div class="shimmer-mid"></div>
    </div>

    <div class="fourth f flex-column flex-start justify-center">
      <div class="shimmer-mid"></div>
      <div class="shimmer-mid"></div>
    </div>
  </div>
</template>

<style scoped>
.shimmer-container {
  background: #21242c;
  border-radius: 0.3rem;
  padding: 0.6rem 2.4rem;
  position: relative;
  transition: 0.3s;
  max-height: 9rem;
  min-height: 6rem;
  overflow: hidden;
  gap: 10px;
}

.shimmer-img {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 0;
}

.shimmer-name {
  height: 20px;
  width: 200px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

.shimmer-price {
  height: 20px;
  width: 80px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

.shimmer-mid {
  height: 20px;
  width: 140px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

.shimmer-mid-up {
  height: 15px;
  width: 170px;
  border-radius: 4px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

.second {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 745px) {
  .second {
    display: none;
  }

  .fifth {
    display: none;
  }

  .fourth {
    display: none;
  }

  .third {
    display: none;
  }

  .shimmer-img {
    height: 60px;
    width: 60px;
  }

  .shimmer-container {
    padding: 0.6rem 2.4rem;
    position: relative;
    transition: 0.3s;
    max-height: 9rem;
    min-height: 7rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 746px) and (max-width: 1240px) {
  .fifth {
    display: none;
  }

  .fourth {
    display: none;
  }
}

@keyframes shimmer {
  to {
    background-position: 200% 0;
  }
}
</style>
