<template>
  <div class="item_row">
    <div class="inner_column">
      <div class="f sb top_section g10">
        <div class="f g10">
          <img
            style="cursor: pointer"
            @click="goRoute(item.item_detail)"
            
            :src="item.item_detail.image"
            class="currency-icon"
            :alt="item.item_detail.name"
            loading="lazy"
          />
          <div class="sb f g20 align-center">
            <div class="f flex-column flex-start">
              <span class="mid_span" :style="{ color: typeColor }">
                {{ getType() }}</span
              >
              <span
                class="mid_span"
                :style="{ color: item.item_detail.color || 'white' }"
                >{{ item.item_detail.name }}</span
              >
              <span class="small_span">{{ item.item_detail.exterior }}</span>
              <div style="cursor: pointer" class="f g5 align-bottom">
                <img class="img-xsmall mt-1" :src="getMarketIcon('Buff.163')" />
                <span :style="{ color: '#fff' }" class="text-white mx-2">
                  $ {{ (item.price / 1000).toFixed(2) || 0 }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="f g10">
          <button
            @click="copyText(item.item_detail.name)"
            aria-label="Copy"
            class="btn_copyname"
          >
            <VueIcon
              height="22px"
              color="white"
              icon="bx:copyr"
            />
          </button>
          <button
            @click="copyText(item.item_detail.name)"
            aria-label="Copy"
            class="btn_copyname"
          >
            <VueIcon height="20px" width="15px" color="white" icon="bx:bookmark" />
          </button>
        </div>
      </div>
      <hr class="line" :style="{color: item.item_detail.color}"/>
      <div class="price_div" style="width:100%">
        <span
            class="mid_span"
          >
          Price Trends
          </span>
      </div>
      <div class="f sb align-center g8 w100">
        <div class="price_div">
          <span
            class="mid_span"
          >
          7 Days
          </span>
          <span
            class="mid_span"
            :style="{
              color: item.change7D.change >= 0 ? '#3fd986' : 'red',
            }"
          >
            {{ item.change7D.change }}%
          </span>
          <span
            class="mid_span"
            :style="{
              color: item.change7D.change >= 0 ? '#3fd986' : 'red',
            }"
          >
            (${{ item.change7D.value > 0 ? "+" : ""
            }}{{ item.change7D.value }} )
          </span>
        </div>
        <div class="price_div">
          <span
            class="mid_span"
          >
          14 Days
          </span>
          <span
            class="mid_span"
            :style="{ color: item.change14D.change >= 0 ? '#3fd986' : 'red' }"
          >
            {{ item.change14D.change }}%
          </span>
          <span
            class="mid_span"
            :style="{ color: item.change14D.change >= 0 ? '#3fd986' : 'red' }"
          >
            (${{ item.change14D.value > 0 ? "+" : ""
            }}{{ item.change14D.value }} )
          </span>
        </div>
        <div class="price_div">
          <span
            class="mid_span"
          >
          30 Days
          </span>
          <span
            class="mid_span"
            :style="{ color: item.change30D.change >= 0 ? '#3fd986' : 'red' }"
          >
            {{ item.change30D.change }}%
          </span>
          <span
            class="mid_span"
            :style="{ color: item.change30D.change >= 0 ? '#3fd986' : 'red' }"
          >
            (${{ item.change30D.value > 0 ? "+" : ""
            }}{{ item.change30D.value }} )
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MarketOptions from '../../core/constants.js';

export default {
  data() {
    return {
      MarketOptions,
      typeColor: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goRoute(item) {
      this.$emit("goItem", item);
    },
    copyText(item) {
      this.$emit("copyText", item);
    },
    goExternalLink(link) {
      this.$emit("externalLink", link);
    },
    getMarketIcon(name) {
      const found = this.MarketOptions.find((e) => e.name === name);
      if (found) return found.icon;
    },
    getType() {
      if (this.item.item_detail.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.item_detail.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }
      this.typeColor = "white";
      return this.item.item_detail.type;
    },
  },
  computed: {},
};
</script>

<style scoped>
.grid_section {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  background: #21242c;
  border-radius: 0.2rem;
  padding: 0.6rem 0.8rem;
  transition: 0.2s;
  max-height: 9rem;
  min-height: 6rem;
  border: 1px transparent solid;
}

.line {
    border: 1.2px solid;
    width: 100%;
  }

.top_section {
  display: flex;
  align-items: center;
  width: 100%;
}

span {
  font-size: 1.7vh;
  color: white;
}

.item_row {
  background: #21242c;
  border-radius: 0.2rem;
  margin-block: 0.1rem;
  padding: 0.8rem 0.5rem;
  transition: 0.3s;
  max-height: 20rem;
}

.item_row:hover {
  background-color: #2b2c30;
}

.inner_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  gap: 2px;
}

.currency-icon {
  height: 80px;
  width: 100px;
}

.price_div {
  padding: 0.2rem 0.4rem;
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
}

.name_div img {
  width: 40px;
}

.mid_span {
  font-size: 12px;
  font-weight: 400;
}

.bold_body {
  font-size: 12px;
  font-weight: bold;
}

.img-xsmall {
  height: 25px;
  width: 25px;
}
</style>
