<template>
  <div class="item_price_bar">
    <div class="crown">
      <h6 class="yellow">#{{ index + 1 }}</h6>
    </div>
    <div v-if="index + 1 == 1" class="flame">
      <VueIcon icon="mdi:flame" width="34px" heigth="30px" color="#F75904" />
    </div>
    <div class="icon_name">
      <img class="market_icon" :src="item.icon" />
      <h5 style="color: rgb(197, 195, 195)">{{ item.name }}</h5>
    </div>
    <div class="stock_wrap">
      <VueIcon icon="ph:stack-thin" width="18px" heigth="18px" color="white" />
      <span>{{ item.stock || 1 }}+</span>
    </div>
    <div class="f g10 align-center sb">
      <div class="discount_wrap">
        <span :style="{ color: 'white' }"> {{ item.discount }}% </span>
      </div>
      <div class="stock_wrap">
        <h5 style="font-weight: bold">{{ formatCurrency(item.value) }}</h5>
      </div>
      <div class="stock_wrap">
        <button @click="goLink" class="custom_button_buy">
          <VueIcon
            class="icon_animation"
            color="white"
            icon="mdi:cart-outline"
            height="22px"
            width="22px"
          >
          </VueIcon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import cs2 from "../../assets/images/csgo.png";

export default {
  data() {
    return {
      cs2,
      exteriors: ["BS", "WW", "FT", "MW", "FN"],
      exteriorsExtended: [
        { name: "Factory New", value: 10009.2, color: "white", code: "FN" },
        { name: "Minimal Wear", value: 1200, color: "white", code: "MW" },
        { name: "Field Tested", value: 21000, color: "white", code: "FT" },
        { name: "Well Worn", value: 4000, color: "white", code: "WW" },
        { name: "Battle Scarred", value: 12000, color: "white", code: "BS" },
        {
          name: "StatTrak™ Factory New",
          value: 1000,
          color: "#CF6931",
          code: "STFN",
        },
        {
          name: "StatTrak™ Minimal Wear",
          value: 1000,
          color: "#CF6931",
          code: "STMW",
        },
        {
          name: "StatTrak™ Field Tested",
          value: 1000,
          color: "#CF6931",
          code: "STFT",
        },
        {
          name: "StatTrak™ Well Worn",
          value: 14000,
          color: "#CF6931",
          code: "STWW",
        },
        {
          name: "StatTrak™ Battle Scarred",
          value: 11000,
          color: "#CF6931",
          code: "STBS",
        },
      ],
      selectedExterior: null,
    };
  },
  methods: {
    findExterior() {
      this.selectedExterior = this.exteriors.find(
        (e) => e === this.item.exterior_code
      );
    },
    goLink() {
      window.open(this.item.link, "_blank", "noopener,noreferrer");
    },
    formatCurrency(price) {
      if (!price) return "$0";
      return price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
    },
    formatDiscount(price) {
      if (!price) return "0%";
      return price.toLocaleString("en-US", {
        style: "percent",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        minimumSignificantDigits: 2,
        maximumSignificantDigits: 2,
      });
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.findExterior();
  },
};
</script>

<style scoped>
h5,
h6 {
  color: white;
  margin-bottom: 0;
}

h4 {
  color: white;
}

.yellow {
  color: #e0e005;
}

.market_icon {
  width: 30px;
}

.crown {
  position: absolute;
  top: 3px;
  left: 3px;
}

.item_price_bar {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 2fr 1fr 2fr;
  justify-content: space-between;
  width: 100%;
  min-height: 8vh;
  background-color: #313156;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 4px;
  border: 2px solid transparent;
  cursor: pointer;
}

.icon_name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item_price_bar:hover {
  background-color: #2a2a49;
  transition: 0.3s;
  border: 2px solid #5a3d92;
}

.discount_wrap {
  border-radius: 5px;
  background: rgb(128, 0, 128);
  background: linear-gradient(120deg, #0b73cf, #ff0000);
  font-size: 10px;
  font-weight: 300;
  transform: skewX(-14deg);
  width: 60px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discount_wrap span {
  font-size: 10px;
  font-weight: 600;
  transform: skewX(14deg);
}

.stock_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 5px;
}

.stock_wrap span {
  font-weight: bold;
}

.icon_animation:hover {
  padding-left: translateX(4px);
  transition: 0.5s;
}

.custom_button_buy {
  width: 50px;
  background-color: #9C2155;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  padding: 0.3rem 0.4rem;
}

.custom_button_buy:hover {
  transition: 0.4s;
  background: linear-gradient(120deg, #ff0000,#0b73cf );
}

.flame {
  position: absolute;
  top: -3px;
  right: -3px;
}

@media screen and (max-width: 1096px) {
  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.9rem;
  }

  .item_price_bar {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #313156;
    padding: 0.6rem 0.5rem;
    border-radius: 4px;
    min-height: 70px;
    grid-template-columns: 1fr 1fr 2fr;
  }

  .icon_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .custom_button_buy {
    width: 40px;
    height: 35px;
    background-color: #9C2155;
    border: none;
    border-radius: 2px;
    padding: 0.4rem 0.4rem;
  }

  .stock_wrap {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 5px;
  }

  .stock_wrap span {
    font-weight: bold;
    font-size: 12px;
  }

  .flame {
    top: -10px;
    right: -10px;
  }
}
</style>
