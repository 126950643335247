<template>
  <div class="sort-select-menu" @click="toggleDropdown">
    <div
      class="select-btn"
      :style="{ height: heightOption ? heightOption : '44px' }"
    >
      <button @click="setOrderDirFunction" class="dir-btn">
        <img
          class="extra-small-img"
          :src="selectedDir === 'ASC' ? asc : desc"
        />
      </button>
      <span class="sBtn-text">{{ selectedItem }}</span>
      <i
        class="i-arrow"
        :class="{
          'bx bx-chevron-down': !isDropdownOpen,
          'bx bx-chevron-up': isDropdownOpen,
        }"
      ></i>
    </div>
    <ul
      class="options"
      v-show="isDropdownOpen"
      :class="{ active: isDropdownOpen }"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        class="option"
        @click="setOrderByFunction($event, option)"
      >
        <span class="option-text p-3">{{ option }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";

export default {
  props: {
    selectedItem: { type: String },
    selectedDir: { type: String },
    options: { type: Array },
    orderByFunction: { type: Function },
    orderDirFunction: { type: Function },
    heightOption: {
      type: String,
      required: false,
      default: "44px",
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      asc,
      desc,
      selectedIcon: desc,
      isAscOrder: false,
    };
  },
  methods: {
    setOrderDirFunction(event) {
      event.stopPropagation();
      this.isDropdownOpen = false;
      this.isAscOrder = !this.isAscOrder;
      this.orderDirFunction(this.isAscOrder);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    setOrderByFunction(event, option) {
      event.stopPropagation();
      this.isDropdownOpen = false;
      this.orderByFunction(option);
    },
  },
  created() {
    this.selectedIcon = this.selectedOrder === "ASC" ? asc : desc;
  },
};
</script>
<style scoped>
.dir-btn {
  border: none;
  background-color: transparent;
  height: 100%;
  min-width: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.dir-btn:hover {
  border: none;
  background-color: rgb(134, 80, 172);
  transition: 0.5s;
  border-radius: 2px;
}

.extra-small-img {
  width: 25px;
  height: 25px;
}

.sort-select-menu {
  width: 200px;
  transition: 0.5s;
}

.sort-select-menu .select-btn {
  display: flex;
  background: #313156;
  padding: 0px 0px;
  padding-right: 10px;
  border-radius: 1px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 500;
  color: white;
  transition: 0.5s;
  border: 1px #8650ac solid;
}

.select-btn i {
  font-size: 25px;
  transition: 0.3s;
}

.sort-select-menu.active .select-btn i {
  transform: rotate(-180deg);
  transition: 0.3s;
}

.sort-select-menu .options {
  position: absolute;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
  background: #313156;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  border: 1px rgb(134, 80, 172) solid;
}

.sort-select-menu.active .options {
  display: block;
  z-index: 999;
}

.options .option {
  display: flex;
  gap: 10px;
  height: 40px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 2px;
  align-items: center;
  background: #313156;
}

.options .option:hover {
  background: rgb(134, 80, 172);
}

.option img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.option .option-text {
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.i-arrow {
  transition: 2s ease-out;
}

.sBtn-text {
  color: white;
  font-size: 14px;
}

@media screen and (max-width: 576px) {
  .sBtn-text {
    font-size: 12px;
  }
}
</style>
