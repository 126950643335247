<template>
  <div class="background-small visible-block">
    <div class="f sb mobile">
      <div>
        <h4 class="text-white">{{ title }}</h4>
      </div>
      <div class="f g20">
        <div style="width: 180px;">
          <h6 class="text-white">{{ typeTitle }}</h6>
          <SelectorDropdown
            :refFunction="setType"
            :options="typeOptions"
            :selectedItem="selectedType"
          />
        </div>
        
      </div>
    </div>

    <div class="wrapper">
      <div class="tab">
        <SearchBar class="" @handleSearch="handleSearch"></SearchBar>
      </div>

      <div class="table-responsive">
        <table class="table">
          <div v-if="loading" class="loading-screen">
            <DotLoader :loading="loading" :width="'50px'" />
          </div>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td @click="showItemModal(item)">
                <a @click="goItem(item)">
                  <img class="img" :src="item.img" />
                </a>
              </td>
              <td :style="{ color: item.rarity_color }">
                <div class="f g30">
                  <span class="name-span">{{ item.name }}</span>
                  <button
                    @click="copyText(item)"
                    aria-label="Copy"
                    class="btn_copyname"
                  >
                    <VueIcon height="22px" color="white" icon="bx:copy" />
                  </button>
                </div>
              </td>
              <td v-for="(market, index) in item.markets" :key="index">
                <span class="name-span light-green">
                  {{
                    (market.price / 1000).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  }}
                </span>
              </td>
              <td>
                <button @click="removeItem(item)" class="remove-button">
                  <img class="small-img" src="../assets/images/cancel.png" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="page_row pt-2 pb-3 f align-center">
        <PaginationRow
          :pageNumber="pageNumber"
          :totalPages="totalPages"
          @update:pageNumber="pageNumber = $event"
        />
      </div>
    </div>
    <InfoModal
      v-if="showModal"
      :message="copyMessage"
      @close="showModal = false"
    />
  </div>
  <ItemModal v-if="selectedItem" :item="selectedItem" @close="closeItemModal" />
</template>

<script>
import SearchBar from "@/components/shared/SearchBar.vue";
import InfoModal from "../components/modal/InfoModal.vue";
import ItemModal from "../components/modal/ItemModal.vue";
import upIcon from "../assets/images/asc.png";
import downIcon from "../assets/images/desc.png";
import { exportPdf } from "../utils/invoiceGenerator.js";
import WatchlistService from "@/services/WatchlistService.js";
import store from "@/store/store";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";

export default {
  data() {
    return {
      totalPages: 6,
      loading: true,
      orderIcon: downIcon,
      title: "Watchlist",
      typeTitle: "Type",
      volumeTitle: "Sales Volume",
      exportTitle: "Export",
      isDropdownOpen: false,
      selectedItem: null,
      showModal: false,
      copyMessage: "Item deleted successfully",
      feedbackMsg: "Msg",
      searchTimeout: null,
      sortBy: "name",
      column: "",
      rotation: false,
      sortOrder: "ASC",
      volumeOptions: ["5", "10", "15", "20", "25", "30", "40", "50", "100"],
      selectedVolume: "5",
      typeOptions: [
        "All",
        "Rifle",
        "Shotgun",
        "Sniper Rifle",
        "Agent",
        "Gloves",
        "Container",
        "Knife",
        "Pass",
        "Sticker",
        "Collectible",
        "Pistol",
        "Machinegun",
        "SMG",
        "Patch",
      ],
      selectedType: "All",
      items: [],
      appID: 730,
      pageNumber: 1,
      pageSize: 10,
      search: "",
      columns: ["Image", "Name", "Remove", "Buff.163"],
    };
  },
  methods: {
    goItem(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    async removeItem(item) {
      const response = await WatchlistService.deleteWatchlist({
        appid: item.app_id,
        market_hash_name: item.name,
      });

      if (!response) this.copyMessage = "Deletion Error!";
      this.copyMessage = `${item.name} deleted!`;
      await this.getItems();
      this.modalClick();
    },
    modalClick() {
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    async createInvoice() {
      this.toggleLoading();
      await exportPdf(this.items, { name: store.state.steamID ?? "X" });
      this.toggleLoading();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    showItemModal(item) {
      return item;
    },
    closeItemModal() {
      this.selectedItem = null;
    },
    copyText(item) {
      const textToCopy = item.name;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.copyMessage = "Item name coppied.";
          this.showModal = true;
          setTimeout(() => {
            this.showModal = false;
          }, 1500);
        })
        .catch((error) => {
          this.copyMessage = `Error copying text${error}`;
        });
    },
    async handleSearch(search) {
      clearTimeout(this.searchTimeout);
      this.search = search;
      this.searchTimeout = setTimeout(() => {
        this.getItems();
      }, 1000);
    },
    async getItems() {
      this.loading = true;
      const response = await WatchlistService.getWatchlist({
        appid: this.appID,
        currentPage: this.pageNumber,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        volume: parseInt(this.selectedVolume),
        selectedType: this.selectedType,
        search: this.search,
      });
      if (!response) return (this.items = []);
      this.items = response;
      this.mapItems();
      this.loading = false;
    },
    mapItems() {
      if (!this.items || this.items.length === 0) return;

      this.items.forEach((e) => {
        let markets = [{ name: "Buff.163", fee: 2.5, price: 0 }];

        if (e && e.markets) {
          e.markets.forEach((market) => {
            const index = markets.findIndex((el) => el.name === market.name);
            if (index != -1) {
              markets[index].price = market.price;
            }
          });
          e.markets = markets;
        }
      });
    },
    async handleOptionClick(option) {
      this.pageSize = option;
      await this.getItems();
      this.isDropdownOpen = false;
    },
    async sortByColumn(column) {
      this.setSortBy(column);

      if (this.sortBy === this.column) {
        this.rotation = !this.rotation;
      } else {
        this.rotation = false;
      }

      await this.getItems();
    },
    setSortBy(column) {
      this.sortBy = column;
      this.column = column;
      this.setOrder();
    },
    setOrder() {
      this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
      this.orderIcon = this.sortOrder === "DESC" ? downIcon : upIcon;
    },
    async setVolume(volume) {
      this.selectedVolume = volume;
      this.isDropdownOpen = false;
      await this.getItems();
    },
    async setType(type) {
      this.selectedType = type;
      this.isDropdownOpen = false;
      await this.getItems();
    },
  },
  async created() {
    try {
      await this.getItems();
    } catch (err) {
      console.error(err);
    }
  },
  watch: {
    pageNumber() {
      this.getItems();
    },
  },
  components: {
    InfoModal,
    ItemModal,
    SelectorDropdown,
    SearchBar,
  },
};
</script>
<style scoped>
@import "../assets/style/watchList.css";
@import "../assets/style/customTable.css";

.tab {
  justify-content: space-between;
  margin-block: 0.5rem;
  border-radius: 0.3rem;
  padding: 0.5rem;
  border: none;
}

.name-span {
  font-size: 1rem;
}

.table > tbody > tr > td {
  padding-bottom: 1rem;
  padding-left: 1rem;
  position: relative;
  z-index: 2;
}

a .img {
  cursor: pointer;
  width: 80px;
}

@media screen and (max-width: 576px) {
  .name-span {
    font-size: 0.875rem;
  }

  .mobile {
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
