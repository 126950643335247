<template>
    <div :style="{ width: this.width }" class="dot-loader"></div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '40px'
        }
    },
    methods: {},
    components: {},
    created() {

    }
}
</script>
  
<style scoped>
.dot-loader {
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #9e9b9b 90%, #bdb8b800);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}
</style>