<template>
  <div class="f flex-column item_row">
    <div class="inner_column">
      <div class="top_section">
        <div class="currency-div">
          <img
            :src="item.icon"
            class="currency-icon"
            :alt="item.name"
            loading="lazy"
          />
          <span class="bold">
            {{ item.type }}
          </span>
        </div>
        <div class="market-div">
          <img :src="item.marketIcon" :alt="item.name" loading="lazy" />
          <span class="bold">
            {{ item.market }}
          </span>
        </div>
      </div>
      <div class="top_section">
        <span class="bold">
          {{ item.wallet }}
        </span>
        <button @click="$emit('copyText', item.name)" class="btn_copyname mx-2">
          <VueIcon height="22px" color="white" icon="bx:copy" />
        </button>
      </div>
      <div class="top_section">
        <span class="bold">
          Amount:
          <span :style="{ color: '#24d700' }"> {{ item.netAmount }} $</span>
        </span>
        <span :style="{ color: '#FF3838' }" class="bold"> Withdrawal </span>
      </div>
      <div class="top_section">
        <span class="bold">
          {{ DateUtils.getDate(item.created) }}
        </span>
        <div class="f align-center">
          <img
            :src="item.statusIcon"
            style="width: 35px"
            :alt="item.status"
            loading="lazy"
          />
          <div style="width: 10px"></div>
          <span :style="{ color: '#24D700' }" class="bold">
            {{ item.status }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateUtils from "@/utils/DateUtils";

export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1000px) {
  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  span {
    font-size: 1.7vh;
    color: white;
  }

  .item_row {
    background: #21242c;
    border-radius: 0.2rem;
    margin-block: 0.4rem;
    padding: 1rem 0.8rem;
    position: relative;
    transition: 0.3s;
    max-height: 19rem;
  }

  .item_row:hover {
    background-color: #3a3e4a;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
  }

  .inner_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    gap: 15px;
  }

  .name {
    display: flex;
    align-items: center;
  }

  .currency-div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .currency-icon {
    height: 50px;
    width: 50px;
  }

  .market-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .market-div img {
    width: 40px;
  }
}

.responsive-title {
  font-size: 1.9vh;
  color: white;
}

.responsive-body {
  font-size: 1.7vh;
  color: white;
}
</style>
