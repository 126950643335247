<template>
  <div class="background-small">
    <div class="f w100 align-center g20 top mb-2">
      <div>
        <h4 class="text-white">{{ title }}</h4>
      </div>
      <div>
        <span v-if="infoLoading" class="loader"></span>
        <div class="f gap align-bottom flex-end" v-else>
          <span class="title text-white"> Total: {{ count }} </span>
          <span class="title text-white"> On Sale: $ {{ totalSale }} </span>
          <span class="title text-white">
            ROI:
            <span
              class="title"
              :style="{ color: profitInfo > 0 ? '#7CFC00' : 'red' }"
            >
              $ {{ profitInfo }} ({{
                ((profitInfo / totalSale) * 100).toFixed(2)
              }}%)
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="filter-div my-2">
      <div class="filter-left">
        <IconDropdown
          :refFunction="setType"
          :options="gameOptions"
          :selectedItem="selectedGame"
          :selectedIcon="selectedIcon"
        />
      </div>
      <div class="right">
        <SelectorDropdown
          :refFunction="setSize"
          :options="sizeOptions"
          :selectedItem="selectedSize"
        />
        <SortDropdown
          :order-by-function="setOrderBy"
          :options="orderOptions"
          :selectedItem="selectedOrder"
          :selectedIcon="selectedIcon"
          :selected-dir="selectedDirection"
          :order-dir-function="setOrderDir"
          :height-option="'40px'"
        />
        <button class="custom_button" @click="refreshData">
          <VueIcon
            height="25px"
            color="white"
            icon="flowbite:refresh-outline"
            :class="{ 'rotate-360': loading }"
          />
        </button>
      </div>
    </div>
    <div class="filter-div-mobile mb-2">
      <IconDropdown
        :refFunction="setType"
        :options="gameOptions"
        :selectedItem="selectedGame"
        :selectedIcon="selectedIcon"
      />
      <SelectorDropdown
        :refFunction="setSize"
        :options="sizeOptions"
        :selectedItem="selectedSize"
      />
      <SortDropdown
        :order-by-function="setOrderBy"
        :options="orderOptions"
        :selectedItem="selectedOrder"
        :selectedIcon="selectedIcon"
        :selected-dir="selectedDirection"
        :order-dir-function="setOrderDir"
        :height-option="'40px'"
      />
      <button class="custom_button" @click="refreshData">
        <VueIcon
          height="25px"
          color="white"
          icon="flowbite:refresh-outline"
          :class="{ 'rotate-360': loading }"
        />
      </button>
    </div>
    <div class="wrapper">
      <div class="search-tab">
        <SearchBar class="my-2" @handleSearch="handleSearch"></SearchBar>
        <CheckBox
          v-if="!isMobile"
          v-model="filterObject.buyPriceZero.isChecked"
        >
          <span class="responsive-title-font">{{
            filterObject.buyPriceZero.name
          }}</span>
        </CheckBox>
      </div>
      <div>
        <div v-if="itemLoading" class="f flex-column g10">
          <ShimmerEffect v-for="index in 6" :key="index" />
        </div>
        <div v-if="!itemLoading && items && items.length > 0">
          <div class="contents" v-for="(item, index) in items" :key="index">
            <component
              ref="itemContent"
              @go-item="goLink"
              :is="isMobile ? 'TableContentMobile' : 'TableContent'"
              @copy-text="copyText"
              @select-item="selectItem"
              @update-price="updatePrice"
              @start-editing-price="startEditingPrice"
              :item="item"
            />
          </div>
        </div>
        <div
          class="f align-center justify-center g5"
          style="height: 150px"
          v-if="!itemLoading && items && items.length == 0"
        >
          <h5 class="text-white">Items Not Found.</h5>
          <VueIcon height="30px" color="white" icon="mingcute:sad-line" />
        </div>
      </div>
    </div>
    <div class="page_row pt-2 pb-3">
      <PaginationRow
        :pageNumber="this.filterObject.pageNumber"
        :totalPages="totalPages"
        @update:pageNumber="this.filterObject.pageNumber = $event"
      />
    </div>
    <div class="f g20 fe">
      <button
        :disabled="!items || items.length === 0"
        @click="selectAll"
        class="button btn-secondary custom"
      >
        Select All
      </button>
      <button
        :disabled="!selectedItems || selectedItems.length == 0"
        @click="lowerPricesProcess"
        class="button btn-secondary custom"
      >
        Lower Prices
      </button>
    </div>
  </div>
  <InfoModal
    v-if="showModal"
    :message="modalMessage"
    @close="showModal = false"
  />
  <SelectionModal
    :show="optionsShow"
    :title="'Select Pricing Options'"
    @close="optionsShow = false"
    :options="checkboxes"
    :pricingOptions="pricingOptions"
    @confirm="handleConfirm"
    @cancel="optionsShow = false"
    :loading="isUpdateLoading"
  />
</template>
<script>
import InfoModal from "../components/modal/InfoModal.vue";
import SideBar from "@/components/shared/SideBar.vue";
import repeatIcon from "../assets/images/refresh.png";
import ListedItemService from "@/services/ListedItemService";
import TableContent from "@/components/listedItemComponents/TableContent.vue";
import TableContentMobile from "@/components/listedItemComponents/TableContentMobile.vue";
import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";
import IconDropdown from "../components/dropdownButtons/IconDropdown.vue";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import SelectionModal from "@/components/modal/SelectSettingsModal.vue";
import DateUtils from "@/utils/DateUtils.js";
import moment from "moment";
import CheckBox from "@/components/checkbox/CheckBox.vue";

import steamIcon from "../assets/images/steam-white.png";
import csgotmIcon from "../assets/marketIcons/csgotm_icon.png";
import shadowIcon from "../assets/marketIcons/shadowpay_icon.png";
import waxIcon from "../assets/marketIcons/waxpeer_icon.png";
import thunder from "../assets/marketIcons/skinthunder.png";
import copy from "../assets/images/copy-white.png";
import edit from "../assets/images/edit.png";
import tick from "../assets/images/tick-white.png";
import allIcon from "../assets/images/all.png";
import csgo from "../assets/images/csgo.png";
import dota from "../assets/images/dota2.png";
import tf2 from "../assets/images/tf2.png";
import rust from "../assets/images/rust.png";

export default {
  data() {
    return {
      isUpdateLoading: false,
      optionsShow: false,
      isAllSelected: false,
      totalPages: 6,
      isMobile: false,
      title: "Listed Items",
      count: 0,
      totalSale: 0,
      profitInfo: 0,
      ROI: 0,
      modalMessage: "Price Successfully Updated",
      loading: true,
      infoLoading: false,
      itemLoading: true,
      isDropdownOpen: false,
      repeatIcon: repeatIcon,
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      items: [],
      searchTimeout: null,
      showModal: false,
      filterObject: {
        pageSize: 10,
        pageNumber: 1,
        orderDir: "DESC",
        orderBy: "price",
        selectedAppID: 730,
        searchQuery: "",
        buyPriceZero: {
          name: "Show items with 0 buy price",
          isChecked: false,
        },
      },
      info: [],
      currentTime: moment().now,
      gameOptions: [
        { name: "All", icon: allIcon, appID: 730 },
        { name: "CS2", icon: csgo, appID: 730 },
        { name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf2, appID: 440 },
        { name: "Rust", icon: rust, appID: 252490 },
      ],
      selectedIcon: allIcon,
      selectedGame: "All",
      sizeOptions: ["10", "15", "25", "30"],
      selectedSize: "10",
      orderOptions: ["Price", "Date", "Name"],
      selectedOrder: "Price",
      selectedDirection: "DESC",
      appID: 730,
      selectedItems: [],
      checkboxes: [
        { id: 0, name: "MarketCSGO", isChecked: false, icon: csgotmIcon },
        { id: 1, name: "Waxpeer", isChecked: false, icon: waxIcon },
        { id: 2, name: "Shadowpay", isChecked: false, icon: shadowIcon },
        { id: 3, name: "Skinthunder", isChecked: false, icon: thunder },
      ],
      pricingOptions: [
        { id: 0, name: "Set Min. Price", isChecked: true, code: "minimum" },
        { id: 0, name: "Lower by 5%", isChecked: false, code: "-5%" },
        { id: 0, name: "Lower by 10%", isChecked: false, code: "-10%" },
      ],
    };
  },
  methods: {
    async setOrderDir(isAscOrder) {
      this.filterObject.orderDir = this.selectedDirection = isAscOrder
        ? "ASC"
        : "DESC";
    },
    async setOrderBy(option) {
      this.filterObject.orderBy = option.toLowerCase();
      this.selectedOrder = option;
    },
    setType(type) {
      this.selectedGame = type.name;
      this.selectedIcon = type.icon;
      this.filterObject.selectedAppID = type.appID;
      this.isDropdownOpen = false;
    },
    async setSize(size) {
      this.selectedSize = size;
      this.isDropdownOpen = false;
      this.filterObject.pageSize = parseInt(size) ?? 10;
    },
    selectItem(item) {
      if (!item) return false;
      const itemIndex = this.selectedItems.findIndex(
        (e) => e.asset_id == item.asset_id && e.app_id == item.app_id
      );

      if (itemIndex !== -1) {
        item.isChecked = false;
        return this.selectedItems.splice(itemIndex, 1);
      }

      item.isChecked = true;
      this.selectedItems.push(item);
    },
    selectAll() {
      if (!this.items || this.items.length == 0) return;
      this.selectedItems = [];

      if (this.isAllSelected) {
        this.selectedItems = [];
        this.isAllSelected = false;
        this.items.forEach((e) => (e.isChecked = false));
      } else {
        this.isAllSelected = true;
        this.items.forEach((e) => (e.isChecked = true));
        this.selectedItems = this.items;
      }

      this.$refs.itemContent.forEach((item) => item.checkActive());
    },
    async lowerPricesProcess() {
      try {
        if (!this.selectedItems || this.selectedItems.length == 0)
          return (this.optionsShow = false);

        this.optionsShow = true;
      } catch (error) {
        console.error(error);
        this.optionsShow = false;
      }
    },
    async handleConfirm(options) {
      if (
        !options ||
        !options?.priceMarkets ||
        !options?.priceOptions ||
        this.selectedItems?.length == 0
      )
        return (this.optionsShow = false);

      this.isUpdateLoading = true;

      const filteredItems = this.getfilteredItems(this.selectedItems, options);

      const response = await ListedItemService.lowerPrices({
        items: filteredItems,
        options: options,
        app_id: this.filterObject.selectedAppID,
      });

      this.isUpdateLoading = false;
      this.optionsShow = false;

      let message = "";
      if (!response) message = "Error while updating prices ❌";
      else message = "Item prices updated successfully ✔️.";

      this.showInfoModal(message);
      this.refreshData();
    },
    getfilteredItems(items, options) {
      return items.map((item) => {
        return {
          app_id: item.app_id,
          asset_id: item.asset_id,
          general_id: item.general_id,
          item_id: item.item_id,
          buy_price: item.buy_price,
          markets: item.markets
            .filter(
              (market) =>
                options.priceMarkets.includes(market.market_name) &&
                market.is_listed &&
                market.market_price > 0
            )
            .map((market) => ({
              market_name: market.market_name,
              slug: market.slug,
              market_id: market.market_id,
              is_listed: market.is_listed,
              sale_price: market.sale_price,
              market_price: market.market_price,
              market_item_id: market.market_item_id,
            })),
        };
      });
    },
    startEditingPrice(item, marketName, market) {
      if (marketName == "steam") {
        item.buyEditingPrice = true;
        item.buyNewPrice = item.buyPrice / 1000;
        return;
      }

      market.editing_price = true;
      market.newPrice = market.market_price / 1000;
      return;
    },
    async updatePrice(item, newPrice, market) {

      try {

        this.loading = true;
        const success = "Price updated successfully.";
        const failed = "Error while updating price! Please Try again later";

        const itemToList = {
          app_id: item.app_id,
          asset_id: item.asset_id,
          item_id: item.item_id,
          market_unique_id: market?.market_item_id
            ? market.market_item_id
            : null,
          price: newPrice * 1000,
          market_id: market.market_id,
          game: "csgo",
          market: market.slug,
        };

        const response = await ListedItemService.updatePrice({
          listItem: itemToList,
        });

        if (market.slug == "steam" && response) {
          item.buy_price = newPrice * 1000;
          item.buyEditingPrice = false;
        } else if (market.slug == "steam" && !response) {
          item.buyEditingPrice = false;
        } else {
          market.sale_price = response ? newPrice * 1000 : market.market_price;
          market.editing_price = false;
        }

        const modalMessage = response ? success : failed;

        this.showInfoModal(modalMessage);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        return;
      }
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.modalMessage = "Item name coppied!";
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    async showInfoModal(msg) {
      this.showModal = true;
      this.modalMessage = msg || "";
      setTimeout(() => {
        this.showModal = false;
      }, 5000);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async handleSearch(search) {
      this.filterObject.pageNumber = 1;
      this.filterObject.searchQuery = search;
    },
    async refreshData() {
      this.loading = true;
      await Promise.all([this.getInfo(), this.getListedItems()]);
      this.loading = false;
    },
    async getListedItems() {
      this.itemLoading = true;
      const response = await ListedItemService.getAll({
        currentPage: this.filterObject.pageNumber,
        appid: this.appID,
        pageSize: this.filterObject.pageSize,
        searchQuery: this.filterObject.searchQuery,
        dir: this.filterObject.orderDir,
        orderBy: this.filterObject.orderBy,
        buyPriceZero: this.filterObject.buyPriceZero.isChecked ? 1 : 0,
      });

      if (!response) {
        this.itemLoading = false;
        return (this.items = []);
      }

      this.items = response;

      this.items.forEach((e) => {
        e.isChecked = false;
      });

      this.itemLoading = false;
    },
    async getInfo() {
      this.infoLoading = true;
      let info = await ListedItemService.getStatistics();
      if (!info || info.length == 0) return (this.infoLoading = false);
      const stat = info[0];
      this.count = stat.count;
      this.profitInfo = stat.profit;
      this.totalSale = stat.total;
      this.ROI = stat.ratio;
      this.infoLoading = false;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 1000;
    },
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.item_slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
  },
  async created() {
    try {
      this.refreshData();
    } catch (err) {
      console.error(err);
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  watch: {
    filterObject: {
      async handler() {

        if (this.filterTimeout) {
          clearTimeout(this.filterTimeout);
        }

        this.filterTimeout = setTimeout(() => {
          this.getListedItems();
        }, 1000);
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    InfoModal,
    SideBar,
    TableContent,
    TableContentMobile,
    ShimmerEffect,
    IconDropdown,
    SortDropdown,
    SelectorDropdown,
    SearchBar,
    SelectionModal,
    CheckBox,
  },
};
</script>
<style scoped>
@import "../assets/style/customTable.css";

.page_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-white {
  padding: 0;
  margin: 0;
}

.filter-div-mobile {
  display: none;
}

.filter-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gap {
  gap: 20px;
}

.filter-div .filter-left {
  display: flex;
  gap: 10px;
}

.filter-div .right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-icon {
  height: 20px;
  width: 20px;
}

.custom {
  width: 180px;
  height: 45px;
  border: 1px solid rgb(134, 80, 172);
  background-color: rgb(49, 49, 86);
}

.custom:hover {
  background-color: rgb(134, 80, 172);
}

.custom_button {
  width: 60px !important;
  background-color: #313156;
  border: 1px #8650ac solid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 1px;
  height: 40px;
}

.custom_button:hover {
  background-color: #8650ac;
}

.rotate-360 {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.selector-menu {
  width: 120px;
}

.selector-menu .select-btn {
  padding-left: 15px;
  padding-right: 15px;
}

.title {
  font-size: 15px;
}

@media screen and (max-width: 728px) {
  .search {
    width: 90%;
  }

  .gap {
    gap: 10px;
  }

  .filter-div {
    display: none;
  }

  .top {
    justify-content: space-between;
  }

  .title {
    font-size: 11px;
  }

  .icon-select-menu {
    width: auto;
  }

  .icon-select-menu .select-btn {
    padding: 10px 5px;
    color: red;
  }

  .selector-menu {
    width: auto;
  }

  .select-menu .select-btn {
    padding: 5px 5px;
  }

  .sort-select-menu {
    width: auto;
  }

  .custom_button {
    padding: 0;
    height: 40px;
    width: 70px;
    border-radius: 1px;
  }

  .filter-div-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
